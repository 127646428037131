import { Controller } from "@stimulus/core";
import $ from 'jquery'
global.$ = jQuery
import _ from "lodash";

export default class extends Controller {
  static targets = [
    'rowTpl',
    'faqFields',
    'categoryInput'
  ];
  static values = {
    venueformid: String,
    venuefaqsurl: String,
    modalid: String,
    category: String
  };

  initialize() {
    this.rows = new Array();
    this.categoryValue = null;
    this.categoryValueId = null;
    _.templateSettings = {
      evaluate: /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape: /\{\{-(.+?)\}\}/g
    };
  }

  connect() {
    const controller = this;

    $(this.modal).on('shown.bs.modal', function (event) {
      let index_arr = []
      $('.faq-input-hidden').each(function(){
        index_arr.push(parseInt($(this).data('index')))
      })
      
      if (index_arr.length){
        controller.index_now = Math.max(...index_arr) + 1
      }else{
        controller.index_now = 0
      }

      if ($(event.relatedTarget).is(".add-faq") === true) {
        controller.addFieldRow({ index: controller.index_now })
        controller.index_now = controller.index_now + 1
      } else if ($(event.relatedTarget).is(".edit-faq") === true) {
        let category = $(event.relatedTarget).prev('input').val()
        let category_id = $(event.relatedTarget).prev('input').data('category-id')
        controller.categoryValue = category;
        controller.categoryInputTarget.value = category;
        controller.categoryValueId = category_id;
        let total_faqs_category = $('.faqs-hidden_wrapper-' + category_id).length

        for (var n = 0; n < total_faqs_category; n++) {
          var faq_div = $('.faqs-hidden_wrapper-' + category_id)[n]
          controller.addFieldRow({
            index: $(faq_div).data('index'),
            category: category,
            categoryId: category_id,
            question: $(faq_div).find('input[name*="question"]').val(),
            answer: $(faq_div).find('input[name*="answer"]').val(),
            id: $(faq_div).find('input[name*="id"]').val()
          })
        }
      };
    });
    $(this.modal).on('hide.bs.modal', function (event) {
      controller.rows = new Array();
      controller.categoryValue = '';
      controller.categoryInputTarget.value = '';
      controller.categoryValueId = '';
      controller.faqFieldsTarget.innerHTML = '';
    });
  }

  disconnect() { }

  connectCategory(category) {
    this.categoryValue = category;
    this.categoryInputTarget.value = category;
    this.categoryValueId = category.toLowerCase().replace(/ /g,"_");
    this.connect();
  }

  get formController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById(this.venueformidValue), 'users--venues--form');
  }

  get modal() {
    return document.getElementById(this.modalidValue);
  }

  updateFieldRows() {
    const controller = this;
    let html = '';

    $.each(this.rows, function (index, faq) {
      if (faq){
        let tpl = _.template(controller.rowTplTarget.innerHTML);
        html += tpl(faq);
      }
    });

    this.faqFieldsTarget.innerHTML = html;
  }

  addFieldRow(faq) {
    let index = this.rows.length;
    let row = {
      index: faq.index,
      id_field_id: `venue_faqs_attributes_${faq.index}_id`,
      id_field_name: `venue[faqs_attributes][${faq.index}][id]`,
      id_value: faq.id,
      destroy_field_name: `venue[faqs_attributes][${faq.index}][_destroy]`,
      category_label: faq.category,
      category_field_name: `venue[faqs_attributes][${faq.index}][category]`,
      category_field_id: `venue_faqs_attributes_${faq.index}_category`,
      category_value: faq.category,
      category_value_to_id: faq.categoryId,
      question_label: `Question ${index + 1}`,
      question_field_name: `venue[faqs_attributes][${faq.index}][question]`,
      question_field_id: `venue_faqs_attributes_${faq.index}_question`,
      question_value: faq.question,
      answer_label: `Answer for Question ${index + 1}`,
      answer_field_name: `venue[faqs_attributes][${faq.index}][answer]`,
      answer_field_id: `venue_faqs_attributes_${faq.index}_answer`,
      answer_value: faq.answer
    }
    this.rows[index] = row;
    this.updateFieldRows();
  }

  addNewFieldRow(event) {
    this.saveFields();
    this.addFieldRow({ index: this.index_now, category: this.categoryValue, categoryId: this.categoryValueId });
    this.index_now = this.index_now + 1
  }

  saveFields() {
    const controller = this;
    this.categoryValue = this.categoryInputTarget.value;
    $.each(this.rows, function (index, faq) {
      if (faq){
        let prefix = `venue[faqs_attributes][${faq.index}]`;
        let id = `venue_faqs_attributes_${faq.index}`;
        let _id = $(`#faq_category_details input#${id}_id`);
        let que = $(`#faq_category_details input#${id}_question`);
        let ans = $(`#faq_category_details input#${id}_answer`);
        controller.rows[index]['id_field_id'] = `${id}_id`;
        controller.rows[index]['id_field_name'] = `${prefix}[id]`;
        controller.rows[index]['id_value'] = _id.val();
        controller.rows[index]['destroy_field_name'] = `${prefix}[_destroy]`;
        controller.rows[index]['category_field_name'] = `${prefix}[category]`;
        controller.rows[index]['category_field_id'] = `${id}_category`;
        controller.rows[index]['category_value'] = controller.categoryValue;
        controller.rows[index]['category_value_to_id'] = controller.categoryValueId;
        controller.rows[index]['category_label'] = controller.categoryValue;
        controller.rows[index]['category_field_name'] = `${prefix}[category]`;
        controller.rows[index]['category_field_id'] = `${id}_category`;
        controller.rows[index]['question_label'] = `Question ${index + 1}`;
        controller.rows[index]['question_field_name'] = `${prefix}[question]`;
        controller.rows[index]['question_field_id'] = `${id}_question`;
        controller.rows[index]['question_value'] = que.val();
        controller.rows[index]['answer_label'] = `Answer for Question ${index + 1}`;
        controller.rows[index]['answer_field_name'] = `${prefix}[answer]`;
        controller.rows[index]['answer_field_id'] = `${id}_answer`;
        controller.rows[index]['answer_value'] = ans.val();
      }
    });
  }

  saveAndClose() {
    this.saveFields();
    this.formController.updateFaqFields(this.rows, this.categoryValue, this.categoryValueId);
    $(this.modal).modal('hide');
  }
}