import _ from 'lodash'
import Rails from '@rails/ujs'
import 'ion-rangeslider'
import 'ion-rangeslider/css/ion.rangeSlider.min'
import 'daterangepicker'
import DetailsAction from './details_action'
// import SmallActivities from './small_activities'
import SidebarDetails from './sidebar_details'
import {hideModal, insertTmpModal} from '../../../../library/modal'
import axios from 'axios'
import { Device } from 'twilio-client'
import MobileDetect from 'mobile-detect'

export default class Details {
  constructor(base_url) {

    this.base_url = base_url
    $(document).on('show.bs.modal', '#edit_event_info', () => {
      this.priceTypeSelector()
      this.dateRange()
      this.jobTitleSelector()
      this.roomSelector()
      this.packageSelector()
    });

    $(document).on('show.bs.modal', '#organiser_edit_form', () => {
      this.jobTitleSelector()
    });
  }

  jobTitleSelector () {
    $('.select-2-job-title').select2({
      dropdownParent: $("#organiser_edit_form"),
      ajax: {
        url: '/api/v1/jobs/search',
        data: function (params) {
          var query = {
            job: params.term
          }

          // Query parameters will be ?search=[term]&type=public
          return query;
        },
        processResults: function (data) {
          console.log(data)
          return {
            results: $.map(data, function (item) {
              return {
                  text: item.job_title,
                  slug: item.job_title,
                  id: item.job_title
              }
            })
          };
        }
      }
    })
  }

  call() {
    this.getData()
  }

  clickContact (element) {
    let value = element.dataset.value

    if (!value) {
      return
    }

    switch(value) {
      case 'whatsapp':
        window.open(this.whatsappLink)
        break;
      case 'call':
        let md = new MobileDetect(window.navigator.userAgent)
        if (md.mobile() || md.tablet()) {
          window.open(this.phoneLink)
        } else {
          this.callFeature(element)
        }
        break;
      case 'email':
        window.open(this.emailLink)
        break;
    }

    this.communicate(value)
  }

  communicate(type) {
    let url = this.base_url + '/event_actions/create/' + type;
    Rails.ajax({
      type: "POST",
      url: url,
    })
  }

  getEditOrganisationDetails() {
    let url = this.base_url + '/edit?form_type=organiser_edit_form';
    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        insertTmpModal(response.html, this.wrapperDetails, 'organiser_edit_form')
      }
    })
  }

  submitEditOrganisationDetails (element) {
    let url = this.base_url + '?form_type=organiser_edit_form';

    Rails.ajax({
      type: 'PUT',
      url: url,
      data: Rails.serializeElement(element),
      success: (response) => {
        hideModal( document.getElementById('organiser_edit_form'), true ).then(() => {
          this.successResponse(response)
        })
      },
      error: (response) => {
        if(response.error != undefined && response.error != null) {
          toastr.error(response.error)
        }
        insertTmpModal(response.html, this.wrapperDetails, 'organiser_edit_form')
      }
    })
  }

  getEditEventInfo() {
    let klass = this
    let url = this.base_url + '/edit?form_type=edit_event_info';
    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        insertTmpModal(response.html, this.wrapperDetails, 'edit_event_info')
        if (document.getElementById('event_total_amount')) {
          setTimeout(function() {
            //console.log('getEditEventInfo')
            document.getElementById('event_budget_final').addEventListener('keyup', klass.updateTotalOn)
            document.getElementById('event_additional_amount').addEventListener('keyup', klass.updateTotalOn)
            document.getElementById('event_taxes_services_amount').addEventListener('keyup', klass.updateTotalOn)
          }, 400);
        }
      }
    })
  }

  submitEditEventInfo (element) {
    let url = this.base_url + '?form_type=edit_event_info';

    Rails.ajax({
      type: 'PUT',
      url: url,
      data: Rails.serializeElement(element),
      success: (response) => {
        hideModal( document.getElementById('edit_event_info'), true ).then(() => {
          this.successResponse(response)
        })
      },
      error: (response) => {
        // insertTmpModal(response.html, this.wrapperDetails, 'edit_event_info')
        if (response.error) { alert(response.error) }
      },
    })
  }

  getData() {
    let url = this.base_url + '/show_data?data_type=details';
    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successResponse.bind(this)
    })
  }

  getDataAction() {
    let url = this.base_url + '/action_button';
    Rails.ajax({
      type: "GET",
      url: url,
      success: (response) => {
        if (response.html) {
          this.wrapperButtonDetails.innerHTML = response.html
          new DetailsAction(response.action_type)
        }
      }
    })
  }

  successResponse(response) {
    this.data = response
    this.wrapperDetails.innerHTML = this.parseTemplate()
    //this.getDataAction()
    // new SmallActivities(this.base_url).call()
    new SidebarDetails(this.base_url).call()
    //this.loadEOProfileCompleteness();
    $('[data-toggle="tooltip"]').tooltip()
  }

  parseTemplate () {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.templateDetails.innerHTML)

    return compiled(this.data)
  }

  priceTypeSelector () {
    let eventBudgetTypeSelector = document.getElementById('event_budget_type')

    eventBudgetTypeSelector.addEventListener('change', () => {
      document.querySelectorAll('.budget_type_selector input').forEach((element) => {
        element.removeAttribute('required')
      })

      document.querySelectorAll('.budget_type_selector').forEach((element) => {
        element.style.display = 'none';
        element.classList.remove('has-error');
      })

      let target_wrapper = document.getElementById('budget_type_' + eventBudgetTypeSelector.value)
      target_wrapper.style.display = 'block';
      target_wrapper.querySelectorAll('input').forEach((element) => {
        element.setAttribute('required', 'required')
      });
    });

    this.priceSlider()
  }

  roomSelector () {
    let eventPackageIdSelector = document.getElementById('event_package_id')
    let eventRoomIdSelector = document.getElementById('event_room_id')

    if (eventRoomIdSelector) {
      eventRoomIdSelector.addEventListener('change', () => {
        let package_ids = $(eventRoomIdSelector).find(':selected').attr('data-package')
        let package_ids_arr = []
        if (package_ids){
          package_ids_arr = package_ids.split(" ")
        }

        if (eventPackageIdSelector.value){
          let room_id = $(eventPackageIdSelector).find(':selected').attr('data-room')
          if (room_id && (room_id != eventRoomIdSelector.value) ){
            eventPackageIdSelector.value = ''
          }
        }else {
          if (package_ids) {
            $(eventPackageIdSelector).children(":not(:first-child)").attr('disabled', true)
            $.each(package_ids_arr, function( index, value ) {
              $('#eventPackageIdSelector option[value="'+value+'"]').attr("disabled", false)
            })
          }
        }
      })
    }
  }

  packageSelector () {
    let eventPackageIdSelector = document.getElementById('event_package_id')
    let eventRoomIdSelector = document.getElementById('event_room_id')

    if (eventPackageIdSelector) {
      eventPackageIdSelector.addEventListener('change', () => {
        let room_id = $(eventPackageIdSelector).find(':selected').attr('data-room')

        if (room_id) {
          eventRoomIdSelector.value = room_id;
        }
      })
    }
  }

  dateRange() {
    document.querySelectorAll('.date-time-rangepicker').forEach((element) => {
      console.log(element)
      $(element).daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        minDate: new Date(),
        timePickerIncrement: 15,
        cancelButtonClasses: 'hidden',
        locale: {
          format: 'DD/MM/YYYY HH:mm'
        }
      })
    });

    document.querySelectorAll('.date-time-rangepicker-event-info').forEach((element) => {
      console.log(element)
      $(element).daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        //minDate: new Date(),
        timePickerIncrement: 15,
        cancelButtonClasses: 'hidden',
        locale: {
          format: 'DD/MM/YYYY HH:mm'
        },
        parentEl: "#edit_event_info .modal-body"
      })
    });
  }

  priceSlider() {
    let target = document.getElementById('price-slider')
    let currency = target.dataset.currency;
    let min_price = target.dataset.minPrice;
    let max_price =  target.dataset.maxPrice;
    let step_price =  target.dataset.stepPrice;
    let from_input = document.getElementById('event_budget_from');
    let to_input =  document.getElementById('event_budget_to');

    let from = from_input.value || min_price;
    let to = to_input.value || (from * 50);

    from_input.value = from;
    to_input.value = to;

    let options = {
      type: 'double',
      min: 0,
      max: max_price,
      from: from,
      to: to,
      step: step_price,
      grid: true,
      from_min: min_price,
      from_max: max_price,
      prefix: currency,

      onChange: function (event) {
        from_input.value = event.from
        to_input.value = event.to
      }
    }

    $(target).ionRangeSlider(options);
  }

  get whatsappLink() {
    return this.data.contact_whatsapp
  }

  get emailLink() {
    return this.data.contact_email_link
  }

  get phoneLink() {
    return `tel:${this.data.contact_phone}`
  }

  get wrapperDetails() {
    return document.getElementById('wrapper-details')
  }

  get wrapperButtonDetails() {
    return document.getElementById('wrapper-button-details')
  }

  get templateDetails() {
    return document.getElementById('template-details')
  }

  get eventBudgetTypeSelector() {
    return document.getElementById('event_budget_type')
  }

  loadEOProfileCompleteness() {
    const obj = this;
    $(".progress").each(function() {
      var value = $(this).attr('data-value');
      var left = $(this).find('.progress-left .progress-bar');
      var right = $(this).find('.progress-right .progress-bar');

      if (value > 0) {
        if (value <= 50) {
          right.css('transform', 'rotate(' + obj.percentageToDegrees(value) + 'deg)')
        } else {
          right.css('transform', 'rotate(180deg)')
          left.css('transform', 'rotate(' + obj.percentageToDegrees(value - 50) + 'deg)')
        }
      }
    });
  }

  percentageToDegrees(percentage) {
    return percentage / 100 * 360
  }

  updateTotalOn(e) {
    let finalBudget = document.getElementById('event_budget_final').value || '0'
    let additionalAmount = document.getElementById('event_additional_amount').value || '0'
    let taxServices = document.getElementById('event_taxes_services_amount').value || '0'
    let total = parseInt(finalBudget) + parseInt(additionalAmount) + parseInt(taxServices)

    document.getElementById('event_total_amount').value = total

    let previousAmount = document.getElementById('event_total_amount').dataset.previousAmount
    let currentAmount = document.getElementById('event_total_amount').value
    try {
      if(parseFloat(previousAmount) != parseFloat(currentAmount)) {
        $('.price-change-reason-input-edit-event-info').attr('required', true)
        $('.price-change-reason-input-edit-event-info-wrapper').removeClass('hidden')
      } else {
        $('.price-change-reason-input-edit-event-info').removeAttr('required')
        $('.price-change-reason-input-edit-event-info-wrapper').addClass('hidden')
      }
    } catch(e) {
      return false
    }
  }

  callFeature(element) {
    $('#modal_for_venue_number').modal('show')

    let country = element.dataset.venueCountry
    let venue_id = element.dataset.venueId
    let contact_phone = element.dataset.contactPhone
    let controller = this

    axios.post("/"+country+"/venues/"+venue_id+"/generate_twilio_token_to_call").then(function (response) {
      controller.to = response.data.to
      controller.user_id = response.data.user_id

      // Set up the Twilio Client Device with the token
      Device.setup(response.data.token)
    })

    Device.ready(function (_device) {
      if (controller.to != undefined) {
        document.getElementById("proceed-to-call").style.display = "block";
        document.getElementById("hangup-call").style.display = "none";
      }
    })

    Device.error(function (error) {
      console.log("ERROR: " + error.message)
    })

    /* Callback for when a call ends */
    Device.disconnect(function(connection) {
      // Disable the hangup button and enable the call buttons
      document.getElementById("proceed-to-call").style.display = "block";
      document.getElementById("hangup-call").style.display = "none";
      document.getElementById("cancel-call").style.display = "block";
    })

    Device.connect(function (connection) {
      document.getElementById("proceed-to-call").style.display = "none";
      document.getElementById("hangup-call").style.display = "block";
      document.getElementById("cancel-call").style.display = "none";
    })

    document.getElementById("hangup-call").addEventListener("click", function() {
      Device.disconnectAll()
      $('#modal_for_venue_number').modal('hide')
    })

    $(document).on('hidden.bs.modal', '#modal_for_venue_number', function () {
      Device.disconnectAll()
    })

    document.getElementById("proceed-to-call").addEventListener("click", function() {
      /* Call a customer from a support ticket */
      // console.log(controller.to)
      // console.log(contact_phone)
      // console.log(controller.user_id)
      // console.log(venue_id)
      Device.connect({
        'From': controller.to,
        'To': contact_phone,
        'user_id': controller.user_id,
        'venue_id': venue_id
      })
    })
  }
}