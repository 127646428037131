export default function infiniteScroll (content_data, target_data, text_loading) {
  if (document.getElementById(content_data)) {
    window.addEventListener('scroll', function(e) {
      let target = document.getElementById(target_data);
      let documentTreshhold = 500; // in pixel

      if ((window.pageYOffset + window.innerHeight) >= (document.body.scrollHeight - documentTreshhold)) {

        if(!target) {
          return
        }

        if(!target.getAttribute('data-remote')) {
          return
        }

        target.click()
        target.removeAttribute('data-remote');
        target.removeAttribute('href');
        target.querySelector('.text-center').innerHTML = 'Load '+ text_loading +' ...'
      }
    })
  }
}