import { Controller } from "@stimulus/core";

export default class extends Controller {
  static targets = [
    'countryIcon'
  ];
  static values = {
    sgBank: Array,
    myBank: Array,
    idBank: Array,
    phBank: Array,
    hkBank: Array
  }

  connect() {
    // prepopulate
    this.countryIconTarget.classList = 'icon icon--' + $('#venue_bank_account_country').val()
    this.updateBankNameOptions($('#venue_bank_account_country').val())
    if ($('#venue_bank_account_bank_name').data('bank-name')) {
      $('#venue_bank_account_bank_name').val($('#venue_bank_account_bank_name').data('bank-name')).change()
    }
    //
    this.manageCountryChange()
    this.updateThumbnailAfterUpload()
    this.modifyInput()
    this.validateFormFields()
  }

  manageCountryChange() {
    const controller = this;
    $('#venue_bank_account_country').on('change', function(e) {
      controller.countryIconTarget.classList = 'icon icon--' + $(this).val()
      controller.updateBankNameOptions($(this).val())
    });
  }

  countryBankOptions(cc) {
    let options
    switch(cc) {
      case 'sg':
        options = this.sgBankValue
        break;
      case 'my':
        options = this.myBankValue
        break;
      case 'id':
        options = this.idBankValue
        break;
      case 'ph':
        options = this.phBankValue
        break;
      case 'hk':
        options = this.hkBankValue
        break;
      default:
        options = []
    }
    return options
  }

  updateBankNameOptions(cc) {
    $('#venue_bank_account_bank_name option:gt(0)').remove()
    var $el = $('#venue_bank_account_bank_name')
    var newOptions = this.countryBankOptions(cc)
    $.each(newOptions, function(idx, v) {
      $el.append($("<option></option>")
         .attr("value", v).text(v))
    });
  }

  updateThumbnailAfterUpload() {
    // size check, size error, prepare preview
    const controller = this;
    let limit_size = 2;
    let limit = limit_size*1024*1024;
    $('#venue_bank_account_business_document').on('change', function() {
      if (this.files[0] && this.files[0].size < limit) {
        var file = this.files[0];
        var file_url = URL.createObjectURL(file);
        $('#upload-business-document-preview span').removeClass('hidden').text(file.name);
        $('#upload-business-document-preview').attr('href', file_url);
        $('.bank-form-business-document .file-size-error').addClass('hidden').text('');
      } else {
        $('#upload-business-document-preview span').addClass('hidden')
        $('.bank-form-business-document .file-size-error').removeClass('hidden').text('Business document is too large (max is 2 MB)');
      }
    });
  }

  modifyInput() {
    $('#venue_bank_account_account_number').on('keyup', function() {
      $(this).val($(this).val()
        .replace(/[^0-9-]/g, ''));
    });

    $('#venue_bank_account_bank_name').on('keyup', function() {
      $(this).val($(this).val().toUpperCase());
    });
  }

  validateFormFields() {
    $('.js-form-bank-account').on('submit', function(e) {
      e.preventDefault();
    });
    $('.js-form-bank-account button[type=submit]').on('click', function(e) {
      $('.js-required-field').each(function(index, field) {
        if ($(field).val() === '') {
          $(field).addClass('js-error-field');
          $(field).parents('.js-validation-wrapper').children('.js-error-span').removeClass('hidden');
        } else {
          $(field).removeClass('js-error-field');
          $(field).parents('.js-validation-wrapper').children('.js-error-span').addClass('hidden');
        }
      });
      if ($('.js-error-field').length === 0) {
        $('.js-form-bank-account').unbind('submit').submit();
      } else {
        if ($('.js-error-field').get(0).id === 'venue_bank_account_business_document') {
          $('#venue_bank_account_business_document').parents('label').get(0).scrollIntoView({ block: 'center' });
        } else {
          $('.js-error-field').get(0).scrollIntoView({ block: 'center' });
        }
      }
    });
  }
}