import { Controller } from 'stimulus'
import Calendar from 'tui-calendar'
import moment from 'moment'
import axios from '../../../vendor/axios';

import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import bootbox from 'bootbox'
window.bootbox = bootbox

export default class extends Controller {
  static targets = ['calendar', 'renderRange', 'source']

  connect () {
    this.axiosInterceptor()
    window.userCalendarIndex = this
    // default timeZone
    this.timeZone = this.calendarTarget.dataset.defaultTimezone
    this.calendarData = []
    this.initCalendar()
    this.initCalendarModal()
    this.deleted_ids = []
    $('#time-zone-select').select2({
      minimumInputLength: 2,
    });
    $('#time-zone-select').on('change', () => {
      this.timeZone = $('#time-zone-select').val()
      $('#time-zone-select').attr('disabled', 'disabled')
      this.loadData()
    })
  }

  axiosInterceptor() {
    axios.interceptors.request.use((config) => {
      loading(true);
      return config;
    }, (error) => {
      loading(false);
      alert('danger', error);
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      loading(false);
      return response;
    }, (error) => {
      loading(false);
      alert('danger', error);
      return Promise.reject(error);
    });

    function loading(show) {
      if (show) {
        document.querySelector('.overlay').style.display = 'block';
      } else {
        document.querySelector('.overlay').style.display = 'none';
      }
    }
  }

  changeSource (event) {
    let selectedRoom = event.currentTarget

    if (selectedRoom.classList.contains('active')) {
      this.selectedRoom = undefined

      selectedRoom.classList.remove('active')
    } else {
      this.selectedRoom = selectedRoom.dataset.id

      selectedRoom.classList.add('active')
    }

    this.sourceTargets.forEach((element) => {
      //console.log(element != selectedRoom)
      if (element != selectedRoom) {
        element.classList.remove('active')
      }
    });

    this.renderCalendarData()
  }

  initCalendar () {
    this.calendar = new Calendar(this.calendarTarget, {
      defaultView: 'month',
      useCreationPopup: false,
      useDetailPopup: true,
      template: {
        milestone: (model) => {
            return '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + model.bgColor + '">' + model.title + '</span>';
        },
        allday: (schedule) => {
            return this.getTimeTemplate(schedule, true);
        },
        time: (schedule) => {
            return this.getTimeTemplate(schedule, false);
        }
      }
    })

    this.calendar.on({
      'clickSchedule': (e) => {
        let selectSchedule = this.calendarData.filter((x) => { return x.id == e.schedule.id })[0];
        let editSelector = document.querySelector('.tui-full-calendar-section-button button.tui-full-calendar-popup-edit')
        let deleteSelector = document.querySelector('.tui-full-calendar-section-button button.tui-full-calendar-popup-delete')
        let sourceId = document.querySelector('#new-event-option') ? document.querySelector('#new-event-option').dataset.sourceId : ''
        let sourceParam = `source_id=${sourceId}`
        let selectScheduleUrl = selectSchedule.url
        let selectScheduleDeleteUrl = selectSchedule.delete_url
        if (selectSchedule.url) {
          selectScheduleUrl = selectSchedule.url.includes('?') ? `${selectSchedule.url}&${sourceParam}` : `${selectSchedule.url}?${sourceParam}`
        }
        if (selectSchedule.delete_url) {
          selectScheduleDeleteUrl = selectSchedule.delete_url.includes('?') ? `${selectSchedule.delete_url}&${sourceParam}` : `${selectSchedule.delete_url}?${sourceParam}`
        }
        if (!selectSchedule) {
          return
        }

        editSelector.addEventListener('click', () => {
          if (selectSchedule.remote_url) {
            $.ajax({
              url: selectScheduleUrl,
            })
          } else {
            window.location.href = selectScheduleUrl
          }
        })

        if (selectSchedule.delete_url) {
          let link = document.createElement('a')
          link.dataset.remote = true
          link.dataset.method = 'delete'
          link.dataset.confirm = `Are you sure you want to delete event ${selectSchedule.title}?`
          link.setAttribute('href', selectScheduleDeleteUrl)

          deleteSelector.parentNode.insertBefore(link, deleteSelector);
          // move el into wrapper
          link.appendChild(deleteSelector);
        } else {
          deleteSelector.classList.add('hidden')
        }
      },
      'beforeCreateSchedule': (e) => {
        if (this.calendarTarget.dataset.newPersonalCalendarEventUrl) {
          $.ajax({
            url: this.calendarTarget.dataset.newPersonalCalendarEventUrl,
            data: {
              calendar_event: {
                start_date: e.start._date,
                end_date: e.end._date
              }
            }
          })
        }
        if (this.calendarTarget.dataset.newEventOption == 'modal') {
          $('#new-event-option').modal('show')
          let modal = document.querySelector('#new-event-option')
          modal.querySelector('.option-block').setAttribute('href', `${modal.dataset.newEventBreakUrl}?${encodeURIComponent('event_break[start_date]')}=${encodeURIComponent(e.start._date)}&${encodeURIComponent('event_break[end_date]')}=${encodeURIComponent(e.end._date)}&source_id=${modal.dataset.sourceId}`)
          modal.querySelector('.option-schedule').setAttribute('href', `${modal.dataset.newCalendarEventUrl}?${encodeURIComponent('calendar_event[start_date]')}=${encodeURIComponent(e.start._date)}&${encodeURIComponent('calendar_event[end_date]')}=${encodeURIComponent(e.end._date)}&source_id=${modal.dataset.sourceId}`)
        } else {
          if (this.calendarTarget.dataset.newEventBreakUrl) {
            $.ajax({
              url: this.calendarTarget.dataset.newEventBreakUrl,
              data: {
                event_break: {
                  start_date: e.start._date,
                  end_date: e.end._date
                }
              }
            })
          }
        }

        e.guide.clearGuideElement();
      },
      'beforeUpdateSchedule': (e) => {
        let selectSchedule = this.calendarData.filter((x) => { return x.id == e.schedule.id })[0];
        let changes = e.changes;

        if (selectSchedule.remote_url) {
          if (changes && changes.end) {
            let offsetBeenSubtract = 480 - moment().utcOffset()
            let event_break = { end_date: moment(changes.end.getTime()).subtract(offsetBeenSubtract, 'minutes').toString() }
            if (changes.start) {
              event_break["start_date"] = moment(changes.start.getTime()).subtract(offsetBeenSubtract, 'minutes').toString()
            }

            $.ajax({
              url: selectSchedule.update_url,
              method: 'PUT',
              data: {
                event_break: event_break
              }
            })
          }
        } else {
          window.location.href = selectSchedule.url
        }
      }
    });

    this.loadData()
  }

  getTimeTemplate(schedule, isAllDay) {

    let html = [];
    let start = moment(schedule.start.toUTCString());

    if (!isAllDay) {
        html.push('<strong>' + start.format('HH:mm') + '</strong> ');
    }
    if (schedule.isPrivate) {
        html.push('<span class="calendar-font-icon ic-lock-b"></span>');
        html.push(' Private');
    } else {
        if (schedule.isReadOnly) {
            html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
        } else if (schedule.recurrenceRule) {
            html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
        } else if (schedule.attendees.length) {
            html.push('<span class="calendar-font-icon ic-user-b"></span>');
        } else if (schedule.location) {
            html.push('<span class="calendar-font-icon ic-location-b"></span>');
        }
        html.push(' ' + schedule.title);
    }

    return html.join('');
  }

  clickCalendarMenu (event) {
    let action = event.currentTarget.dataset.menu
    let options = this.calendar.getOptions()
    let viewName = ''

    switch (action) {
    case 'toggle-daily':
      viewName = 'day';
      break;
    case 'toggle-weekly':
      viewName = 'week';
      break;
    case 'toggle-monthly':
      options.month.visibleWeeksCount = 0;
      viewName = 'month';
      break;
    default:
      break;
    }

    this.calendar.setOptions(options, true);
    this.calendar.changeView(viewName, true);
    this.loadData()
  }

  clickCalendarNavigation (event) {
    let action = event.currentTarget.dataset.nav

    switch (action) {
    case 'move-prev':
      this.calendar.prev();
      break;
    case 'move-next':
      this.calendar.next();
      break;
    case 'move-today':
      this.calendar.today();
      break;
    default:
      return;
    }

    this.loadData()
  }

  loadData() {
    this.setDropdownCalendarType()
    this.setRenderRangeText()
    this.loadDataCalendar(this.calendar.getDateRangeStart(), this.calendar.getDateRangeEnd())
  }

  setDropdownCalendarType () {
    let calendarTypeName = document.getElementById('calendarTypeName');
    let calendarTypeIcon = document.getElementById('calendarTypeIcon');
    let options = this.calendar.getOptions();
    let type = this.calendar.getViewName();
    let iconClassName;

    if (type === 'day') {
        type = 'Daily';
        iconClassName = 'calendar-icon ic_view_day';
    } else if (type === 'week') {
        type = 'Weekly';
        iconClassName = 'calendar-icon ic_view_week';
    } else if (options.month.visibleWeeksCount === 2) {
        type = '2 weeks';
        iconClassName = 'calendar-icon ic_view_week';
    } else if (options.month.visibleWeeksCount === 3) {
        type = '3 weeks';
        iconClassName = 'calendar-icon ic_view_week';
    } else {
        type = 'Monthly';
        iconClassName = 'calendar-icon ic_view_month';
    }

    calendarTypeName.innerHTML = type;
    calendarTypeIcon.className = iconClassName;
  }

  setRenderRangeText () {
    let options = this.calendar.getOptions();
    let viewName = this.calendar.getViewName();

    let html = [];
    if (viewName === 'day') {
        html.push(this.currentCalendarDate('YYYY.MM.DD'));
    } else if (viewName === 'month' &&
        (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {
        html.push(this.currentCalendarDate('YYYY.MM'));
    } else {
        html.push(moment(this.calendar.getDateRangeStart().getTime()).format('YYYY.MM.DD'));
        html.push(' ~ ');
        html.push(moment(this.calendar.getDateRangeEnd().getTime()).format(' MM.DD'));
    }
    this.renderRangeTarget.innerHTML = html.join('');
  }

  currentCalendarDate(format) {
    let currentDate = moment(
      [
        this.calendar.getDate().getFullYear(),
        this.calendar.getDate().getMonth(),
        this.calendar.getDate().getDate()
      ]
    );

    return currentDate.format(format);
  }

  renderCalendarData() {
    this.calendar.clear()
    this.createSchedules()
  }

  createSchedules () {
    let dataState = this.calendarData.filter((currentValue) => {
      // prevent deleted event to reappear
      if (this.deleted_ids.includes(currentValue.id)) {
        return false
      }
      if (!this.selectedRoom) {
        return true
      } else {
        return currentValue.room_name == this.selectedRoom
      }
    })

    this.calendar.createSchedules(dataState)
    $('#time-zone-select').removeAttr('disabled')
  }

  loadDataCalendar(start, end) {
    axios({
      method: 'GET',
      url: this.calendarTarget.dataset.url,
      params: {
        start: start._date,
        end: end._date,
        timezone: this.timeZone
      },
    }).then((response) => {
      let data = this.neutralCalendarData(response.data);
      this.calendarData = data
      this.renderCalendarData()
    })
  }

  neutralCalendarData(responseData) {
    return responseData.map((currentValue) => {
      return this.calendarValue(currentValue)
    })
  }

  calendarValue (value) {
    value.calendarId = '1'
    value.category = 'time'
    value.bgColor = value['color']
    value.dragBgColor = value['color']
    value.borderColor = value['color']
    value.color = '#ffffff'
    value.isVisible = true

    return value
  }

  addCalendarData(data) {
    let calendarValue = this.calendarValue(data)

    this.calendarData.push(calendarValue)
    this.renderCalendarData();
    $('#calendar-modal, .calendar-modal').modal('hide');
  }

  updateCalendarData(data) {
    this.calendar.updateSchedule(data.id, '1', this.calendarValue(data))
    $('#calendar-modal, .calendar-modal').modal('hide');
  }

  deleteCalendarData(event_id) {
    // collect deleted event id for createSchedules filtering
    this.deleted_ids.push(event_id)
    this.calendar.deleteSchedule(event_id, '1')
  }

  initCalendarModal () {
    $(document).on('hidden.bs.modal', '#calendar-modal, .calendar-modal, #venue-timezone-modal', () => {
      $('#calendar-modal').remove()
      $('.calendar-modal').remove()
      $('.daterangepicker').remove()
      $('#venue-timezone-modal').remove();
    });

    $(document).on('show.bs.modal', '#calendar-modal, .calendar-modal__daterangepicker', () => {
      this.dateRangePicker();
    });
  }

  dateRangePicker () {
    $('.date-time-rangepicker').daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      showDropdowns: true,
      drops: 'up',
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    });
  }

  toggleCalendar(e) {
    const target = e.currentTarget
    target.setAttribute('disabled', 'disabled')
    // axios({
    //   method: 'PUT',
    //   url: e.currentTarget.dataset.url,
    // }).then((response) => {
    //   console.log(response.status)
    //   if (response.status === 200) { target.removeAttribute('disabled') }
    // })
    let csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(e.currentTarget.dataset.url, {
      method: 'PUT',
      headers: {
        "X-CSRF-Token": csrfToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(response => {
      // console.log(response.status)
      if (response.status === 200) { target.removeAttribute('disabled') }
    })
  }

  filterTitle(e) {
    const target = e.currentTarget
    target.setAttribute('disabled', 'disabled')
    // axios({
    //   method: 'PUT',
    //   url: e.currentTarget.dataset.url + `?use_break_filter=${encodeURIComponent(document.querySelector('#filterTitle').value)}`,
    // }).then((response) => {
    //   if (response.status === 200) {
    //     target.removeAttribute('disabled')
    //     bootbox.alert('Filter for block date updated.')
    //   }
    // })
    let csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(e.currentTarget.dataset.url + `?use_break_filter=${encodeURIComponent(document.querySelector('#filterTitle').value)}`, {
      method: 'PUT',
      headers: {
        "X-CSRF-Token": csrfToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(response => {
      console.log(response.status)
      if (response.status === 200) {
        target.removeAttribute('disabled')
        bootbox.alert({ message: 'Filter for block date updated.', centerVertical: true })
      }
    })
  }

  toggleCalendarList(e) {
    const controller = this;
    const allRadio = document.querySelectorAll('input[name="calendarListRadio"]');
    if (allRadio.length == 1) {
      return;
    }
    const target = e.currentTarget
    const wrapper = document.querySelector('.google-calendar-list')
    const checked = document.querySelector('input[name="calendarListRadio"]:checked')
    if ((wrapper.dataset.selected == 'Primary') && (target.dataset.primary == 'Primary')) {
      return;
    }
    if ((wrapper.dataset.selected == checked.value)) {
      return;
    }
    const url = wrapper.dataset.url;

    allRadio.forEach((elm) => {
      elm.setAttribute('disabled', 'disabled');
    });

    let csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(`${url}?calendar_summary=${encodeURIComponent(checked.value)}`, {
      method: 'PUT',
      headers: {
        "X-CSRF-Token": csrfToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(response => response.json())
    .then(data => {
      if (data.message == 'success') {
        wrapper.dataset.selected = checked.value;
        bootbox.alert({ message: `Google Calendar source changed to "${wrapper.dataset.selected}".`, centerVertical: true })
        controller.loadData();
      } else {
        bootbox.alert({ message: `Failed to change Google Calendar source to "${wrapper.dataset.selected}".`, centerVertical: true })
      }
      allRadio.forEach((elm) => {
        elm.removeAttribute('disabled');
      });
    })
  }
}
