import { Controller } from 'stimulus';
import axios from 'axios';
import toastr from 'toastr';
export default class extends Controller {
  static targets = [
    'dataFormWrapper',
    'custPeppolIdInput',
    'companyNameInput',
    'peppolIdInput',
    'taxSchemeSelect',
    'taxRegNumberInput',
    'phoneNumberInput',
    'addressLine1Input',
    'addressLine2Input',
    'addressLine3Input',
    'cityInput',
    'postalCodeInput',
    'stateInput',
    'countrySelect',
    'checkBtn'
  ];
  static values = {
    'countriesproviderurl': String,
    'companyautocompleteurl': String,
    'countryoptions': Array,
    'findcompanyurl': String,
    'requirepeppolinfo': Boolean
  };
  static classes = [
    'requirepeppolinfo',
    'skippeppolinfo'
  ];

  initialize() {}
  connect() {
    const controller = this;
    this.checkBtnTarget.classList.add(this.requirepeppolinfoClass);
    $(this.companyNameInputTarget).autocomplete({
      minLength: 3,
      source: function(request, responseFn) {
        let term = request.term;
        axios.get(controller.companyautocompleteurlValue, {
          params: {
            query: term,
            purpose: 'autocomplete'
          }
        }).then(function(response) {
          if(response.status == '200') {
            responseFn(response.data['suggestions']);
          }
        }).catch(function(error) {
          controller.getFromPeppolDirectory(term, function(dir) {
            if(dir.status == '200') {
              let participants = dir.data['participants'];
              if(participants.length > 0) {
                let suggestions = [];
                participants.forEach(function(participant) {
                  let id = participant.participantIdentifier
                  let business = participant.businessEntityDtos[0];
                  let company = business.multilingualNameDtos[0];
                  let customer = {
                    peppol_id: `${id.participantIdentifierTypeCode}:${id.participantIdentifierValue}`,
                    company_name: company.name,
                    address: {
                      line1: business.geographicalInformation
                    },
                    country: business.countryCode.toLowerCase()
                  };
                  suggestions.push({ label: company.name, value: customer });
                });

                responseFn(suggestions);
              }
            }
          });
        });
      },
      select: function(event, ui) {
        event.preventDefault();

        let selected = ui.item;
        let label = selected.label;
        let value = selected.value;

        controller.companyNameInputTarget.value = label;
        if(typeof value == 'object') {
          controller.fillForm(value);
        } else {
          controller.openCustomerInfo(label);
        }
        controller.requirePeppolUpdateUi();
      }
    });
  }
  disconnect() {}

  toggleDataForm() {
    if(!this.requirepeppolinfoValue) {
      this.openCustomerInfo(this.companyNameInputTarget.value);
      this.requirePeppolUpdateUi();
    } else {
      this.skipPeppolUpdateUi();
    }
  }

  hideDataForm() {
    if(this.dataFormWrapperTarget.classList.contains('hidden')) {
      return true;
    }

    this.dataFormWrapperTarget.classList.add('hidden');
  }

  showDataForm() {
    this.dataFormWrapperTarget.classList.remove('hidden');
  }

  fetchCountries() {
    const controller = this;
    axios.get(controller.countriesproviderurlValue,
              {
                params: { code_format: 'lcase' }
              })
         .then(function(response) {
           const codes = Object.keys(response);
           codes.forEach(function(code) {
            controller.countryoptionsValue.push({ code: code, name: response[code] });
           });
         });
  }

  fillForm(customer) {
    this.showDataForm();
    const address = customer.address;
    this.custPeppolIdInputTarget.value = customer.peppol_id;
    this.peppolIdInputTarget.value = customer.peppol_id;
    this.taxSchemeSelectTarget.value = customer.tax_scheme;
    this.taxRegNumberInputTarget.value = customer.tax_registration_number;
    this.phoneNumberInputTarget.value = customer.phone_number;
    if(address != null) {
      this.addressLine1InputTarget.value = address.line1;
      this.addressLine2InputTarget.value = address.line2;
      this.addressLine3InputTarget.value = address.line3;
    }
    this.cityInputTarget.value = customer.city;
    this.postalCodeInputTarget.value = customer.postal_code;
    this.stateInputTarget.value = customer.state;
    this.countrySelectTarget.value = customer.country;
    this.toggleInputs(true);
  }

  getCustomerInfo(companyName, callbackFn, errorHandler) {
    axios.get(this.findcompanyurlValue,
              {
                params: { company_name: companyName }
              })
         .then(callbackFn)
         .catch(errorHandler);
  }

  customerInfoError(error) {
    toastr.error(error);
    this.showDataForm();
    this.toggleInputs(true);
  }

  toggleInputs(enabled) {
    this.custPeppolIdInputTarget.disabled = !enabled;
    //this.companyNameInputTarget.disabled = !enabled;
    this.peppolIdInputTarget.disabled = !enabled;
    this.taxSchemeSelectTarget.disabled = !enabled;
    this.taxRegNumberInputTarget.disabled = !enabled;
    this.phoneNumberInputTarget.disabled = !enabled;
    this.addressLine1InputTarget.disabled = !enabled;
    this.addressLine2InputTarget.disabled = !enabled;
    this.addressLine3InputTarget.disabled = !enabled;
    this.cityInputTarget.disabled = !enabled;
    this.postalCodeInputTarget.disabled = !enabled;
    this.stateInputTarget.disabled = !enabled;
    this.countrySelectTarget.disabled = !enabled;
  }

  openCustomerInfo(companyName) {
    const controller = this;
    this.getCustomerInfo(companyName,
                         function(response) {
                           if(response.status == '200') {
                            controller.fillForm(response.data);
                           } else {
                             controller.customerInfoError(response.statusText);
                           }
                         },
                         function(error) {
                           controller.customerInfoError(error);
                         });
  }

  requirePeppolUpdateUi() {
    this.requirepeppolinfoValue = true;
    this.checkBtnTarget.innerText = "Skip PEPPOL";
    this.checkBtnTarget.classList.remove(this.requirepeppolinfoClass);
    this.checkBtnTarget.classList.add(this.skippeppolinfoClass);
  }

  skipPeppolUpdateUi() {
    this.requirepeppolinfoValue = false;
    this.checkBtnTarget.innerText = "Check";
    this.checkBtnTarget.classList.add(this.requirepeppolinfoClass);
    this.checkBtnTarget.classList.remove(this.skippeppolinfoClass);
    this.companyNameInputTarget.value = "";
    this.toggleInputs(false);
    this.hideDataForm();
  }

  getFromPeppolDirectory(companyName, responseCallbackFn) {
    axios.get("https://api.peppoldirectory.sg/public/web/search", {
      params: {
        sortBy: 'registered',
        sortDirection: 'DESC',
        limit: 100,
        freeText: companyName
      }
    }).then(function(response) {
      responseCallbackFn(response);
    });
  }
}