import { Controller } from 'stimulus'
import '../../../frontend_application/vendor/js-captcha/longbow.slidercaptcha';

export default class extends Controller {
  initialize() {
    this.captchaValue = null
  }

  connect() {
    const controller = this

    if(document.querySelector('#captchaModal') != undefined) {
      $(document.querySelector('#captchaModal')).on('hide.bs.modal', function() {
        controller.resetCaptcha()
      })
    }
  }

  initCaptcha(successFn, failureFn) {
    this.captchaValue = sliderCaptcha({
      id: "captcha",
      repeatIcon: "fa fa-repeat",
      onSuccess: successFn,
      onFail: failureFn
    });
  }

  requireCaptcha(successFn, failureFn) {
    if(document.querySelector('#captchaModal') != undefined) {
      if(this.captchaValue == null) {
        this.initCaptcha(successFn, failureFn)
      } else {
        this.captchaValue.reset()
      }

      $(document.querySelector('#captchaModal')).modal('show')
    } else {
      return false
    }
  }

  resetCaptcha() {
    if(this.captchaValue == null) { return false }

    this.captchaValue.reset()
  }

  dismiss() {
    if(document.querySelector('#captchaModal') != undefined) {
      $(document.querySelector('#captchaModal')).modal('hide')
    } else {
      return false
    }
  }
}