import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.initEditor()
  }

  isScriptInjected () {
    const scripts = document.querySelectorAll('script');
    let injected = false;
    let controller = this

    scripts.forEach((script) => {
      if (script.src.includes(controller.scriptUrl)) {
        injected = true;
      }
    });

    return injected;
  };

  initEditor() {
    let controller = this
    if (!this.isScriptInjected()) {
      const embedScript = document.createElement('script');
      embedScript.setAttribute('src', controller.scriptUrl);
      embedScript.onload = () => {
        controller.loadEditor();
      };
      document.head.appendChild(embedScript);
    } else {
      controller.loadEditor();
    }
  }

  loadEditor() {
    this.editor = unlayer.createEditor({
      id: 'email-editor',
      displayMode: 'email',
      mergeTags: {
        first_name: {
          name: "First Name",
          value: "{{first_name}}",
          sample: "John"
        },
        last_name: {
          name: "Last Name",
          value: "{{last_name}}",
          sample: "Doe"
        }
      }
    });

    this.editor.registerCallback('selectImage', function (data, done) {
      // Open the modal
      $('#mediaLibrary').modal();

      $('#mediaLibrary .images img').bind('click', function (e) {
        // User has clicked on an image
        // This is where we will call Unlayer's "done" callback function
        // We will pass the image URL so Unlayer knows what image is added
        done({ url: $(e.target).attr('src') });

        // Close the modal
        $('#mediaLibrary').modal('hide');
      });
    });

    // We will unbind any previous image click events when modal opens to avoid duplicate events
    $(document).ready(function () {
      $('#mediaLibrary').on('show.bs.modal', function () {
        $('#mediaLibrary .images img').unbind('click');
      });
    });

  }

  get scriptUrl () {
    return '//editor.unlayer.com/embed.js?2'
  }

  get editorId () {
    document.getElementById('email-editor')
  }
}
