import { Controller } from 'stimulus'
import _ from 'lodash'
import axios from '../../../vendor/axios'
import cropper from '../../../library/cropper'

export default class extends Controller {

  static targets = ['verifiedOtp', 'sendOtpButton', 'sendingOtpText', 'verifyPhone', 'errorUserPhoneCodeVerification', 'timerUserPhoneCodeVerification', 'submitUserPhoneCodeVerification', 'aboutMeText', 'aboutMeCharsCount']

  connect () {
    this.country_search()
    this.job_search()
    this.initCropper()
    this.initAboutMeCounter()
  }

  country_search(){
    $("#user_country").select2({
      tags: true
    })
  }

  job_search(){
    $("#user_job_title").select2({
      ajax: {
        url: '/api/v1/jobs/search',
        data: function (params) {
          var query = {
            job: params.term
          }

          // Query parameters will be ?search=[term]&type=public
          return query;
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                  text: item.job_title,
                  slug: item.job_title,
                  id: item.job_title
              }
            })
          };
        }
      }
    });
  }

  initCropper () {
    let input = document.getElementById('logo-input')
    cropper(input)
  }

  startTimerOtp() {
    let controller = this
    var timer = 60 * 5;
    var minutes, seconds;

    this.timerUserPhoneCodeVerificationTarget.innerHTML = ''

    let interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        controller.timerUserPhoneCodeVerificationTarget.innerHTML = minutes + ":" + seconds;
        if (timer > 0) {
            timer -= 1;
        } else {
          controller.alreadySentOtp = false
          controller.submitUserPhoneCodeVerificationTarget.innerHTML = 'Resend'

          clearInterval(interval);
        }
    }, 1000);
  }

  activateVerifiedButton () {
    this.verifiedOtpTarget.classList.add('hidden')
    this.sendOtpButtonTarget.classList.remove('hidden')
  }

  sendOtpVerification () {
    let controller = this

    if (this.alreadySentOtp) {
      controller.errorUserPhoneCodeVerificationTarget.innerHTML = ''

      axios({
        method: 'post',
        url: this.userPhoneCodeVerification.dataset.url,
        params: {
          code: this.userPhoneCodeVerification.value
        },
      }).then(function (_response) {
        $(controller.verifyPhoneTarget).modal('hide')
        controller.verifiedOtpTarget.classList.remove('hidden')
        controller.sendOtpButtonTarget.classList.add('hidden')
      }).catch(function (error) {
        controller.errorUserPhoneCodeVerificationTarget.innerHTML = error.response.data.status
      })
    } else {
      controller.submitUserPhoneCodeVerificationTarget.classList.add('hidden')
      controller.timerUserPhoneCodeVerificationTarget.innerHTML = 'Resending...'
      this.sendOtp()
    }
  }

  sendOtp() {
    let controller = this

    this.sendOtpButtonTarget.setAttribute('disabled', 'disabled');
    this.sendOtpButtonTarget.innerHTML = 'Sending';


    axios({
      method: 'get',
      url: this.userPhone.dataset.url,
      params: {
        phone: this.userPhone.value
      },
    }).then(function (_response) {
      $(controller.verifyPhoneTarget).modal('show')
      controller.alreadySentOtp = true
      controller.startTimerOtp()
      controller.submitUserPhoneCodeVerificationTarget.classList.remove('hidden')
      controller.submitUserPhoneCodeVerificationTarget.innerHTML = 'Submit'

    }).catch(function (error) {
      $(controller.verifyPhoneTarget).modal('show')
      controller.sendingOtpTextTarget.innerHTML = error.response.data.status
    }).then(function () {
      controller.sendOtpButtonTarget.removeAttribute('disabled');
      controller.sendOtpButtonTarget.innerHTML = 'Verify Number';
    });
  }

  get userPhone () {
    return document.getElementById('user_phone')
  }

  get userPhoneCodeVerification () {
    return document.getElementById('user_phone_code_verification')
  }

  initAboutMeCounter() {
    const controller = this;
    controller.updateAboutMeCounter(controller.countCharsFromText(controller.aboutMeTextTarget.value));
    controller.aboutMeTextTarget.addEventListener('keyup', function(e) {
      let newCount = controller.countCharsFromText(e.target.value);
      controller.updateAboutMeCounter(newCount);
    });
  }

  countCharsFromText(text) {
    return text.toString().replaceAll(/\s/g, '').length;
  }

  updateAboutMeCounter(newCount) {
    this.aboutMeCharsCountTarget.innerHTML = newCount.toString();
  }
}
