import Sortable from 'sortablejs'
import axios from '../vendor/axios';

export default function moveable () {
  if (document.querySelectorAll('.moveable')) {
    document.querySelectorAll('.moveable').forEach(function(element) {
      let target = element;
      let target_item = element.getAttribute('data-target-item');
      let target_cursor = element.getAttribute('data-target-cursor');
      let target_url = element.getAttribute('data-target-url');

      if (target.querySelectorAll(target_cursor).length > 1) {
        new Sortable(target, {
          animation: 150,
          handle: target_cursor,
          draggable: target_item,
          onUpdate: function(event){
            disableTemporary(true, target, target_cursor);

            let urlPut = event.item.getAttribute('data-' + target_url) + '?position=' + event.newIndex

            axios({
              method: 'put',
              url: urlPut
            }).then(function (_response) {
              disableTemporary(false, target, target_cursor)
            }).catch(function (_error) {
              alert('sorting error please try again')
            });
          }
        });
      } else if (target.querySelectorAll(target_cursor).length == 1) {
        let button = target.querySelector(target_cursor)
        let message = button.getAttribute('data-message')

        button.addEventListener('mousedown', function() {
          toastr.error(message)
        });
      }
    });

    function disableTemporary(disable, target, target_cursor) {
      if (disable == undefined) {
        disable = false
      }

      if (disable) {
        target.querySelector(target_cursor).classList.add('disabled');
      } else {
        target.querySelector(target_cursor).classList.remove('disabled');
      }
    }
  }
}