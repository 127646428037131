import { Controller } from 'stimulus'
import 'slick-carousel'

export default class extends Controller {
  static targets = ['submitBtn']

  initialize() {
    this.contactRequestValue = null
    this.captchaValue = null
  }

  connect() {
    // testimoni slider
    $(".testimoni-slider").slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      prevArrow: $(".slider-prev"),
      nextArrow: $(".slider-next"),
      infinite: true,
      dots: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
      ],
    });
    // client slider
    $(".client-slider").slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      prevArrow: $(".slider-client-prev"),
      nextArrow: $(".slider-client-next"),
      infinite: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    });
    // smooth scroll
    $(".btn--contact").on('click', function(event) {

      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 2000, function(){

          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });

    this.preloadCaptcha()
  }

  preloadCaptcha() {
    const controller = this

    $(controller.submitBtnTarget).off('click')
    $(controller.submitBtnTarget).on('click', function (e) {
      e.preventDefault()
      $('#captchaModal').modal('show')
    })

    if(!this.captchaValue != null) {
      this.captchaValue = sliderCaptcha({
        id: "captcha",
        repeatIcon: "fa fa-repeat",
        onSuccess: function() {
          controller.copyCaptchaSession()
          setTimeout(function() { controller.postContactRequest() }, 0)
        },
        onFail: function() {
          controller.captchaValue.reset()
        }
      });
    } else {
      this.captchaValue.reset()
    }
  }

  postContactRequest() {
    const controller = this

    if(this.contactRequestValue != null) {
      this.contactRequestValue.abort()
    }

    this.contactRequestValue = $.ajax({
      method: this.cpForm.getAttribute('method'),
      url: this.cpForm.getAttribute('action'),
      data: $(this.cpForm).serialize(),
      type: 'script'
    })

    this.contactRequestValue
      .done(function(script) {eval(script) })
      .always(function() {
        $('#captchaModal').modal('hide')
        controller.captchaValue.reset()
      })
  }

  copyCaptchaSession() {
    this.sidField.removeAttribute('disabled')
    this.sidField.value = this.cpForm.dataset.session
  }

  get cpForm() { return document.getElementById('contentproductionform') }

  get sidField() { return document.getElementById('captcha_sid') }
}