import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryFlagIcon', 'countryCodeSelect']
  static values = {
    country: String
  }

  initialize() {
    if(this.countryValue == undefined) { this.countryValue = 'sg' }
    this.codes = {
      sg: '+65',
      ph: '+63',
      id: '+62',
      hk: '+852',
      my: '+60'
    }
  }

  connect() {
    this.loadCountryOptions()
    this.updateFlag()
  }

  disconnect() {}

  updateFlag() {
    const controller = this
    let option = this.selectedOption

    this.countryClasses.forEach(function(cls) {
      controller.countryFlagIconTarget.classList.remove(cls)
    })
    this.countryFlagIconTarget.classList.add(option.dataset.countryCode)
  }

  loadCountryOptions () {
    const controller = this
    const select = this.countryCodeSelectTarget

    Object.keys(this.codes).forEach(function(code) {
      let option = document.createElement('option')
      let prefix = controller.codes[code]

      option.setAttribute('value', prefix)
      option.dataset.countryCode = code
      option.innerHTML = prefix

      select.appendChild(option)
    })
  }

  get selectedOption() {
    return this.countryCodeSelectTarget.options[this.countryCodeSelectTarget.selectedIndex]
  }

  get countryClasses() {
    return Object.keys(this.codes)
  }
}