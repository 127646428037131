import { Controller } from 'stimulus'
import 'lightgallery.js/dist/js/lightgallery'
export default class extends Controller {
  static targets = ['gallery']

  initialize() {
    this.isFreePlan = this.element.dataset['isFreePlan'] === 'true'

    for(let galleryTarget of this.galleryTargets) {
      lightGallery(galleryTarget, {
        selector: 'a'
      })
    }
  }

  navigate(event) {
    let location = event.target.dataset['url']
    let filterBy = event.target.value

    window.location.href = `${location}?hidden=${filterBy}`
  }
}
