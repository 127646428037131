import 'select2/dist/js/select2.full';
import axios from 'axios'
import { Controller } from "stimulus"
import bootbox from 'bootbox';
import infiniteScroll from '../../../library/infinite_scroll';

export default class extends Controller {
  connect() {
    infiniteScroll('events_wrapper', 'ipagination', 'Events');
    window.usersEventsMyHostingController = this
    this.assignOperator()
    this.daterangepickerAdded()
  }

  newDeal (event) {
    let value = event.currentTarget.value
    let target_click = document.getElementById('new-deal-option-target')

    if (value) {
      target_click.classList.remove('hidden')
      target_click.setAttribute('href', value)
    } else {
      target_click.classList.add('hidden')
    }
  }

  assignOperator() {
    if (document.querySelectorAll('.assign-operator:not(.select2-hidden-accessible)')) {

      document.querySelectorAll('.assign-operator:not(.select2-hidden-accessible)').forEach(function(element, _index) {
        $(element).select2({
          dropdownAutoWidth: true,
          placeholder: $(element).attr('placeholder'),
          ajax: {
            url: $(element).data('url')
          }
        })
        .on('select2:select', function (e) {
          let user = e.params.data;
          let url_operator = $(element).data('assign-event-operator-url')

          bootbox.confirm("Assign "+user.text+" as the event manager?", function(result){
            if(result) {
              axios({
                method: 'post',
                url: url_operator,
                data: {
                  entity_role: {
                    user_id: user.id
                  }
                }
              })
              .then(function (response) {

                // temporary waiting for ricardo change his mind
                $($(element).data('target-wrapper') + ' .assign-inquiry .badge-assign').remove();

                // showing the name of user
                let wrapper = document.createElement('div');
                wrapper.classList.add('assign-wrapper', "entity-role-" + response.data.id);

                let nameWrapper = document.createElement('span');
                nameWrapper.innerHTML = user.text;

                let removeWrapper = document.createElement('a');
                removeWrapper.classList.add('badge','badge-assign');
                removeWrapper.setAttribute('data-remote', true);
                removeWrapper.setAttribute('data-method', 'delete');
                removeWrapper.setAttribute('data-confirm', "are you sure want to delete "+user.text+" as event manager?");
                removeWrapper.href = url_operator + '/' + response.data.id;
                removeWrapper.innerHTML = nameWrapper.outerHTML + '<i class="fa fa-window-close ml-1"></i>';

                wrapper.innerHTML = removeWrapper.outerHTML
                $($(element).data('target-wrapper') + ' .assign-inquiry').append(wrapper.outerHTML);

                // clear placeholder
                $(e.target).empty()
              })
            } else {
              $(e.target).empty()
            }
          })
        })
      })
    }
  }

  submitFilter () {
    document.getElementById('search_subpage').submit()
  }

  filterEventStatus (event) {
    let value = event.currentTarget.getAttribute('data-value')
    document.getElementById('search_event_status').value = value
    this.submitFilter()
  }

  filterListView (event) {
    let value = event.currentTarget.getAttribute('data-value')
    document.getElementById('search_list_view').value = value
    this.submitFilter()
  }

  filterKeyword (event) {
    if(event.key == 'Enter') {
      this.submitFilter()
    }
  }

  filterOnChange (event) {
    this.submitFilter()
  }

  daterangepickerAdded() {
    let controller = this;

    $('#search_event_date_picker').daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'DD-MM-YYYY',
        separator: ' - ',
        cancelLabel: 'Clear'
      }
    });

    $('#search_event_date_picker').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
      controller.submitFilter();
    });

    $('#search_event_date_picker').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        controller.submitFilter();
    });
  }
}