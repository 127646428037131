//= require summernote

function loadSummernoteEditor () {
  $('.summernote-editor').each(function() {
    $(this).summernote({
      tooltip: false,
      toolbar: [
        ['style', ['highlight','bold', 'italic', 'underline', 'clear']],
        ['para', ['ul', 'ol']],
        ['insert', ['link']]
      ],
      callbacks: {
        onPaste: function(e) {
          // var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
          // e.preventDefault();
          // var div = $('<div>');
          // div.append(bufferText);
          // div.find('iframe, style, script, applet, embed, noframes, noscript').remove();
          // div.find('*').removeAttr('style class id');
          // setTimeout(function () {
          //   document.execCommand('insertHtml', false, div.html());
          // }, 10);
          if (document.queryCommandSupported("insertText")) {
            var text = $(e.currentTarget).html();
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

            setTimeout(function () {
                document.execCommand('insertText', false, bufferText);
            }, 10);
            e.preventDefault();
          } else { //IE
            var text = window.clipboardData.getData("text")
            if (trap) {
                trap = false;
            } else {
                trap = true;
                setTimeout(function () {
                    document.execCommand('paste', false, text);
                }, 10);
                e.preventDefault();
            }
          }
        }
      }
    });
  });
}

// load function in here
$(document).ready(function() {
  loadSummernoteEditor()
});