import { Controller } from 'stimulus'
import 'summernote'
import 'daterangepicker'
import moment from 'moment'
import bootbox from 'bootbox';

export default class extends Controller {
  connect() {
    let triggerInputItem = document.querySelectorAll(".trigger-input-item");
    for (let i = 0; i < triggerInputItem.length; i++) {
      this.addEventTriggerInputItem(triggerInputItem[i])
    }

    let triggerInputTax = document.querySelectorAll(".trigger-input-tax");
    for (let i = 0; i < triggerInputTax.length; i++) {
      this.addEventTriggerInputTax(triggerInputTax[i])
    }

    let triggerTotalPrice = document.querySelectorAll(".total-price");
    for (let i = 0; i < triggerTotalPrice.length; i++) {
      this.addEventTriggerTotalPrice(triggerTotalPrice[i])
    }

    $('#items')
        .on('cocoon:after-insert', (_e, added_task) => {
          let triggerInputItem = added_task.find(".trigger-input-item");

          for (let i = 0; i < triggerInputItem.length; i++) {
            this.addEventTriggerInputItem(triggerInputItem[i])
          };

          this.addEventTriggerTotalPrice(added_task.find('.total-price')[0])
        })
        .on("cocoon:after-remove", () => {
          this.calculateAll();
        });

    $('#taxes')
        .on('cocoon:after-insert', (_e, added_task) => {
          let triggerInputTax = added_task.find(".trigger-input-tax");

          for (let i = 0; i < triggerInputTax.length; i++) {
            this.addEventTriggerInputTax(triggerInputTax[i])
          };

          this.addEventTriggerTotalPrice(added_task.find('.total-price')[0])
        })
        .on("cocoon:after-remove", () => {
          this.calculateAll();
        });

    this.loadSummernoteEditor()
    this.loadDatePicker()

    $('#invoice_save_button').on('click', function(e) {
      e.preventDefault();

      const form = $(this).parents('#form_invoice');
      let peppolCompanyNameField = $('#invoice_peppol_customer_attributes_company_name');
      let peppolIdField = $('#invoice_peppol_customer_attributes_peppol_id');
      if(peppolIdField.length == 0 || peppolCompanyNameField.length == 0) { form.submit(); }

      let peppolIdEmpty = !peppolIdField.is(':disabled') && peppolIdField.val().trim().length == 0;
      if(peppolCompanyNameField.length > 0 &&
         !peppolCompanyNameField.is(':disabled') &&
         peppolIdEmpty) {
        bootbox.confirm({
          message: "Required fields for PEPPOL Invoice are empty. \
                    <strong>This invoice won't be uploaded to PEPPOL</strong>. \
                    Continue anyway?",
          closeButton: false,
          buttons: {
            confirm: { label: 'Yes', className: 'btn-orange' },
            cancel: { label: 'No', className: 'btn-transparent' }
          },
          callback: function(result) {
            if(result) { form.submit(); }
          }
        });
      } else {
        form.submit();
      }
    });
  }

  loadDatePicker () {
    let maxYear = moment().add(1, 'year').year();
    let minYear = moment().subtract(2, 'years').year();

    $('.datepicker').daterangepicker({
      timePicker: false,
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'DD-MM-YYYY'
      },
      minYear: minYear,
      maxYear: maxYear
    });
  }

  loadSummernoteEditor () {
    $('.summernote-editor').each(function() {
      $(this).summernote({
        tooltip: false,
        toolbar: [['style', ['highlight','bold', 'italic', 'underline', 'clear']], ['para', ['ul', 'ol']]],
        callbacks: {
          onPaste: function(e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');

            e.preventDefault();

            var div = $('<div>');
            div.append(bufferText);

            div.find('iframe, style, script, applet, embed, noframes, noscript').remove();
            div.find('*').removeAttr('style class id');

            setTimeout(function () {
              document.execCommand('insertHtml', false, div.html());
            }, 10);
          }
        }
      });
    });
  }

  addEventTriggerInputItem(element) {
    element.addEventListener("change", () => {
      this.changeTotalPriceInputItem(element);
    });
  }

  addEventTriggerInputTax(element) {
    element.addEventListener("change", () => {
      this.changeTotalPriceInputTax(element);
    });
  }

  addEventTriggerTotalPrice(element) {
    element.addEventListener("change", () => {
      this.calculateAll();
    });
  }

  reCalculateTax() {
    let triggerTotalPrice = document.querySelectorAll(".total-price[data-tag='total-price-tax']");
    for (let i = 0; i < triggerTotalPrice.length; i++) {
      this.changeTotalPriceInputTax(triggerTotalPrice[i])
    }
  }

  changeTotalPriceInputItem(element) {
    let wrapper = $(element).parents('.nested-fields')[0];
    let price = wrapper.querySelector(".trigger-input-item[data-tag='price']");
    let quantity = wrapper.querySelector(".trigger-input-item[data-tag='quantity']");
    let totalPrice = wrapper.querySelector(".total-price[data-tag='total-price-item']");

    if (price && price.value && quantity && quantity.value) {
      totalPrice.value = Number((price.value * quantity.value).toFixed(2));
    } else {
      totalPrice.value = null;
    }

    this.reCalculateTax()
    totalPrice.dispatchEvent(new Event("change"));
  }

  changeTotalPriceInputTax(element) {
    let wrapper = $(element).parents('.nested-fields')[0];
    let tax_percentage = wrapper.querySelector(".trigger-input-tax[data-tag='tax-percentage']");
    let totalPrice = wrapper.querySelector(".total-price[data-tag='total-price-tax']");

    if (tax_percentage && tax_percentage.value) {
      totalPrice.value = Number(((tax_percentage.value/100) * this.calculateItemPrices()).toFixed(2))
    } else {
      totalPrice.value = null;
    }

    totalPrice.dispatchEvent(new Event("change"));
  }

  calculateAll () {
    this.totalPrice.innerHTML = this.calculateItemPrices();
    this.totalTax.innerHTML = this.calculateItemTaxes();
    this.sumPrice.innerHTML = this.calculateItemPrices() + this.calculateItemTaxes();

    this.inputTotalPrice.value = this.calculateItemPrices();
    this.inputTotalTax.value = this.calculateItemTaxes();
    this.inputSumPrice.value = this.calculateItemPrices() + this.calculateItemTaxes();
  }

  calculateItemPrices () {
    if (this.ItemPrices) {
      let data = _.sum(_.map(this.ItemPrices, function(element) {
                  if ($(element).is(':visible') && element.value) {
                    return parseFloat(element.value)
                  } else {
                    return 0
                  }
                }));

      return data;
    } else {
      return 0;
    }
  }

  calculateItemTaxes () {
    if (this.taxPrices) {
      let data = _.sum(_.map(this.taxPrices, function(element) {
                  if ($(element).is(':visible') && element.value) {
                    return parseFloat(element.value)
                  } else {
                    return 0
                  }
                }));

      return data;
    } else {
      return 0;
    }
  }

  get ItemPrices () {
    return document.querySelectorAll(".total-price[data-tag='total-price-item']")
  }

  get taxPrices () {
    return document.querySelectorAll(".total-price[data-tag='total-price-tax']")
  }

  get totalPrice () {
    return document.getElementById('total-price');
  }

  get totalTax () {
    return document.getElementById('total-tax');
  }

  get sumPrice () {
    return document.getElementById('sum-total-price');
  }

  get inputTotalPrice () {
    return document.getElementById('invoice_total_price');
  }

  get inputTotalTax () {
    return document.getElementById('invoice_tax_price');
  }

  get inputSumPrice () {
    return document.getElementById('invoice_sum_price');
  }
}