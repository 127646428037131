$(document).ready(function() {
  
  $(".create_loghit").click(function(event) {
    if ( $(this).hasClass('active_log')) {
      var phone_number = $(this).data("loghit-phone");
      url = $(this).data("loghit-url");
      $obj = $(this);
      console.log("klik");

      $.ajax({
          url: url,
          type: 'GET',
          dataType: 'script',
        })
        .done(function() {
        })
        .fail(function() {
        })
        .always(function() {
          $obj.removeClass('active_log')
        });
    }
  });

  $(".click_loghit").click(function(event) {
    url = $(this).data("loghit-url");

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'script',
    });
  });

});