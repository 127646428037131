import { Controller } from "@stimulus/core";
import axios from "axios";
import SmallActivities from "./show/small_activities";

export default class extends Controller {
  static targets = [
    'eventTplOpenBtn',
    'eventTplSelect'
  ];

  static values = {
    templatesurl: String,
    templatedetailurl: String,
    selectedtplid: String,
    type: String,
    eventid: Number,
    eventurl: String
  };

  initialize() {}
  connect() {
    const controller = this;
    $(`#${this.emailModal.getAttribute('id')}`).on('shown.bs.modal', function() {
      controller.communicationType = 'email';
      controller.loadTemplates(function(data) {
        let options = [
          { id: 'default_email', name: 'Default Template' }
        ];
        controller.resetOptions(controller.eventTplSelectTarget, options.concat(data));
      });
    });
    $(`#${this.emailModal.getAttribute('id')}`).on('hide.bs.modal', function() {
      controller.communicationType = null;
      controller.resetOptions(controller.eventTplSelectTarget, []);
    });
    $(`#${this.waModal.getAttribute('id')}`).on('shown.bs.modal', function() {
      controller.communicationType = 'whatsapp';
      controller.loadTemplates(function(data) {
        let options = [
          { id: 'default_whatsapp', name: 'Default Template' }
        ];
        controller.resetOptions(controller.eventTplSelectTarget, options.concat(data));
      });
    });
    $(`#${this.waModal.getAttribute('id')}`).on('hide.bs.modal', function() {
      controller.communicationType = null;
      controller.resetOptions(controller.eventTplSelectTarget, []);
    });
  }
  disconnect() {
    this.resetOptions(this.eventTplSelectTarget, []);
  }

  updateSelectedTpl(event) {
    this.selectedtplidValue = event.currentTarget.value;
  }

  compose(event) {
    const controller = this;
    // let url = controller.templatedetailurlValue.replaceAll(/\-\-ID\-\-/g, controller.selectedtplidValue);
    let url = controller.templatedetailurlValue.replace("--ID--", controller.selectedtplidValue);

    // axios.get(url, {
    //   params: {
    //     event_id: controller.eventidValue,
    //     format: 'text'
    //   }
    // }).then(function(response) {
    //   if(response.status == '200') {
    //     //controller.logEventAction();
    //     // var a = document.createElement('a');
    //     // a.setAttribute('style', 'display: none;');
    //     // a.href = response.data;
    //     // a.setAttribute('target', '_blank');
    //     // a.click()
    //     // document.body.removeChild(a);
    //   }
    // });


    // Safari Fix
    $.ajax({
      type : 'GET',
      url : url,
      data: {
        event_id: controller.eventidValue,
        format: 'text'
      },
      async: false,
      success: function(response) {
        controller.composeSuccess = true
        controller.composeUrl = response
      }
    })

    if (controller.composeSuccess) {
      controller.logEventAction();
      window.open(controller.composeUrl)
    }
  }

  loadTemplates(callbackFn) {
    const controller = this;
    axios.get(this.templatesurlValue, {
      params: {}
    }).then(function(response) {
      if(response.status == '200') {
        callbackFn(response.data);
      }
    });
  }

  get emailModal() {
    return document.getElementById('event_show_email_templates_modal');
  }

  get waModal() {
    return document.getElementById('event_show_wa_templates_modal');
  }

  resetOptions(select, data) {
    let selected = null;
    select.value = selected;
    select.querySelectorAll('option').forEach(function(opt) { opt.remove(); });
    data.forEach(function(datum, index, arr) {
      let opt = document.createElement('option');
      opt.setAttribute('value', datum.id);
      opt.innerText = datum.name;
      opt.selected = index == 0;
      if(opt.selected) { selected = opt.value; }

      select.appendChild(opt);
    });
    select.value = selected;
    this.selectedtplidValue = selected;
  }

  logEventAction() {
    const controller = this;
    axios.post(`${controller.eventurlValue}/event_actions/create/${controller.communicationType}.js`, 
    {
      params: {
        communication_type: controller.communicationType
      }
    },
    {
      headers: {
        'Accept': 'application/javascript'
      }
    })
    .then(function(_response) {
      //new SmallActivities(controller.eventurlValue).call();
    }); 
  }
};