import _ from 'lodash'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class Analytics {
  toggle_confirmed_state = 'point';
  toggle_inquiries_state = 'point';

  constructor(data) {
    this.data = data
  }

  call() {
    this.templateWrapper.innerHTML = this.parseTemplate()
    this.buildChart()
    this.bindingToggleConfirmed()
    this.bindingToggleInquiries()
    this.clickOnChartGraphTab()
    this.bindingChangeLostIn()
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate: /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape: /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  bindingChangeLostIn() {
    let lostInNumber = document.getElementById('lost-in-number')
    let lostInTotal = document.getElementById('lost-in-total')

    document.getElementById('change-lost-in').addEventListener('click', function (e) {
      e.preventDefault();

      if (lostInNumber.classList.contains('hidden')) {
        lostInNumber.classList.remove('hidden')
        lostInTotal.classList.add('hidden')
      } else {
        lostInNumber.classList.add('hidden')
        lostInTotal.classList.remove('hidden')
      }
    });
  }

  bindingToggleConfirmed() {
    let thisClass = this;
    document.getElementById('toggle-confirmed').addEventListener('click', function () {
      if (thisClass.toggle_confirmed_state == 'point') {
        thisClass.toggle_confirmed_state = 'money'
      } else {
        thisClass.toggle_confirmed_state = 'point'
      }

      thisClass.buildChartConfirmed()
    });
  }

  bindingToggleInquiries() {
    let thisClass = this;
    document.getElementById('toggle-inquiries').addEventListener('click', function () {
      if (thisClass.toggle_inquiries_state == 'point') {
        thisClass.toggle_inquiries_state = 'money'
      } else {
        thisClass.toggle_inquiries_state = 'point'
      }

      thisClass.buildChartInquiries()
    });
  }

  buildChart() {
    this.buildChartConfirmed()
    this.buildChartViews()
    this.buildChartConversion()
    this.buildChartResponse()
    this.buildChartInquiries()
    this.buildChartGraphTotal()
    this.buildChartEventInterest()
    this.buildChartSpendingRange()
  }

  clickOnChartGraphTab() {
    let thisClass = this;
    document.querySelectorAll('.chart-graph-button').forEach(function (element) {
      element.addEventListener('click', function () {
        element.classList.toggle('active')

        let data = [];
        document.querySelectorAll('.chart-graph-button').forEach(function (el) {
          if (!el.classList.contains('active')) {
            data.push(el.getAttribute('data-value'))
          }
        });

        thisClass.buildChartGraphTotal(data)
      });
    })
  }

  buildChartConfirmed() {
    let ctx = document.getElementById('chartConfirmed').getContext('2d');
    let header;
    let labels;
    let data;

    if (this.toggle_confirmed_state == 'point') {
      header = this.totalConfirmed
      labels = _.keys(this.data.confirmed.data)
      data = _.values(this.data.confirmed.data)
    } else {
      header = this.totalConfirmedMoney
      labels = _.keys(this.data.confirmed_money.data)
      data = _.values(this.data.confirmed_money.data)
    }

    let dataset = {
      labels: labels,
      datasets: [{
        label: 'Confirmed Events',
        data: data,
        borderColor: "#004383",
        fill: false
      }]
    }

    document.getElementById('total_confirmed').innerHTML = header

    this.buildChartGraph(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartInquiries() {
    let ctx = document.getElementById('chartInquiries').getContext('2d');
    let header;
    let labels;
    let data;

    if (this.toggle_inquiries_state == 'point') {
      header = this.totalInquiries
      labels = _.keys(this.data.total_inquiries.data)
      data = _.values(this.data.total_inquiries.data)
    } else {
      header = this.totalInquiriesMoney
      labels = _.keys(this.data.total_inquiries_money.data)
      data = _.values(this.data.total_inquiries_money.data)
    }

    let dataset = {
      labels: labels,
      datasets: [{
        label: 'Enquiries',
        data: data,
        borderColor: "#33BC72",
        fill: false
      }]
    }

    document.getElementById('total_inquiries').innerHTML = header

    this.buildChartGraph(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartViews() {
    let ctx = document.getElementById('chartViews').getContext('2d');

    let dataset = {
      labels: _.keys(this.data.views.data),
      datasets: [{
        label: 'Total Views',
        data: _.values(this.data.views.data),
        borderColor: "#BCE0FD",
        fill: false
      }]
    }

    this.buildChartGraph(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartConversion() {
    let ctx = document.getElementById('chartConversion').getContext('2d');
    let dataset = {
      labels: _.keys(this.data.conversion.data),
      datasets: [{
        label: 'Conversion',
        data: _.values(this.data.conversion.data),
        borderColor: "#831BFF",
        fill: false
      }]
    }
    this.buildChartGraph(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartResponse() {
    let ctx = document.getElementById('chartResponse').getContext('2d');
    let dataset = {
      labels: _.keys(this.data.response.data),
      datasets: [{
        label: 'Response Time',
        data: _.values(this.data.response.data),
        borderColor: "#F6B021",
        fill: false,
        legend: false
      }]
    }
    this.buildChartGraph(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartGraphTotal(hideTab = []) {
    let ctx = document.getElementById('chartGraph').getContext('2d');
    let dataset = {
      labels: _.keys(this.data.views.data),
      datasets: [{
        label: 'Views',
        data: _.values(this.data.views.data),
        borderColor: "#31B4E6",
        fill: false,
        hidden: _.includes(hideTab, 'views'),
      }, {
        label: 'Enquiries',
        data: _.values(this.data.total_inquiries.data),
        borderColor: "#E5E50A",
        fill: false,
        hidden: _.includes(hideTab, 'inquiries'),
      }, {
        label: 'Confirmed',
        data: _.values(this.data.confirmed.data),
        borderColor: "#75CA9F",
        fill: false,
        hidden: _.includes(hideTab, 'confirmed'),
      }, {
        label: 'Rejected',
        data: _.values(this.data.rejected.data),
        borderColor: "#F94F46",
        fill: false,
        hidden: _.includes(hideTab, 'rejected'),
      }, {
        label: 'Calls',
        data: _.values(this.data.call_logs.data),
        borderColor: "#333333",
        fill: false,
        hidden: _.includes(hideTab, 'calls'),
      }]
    }
    this.buildChartGraph(ctx, dataset, true, this.calendarType != 'Weekly')
  }

  buildChartGraph(ctx, dataset, yAxesLegend = false, xAxesLegend = true) {
    new Chart(ctx, {
      type: 'line',
      data: dataset,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              display: yAxesLegend
            }
          }],
          xAxes: [{
            ticks: {
              display: xAxesLegend //this will remove only the label
            }
          }],
        },
        legend: {
          display: false
        }
      }
    });
  }

  // Pie Chart
  buildChartEventInterest() {
    $('[data-toggle="tooltip"]').tooltip()

    let ctx = document.getElementById('chartEventInterest').getContext('2d');
    let keys = _.keys(this.data.event_interests)
    let arrayBackgroundColor = []
    
    for (let i = 0; i < keys.length; ++i) {
      switch(keys[i]) {
        case 'Corporate Event':
          arrayBackgroundColor.push("#4CC9F0");
          break;
        case 'Others':
          arrayBackgroundColor.push("#F72585");
          break;
        case 'Social Events':
          arrayBackgroundColor.push("#7209B7");
          break;
        case 'Weddings/Solemnisation':
          arrayBackgroundColor.push("#4361EE");
      }
    }

    let dataset = {
      labels: keys,
      datasets: [{
        label: 'Event Breakdown',
        labels: keys,
        data: _.map(_.values(this.data.event_interests), 'total'),
        backgroundColor: arrayBackgroundColor,
        //borderColor: 'rgba(200, 200, 200, 0.75)',
        //hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
        //hoverBorderColor: 'rgba(200, 200, 200, 1)',
      }]
    }
    this.buildChartPie(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartSpendingRange() {
    let ctx = document.getElementById('chartSpendingRange').getContext('2d');
    let keys = _.keys(this.data.spending_ranges)
    let arrayBackgroundColor = []
    
    for (let i = 0; i < keys.length; ++i) {
      switch(keys[i]) {
        case '<$1,000':
          arrayBackgroundColor.push("#D9ED92");
          break;
        case '$1,001 - $5,000':
          arrayBackgroundColor.push("#99D98C");
          break;
        case '$5,001 - $10,000':
          arrayBackgroundColor.push("#52B69A");
          break;
        case '$10,001 - $20,000':
          arrayBackgroundColor.push("#168AAD");
          break;
        case '$20,000 - $50,000':
          arrayBackgroundColor.push("#4361EE");
          break;
        case '>$50,000':
          arrayBackgroundColor.push("#1E6091");
      }
    }

    let dataset = {
      labels: keys,
      datasets: [{
        label: 'Spending Range',
        labels: keys,
        data: _.values(this.data.spending_ranges),
        backgroundColor: ["#D9ED92", "#99D98C", "#52B68A", "#168AAD", "#1E6091"],
        //borderColor: 'rgba(200, 200, 200, 0.75)',
        //hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
        //hoverBorderColor: 'rgba(200, 200, 200, 1)',
      }]
    }
    this.buildChartPie(ctx, dataset, false, this.calendarType != 'Weekly')
  }

  buildChartPie(ctx, dataset, yAxesLegend = false, xAxesLegend = true) {
    let controller = this
    let myNewChart = new Chart(ctx, {
      type: 'pie',
      data: dataset,
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
          // position: 'bottom',
          // labels: {
          //   padding: 25
          // }
        },
        legendCallback: function(chart) {
          var ul = document.createElement('ul');
          var indexParent = 0
          if (chart.data.datasets.length == 2)
            indexParent = 1
          var backgroundColors = chart.data.datasets[indexParent].backgroundColor;
          chart.data.datasets[indexParent].labels.forEach(function(label, index) {
            ul.innerHTML += `
              <li id="${chart.canvas.id}${indexParent}legend-${index}-item" class="${chart.canvas.id}${indexParent}legend-item">
                <span style="background-color: ${backgroundColors[index]}"></span>
                  ${label}
              </li>
            `; // ^ ES6 Template String
          });
          return ul.outerHTML;
        },
        layout: {
          padding: 40
        },
        hover: { mode: null },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.dataset.data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = (value * 100 / sum).toFixed(2) + "%";

              return percentage;
            },
            // color: function(context) {
            //   var index = context.dataIndex;
            //   var hex = context.dataset.backgroundColor[index]
            //   if (context.datasetIndex > 0) {
            //     var str = context.dataset.backgroundColor[index]
            //     var hsl = str.substring(4, str.indexOf(")")).split(",")
            //     hex = controller.hslToHex(parseInt(hsl[0]), parseInt(hsl[1].replace('%', '')), parseInt(hsl[2].replace('%', '')));
            //   }
            //   var value = controller.hexToRgb(hex);
            //   var threshold = 140;
            //   var luminance = 0.299 * value.r + 0.587 * value.g + 0.114 * value.b;
            //   return luminance > threshold ? 'black' : 'white';
            // },
            color: "black",
            font: {
              size: 15
            }
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let index = tooltipItem.index;
              let label = dataset.labels[index];
              let value = dataset.data[index];

              let sum = 0;
              let dataArr = dataset.data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              
              return ' '+label + ': ' + value + ' (' + percentage + ')';
            }
          }
        }
      }
    });

    document.getElementById(ctx.canvas.id+"Legend").innerHTML = myNewChart.generateLegend();
    controller.bindChartEvents(myNewChart, 0, document);

    if (ctx.canvas.id == "chartEventInterest") {
      $("#" + ctx.canvas.id).on('click', function (evt) {
        let activePoints = myNewChart.getElementsAtEvent(evt);
        if (activePoints.length) {
          let wait = 200
          let add = true
          if (myNewChart.data.datasets.length > 1) {
            myNewChart.data.datasets.pop();
            myNewChart.update();
            document.getElementById(ctx.canvas.id+"Legend").innerHTML = myNewChart.generateLegend();
            controller.bindChartEvents(myNewChart, 0, document);
            wait = 700;
            add = false
          }

          let chartData = activePoints[0]['_chart'].config.data;
          let idx = activePoints[0]['_index'];
          let label = chartData.labels[idx];

          if (add && myNewChart.data.datasets.length == 1) {
            setTimeout(function () {
              if (controller.data.event_interests[label]){
                let data = controller.data.event_interests[label].data

                //const data = myNewChart.data;
                let keys = _.keys(data)
                let h = 0
                switch(label) {
                  case 'Corporate Event':
                    h = 180;
                    break;
                  case 'Others':
                    h = 300;
                    break;
                  case 'Social Events':
                    h = 270;
                    break;
                  case 'Weddings/Solemnisation':
                    h = 240;
                }

                let arrayOfNumber = controller.createTransparencyColor(keys.length)

                let dynamicColors = function (index) {
                  return "hsl(" + h + ",100%," + arrayOfNumber[index] + "%)";
                };

                let coloR = [];
                for (let i = 0; i < keys.length; i++) {
                  coloR.push(dynamicColors(i));
                }

                const newDataset = {
                  label: label,
                  labels: keys,
                  data: _.values(data),
                  backgroundColor: coloR,
                };

                myNewChart.data.datasets.push(newDataset);
                myNewChart.update();
                document.getElementById(ctx.canvas.id+"Legend").innerHTML = myNewChart.generateLegend();
                controller.bindChartEvents(myNewChart, 1, document);
              }
            }, wait);
          }
        }
      });
    }
  }

  bindChartEvents(myChart, indexParent, containerElement){
    const legendItemSelector = "."+myChart.canvas.id+indexParent+"legend-item";
    const legendItems = [
      ...containerElement.querySelectorAll(legendItemSelector)
    ];

    legendItems.forEach((item, i) => {
      item.addEventListener("click", (e) => {
        var target = e.target
        if (target.tagName == "SPAN")
          target = target.parentNode
        updateDataset(target, i)
      });
    });
  
    const updateDataset = (currentEl, index) => {
      const meta = myChart.getDatasetMeta(indexParent);
      const result = meta.data[index].hidden === true ? false : true;
      if (result === true) {
        meta.data[index].hidden = true;
        currentEl.classList.add("legend-line-through")
      } else {
        currentEl.classList.remove("legend-line-through")
        meta.data[index].hidden = false;
      }
      myChart.update();
    };
  };

  // hexToRgb(hex) {
  //   const r = parseInt(hex.slice(1, 3), 16)
  //   const g = parseInt(hex.slice(3, 5), 16)
  //   const b = parseInt(hex.slice(5, 7), 16)

  //   return { r: r, g: g, b: b }
  // }

  // hslToHex(h,s,l) {
  //   l /= 100;
  //   const a = s * Math.min(l, 1 - l) / 100;
  //   const f = n => {
  //     const k = (n + h / 30) % 12;
  //     const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  //     return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  //   };
  //   return `#${f(0)}${f(8)}${f(4)}`;
  // }

  createTransparencyColor(length) {
    if (length <= 1)
      return [50]
    
    let median = 50, increment = median / length, left = [], right = [], result = []

    let arrayPercentage = function(operator){
      switch(operator) {
        case '+': return Array.from({ length: Math.floor(length / 2) }, (v,k) => Math.round(median-(increment*(k+(k+1)))))
        case '-': return Array.from({ length: Math.floor(length / 2) }, (v,k) => Math.round(median+(increment*(k+(k+1)))))
        default: return 'Invalid operation'
      }
    };

    left = arrayPercentage("-")
    right = arrayPercentage("+")
    
    left.reverse()
    if (length % 2 == 1) {
      left.push(50)
    }

    result = $.merge(left, right)
    
    return result
  }

  get compiledData() {
    let data = {}

    data['total_confirmed'] = this.totalConfirmed
    data['total_views'] = this.totalViews
    data['average_conversion'] = this.averageConversion
    data['average_response'] = this.averageResponse

    data = {
      ...data,
      ...this.data
    }

    return data
  }

  get totalConfirmed() {
    return this.data.confirmed.header
  }

  get totalConfirmedMoney() {
    return this.data.confirmed_money.header
  }

  get totalViews() {
    return this.data.views.header
  }

  get averageConversion() {
    return this.data.conversion.header
  }

  get averageResponse() {
    return this.data.response.header
  }

  get totalInquiries() {
    return this.data.total_inquiries.header
  }

  get totalInquiriesMoney() {
    return this.data.total_inquiries_money.header
  }

  get templateWrapper() {
    return document.getElementById('nav-analytics')
  }

  get template() {
    return document.getElementById('template-analytics')
  }

  get calendarType() {
    return document.querySelector('[data-users--venues--stats-target="calendarType"]').value
  }
}