import { Controller } from 'stimulus'
import bootbox from 'bootbox';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'confirmCardPayment',
    'unsubscribeButton',
  ]

  static values = {
    featuresSubscriptionId: String,
    country: String,
    stripePublicKey: String
  }

  confirmCardPayment(e) {
    e.preventDefault()
    const controller = this

    bootbox.confirm({
      message: 'Proceed with payment?',
      closeButton: false,
      callback: async function(result) {
        if (result) {
          controller.confirmCardPaymentTarget.textContent = 'Activating...'
          controller.confirmCardPaymentTarget.setAttribute('disabled', 'disabled')
          let csrfToken = document.querySelector("[name='csrf-token']").content

          const subResponse = await fetch(`/${controller.countryValue}/users/subscriptions/${controller.featuresSubscriptionIdValue}/activate`, {
            method: 'POST',
            headers: {
              "X-CSRF-Token": csrfToken
            },
          });
          const subJson = await subResponse.json()

          if (subJson.client_secret && subJson.payment_method) {
            controller.stripe = Stripe(controller.stripePublicKeyValue, { apiVersion: '2020-08-27' })
            controller.stripe.confirmCardPayment(subJson.client_secret, {
              payment_method: subJson.payment_method
            }).then(async (result) => {
              var message = 'Payment successful! Your subscription will be activated shortly'
              if (result.error) {
                message = result.error.message
                let csrfToken = document.querySelector("[name='csrf-token']").content

                await fetch(`/${controller.countryValue}/users/subscriptions/${controller.featuresSubscriptionIdValue}/failed_state`, {
                  method: 'PUT',
                  headers: {
                    "X-CSRF-Token": csrfToken
                  },
                });
              }
              bootbox.confirm({
                message: message,
                closeButton: false,
                buttons: {
                  cancel: {
                    className: "d-none"
                  }
                },
                callback: function(result) {
                  if (result) {
                    setTimeout(() => {
                      window.location.href = `/${controller.countryValue}/users/subscriptions/${controller.featuresSubscriptionIdValue}`
                    }, 3000)
                  }
                }
              });
            });
          }
        }
      }
    });
  }

  unsubscribeButtonAction(e) {
    e.preventDefault();
    const controller = this;
    let cancelButton = e.currentTarget
    let plan = cancelButton.dataset.plan;
    let unsubscribe_form = document.getElementById('unsubscribe_form');
    let planUntil = cancelButton.dataset.planUntil;

    bootbox.dialog({
      title: 'Confirm cancellation of your plan',
      message: '<p>Your '+plan+' subscription is paid until '+planUntil+
                '. If you would like to proceed with cancelling your subscription'+
                ', please select "Cancel Subscription" below.</p>'+
                '<p>If you change your mind, you can resubscribe later.</p>',
      closeButton: false,
      buttons: {
        cancel: {
          label: "Never mind",
          className: 'btn-primary',
          callback: function(){
            return true;
          }
        },
        ok: {
          label: "Cancel Subscription",
          className: 'btn-danger',
          callback: function(){
            bootbox.dialog({
              backdrop: true,
              onEscape: true,
              closeButton: true,
              title: 'Reason you unsubscribe',
              message: '<p>Please tell us the reason you want to unsubscribe.</p>'+
                        '<br>'+
                        "<small>Optional</small><br><textarea class='form-control' id='unsubscribe_reason'>",
              buttons: {
                cancel: {
                  className: "d-none"
                },
                ok: {
                  label: "Next",
                  className: 'btn-primary',
                  callback: function(){
                    let reason_value = document.getElementById('unsubscribe_reason').value
                    bootbox.dialog({
                      backdrop: true,
                      onEscape: true,
                      closeButton: true,
                      title: 'Give us some comments',
                      message: '<p>Please give us some comments regarding our subscription plan.</p>'+
                                '<br>'+
                                "<small>Optional</small><br><textarea class='form-control' id='unsubscribe_comment'>",
                      buttons: {
                        cancel: {
                          className: "d-none"
                        },
                        ok: {
                          label: "Submit",
                          className: 'btn-primary',
                          callback: function(){
                            let comments_value = document.getElementById('unsubscribe_comment').value
                            Rails.ajax({
                              type: "PUT",
                              dataType: 'json',
                              url: window.location.href+'/cancel_reason',
                              data: "features_subscription[unsubscribe_reason]="+reason_value+"&features_subscription[unsubscribe_comment]="+comments_value,
                              success: (response) => {
                                cancelButton.setAttribute('disabled', 'disabled');
                                unsubscribe_form.submit();
                                bootbox.alert({
                                  message: 'Thank you for using our service.',
                                  backdrop: true,
                                  onEscape: true
                                });
                              },
                              error: (response) => {
                                bootbox.alert({
                                  message: 'Error occurred when cancelling your subscription. Please contact our support.',
                                  backdrop: true,
                                  onEscape: true
                                });
                              },
                            })
                          }
                        }
                      }
                    });
                  }
                }
              }
            });
          }
        }
      }
    });
  }
}