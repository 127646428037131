export default function copyTextInput(target, callback) {
  /* Get the text field */
  var copyText = target;

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");

  if (callback) {
    callback()
  }
}