import { Controller } from 'stimulus'
import owlCarousel from 'owl.carousel'
import toastr from 'toastr'
import axios from 'axios'
import bootbox from 'bootbox'

export default class extends Controller {
  static targets = ['modalIconRow', 'modalIconTitle', 'googleMap', 'favoriteVenue', 'similarVenue', 'favoriteVenueWrapper']

  initialize() {
    if (!this.hasFavoriteVenueWrapperTarget) {
      return
    }

    this.markers = []
    window.usersVenuesFavoriteVenues = this
    this.embedMapScript()
    $("#similarFav").owlCarousel({
      loop: true,
      margin: 20,
      nav: true,
      autoWidth: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 4
        }
      }
    })
  }

  embedMapScript() {
    let apiKey = this.element.dataset['googleMapApiKey']
    const embedScript = document.createElement('script');
    embedScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=window.usersVenuesFavoriteVenues.initMap`);
    document.head.appendChild(embedScript);
  }

  initMap() {
    let latLng = this.getLatlngFromCountry()
    this.geocoder = new google.maps.Geocoder();
    this.map = new google.maps.Map(this.googleMapTarget, {
      center: latLng,
      zoom: 17,
      scrollwheel: false
    });

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.initMarkers()
    });
  }

  getLatlngFromCountry() {
    let lat = parseFloat(this.element.dataset['countryLat'])
    let lng = parseFloat(this.element.dataset['countryLng'])
    return { lat, lng }
  }

  setMarkerFromVenue(venueDOM) {
    const { placeId, placeName, placeImage, placeTitle, placeSubtitle, placeLink, id } = venueDOM.dataset
    this.geocoder.geocode({ placeId }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);
        let marker = new google.maps.Marker({
          map: this.map,
          position: results[0].geometry.location,
          title: placeName
        });

        marker.addListener("click", () => {
          this.popupVenue(marker, placeImage, placeTitle, placeSubtitle, placeLink)
        });

        this.markers = [...this.markers, { id: id, marker: marker }]
      }
    });
  }

  popupVenue(marker, placeImage, placeTitle, placeSubtitle, placeLink) {
    if (this.infowindow) {
      this.infowindow.close()
    }

    const contentString =
      "<figure class='mb-0 venue-img' style='max-width: 100%'>" +
      "<img src='" + placeImage + "' style='opacity: 1; width: 100%'>" +
      "</figure>" +
      "<div class='name-wrapper mt-2 text-left'>" +
      "<h6 class='title text-truncate'><strong><a class='text-dark' href='" + placeLink + "'>" + placeTitle + "</a></strong></h6>" +
      "<h6 class='subtitle text-truncate'>" + placeSubtitle + "</h6>" +
      "</div>"

    this.infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    this.infowindow.open(this.map, marker);
  }

  initMarkers() {
    // Init markers from favorite list
    for (let venueDOM of this.favoriteVenueTargets) {
      if (venueDOM.dataset['placeId']) {
        this.setMarkerFromVenue(venueDOM)
      }
    }
  }

  onClickMoreAmenities(e) {
    $('#modalAmenities').modal('show')

    this.modalIconTitleTarget.innerHTML = e.target.dataset['venueName']
    this.modalIconRowTarget.innerHTML = ''

    const allAmenities = JSON.parse(e.target.dataset['amenities'])
    for (const amenity of allAmenities) {
      this.modalIconRowTarget.insertAdjacentHTML('beforeend',
        `
        <div class="col-6">
          <div class="single-icon-wrapper">
            <span class="icon-${amenity[1]}"></span>
            <span class="text">${amenity[0]}</span>
          </div>
        </div>
      `
      )
    }
  }

  onClickShareButton(e) {
    e.preventDefault()
    this.setClipboard(e.target.dataset['url'])
    toastr.success("URL Copied")
  }

  onClickHeart(e) {
    if (e.target.parentNode.classList.contains('active')) { // is liked icon
      this.unfavoriteVenue(e.target)
    } else {
      this.favoriteVenue(e.target)
    }
  }

  favoriteVenue(venueDOM) {
    axios({
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: venueDOM.dataset['favoriteUrl']
    }).then(response => {
      if (response.data.success) {
        venueDOM.parentNode.classList.add('active')
        this.addVenueById(response.data.venue_id, response.data.html)
        toastr.success('Venue added to favorite list')
      }
    })
  }

  unfavoriteVenue(venueDOM) {
    bootbox.confirm({
      message: `<h5 class='modal-title text-center'>Remove this venue ?</h5>`,
      closeButton: false,
      onEscape: true,
      backdrop: true,
      buttons: {
        cancel: {
          label: 'No, I like it',
          className: 'btn-orange'
        },
        confirm: {
          label: 'Remove',
          className: 'btn-shadow'
        },
      },
      callback: result => {
        if (result) {
          axios({
            headers: { 'Content-Type': 'application/json' },
            method: 'DELETE',
            url: venueDOM.dataset['unfavoriteUrl']
          }).then(response => {
            if (response.data.success) {
              this.removeVenueById(response.data.venue_id)
              toastr.success('Venue removed from favorite list')
            }
          })
        }
      }
    });
  }

  addVenueById(venueId, html = null) {
    let venueDom = this.similarVenueTargets.find(venue => venue.dataset['id'] == venueId)
    this.setMarkerFromVenue(venueDom)

    if (html) {
      this.favoriteVenueWrapperTarget.insertAdjacentHTML('afterbegin', html)
    }
  }

  removeVenueById(venueId) {
    // remove DOM from favorite list
    let venueDom = this.favoriteVenueTargets.find(venue => venue.dataset['id'] == venueId)
    venueDom.parentNode.removeChild(venueDom)

    // toggle similar venue dom heart icon to gray
    let venueButtonDom = document.getElementById(`venue-${venueId}-btn`)
    if (venueButtonDom)
      venueButtonDom.classList.remove('active')

    // remove marker
    let removeMarkerIndex = this.markers.findIndex(venueMarker => venueMarker['id'] == venueId)
    if (this.markers[removeMarkerIndex]) {
      this.markers[removeMarkerIndex].marker.setMap(null)
      this.markers.splice(removeMarkerIndex, 1)
    }
  }

  setClipboard(value) {
    let tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
}
