export default function trackPage(category, action, label) {
  setTimeout(function(){
    // ga method currently not working
    // ga('send', 'event', category, action, label)
    if (dataLayer) {
      dataLayer.push({
        'event': 'eventTracking',
        'category': category,
        'action': action,
        'label': label
      });
    }
  }, 500)
}
// import 'select2/dist/js/select2.full';
// import axios from '../vendor/axios';
// import bootbox from 'bootbox';

// $(document).on('change', '.search-change', function() {
//   $(this).closest('form').submit()
// });

// $(document).on('change', '#new-deal-option', function() {
//   loadNewDealModal()
// });

// function loadNewDealModal () {
//   var value = $('#new-deal-option').val()
//   if(value != undefined && value != '') {
//     $('#new-deal-option-target').show()
//     $('#new-deal-option-target').attr('href', value)
//   } else {
//     $('#new-deal-option-target').hide()
//   }
// }

//loadNewDealModal();

// function infiniteScroll () {
//   $(window).scroll(function() {
//     if(($(window).scrollTop() + $(window).height() >= ($(document).height() - 500)) && ($('#ipagination').length > 0 && $('#ipagination').data('remote') !== undefined)) {
//       document.querySelector("#ipagination").click()
//       //$('#ipagination').click()
//       $('#ipagination').removeAttr('data-remote');
//       $('#ipagination').removeAttr('href');
//       $('#ipagination .text-center').html('Load Events ...')
//     }
//   });
// }

// function daterangepickerAdded() {
//   $('#search_event_date_picker').daterangepicker({
//       autoUpdateInput: false,
//       locale: {
//         format: 'DD-MM-YYYY',
//         separator: ' - ',
//         cancelLabel: 'Clear'
//       }
//   });

//   $('#search_event_date_picker').on('apply.daterangepicker', function(ev, picker) {
//       $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
//       $('.simple_form.search_subpage').submit();
//   });

//   $('#search_event_date_picker').on('cancel.daterangepicker', function(ev, picker) {
//       $(this).val('');
//       $('.simple_form.search_subpage').submit();
//   });
// }

// function checkSearchKeywordEnterTriggered() {
//   $(document).on('keydown', '#search_keyword', function(e) {
//     if(e.which == 13) {
//       $('.simple_form.search_subpage').submit();
//     }
//   });
// }

// function assignOperator() {
//   if ($('.assign-operator:not(.select2-hidden-accessible)')) {

//     $('.assign-operator:not(.select2-hidden-accessible)').each(function(index, element) {
//       $(element).select2({
//         dropdownAutoWidth: true,
//         placeholder: $(element).attr('placeholder'),
//         ajax: {
//           url: $(element).data('url')
//         }
//       })
//       .on('select2:select', function (e) {
//         let user = e.params.data;
//         let url_operator = $(element).data('assign-event-operator-url')

//         bootbox.confirm("Assign "+user.text+" as the event manager?", function(result){
//           if(result) {
//             axios({
//               method: 'post',
//               url: url_operator,
//               data: {
//                 entity_role: {
//                   user_id: user.id
//                 }
//               }
//             })
//             .then(function (response) {

//               // temporary waiting for ricardo change his mind
//               $($(element).data('target-wrapper') + ' .assign-inquiry .badge-assign').remove();

//               // showing the name of user
//               let wrapper = document.createElement('div');
//               wrapper.classList.add('badge','badge-assign','mt-1');
//               wrapper.classList.add("entity-role-" + response.data.id);

//               let nameWrapper = document.createElement('span');
//               nameWrapper.classList.add('d-inline-block','text-truncate');
//               nameWrapper.innerHTML = user.text;

//               let removeWrapper = document.createElement('a');
//               removeWrapper.setAttribute('data-remote', true);
//               removeWrapper.setAttribute('data-method', 'delete');
//               removeWrapper.setAttribute('data-confirm', "are you sure want to delete "+user.text+" as event manager?");
//               removeWrapper.href = url_operator + '/' + response.data.id
//               removeWrapper.innerHTML = '<i class="far fa-window-close"></i>';

//               wrapper.innerHTML = wrapper.innerHTML + nameWrapper.outerHTML + removeWrapper.outerHTML
//               $($(element).data('target-wrapper') + ' .assign-inquiry').append(wrapper.outerHTML);

//               // clear placeholder
//               $(e.target).empty()
//             })
//           } else {
//             $(e.target).empty()
//           }
//         })
//       })
//     })
//   }
// }

// window.assignOperator = assignOperator;

// function bindSaveAsInvoiceButton() {
//   if($('#save_quotation_as_invoice_btn').length == 0) { return false; }

//   $('#save_quotation_as_invoice_btn').on('click', function(event) {
//     event.preventDefault();
//     if(confirm($(this).data('confirm'))) {
//       return true;
//     } else {
//       return false;
//     }
//   })
// }

// $(document).ready(function () {
//   infiniteScroll();
//   daterangepickerAdded();
//   checkSearchKeywordEnterTriggered();
//   assignOperator();
//   bindSaveAsInvoiceButton();
// });
