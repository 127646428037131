import { Controller } from 'stimulus'
import trackPage from '../users/events'
import axios from 'axios'

export default class extends Controller { 
  connect() {
    $('.icon--video').tooltip('enable')
    $('.icon--three-sixty').tooltip('enable')
    this.gaEvents()
  }

  onClickCard(e) {
    var venue_name = $(e.currentTarget).data('venue-name')
    var category = $(e.currentTarget).data('click-category')
    trackPage(category ? category : 'link', 'click', venue_name)
  }

  gaEvents(){
    $(document).on('click', '.click_to_show', function(e) {
      set_ga_vars(this)
    })

    $(document).on('click', '.ga_event_onclick', function(e) {
      set_ga_vars(this)
    })

    function set_ga_vars(obj) {
      var event_action, event_category, event_label, event_value
      if (typeof $(obj).data('analytics-category') !== 'undefined') {
        event_category = $(obj).data('analytics-category')
        event_action = $(obj).data('analytics-action')
        event_label = $(obj).data('analytics-label')
        event_value = null
        if (!(typeof $(obj).data('analytics-value') === 'undefined' || $(obj).data('analytics-value') === 'null')) {
          event_value = $(obj).data('analytics-value')
        }
        ga('send', 'event', event_category, event_action, event_label, event_value)
      }
    }
  }

  onClickForTracking(e) {
    let controller = this
    var type = $(e.currentTarget).data('type')
    var extras = $(e.currentTarget).data('extras')

    axios({
      method: 'POST',
      url: '/api/v1/kpi_trackers/track',//controller.contentTarget.dataset.trackerUrl,
      data: {
        kpi_tracker: {
          track_type: type,
          extras: extras
        }
      },
    })
  }

  onClickForContentTracking(e) {
    if (e.currentTarget.parentElement.dataset.track != "") {
      axios({
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'GET',
        url: e.currentTarget.parentElement.dataset.track,
      })
    }
  }

}