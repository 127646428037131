import { Controller } from "stimulus"
import 'bootstrap-monthrangepicker'
import 'bootstrap-daterangepicker'
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import bootbox from 'bootbox'
import Analytics from './stats/analytics'
import Inquiries from './stats/inquiries'
import AllEvents from './stats/all_events'
import ConfirmedEvents from './stats/confirmed_events'
import RejectedEvents from './stats/rejected_events'
import Customers from './stats/customers'
import Goals from './stats/goals'
import Emails from './stats/emails'
import CustomerDetail from './stats/customer_detail'
import EmailDetail from './stats/email_detail'
import ExternalInquiryDetail from './stats/external_inquiry_detail'

export default class extends Controller {
  static targets = ['calendarType', 'calendarTypeWrapper', 'calendar', 'sendOtpButton',
                    'timerUserPhoneCodeVerification', 'basedOnWrapper', 'basedOn',
                    'eventStatus', 'venue']
  oldRangeCalendarValue;
  oldCalendarValue;

  connect() {
    let controller = this
    controller.calendarInit(false)
    if (this.statsStatus == "single") {
      controller.submit()
    }else {
      document.getElementById("navigationStats").style.display = "none";
      $('#venue_ids').select2({
        maximumSelectionLength: 5,
        placeholder: "Select a venue",
      })
    }

    $('#export_data').on('hide.bs.modal', function(e){
      let passwordField = document.getElementById('user_password_verification')
      let errorPasswordDiv = document.getElementById('errorUserPasswordVerification')
      let exportForm = document.getElementById('btn_export')
      let button = document.getElementById('userPasswordExport')
      
      if (exportForm) {
        controller.alreadySentOtp = false
        controller.sendOtpButtonTarget.innerHTML = 'Send OTP'
        controller.sendOtpButtonTarget.removeAttribute('disabled')
        button.classList.add('hidden')
        button.classList.add('disabled')
        button.setAttribute('disabled', 'disabled')
        exportForm.action = ''
        passwordField.value = ''
        passwordField.classList.add('hidden')
        errorPasswordDiv.innerHTML = ''
        controller.timerUserPhoneCodeVerificationTarget.innerHTML = ''
        clearInterval(controller.interval)
      }
    });
  }

  changeCalendarType() {
    this.calendarInit();
  }

  changeBasedOn() {
    this.basedOnInit();
  }

  changeEventStatus() {
    let controller = this;
    controller.showhideLoading()

    var paramsSubmit = {
      day_range_picker: this.calendar,
      range_type: this.calendarType,
      data_type: this.dataType,
      based_on: this.basedOn,
      event_status: this.eventStatus,
    }

    if (this.statsStatus == "group") {
      paramsSubmit.venue_ids = this.venueIds;
    }

    axios.get(this.submitPath, {
      params: paramsSubmit
    }).then(function (response) {
      var responseURL = response.request.responseURL.replace("stats_data", "stats_data_export");
      if (document.getElementById('btn_export')) document.getElementById('btn_export').action = responseURL
      new AllEvents(response.data).call()
      controller.showhideLoading(false)
    })
  }

  basedOnInit() {
    this.submit()
  }

  submitButton(_event) {
    let controller = this

    if (controller.statsStatus == "group") {
      document.getElementById("navigationStats").style.display = "block";
    }

    setTimeout(function () {
      controller.submit()
    }, 1);
  }

  changeTab(_event) {
    let controller = this

    if (controller.statsStatus == "group") {
      document.getElementById("navigationStats").style.display = "block";
    }

    setTimeout(function () {
      controller.changeTabSetCalendarValue()
      controller.submit()
    }, 1);
  }

  changeTabSetCalendarValue() {
    $("#form_calendar").show()
    if (this.calendarType == 'OnlyMonth') {
      this.oldRangeCalendarValue = this.calendar
      this.calendarTarget.value = ((this.oldCalendarValue) ? this.oldCalendarValue : moment().format("MM-YYYY"))
      this.showHidecalendarTypeWrapper(false)
      this.showHidebasedOnWrapper(false)
    } else if (this.calendarType == 'HideCalendar') {
      if (this.oldRangeCalendarValue) {
        this.calendarTarget.value = this.oldRangeCalendarValue
      }
      $("#form_calendar").hide()
    } else {
      if (this.oldRangeCalendarValue) {
        this.calendarTarget.value = this.oldRangeCalendarValue
      } else {
        switch (this.calendarType) {
          case 'Weekly':
            this.calendarTarget.value = moment().format("DD-MM-YYYY") + " - " + moment().format("DD-MM-YYYY")
            break;
          case 'Monthly':
            this.calendarTarget.value = moment().format("MM-YYYY") + " - " + moment().format("MM-YYYY")
            break;
          case 'Yearly':
            this.calendarTarget.value = moment().format("YYYY") + " - " + moment().format("YYYY")
            break;
        }
      }
      this.showHidecalendarTypeWrapper()
      this.showHidebasedOnWrapper()
    }

    this.calendarInit(false);
  }

  calendarInit(clearInput = true) {
    if ($(this.calendarTarget).data('daterangepicker')) {
      $(this.calendarTarget).data('daterangepicker').remove();
    } else if ($(this.calendarTarget).data('monthrangepicker')) {
      $(this.calendarTarget).data('monthrangepicker').remove();
    }

    if (clearInput) {
      this.calendarTarget.value = ''
    }

    switch (this.calendarType) {
      case 'Weekly':
        this.calendarInitWeekly()
        break;
      case 'Monthly':
        this.calendarInitMonthly()
        break;
      case 'Yearly':
        this.calendarInitYearly()
        break;
      case 'OnlyMonth':
        this.calendarInitOnlyMonth()
        break;
      case 'HideCalendar':
        break;
      default:
        this.calendarInitWeekly()
    }
  }

  calendarInitOnlyMonth() {
    let controller = this

    $(this.calendarTarget).monthrangepicker({
      autoUpdateInput: false,
      singleDatePicker: true,
      locale: {
        format: 'MM-YYYY'
      }
    });

    $(this.calendarTarget).on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('MM-YYYY'));
      controller.oldCalendarValue = $(this).val()
      controller.submit()
    });
  }

  calendarInitWeekly() {
    let controller = this
    $(this.calendarTarget).daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'DD-MM-YYYY'
      }
    });

    $(this.calendarTarget).on('apply.daterangepicker', function (ev, picker) {
      var value = parseInt(picker.endDate.format('YYYY')) - parseInt(picker.startDate.format('YYYY'));

      if ( value == 0 || value == 1 ){
        $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
        controller.oldRangeCalendarValue = $(this).val()
        controller.submit()
      } else {
        bootbox.alert("Sorry, we couldn`t compelete your request. Search data maximum only two year.");
      }
    });
  }

  calendarInitMonthly() {
    let controller = this
    $(this.calendarTarget).monthrangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'MM-YYYY'
      },
      //dateLimit: { months: 6 }
    });

    $(this.calendarTarget).on('apply.daterangepicker', function (ev, picker) {
      var value = parseInt(picker.endDate.format('YYYY')) - parseInt(picker.startDate.format('YYYY'));

      if ( value == 0 || value == 1 ){
        $(this).val(picker.startDate.format('MM-YYYY') + ' - ' + picker.endDate.format('MM-YYYY'));
        controller.oldRangeCalendarValue = $(this).val()
        controller.submit()
      } else {
        bootbox.alert("Sorry, we couldn`t compelete your request. Search data maximum only two year.");
      }
    });
  }

  calendarInitYearly() {
    let controller = this

    $(this.calendarTarget).monthrangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'YYYY'
      },
      monthOrQuarter: 2
    });

    $(this.calendarTarget).on('apply.daterangepicker', function (ev, picker) {
      var value = parseInt(picker.endDate.format('YYYY')) - parseInt(picker.startDate.format('YYYY'));

      if ( value == 0 || value == 1 ){
        $(this).val(picker.startDate.format('YYYY') + ' - ' + picker.endDate.format('YYYY'));
        controller.oldRangeCalendarValue = $(this).val()
        // controller.submit()
        bootbox.alert("Sorry, we couldn`t compelete your request.");
      } else {
        bootbox.alert("Sorry, we couldn`t compelete your request. Search data maximum only two year.");
      }
    });
  }

  backFromDetail() {
    this.showhideEventDetail();
  }

  clickForEventDetail(event) {
    let controller = this
    let url = event.currentTarget.getAttribute('data-url')
    controller.showhideLoading()
    axios.get(url).then(function (response) {
      new CustomerDetail(response.data).call()
      controller.showhideLoading(false)
      controller.showhideEventDetail(true)
    })
  }

  clickForEmailDetail(event) {
    let controller = this
    let email_id = event.currentTarget.getAttribute('data-id')
    let email_date = event.currentTarget.getAttribute('data-date')

    controller.showhideLoading()
    axios.get(this.submitPath, {
      params: {
        data_type: 'email_detail',
        email_id: email_id,
        email_date: email_date
      }
    }).then(function (response) {
      new EmailDetail(response.data, email_id, email_date).call()
      controller.showhideLoading(false)
      controller.showhideEventDetail(true)
    })
  }

  clickForExternalInquiryDetail(event) {
    let controller = this
    let customer_get_in_touch = event.currentTarget.getAttribute('data-value')
    controller.showhideLoading(false)

    var paramsSubmit = {
      day_range_picker: this.calendar,
      range_type: this.calendarType,
      data_type: 'event_inquiries',
      customer_get_in_touch: customer_get_in_touch,
      based_on: this.basedOn,
    }

    if (this.statsStatus == "group") {
      paramsSubmit.venue_ids = this.venueIds;
    }

    axios.get(this.submitPath, {
      params: paramsSubmit
    }).then(function (response) {
      new ExternalInquiryDetail(response.data, customer_get_in_touch).call()
      controller.showhideLoading(false)
      controller.showhideEventDetail(true)
    })
  }

  showhideEventDetail(show = false) {
    if (show) {
      document.getElementById('stats-data').classList.add('hidden')
      document.getElementById('stats-detail').classList.remove('hidden')
    } else {
      document.getElementById('stats-data').classList.remove('hidden')
      document.getElementById('stats-detail').classList.add('hidden')
    }
  }

  showhideLoading(show = true) {
    if (show) {
      document.getElementById('loading-stats').classList.remove('hidden')
      document.getElementById('nav-tabContent').classList.add('hidden')
    } else {
      document.getElementById('loading-stats').classList.add('hidden')
      document.getElementById('nav-tabContent').classList.remove('hidden')
    }
  }

  submit() {
    let controller = this;

    var paramsSubmit = {
      day_range_picker: this.calendar,
      range_type: this.calendarType,
      data_type: this.dataType,
      based_on: this.basedOn,
    }

    if (this.statsStatus == "group") {
      if (this.venueIds.length == 0) return

      paramsSubmit.venue_ids = this.venueIds;
    }

    controller.showhideLoading()

    axios.get(this.submitPath, {
      params: paramsSubmit
    }).then(function (response) {
      switch (controller.dataType) {
        case 'analytics':
          new Analytics(response.data).call()
          break;
        case 'inquiries':
          new Inquiries(response.data).call()
          break;
        case 'events':
          new AllEvents(response.data).call()

          var responseURL = response.request.responseURL.replace("stats_data", "stats_data_export");
          if (document.getElementById('btn_export')) document.getElementById('btn_export').action = responseURL
          
          break;
        case 'confirmed':
          new ConfirmedEvents(response.data).call()
          break;
        case 'rejected':
          new RejectedEvents(response.data).call()
          break;
        case 'customers':
          // new Customers(response.data).call()
          // break;

          if (!controller.customer) {
            controller.customer = new Customers(response.data)
            controller.customer.call()
            controller.customer.updateTables(response.data)
            controller.customer.bindingDataTable()
          } else {
            controller.customer.updateTables(response.data)
            controller.customer.bindingDataTable()
          }

          var responseURL = response.request.responseURL.replace("stats_data", "stats_data_export");
          if (document.getElementById('btn_export')) document.getElementById('btn_export').action = responseURL

          break;
        case 'goals':
          new Goals(response.data).call()
          break;
        case 'emails':
          new Emails(response.data).call()
          
          var responseURL = response.request.responseURL.replace("stats_data", "stats_data.xlsx");
          if ($('#btn_download_email').length) {
            $('#btn_download_email').data('location', responseURL)
          }

          break;
      }
      controller.showhideLoading(false)
    })
  }

  showHidecalendarTypeWrapper(show = true) {
    if (show) {
      this.calendarTypeWrapperTarget.classList.remove('hidden')
    } else {
      this.calendarTypeWrapperTarget.classList.add('hidden')
    }
  }

  showHidebasedOnWrapper(show = true) {
    if (show) {
      this.basedOnWrapperTarget.classList.remove('hidden')
    } else {
      this.basedOnWrapperTarget.classList.add('hidden')
    }
  }


  get submitPath() {
    return document.querySelector('main.stats[data-controller]').getAttribute('data-submit-url')
  }

  get statsStatus() {
    return document.querySelector('main.stats[data-controller]').getAttribute('data-stats')
  }

  get calendar() {
    return this.calendarTarget.value
  }

  get dataType() {
    return document.querySelector('main.stats .nav-tabs .active').getAttribute('data-value')
  }

  get calendarType() {
    if (this.dataType == 'goals') {
      return 'OnlyMonth'
    } else if (this.dataType == 'customers' || this.dataType == 'emails') {
      return 'HideCalendar'
    } else {
      return this.calendarTypeTarget.value
    }
  }

  get basedOn() {
    var arrayHiddenDataType = ['goals', 'customer', 'emails']

    if(jQuery.inArray(this.dataType, arrayHiddenDataType) === -1) {
      return this.basedOnTarget.value
    } else {
      return ''
    }
  }

  get venueIds() {
    var options = this.venueTarget.selectedOptions;
    var values = Array.from(options).map(({ value }) => value);

    return values;
  }

  get eventStatus() {
    return this.eventStatusTarget.value
  }

  submitCustomerForm(event) {
    let controller = this
    // html5 validation
    event.currentTarget.checkValidity()
    event.preventDefault()

    switch (event.currentTarget.dataset.type) {
      case 'submitCustomer':
        controller.customer.submitCustomer(event.currentTarget)
        break;
    }
  }

  clickCustomerAction(event) {
    let controller = this
    let url = event.currentTarget.getAttribute('data-url')
    let action = event.currentTarget.getAttribute('data-form')

    if (action == "update") {
      axios.get(url).then(function (response) {
        controller.customer.applyEditCustomer(url, response.data)
      })
    } else if (action == "delete") {
      controller.deleteCustomerAction(url)
    }
  }

  startTimerOtp() {
    let controller = this
    var timer = 60 * 5;
    var minutes, seconds;
    
    controller.timerUserPhoneCodeVerificationTarget.classList.remove('hidden')
    controller.timerUserPhoneCodeVerificationTarget.innerHTML = ''

    controller.interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        controller.timerUserPhoneCodeVerificationTarget.innerHTML = minutes + ":" + seconds;
        if (timer > 0) {
            timer -= 1;
        } else {
          controller.alreadySentOtp = false
          controller.timerUserPhoneCodeVerificationTarget.innerHTML = 'Resend'

          clearInterval(controller.interval);
        }
    }, 1000);
  }

  sendOtp(event) {
    let controller = this
    let passwordField = document.getElementById('user_password_verification')
    let exportButton = document.getElementById('userPasswordExport')
    let button = event.currentTarget
    button.setAttribute('disabled', 'disabled');
    button.innerHTML = 'Sending';

    axios({
      method: 'get',
      url: button.dataset.url,
      params: {
        phone: passwordField.dataset.phone
      },
    }).then(function (_response) {
      passwordField.classList.remove('hidden')
      exportButton.classList.remove('hidden')
      exportButton.classList.remove('disabled')
      exportButton.removeAttribute('disabled')
      controller.alreadySentOtp = true
      controller.startTimerOtp()
    }).catch(function (error) {
      document.getElementById('errorUserPasswordVerification').innerHTML = error.response.data.status
    }).then(function () {
      button.removeAttribute('disabled');
      button.innerHTML = 'Resend OTP';
    });
  }

  passwordVerification(event) {
    let controller = this
    let passwordField = document.getElementById('user_password_verification')
    let exportForm = document.getElementById('btn_export')
    let errorPasswordDiv = document.getElementById('errorUserPasswordVerification')
    let url = passwordField.getAttribute('data-url')

    axios({
      method: 'post',
      url: url,
      params: {
        password: passwordField.value
      },
    }).then(function (_response) {
      exportForm.submit()
      $("#export_data").modal('hide')
      controller.alreadySentOtp = false
      passwordField.value = ''
      errorPasswordDiv.innerHTML = ''
    }).catch(function (error) {
      errorPasswordDiv.innerHTML = error.response.data.status
      passwordField.value = ''
    })
  }

  deleteCustomerAction(url) {
    bootbox.confirm({
      message: `<h5 class='modal-title text-center'>Are you sure want to delete this customer?</h5>`,
      closeButton: false,
      onEscape: true,
      backdrop: true,
      buttons: {
        cancel: {
          label: "No",
          className: 'btn-orange text-white'
        },
        confirm: {
          label: "Yes",
          className: 'btn-shadow'
        },
      },
      callback: result => {
        if (result) {
          axios({
            headers: { 'Content-Type': 'application/json' },
            method: 'DELETE',
            url: url
          }).then(response => {
            if (response.data.success) {
              var click = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                synthetic: true,
                view: window
              });
              var tab = document.getElementById("nav-customer-tab");
              tab.dispatchEvent(click);
              toastr.success('Customer deleted.')
            }
          })
        }
      }
    });
  }
}