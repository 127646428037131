export async function hideModal (target, remove) {
  new Promise((resolve, _reject) => {
    if (target && target.classList.contains('show')) {
      $(target).modal('hide').on('hidden.bs.modal', function () {
        if (remove) {
          target.remove()
        }
        resolve(true)
      })
    } else if (target) {
      target.remove()
      resolve(true)
    }else {
      resolve(true)
    }
  });
}

export async function insertTmpModal (html_response, wrapper_target, element_target) {
  hideModal( document.getElementById(element_target), true ).then(() => {
    wrapper_target.insertAdjacentHTML( 'beforeend', html_response );
    $(document.getElementById(element_target)).modal('show')
  })
}