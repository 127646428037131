import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.managePill()
  }

  managePill() {
    // open pill with anchor tag
    var active_pill = $('[href="' + location.hash + '"]');
    active_pill && active_pill.tab('show');
    setTimeout(function() { window.scrollTo(0, 0); }, 10)

    // add anchor tag when clicking pill
    $('#pills-tab .nav-link').on('shown.bs.tab', function(e) {
      window.location.hash = e.target.hash;
      setTimeout(function() { window.scrollTo(0, 0); }, 10)
    });
  }
}