import _ from 'lodash'

export default class Analytics {
  constructor(data) {
    this.data = data
    console.log(data)
  }

  call() {
    this.templateWrapper.innerHTML = this.parseTemplate()
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  get compiledData () {
    return this.data
  }

  get templateWrapper() {
    return document.getElementById('nav-inquiries')
  }

  get template() {
    return document.getElementById('template-inquiries')
  }
}