import { Controller } from 'stimulus';
import template from "lodash/template";

export default class extends Controller {
  static targets = [
    'iconDot',
    'emptyPlaceholder'
  ];
  static values = {
    unreadcount: Number,
    countpollingurl: String
  };

  initialize() {
    this.pollingSeconds = 30;
    this.emptyPlaceholderRow = template(this.emptyPlaceholderTarget.innerHTML);
  }

  connect() {
    const controller = this;
    this.pollingInterval = setInterval(function() {
      controller.pollingUnreadCount();
    }, controller.pollingSeconds * 1000);
    $('.nav-tabs.tab-popup .nav-link').on('click', function(e) {
      e.stopPropagation();
      $(this).tab('show');
    });
    $('.notif-wrapper.dropdown-item').on('click', function(e) {
      e.preventDefault();
      $(this).remove();
      setTimeout(function() {
        if($(controller.notificationItemsTarget).children().length == 0) {
          $(controller.notificationItemsTarget).append(controller.emptyPlaceholderRow());
          controller.iconDotTarget.classList.add('hidden');
        }
      }, 0);
      window.open($(this).data('href'));
    });
  }
  disconnect() {
    clearInterval(this.data.pollingInterval);
  }

  pollingUnreadCount() {
    const controller = this;

    $.ajax({
      url: this.countpollingurlValue,
      type: 'json',
      method: 'get',
      success: function(data) {
        let total = parseInt(data.event_planner) + parseInt(data.venue_owner);
        controller.unreadcountValue = total;
        if(total > 0) {
          controller.iconDotTarget.classList.remove('hidden');
          $('.bottom-nav .notification .notif-dot').removeClass('hidden');
        } else {
          controller.iconDotTarget.classList.add('hidden');
          $('.bottom-nav .notification .notif-dot').addClass('hidden');
        }
      },
      error: function(xhr, status, error) {
        // stop when getting error response
        clearInterval(controller.data.pollingInterval);
      }
    });
  }

  get notificationItemsTarget() {
    return document.querySelector('.notif-list.active');
  }
}