import _ from 'lodash'
import moment from 'moment'
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatable-sorting-datetime-moment';

export default class AllEvents {
  constructor(data) {
    this.data = data
  }

  call() {
    this.updateTables()
    this.bindingDataTable()

    this.bindingShowHideColumns()
    this.bindingSortColumn()
    this.bindingSelect2()
    this.bindingSelectFilters()
    
    this.setFilterEnquiryDate()
    this.setFilterEventDate()
    this.setFilterEventTypes()
    this.setFilterAttendees()
    this.setFilterBudget()
    this.setFilterStatus()
    this.bindingFilterEnquiryDate()
    this.bindingFilterEventDate()
    // this.bindingFilterEventType()
    // this.bindingFilterAttendees()
    // this.bindingFilterBudget()
    // this.bindingFilterStatus()
  }

  bindingEventTemplate () {
    this.templateWrapper.innerHTML = this.parseTemplate()
  }

  updateTables() {
    this.bindingEventTemplate()

    /**
     *  Customers Table section
     *
     * load and parse <template id="template-customer-table"> then
     * bind each checkboxes for email and whatsapp template buttons toggle.
     */
    this.setTemplateTableWrapper()

    $(".btn-toggle-filter-events").each(function() {
      var $element = $(this).find('.fa')
      if ($element.hasClass("fa-eye-slash")) {
        $element.removeClass('fa-eye-slash').addClass('fa-eye')
      }
    })
  }

  bindingDataTable() {
    if ($.fn.dataTable.isDataTable('#all-events-table')) {
      this.datatable.destroy()
    }

    $.fn.dataTable.moment('DD MMM YYYY HH:mm');
    this.datatable = $('#all-events-table').DataTable({
      order: [[ 4, "desc" ]]
    })
  }

  bindingSelect2 () {
    $('[data-select]').select2({
      width: '100%'
    })
  }

  bindingSelectFilters() {
    let controller = this
    $('.select-more-filter-events').on('change', function(e){
      var value = $(this).val();

      // $('.status-dropdown').val(value)
      // console.log(status)
      //dataTable.column(6).search('\\s' + status + '\\s', true, false, true).draw();
      controller.datatable.column($(this).data('column')).search(value).draw();
    })
  }

  bindingSortColumn() {
    let controller = this

    $('.sortby-column-events').on('click', function(e){
      e.preventDefault();

      switch($(this).data('sort')) {
        case 'a':
          controller.datatable.order( [ 0, 'asc' ] ).draw();
          break;
        case 'z':
          controller.datatable.order( [ 0, 'desc' ]).draw();
          break;
        case 'n':
          controller.datatable.order( [ 4, 'desc' ] ).draw();
          break;
        case 'o':
          controller.datatable.order( [ 4, 'asc' ] ).draw();
          break;
      }
    })
  }

  bindingShowHideColumns() {
    let controller = this

    $('.btn-toggle-filter-events').on('click', function (e) {
      // Get the column API object
      let column = controller.datatable.column($(this).attr('data-column'))

      // Toggle the visibility
      column.visible(!column.visible(), false)

      //resize
      //controller.datatable.columns.adjust().draw()

      $(this).find('i').toggleClass('fa-eye-slash fa-eye');
    })
  }

  setTemplateTableWrapper () {
    this.templateTableWrapper.innerHTML = this.parseTemplateTable()
  }

  bindingFilterEnquiryDate () {
    let classDefine = this

    $(classDefine.filterEnquiryDateWrapper).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD MMM YYYY') + ' - ' + picker.endDate.format('DD MMM YYYY'));
      //classDefine.setTemplateTableWrapper()

      var start = picker.startDate.format('DD-MM-YYYY');
      var end = picker.endDate.format('DD-MM-YYYY');

      $.fn.dataTable.ext.search.push(
        function(settings, data, dataIndex) {
          var min = moment(start, "DD-MM-YYYY").toDate()
          var max = moment(end, "DD-MM-YYYY").toDate()
          var date = moment(data[4]).toDate()

          if (
            ( min === null && max === null ) ||
            ( min === null && date <= max ) ||
            ( min <= date   && max === null ) ||
            ( min <= date   && date <= max )
          ) {
            return true;
          }
          return false;
        }
      );
      classDefine.datatable.draw();
      $.fn.dataTable.ext.search.pop();
    });

    $(classDefine.filterEnquiryDateWrapper).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val("");
      picker.setStartDate(new Date());
      picker.setEndDate(new Date());
      classDefine.datatable.search('').draw();
      //classDefine.setTemplateTableWrapper()
    });
  }

  bindingFilterEventDate () {
    let classDefine = this

    $(classDefine.filterEventDateWrapper).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD MMM YYYY') + ' - ' + picker.endDate.format('DD MMM YYYY'));
      //classDefine.setTemplateTableWrapper()
      var start = picker.startDate.format('DD-MM-YYYY');
      var end = picker.endDate.format('DD-MM-YYYY');

      $.fn.dataTable.ext.search.push(
        function(settings, data, dataIndex) {
          var min = moment(start, "DD-MM-YYYY").toDate()
          var max = moment(end, "DD-MM-YYYY").toDate()
          var date = moment(data[5]).toDate()

          if (
            ( min === null && max === null ) ||
            ( min === null && date <= max ) ||
            ( min <= date   && max === null ) ||
            ( min <= date   && date <= max )
          ) {
            return true;
          }
          return false;
        }
      );
      classDefine.datatable.draw();
      $.fn.dataTable.ext.search.pop();
    });

    $(classDefine.filterEventDateWrapper).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val("");
      picker.setStartDate(new Date());
      picker.setEndDate(new Date());
      classDefine.datatable.search('').draw();
      //classDefine.setTemplateTableWrapper()
    });
  }

  bindingFilterEventType () {
    let classDefine = this

    this.filterEventTypeWrapper.addEventListener('change', function() {
      classDefine.setTemplateTableWrapper()
    })
  }

  bindingFilterAttendees () {
    let classDefine = this

    this.filterAttendeesWrapper.addEventListener('change', function() {
      classDefine.setTemplateTableWrapper()
    })
  }

  bindingFilterBudget () {
    let classDefine = this

    this.filterBudgetWrapper.addEventListener('change', function() {
      classDefine.setTemplateTableWrapper()
    })
  }

  bindingFilterStatus () {
    let classDefine = this

    this.filterStatusWrapper.addEventListener('change', function() {
      classDefine.setTemplateTableWrapper()
    })
  }

  setFilterEnquiryDate (){
    let classDefine = this

    $(classDefine.filterEnquiryDateWrapper).daterangepicker({
        autoUpdateInput: false,
        locale: {
          format: 'DD-MM-YYYY',
          cancelLabel: 'Reset'
        }
    });
  }

  setFilterEventDate (){
    let classDefine = this

    $(classDefine.filterEventDateWrapper).daterangepicker({
        autoUpdateInput: false,
        locale: {
          format: 'DD-MM-YYYY',
          cancelLabel: 'Reset'
        }
    });
  }

  setFilterEventTypes () {
    let classDefine = this

    this.dataEventTypes.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item
      option.innerHTML = item
      classDefine.filterEventTypeWrapper.append(option)
    });
  }

  setFilterAttendees () {
    let classDefine = this

    this.dataAttendees.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item.value
      option.innerHTML = item.label
      classDefine.filterAttendeesWrapper.append(option)
    });
  }

  setFilterBudget () {
    let classDefine = this

    this.dataBudget.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item.value
      option.innerHTML = item.label
      classDefine.filterBudgetWrapper.append(option)
    });
  }

  setFilterStatus () {
    let classDefine = this

    this.dataEventStatus.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item
      option.innerHTML = item
      classDefine.filterStatusWrapper.append(option)
    });
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  parseTemplateTable() {
    let htmlTemplate = ''
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.templateTable.innerHTML)
    this.dataEvents.forEach(function(item, _index) {
      htmlTemplate += compiled(item)
    });

    return htmlTemplate;
  }

  get compiledData () {
    let data = {}

    data['average_daily_rate'] = this.data.average_daily_rate
    data['average_response_time'] = this.data.average_response_time
    data['revenue'] = this.data.revenue
    data['total_inquiries'] = this.data.total_inquiries
    data['event_status'] = this.data.event_status

    return data
  }

  get templateWrapper() {
    return document.getElementById('nav-events')
  }

  get template() {
    return document.getElementById('template-events')
  }

  get templateTableWrapper() {
    return document.querySelector('table#all-events-table tbody')
  }

  get templateTable() {
    return document.getElementById('template-events-table')
  }

  get templateEventsFound() {
    return document.getElementById('eventfound')
  }

  get filterEnquiryDateWrapper() {
    return document.getElementById('enquirydate')
  }

  get filterEventDateWrapper() {
    return document.getElementById('eventdate')
  }

  get filterEventTypeWrapper() {
    return document.getElementById('eventtype')
  }

  get filterAttendeesWrapper() {
    return document.getElementById('attendees')
  }

  get filterBudgetWrapper() {
    return document.getElementById('budget')
  }

  get filterStatusWrapper() {
    return document.getElementById('status')
  }


  get dataEvents() {
    let data = this.data.data_events
    // let enquiryDate = $(this.filterEnquiryDateWrapper).val()
    // let eventDate = $(this.filterEventDateWrapper).val()
    // let eventType = this.filterEventTypeWrapper.value
    // let attendees = this.filterAttendeesWrapper.value
    // let budget = this.filterBudgetWrapper.value
    // let status = this.filterStatusWrapper.value

    // if (enquiryDate) {
    //   let idx = enquiryDate.length / 2;
    //   let enquiryDate_down = enquiryDate.substr(0, idx)
    //   let enquiryDate_up = enquiryDate.substr(idx + 1)
    //   let match = ""

    //   match = /(\d+)\-(\d+)\-(\d+)/.exec(enquiryDate_down)
    //   enquiryDate_down = new Date(match[3], parseInt(match[2])-1, match[1])
    //   match = /(\d+)\-(\d+)\-(\d+)/.exec(enquiryDate_up)
    //   enquiryDate_up = new Date(match[3], parseInt(match[2])-1, match[1]);
      
    //   data = _.filter(data, function(item) {
    //     var date = new Date(item.created_at)
    //     return date >= enquiryDate_down && date <= enquiryDate_up
    //   })
    // }

    // if (eventDate) {
    //   let idx = eventDate.length / 2;
    //   let eventDate_down = eventDate.substr(0, idx)
    //   let eventDate_up = eventDate.substr(idx + 1)
    //   let match = ""

    //   match = /(\d+)\-(\d+)\-(\d+)/.exec(eventDate_down)
    //   eventDate_down = new Date(match[3], parseInt(match[2])-1, match[1])
    //   match = /(\d+)\-(\d+)\-(\d+)/.exec(eventDate_up)
    //   eventDate_up = new Date(match[3], parseInt(match[2])-1, match[1]);
      
    //   data = _.filter(data, function(item) {
    //     var date = new Date(item.event_date)
    //     return date >= eventDate_down && date <= eventDate_up
    //   })
    // }

    // if(eventType) {
    //   data = _.filter(data, function(item) { return item.event_type == eventType })
    // }

    // if (attendees) {
    //   let attendees_down = attendees.split("-")[0];
    //   let attendees_up = attendees.split("-")[1];
  
    //   data = _.filter(data, function(item) {
    //     if (attendees_down == '<') {
    //       return parseInt(item.attendees) < parseInt(attendees_up)
    //     } else if (attendees_up == '>') {
    //       return parseInt(item.attendees) > parseInt(attendees_down)
    //     } else {
    //       return parseInt(item.attendees) >= parseInt(attendees_down) && parseInt(item.attendees) <= parseInt(attendees_up)
    //     }
    //   })
    // }

    // if (budget) {
    //   let budget_down = budget.split("-")[0];
    //   let budget_up = budget.split("-")[1];

    //   data = _.filter(data, function(item) {
    //     if (budget_down == '<') {
    //       return parseInt(item.budget) < parseInt(budget_up)
    //     } else if (budget_up == '>') {
    //       return parseInt(item.budget) > parseInt(budget_down)
    //     } else {
    //       return parseInt(item.budget) >= parseInt(budget_down) && parseInt(item.budget) <= parseInt(budget_up)
    //     }
    //   })
    // }

    // if(status) {
    //   data = _.filter(data, function(item) { return item.event_status_text == status })
    // }

    // this.templateEventsFound.innerHTML = data.length+' events found'

    return data
  }

  get dataEventTypes() {
    return _.uniq(_.map(this.data.data_events, 'event_type'))
  }

  get dataEventStatus() {
    return _.uniq(_.map(this.data.data_events, 'event_status_text'))
  }

  get dataAttendees() {
    return this.data.data_attendeeses
  }

  get dataBudget() {
    return this.data.data_budgets
  }
}