import { Controller } from 'stimulus'
import 'slick-carousel'
import WOW from 'wow.js'
import axios from 'axios';
import 'select2'

export default class extends Controller {
  static targets = [
    'continueBtn',
    'nextBtn',
    'formInput1',
    'formInput',
    'referralInput',
  ]

  initialize() {
    this.meetingRequestValue = null
    this.captchaValue = null
  }

  connect() {
    this.window_scroll()
    this.initialize_wow()
    this.data_toggle()
    this.init_select2()
    this.preloadCaptcha()

    this.togglePasswordDisplay()
    // Password strength checker
    $(".form-control--password-strength").on('keyup', function (e) {
      var result = document.querySelector("#progress"),
        pass = this.value,
        strong = new RegExp(
          "^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
          "g"
        ),
        medium = new RegExp(
          "^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
          "g"
        ),
        normal = new RegExp("(?=.{6,}).*", "g"),
        meter;
      if (pass.length) {
        if (strong.test(pass)) {
          meter = "strong";
        } else if (medium.test(pass)) {
          meter = "medium";
        } else if (normal.test(pass)) {
          meter = "normal";
        } else {
          meter = "bad";
        }
      } else {
        meter = "";
      }
      result.className = meter;
      return true;
    });
    this.formInputEvent()
  }

  // toggle password auth
  togglePasswordDisplay() {
    $(".toggle-password").on('click', function () {
      var inputPassword = $(this).next(".form-control--password");
      $(this).toggleClass('fa fa-eye fa fa-eye-slash')
      if (inputPassword.attr("type") === "password") {
        inputPassword.attr("type", "text");
      } else {
        inputPassword.attr("type", "password");
      }
    });
  }

  init_select2(){
    $('[data-select]').each(function() {
      $('[data-select]').select2({
        maximumSelectionLength: 5,
        placeholder: $(this).attr('placeholder'),
      })
    })
  }

  createSuccess(event){
    alert('fully tested');
  }

  data_toggle(){
    $('[data-toggle="tooltip"]').tooltip();
  }

  window_scroll(){
    $(window).on('scroll', function() {
      var scroll = $(window).scrollTop();
      if (scroll >= 450) {
          $(".sticky-top").addClass('full');
      } else {
          $(".sticky-top").removeClass("full");
      }
    });
  }

  initialize_wow(){
    new WOW().init();
  }

  preloadCaptcha() {
    const controller = this

    if (controller.hasContinueBtnTarget) {
      $(controller.continueBtnTarget).off('click')
      $(controller.continueBtnTarget).on('click', function (e) {
        e.preventDefault()
        if (controller.formIsValid1() === true) {
          let continueAction = true;
          let referral = controller.referralInputTarget.value.trim()
          if (referral != '') {
            $.ajax({
              method: "get",
              url: "/api/v1/vendors/referrals",
              data: {
                code: referral,
              },
              async: false,
              success: function (result) {
                if(!result.success) {
                  continueAction = false
                  toastr.error("Referral code does not exist")
                }
              }
            })
          }

          if (continueAction){
            $('#detailsForm').hide()
            $('#details2Form').show()
          }
        }
      })
    }

    $(controller.nextBtnTarget).off('click')
    $(controller.nextBtnTarget).on('click', function (e) {
      e.preventDefault()
      if (controller.formIsValid() === true) {
        $('#captchaModal').modal('show')
      }
    })

    if(!this.captchaValue != null) {
      this.captchaValue = sliderCaptcha({
        id: "captcha",
        repeatIcon: "fa fa-repeat",
        onSuccess: function() {
          controller.copyCaptchaSession()
          setTimeout(function() { controller.postMeetingRequest() }, 0)
        },
        onFail: function() {
          controller.captchaValue.reset()
        }
      });
    } else {
      this.captchaValue.reset()
    }
  }

  get whyDetailForm() { return document.getElementById('new_user') }

  get whySidField() { return document.getElementById('captcha_sid') }

  postMeetingRequest() {
    const controller = this

    if(this.meetingRequestValue != null) {
      this.meetingRequestValue.abort()
    }

    var formData = new FormData(this.whyDetailForm);

    this.meetingRequestValue = $.ajax({
      method: this.whyDetailForm.getAttribute('method'),
      url: this.whyDetailForm.getAttribute('action'),
      processData: false,
      contentType: false,
      data: formData,
      type: 'script'
    })

    this.meetingRequestValue
      .done(function() {})
      .fail(function(e) {
        toastr.error(e.responseText)
      })
      .always(function() {
        $('#captchaModal').modal('hide')
        controller.captchaValue.reset()
      })
  }

  copyCaptchaSession() {
    this.whySidField.removeAttribute('disabled')
    this.whySidField.value = this.whyDetailForm.dataset.session
  }

  formInputEvent() {
    const controller = this

    if (controller.hasContinueBtnTarget) {
      controller.continueBtnTarget.setAttribute('disabled', 'disabled')
      for (const input of controller.formInput1Targets) {
        $(input).on('change input', function(e) {
          if (controller.formIsValid1() === true) {
            controller.continueBtnTarget.removeAttribute('disabled')
          } else {
            controller.continueBtnTarget.setAttribute('disabled', 'disabled')
          }
        })
      }
    }

    controller.nextBtnTarget.setAttribute('disabled', 'disabled')
    for (const input of controller.formInputTargets) {
      input.addEventListener('input', () => {
        if (controller.formIsValid() === true) {
          controller.nextBtnTarget.removeAttribute('disabled')
        } else {
          controller.nextBtnTarget.setAttribute('disabled', 'disabled')
        }
      })
    }

    // $("#vendor_description").on('keyup', function (e) {
    //   // If the user has pressed enter
    //   if ((e.keyCode == 10 || e.keyCode == 13)) {
    //     e.preventDefault()
    //   }

    //   $(".limit-character").html((200 - this.value.length))
    // })
  }

  formIsValid1() {
    const controller = this
    let validForm = true
    for (const input of controller.formInput1Targets) {
      if (input.value === '') {
        validForm = false
        break
      }

      if (input.tagName == "SELECT"){
        var selected = []
        for (var option of input.options) {
          if (option.selected) {
            selected.push(option.value);
          }
        }

        if (selected.length == 0) {
          validForm = false
          break
        }
      }

      if (!input.checkValidity()){
        validForm = false
        break
      }
    }
    return validForm
  }

  formIsValid() {
    const controller = this
    let validForm = true
    for (const input of controller.formInputTargets) {
      // if (input.type == "file" && input.files.length == 0){
      //   validForm = false
      //   break
      // }

      if (input.tagName == "SELECT"){
        var selected = []
        for (var option of input.options) {
          if (option.selected) {
            selected.push(option.value);
          }
        }

        if (selected.length == 0) {
          validForm = false
          break
        }
      }

      if (input.value === '') {
        validForm = false
        break
      }

      if (!input.checkValidity()){
        validForm = false
        break
      }
    }
    return validForm
  }
}