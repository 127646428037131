import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['newAssignBtn', 'searchKeywordInput']

  static values = {}

  initialize() {}

  connect() {
    const controller = this;
    //let searchRequest = null;
    this.newAssignBtnTarget.addEventListener('click', function(e) {
      controller.assignsController.openModal({})
    })
    // TODO: async search
    // this.searchKeywordInputTarget.addEventListener('keyup', function(e) {
    //   e.preventDefault()
    //   if(e.keyCode == 13) {
    //     alert(this.value);
    //   }
    // })
    document.querySelectorAll('.btn--change-access')
            .forEach(function(el) {
              el.addEventListener('click', function (e) {
                controller.changeAccessClickListener(e)
              })
            })
  }

  disconnect() {
    this.assignsController.closeModal()
  }

  get assignsController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('assign_user_modal'), 'users--entity-roles--assigns')
  }

  changeAccessClickListener(e) {
    this.assignsController.openModal({
      submitUrl: e.target.dataset.submitUrl,
      entity: JSON.parse(e.target.dataset.entity)
    });
  }
}
