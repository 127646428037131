import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    usertype: String,
  };

  connect() {
    this.checkingContent()
    this.bindingSearchHelpCenter()
  }

  smoothSlider(target){
    $('html,body').animate({
      scrollTop: target.offset().top - 70
    }, 1000);
  }

  checkingContent() {
    let controller = this
    
    if ($('#faq-wrapper').length > 0 & $('#faq-wrapper').data('select-faq') != undefined & $('#room-wrapper').data('select-faq') != '') {
      var faq_id = $('#faq-wrapper').data('select-faq');
      var select_faq = $('.faqItem[data-id="'+faq_id+'"]');
      if (select_faq.length > 0) {
        var click = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          synthetic: true,
          view: window
        });
        var tab = document.getElementById(select_faq.attr('id'));
        tab.dispatchEvent(click);
      }

      var content_id = $('#faq-wrapper').data('select-content');
      var select_content = $('#content_'+content_id);
      if (select_content.length > 0) {
        setTimeout(function(){
          controller.smoothSlider(select_content)
          var click = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            synthetic: true,
            view: window
          });
          var tab = document.getElementById(select_content.attr('id'));
          tab.dispatchEvent(click);
        }, 500)
      }
    }
  }

  bindingSearchHelpCenter(){
    let controller = this
    let country = $('#nav_venue_default').data('country')

    $( "#help_center_keyword" ).autocomplete({
      source: "/api/v1/help_center_contents/search?country="+country+"&user_type="+controller.usertypeValue,
      minLength: 1,
      open: function(event, ui) {
        $("ul.ui-menu").width( $(this).innerWidth() )
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
          return $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
        }
      },
      select: function(event, ui) {
        event.preventDefault();
        $( "#help_center_keyword" ).val(ui.item.label)
        location.href = ui.item.link;
      }
    }).data("ui-autocomplete")._renderItem = function(ul, item) {
      return $('<li class="ui-menu-item">').append('<div tabindex="-1" class="ui-menu-item-wrapper"><div class="ui-menu-item-label">' + item.label + '</div><div class="ui-menu-item-type">' + item.type_label + '</div></div>').appendTo(ul);
    }
  }
}