import { Controller } from 'stimulus'
import _ from 'lodash'
import axios from '../../../vendor/axios'
import { freeTrialSetup } from '../../../mixins/free_trial_setup'

const moment = require('moment')

export default class extends Controller {
  static targets = ['analyticsTemplate', 'changeVenue', 'analyticsWrapper',
                    'opportunityCalculator',
                    'opportunityCalculatorResult',
                    'opportunityCalculatorSubmit',
                    'opportunityCalculatorLoading',
                    'opportunityCalculatorForm',
                    'opportunityCalculatorTable',
                    'opportunityCalculatorSearch',
                    'opportunityCalculatorSearchWrapper']

  static values = {
    showAfterCampaignRegModal: Boolean
  }

  connect () {
    this.owner_and_paid_plan = false
    const controller = this;

    freeTrialSetup(controller)

    if (this.hasAnalyticsTemplateTarget) {
      this.setAnalyticsTemplate()
    }
    $(".progress").each(function() {
      var value = $(this).attr('data-value');
      var left = $(this).find('.progress-left .progress-bar');
      var right = $(this).find('.progress-right .progress-bar');

      if (value > 0) {
        if (value <= 50) {
          right.css('transform', 'rotate(' + controller.percentageToDegrees(value) + 'deg)')
        } else {
          right.css('transform', 'rotate(180deg)')
          left.css('transform', 'rotate(' + controller.percentageToDegrees(value - 50) + 'deg)')
        }
      }
    });

    this.initAfterSignUpModal()
  }

  clickAddOn (_event) {
    this.showhideWhatsappButton()
  }

  setAnalyticsTemplate () {
    let controller = this

    controller.showhideLoading()
    // controller.showhideBanner(false)
    axios.get(this.analyticsPath, {
      params: {
        day_range_picker: this.dayRange,
        range_type: 'day',
        data_type: 'analytics',
        based_on: 'created_at'
      }
    }).then((response) => {
      controller.showhideLoading(false)
      // controller.showhideBanner()
      let compiledData = controller.compiledData(response.data)
      let parseTemplate = controller.parseTemplate(compiledData)

      controller.analyticsWrapperTarget.innerHTML = parseTemplate

      controller.opportunityCalculatorSearch()
    });
  }

  parseTemplate(parseResult) {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.analyticsTemplateTarget.innerHTML)

    return compiled(parseResult)
  }

  changeVenue(_event) {
    this.setAnalyticsTemplate()
  }

  clickToWhatsapp () {
    let selected = document.querySelectorAll('.card-input-element:checked')

    if(document.querySelectorAll('.card-input-element:checked').length <= 0) {
      return
    }

    let text_element = [...selected].map(function(elem){ return elem.dataset.value }).join(', ')
    let text = `Hi Venuerific Team, %0a%0aThis is ${this.ownerName} from ${this.venueName}. I'm interested in finding out more about these add-ons: ${text_element} for my venue.`
    let phone = this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.phone
    window.open("https://wa.me/"+phone+'?text='+text);
  }

  clickToWhatsappCalculator () {
    if (this.owner_and_paid_plan === true) {
      let text_element = 'Ranking Boost'
      let text = `Hi Venuerific Team, %0a%0aThis is ${this.ownerName} from ${this.venueName}. I'm interested in finding out more about these add-ons: ${text_element} for my venue.`
      let phone = this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.phone
      window.open("https://wa.me/"+phone+'?text='+text);
    } else {
      $('#upgradeModal').modal('show');
    }
  }

  showhideWhatsappButton () {
    if(document.querySelectorAll('.card-input-element:checked').length > 0) {
      document.querySelector('#whatsapp-button').classList.remove('na')
    } else {
      document.querySelector('#whatsapp-button').classList.add('na')
    }
  }

  showhideLoading(show = true) {
    if (show) {
      document.getElementById('loading-stats').classList.remove('hidden')
      document.getElementById('loading-wrapper').classList.remove('hidden')
      this.analyticsWrapperTarget.classList.add('hidden')
    } else {
      document.getElementById('loading-stats').classList.add('hidden')
      document.getElementById('loading-wrapper').classList.add('hidden')
      this.analyticsWrapperTarget.classList.remove('hidden')
    }
  }

  showhideBanner(show = true) {
    if (!this.bannerShowHideTarget) {
      return
    }

    if (!this.venueIsPsg) {
      if (show) {
        this.bannerShowHideTarget.classList.remove('hidden')
      } else {
        this.bannerShowHideTarget.classList.add('hidden')
      }
    } else {
      this.bannerShowHideTarget.classList.add('hidden')
    }
  }

  compiledData (data) {
    data['venue_name'] = this.venueName
    data['venue_country'] = this.venueCountry
    data['upgrade_modal_url'] = this.upgradeModalUrl
    data['total_inquiries'] = data.total_inquiries.header
    data['total_views'] = data.views.header
    data['total_calls'] = data.call_logs.header
    data['is_free'] = this.venueIsFree
    data['is_psg'] = this.venueIsPsg
    data['stats_link'] = this.venueStatsLink
    this.owner_and_paid_plan = data.owner_and_paid_plan

    return data
  }

  get venueName() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].text
  }

  get venueIsFree() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.isFree == 'true'
  }

  get venueIsPsg() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.isPsg == 'true'
  }

  get venueStatsLink () {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.statsLink
  }

  get venueId() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.id
  }

  get venueCountry() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.country
  }

  get ownerName() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.user
  }

  get analyticsPath() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].value
  }

  get upgradeModalUrl() {
    return this.changeVenueTarget.options[this.changeVenueTarget.selectedIndex].dataset.upgradeModal
  }

  get bannerShowHideTarget() {
    return document.getElementById('banner-show-hide')
  }

  get dayRange() {
    return moment().subtract(1, 'month').format("DD-MM-YYYY") + ' - ' + moment().format("DD-MM-YYYY")
  }

  percentageToDegrees(percentage) {
    return percentage / 100 * 360
  }

  get afterCampaignRegModal() { return document.getElementById('afterRegistrationModal') }

  opportunityCalculatorSearch() {
    const controller = this
    if (controller.hasOpportunityCalculatorTarget) {
      // Change button icon and text depending if the venue plan is paid or free
      if (controller.owner_and_paid_plan === true) {
        document.querySelector('.calc-button').innerHTML = "The right keyword can help you grow your business!<br>Don't miss out, enquire now.";
        document.querySelector('.svg-wa').classList.remove('hidden');
        document.querySelector('.svg-upgrade').classList.add('hidden');
      } else {
        document.querySelector('.calc-button').innerHTML = 'Take a peek who is getting more business in your space!<br>Talk to our team and Upgrade your account today.';
        document.querySelector('.svg-wa').classList.add('hidden');
        document.querySelector('.svg-upgrade').classList.remove('hidden');
      }
      controller.opportunityCalculatorLoadingTarget.classList.add('hidden')
      controller.opportunityCalculatorResultTarget.classList.add('hidden')
      controller.opportunityCalculatorTableTarget.classList.add('hidden')
      $(controller.opportunityCalculatorTarget).each(function(index, data) {
        let that = $(this)
        return $(data).autocomplete({
          source: $(data).data('source'),
          minLength: $(data).data('length'),
          autoFocus: true,
          open: function(event, ui) {
            $("ul.ui-menu").width( $(this).innerWidth() )
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
              return $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
            }
          },
          select: function(event, ui) {
            event.preventDefault();
            that.val(ui.item.label);
          },
          focus: function(event, ui) {
            event.preventDefault()
            if (that.val().toLowerCase() == ui.item.label.toLowerCase()){
              that.data('ui-autocomplete')._trigger('select', 'autocompleteselect', ui);
            }
          },
          change: function(event, ui) {
            if ((ui.item === null) || (that.val().toLowerCase() != ui.item.label.toLowerCase())) {
              that.val('');
            }
          }
        }).data("ui-autocomplete")._renderItem = function(ul, item) {
          return $('<li class="ui-menu-item">').append('<div tabindex="-1" class="ui-menu-item-wrapper"><div class="ui-menu-item-label">' + item.label + '</div><div class="ui-menu-item-type">' + item.type_label + '</div></div>').appendTo(ul);
        };
      })
      controller.opportunityCalculatorSubmitTarget.addEventListener('click', function(e) {
        e.preventDefault()
        controller.opportunityCalculatorSubmitTarget.setAttribute('disabled', 'disabled')
        controller.opportunityCalculatorLoadingTarget.classList.remove('hidden')

        if (controller.opportunityCalculatorTarget.value !== '') {
          $.ajax({
            url: `${controller.opportunityCalculatorFormTarget.dataset.url}?keyword=${encodeURIComponent(controller.opportunityCalculatorTarget.value)}`,
            type: 'json',
            method: 'GET'
          }).done((data) => {
            // console.log(data)
            if (data.status === 'ok') {
              controller.opportunitySearch(data.search_counts[0])
              controller.opportunityCounts(data.enquiry_counts, data.view_counts, data.attempt_counts, data.country_currency)
              // content click tracking Content::OpportunityCalculator
              $.ajax({
                url: `${controller.opportunityCalculatorSubmitTarget.dataset.contentNoLink}&url=${encodeURIComponent(window.location.href)}&event_type=${encodeURIComponent(controller.opportunityCalculatorTarget.value)}`,
                dataType: 'json',
                type: 'get',
                success: function(data) {
                  // console.log(data)
                }
              });
            }
            controller.opportunityCalculatorLoadingTarget.classList.add('hidden')
            controller.opportunityCalculatorResultTarget.classList.remove('hidden')
            controller.opportunityCalculatorSubmitTarget.removeAttribute('disabled')
          })
        } else {
          controller.opportunityCalculatorSubmitTarget.removeAttribute('disabled')
          controller.opportunityCalculatorLoadingTarget.classList.add('hidden')
        }
      })
    }
  }

  opportunitySearch(search_counts) {
    const controller = this
    if (!search_counts) {
      controller.opportunityCalculatorSearchTarget.innerText = '';
      return;
    }
    var text = `${search_counts.value} searched ${search_counts.avg_hit_count} times`;
    controller.opportunityCalculatorSearchTarget.innerText = text;
  }

  opportunityCounts(enquiry_counts, view_counts, attempt_counts, country_currency) {
    const controller = this
    let names = document.getElementsByClassName('calc-name')
    let views = document.getElementsByClassName('calc-view')
    let enquiries = document.getElementsByClassName('calc-enquiry')
    let attempts = document.getElementsByClassName('calc-attempt')

    for (let i = 0; i < 3; i++) {
      var enquiry_count = enquiry_counts[i];
      var name = ''
      var view = ''
      var enquiry = ''
      var attempt = ''

      if (enquiry_count !== undefined) {
        name = enquiry_count.venue_name
        view = view_counts[enquiry_count.venue_id] ? view_counts[enquiry_count.venue_id].avg_view : ''
        enquiry = enquiry_count.avg_enquiry
        attempt = attempt_counts[[enquiry_count.venue_id]] ? attempt_counts[enquiry_count.venue_id].avg_attempt : ''
        if (controller.owner_and_paid_plan === false) {
          view = '&lowast;&lowast;&lowast;'
          enquiry = '&lowast;&lowast;&lowast;'
          attempt = '&lowast;&lowast;&lowast;'
        }
      }

      names[i].innerText = name
      views[i].innerHTML = view
      enquiries[i].innerHTML = enquiry
      attempts[i].innerHTML = attempt
    }

    controller.opportunityCalculatorTableTarget.classList.remove('hidden')
  }
}
