import Rails from '@rails/ujs'
import _ from "lodash";

export default class Activities {
  constructor(base_url) {
    this.base_url = base_url
  }

  call() {
    this.getData()
  }

  getData() {
    let url = this.base_url + '/show_data?data_type=event_actions'

    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successResponse.bind(this)
    })
  }

  successResponse(response) {
    this.data = response
    this.wrapperActivities.innerHTML = this.parseTemplate()
  }

  parseTemplate () {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    }

    let compiled = _.template(this.templateActivities.innerHTML)

    return compiled(this.data)
  }

  get wrapperActivities() {
    return document.getElementById('wrapper-activities')
  }

  get templateActivities() {
    return document.getElementById('template-activities')
  }
}