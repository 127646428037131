import { Controller } from 'stimulus'
import axios from '../../../vendor/axios';
import toastr from 'toastr';

export default class extends Controller {
  static targets = [ 'content' ]
  static values = {
    backurl: String,
    subject: String
  }

  connect() {
    this.loadAllHeaderFunction()
    this.initEditor()
    this.setSubject()
  }

  loadAllHeaderFunction () {
    let controller = this
    let timer = null;

    this.saveButton.addEventListener('click', function() {
      $(controller.modalSetName).modal('show')
    });

    this.realSave.addEventListener('click', function() {
      controller.save()
    });

    this.publishedButton.style = "display:none;"

    this.backButton.innerText = "Back to Message Templates"
    this.backButton.href = controller.backurlValue;
    this.backButton.addEventListener('click', function(event) {
      window.location.href = controller.backurlValue;
    })
  }

  save () {
    this.saveData({ showing_failed_saving: true })
  }

  saveSubject () {
    this.saveData({ showing_failed_saving: true, subject: this.subjectEditor.value })
  }

  saveData (params = {}) {
    let controller = this
    let showing_failed_saving = params.showing_failed_saving
    let subject = this.subjectEditor.value

    this.editor.exportHtml(function(data) {
      axios({
        method: controller.contentTarget.dataset.method,
        url: controller.contentTarget.dataset.url,
        data: {
          template: {
            message_content: data,
            message_subject: subject,
            name: controller.setName.value
          }
        },
      }).then(function (response) {
        window.location.href = controller.backurlValue;
      }).catch(function (error) {
        if (showing_failed_saving) {
          toastr.error("Failed to update template")
        } else {
          console.log(error)
        }
      });
    });
  }

  isScriptInjected () {
    const scripts = document.querySelectorAll('script');
    let injected = false;
    let controller = this

    scripts.forEach((script) => {
      if (script.src.includes(controller.scriptUrl)) {
        injected = true;
      }
    });

    return injected;
  };

  initEditor() {
    let controller = this
    if (!this.isScriptInjected()) {
      const embedScript = document.createElement('script');
      embedScript.setAttribute('src', controller.scriptUrl);
      embedScript.onload = () => {
        controller.loadEditor();
      };
      document.head.appendChild(embedScript);
    } else {
      controller.loadEditor();
    }
  }

  loadEditor() {
    this.editor = unlayer.createEditor({
      id: 'email-editor',
      displayMode: 'email',
      mergeTags: {
        first_name: {
          name: "First Name",
          value: "{{first_name}}",
          sample: "John"
        },
        last_name: {
          name: "Last Name",
          value: "{{last_name}}",
          sample: "Doe"
        }
      }
    });

    let data_saving = JSON.parse(this.contentTarget.dataset.value)

    if (data_saving && data_saving.design) {
      this.editor.loadDesign(data_saving.design);
    }
  }

  get scriptUrl () {
    return '//editor.unlayer.com/embed.js?2'
  }

  get editorId () {
    return document.getElementById('email-editor')
  }

  get saveButton () {
    return document.getElementById('save-button')
  }

  get subjectEditor () {
    return document.getElementById('subject-editor')
  }

  get publishedButton () {
    return document.getElementById('published-button')
  }

  get backButton () {
    return document.getElementById('back-button')
  }

  get realSave () {
    return document.getElementById('real-save')
  }

  get modalSetName () {
    return document.getElementById('modalSetName')
  }

  get setName () {
    return document.getElementById('input_name')
  }

  setSubject() {
    this.subjectEditor.value = this.subjectValue;
  }
}
