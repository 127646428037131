import { Controller } from 'stimulus'
import 'slick-carousel'
import 'lightslider'
import axios from 'axios';
import 'lazysizes'
import { Device } from 'twilio-client'
import MobileDetect from 'mobile-detect'

export default class extends Controller {
  static targets = [
    'fastEnquiryButton',
    'manualEnquiryButton',
    'fastEnquiryModal'
  ];

  connect() {
    this.cancelModal()
    this.check_size_validation()
    this.popover_preview_file()
    this.change_image_based_review_photo()
    this.buildReviewPhotoEventAfterInsert()
    this.promoVenueSlider()
    this.modalAfterReview()
    this.daterangepickerAdded()
    this.bindingFormInputEventType()
    this.eventStatusFilter()
    this.contactLink()
    this.initClaimRewardFrom()
  }

  // Hide and show textarea in cancel enquiry modal
  cancelModal() {
    $("#cancel-select-form").on("change", function () {
      var textarea = $("#cancel-reason-box");
      if ($("#cancel-select-form").val() === "Other") {
        textarea.addClass("active");
      } else {
        textarea.removeClass("active");
      }
    });
  }

  // Check uploaded file size
  check_size_validation() {
    $('.check-size-validation').each(function(index, element) {
      let limit_size = $(element).data('limit-size');
      let limit = limit_size*1024*1024;

      $(element).on('change', function() {
        if(this.files[0] && this.files[0].size < limit) {
          $(this).closest('.review-photo-fields').find('.text--error').text('').removeClass('hold-submit');
          if ($(this).closest('form').find('.hold-submit').length === 0) {
            $(this).closest('form').find('[type=submit]').removeAttr('disabled');
          }
        } else {
          $(this).closest('.review-photo-fields').find('.text--error').text("File is more than "+limit_size+"MB").addClass('hold-submit');
          $(this).closest('form').find('[type=submit]').prop('disabled', true)
        }
      });
    });
  }

  // review form popover image
  popover_preview_file() {
    if ($('[data-toggle="popover-hover"][data-img]').length > 0) {
      $('[data-toggle="popover-hover"][data-img]').popover({
        html: true,
        trigger: 'hover',
        placement: 'right',
        content: function () { return '<img src="' + $(this).data('img') + '" class="img-responsive" />'; }
      });
    }
  }

  readURL(input,dest) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        dest.attr('src', reader.result);
        dest.data('img', reader.result);
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  change_image_based_review_photo() {
    const that = this;
    $('#review_photos').on('change','input[type=file]', function(){
      that.readURL(this, $(this).closest('.review-photo-fields').find('img'))
      $(this).closest('.review-photo-fields').find('.text--message').text(this.files[0] ? this.files[0].name : 'No File Choosen')
      that.popover_preview_file()
    })
  }

  // add event after insert(cocoon gem)
  buildReviewPhotoEventAfterInsert() {
    const that = this;
    $('#review_photos').on('cocoon:after-insert', function() {
      that.check_size_validation(); // activate event for newly added photo field
    });
  }

  promoVenueSlider() {
    $(".promo-items").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $(".promo-prev"),
      nextArrow: $(".promo-next"),
      variableWidth: true,
    });
    if ($(".promo-venue_photo_lightslider").length > 0) {
      $(".promo-venue_photo_lightslider").lightSlider({
        item: 1
      });
    }
  }

  modalAfterReview() {
    if ($(".after-review-trigger").length > 0) {
      setTimeout(function () {
        $("#afterSubmitModal").modal("show");
      }, 500);
    }
  }

  daterangepickerAdded() {
    $('#search_event_date_picker').daterangepicker({
        autoUpdateInput: false,
        locale: {
          format: 'DD-MM-YYYY',
          separator: ' - ',
          cancelLabel: 'Clear'
        }
    });

    $('#search_event_date_picker').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
      $('.simple_form.search_subpage').submit();
    });

    $('#search_event_date_picker').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
      $('.simple_form.search_subpage').submit();
    });
  }

  bindingFormInputEventType(){
    $("#search_event_types_supported").autocomplete({
      minLength: 3,
      source: function(request, responseFn) {
        let term = request.term;
        axios.get("/api/v1/venues/search?type=event_type&country="+$("#search_event_types_supported").data('country'), {
          params: {
            query: term,
            purpose: 'autocomplete'
          }
        })
        .then(function(response) {
          if(response.status == '200') {
            responseFn(response.data['suggestions']);
          }
        })
      }
    })
  }

  eventStatusFilter() {
    $('.event-status-filter').on('click', function(){
      if ($(this).hasClass('active')) {
        if (!$(this).hasClass('all-status')) {
          $(this).removeClass('active')
        }
        $('#search_event_status').val('')
      } else {
        $(this).siblings().removeClass('active')
        $(this).addClass('active')
        $('#search_event_status').val($(this).attr('value'))
      }

      $('.simple_form.search_subpage').submit()
    });
  }

  contactLink() {
    let controller = this

    $(document).on('click', '[data-remote-href]', function(e) {
      var element = $(this)
      var link = element.data('remote-href');
      if (element.data('value') != "call"){
        var win = window.open(link, '_blank');
        win.focus();
      } else {
        let md = new MobileDetect(window.navigator.userAgent)
        if (md.mobile() || md.tablet()) {
          var win = window.open(link, '_blank');
          win.focus();
        } else {
          controller.callFeature(e.currentTarget)
        }
      }
    });

    $( "[data-remote-href]" ).contextmenu(function(e) {
      e.preventDefault();
    });
  }

  callFeature(element) {
    $('#contactOwnerModal').modal('hide')
    $('#modal_for_venue_number').modal('show')

    let country = element.dataset.venueCountry
    let venue_id = element.dataset.venueId
    let contact_phone = element.dataset.contactPhone
    let controller = this

    axios.post("/"+country+"/venues/"+venue_id+"/generate_twilio_token_to_call").then(function (response) {
      controller.to = response.data.to
      controller.user_id = response.data.user_id

      // Set up the Twilio Client Device with the token
      Device.setup(response.data.token)
    })

    Device.ready(function (_device) {
      if (controller.to != undefined) {
        document.getElementById("proceed-to-call").style.display = "block";
        document.getElementById("hangup-call").style.display = "none";
      }
    })

    Device.error(function (error) {
      console.log("ERROR: " + error.message)
    })

    /* Callback for when a call ends */
    Device.disconnect(function(connection) {
      // Disable the hangup button and enable the call buttons
      document.getElementById("proceed-to-call").style.display = "block";
      document.getElementById("hangup-call").style.display = "none";
      document.getElementById("cancel-call").style.display = "block";
    })

    Device.connect(function (connection) {
      document.getElementById("proceed-to-call").style.display = "none";
      document.getElementById("hangup-call").style.display = "block";
      document.getElementById("cancel-call").style.display = "none";
    })

    document.getElementById("hangup-call").addEventListener("click", function() {
      Device.disconnectAll()
      $('#modal_for_venue_number').modal('hide')
    })

    $(document).on('hidden.bs.modal', '#modal_for_venue_number', function () {
      Device.disconnectAll()
    })

    document.getElementById("proceed-to-call").addEventListener("click", function() {
      /* Call a customer from a support ticket */
      // console.log(controller.to)
      // console.log(contact_phone)
      // console.log(controller.user_id)
      // console.log(venue_id)
      Device.connect({
        'From': contact_phone,
        'To': controller.to,
        'user_id': controller.user_id,
        'venue_id': venue_id
      })
    })
  }

  initClaimRewardFrom() {
    $('#claim-reward-form').on('submit', function(e) {
      e.preventDefault();
    });
  }

  setClaimButtonData(e) {
    $('.claim-reward-button').data('event-id', $(e.currentTarget).data('event-id'));
    $('#claim-reward-form .claim-name-input').val($(e.currentTarget).data('name'));
    $('#claim-reward-form .claim-phone-input').val($(e.currentTarget).data('phone'));
    $('#claim-reward-form textarea').val('');
    $('.claim-reward-name').text($(e.currentTarget).data('reward-name'));
  }

  sendClaimRequest(e) {
    var country = $(e.currentTarget).data('country'),
        event_id = $(e.currentTarget).data('event-id');

    let token = function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    };

    $.ajax({
      beforeSend: token,
      dataType: 'json',
      url: '/' + country + '/users/events/' + event_id + '/venue_reward_shipments',
      method: 'POST',
      data: $('#claim-reward-form').serialize()
    }).done(function(data) {
      if (data.success) {
        $('#modalClaimReward').modal('hide')
        $('#modalAfterClaim').modal('show');
        $('.claim-reward-' + event_id).remove();
        $('.give-review-' + event_id).removeClass('hidden');
      } else {
        // console.log(data);
      }
    });
  }

  clickFastEnquiryModal(e) {
    let eventId = e.currentTarget.dataset.event
    let eventType = e.currentTarget.dataset.eventType
    let extras = e.currentTarget.dataset.extras

    this.fastEnquiryButtonTarget.dataset.event = eventId
    this.fastEnquiryButtonTarget.dataset.extras = extras
    this.manualEnquiryButtonTarget.dataset.eventType = eventType

    $(this.fastEnquiryModalTarget).modal('show')
  }

  clickFastEnquiryButton(e) {
    let eventId = e.currentTarget.dataset.event

    axios.get("/api/v1/venues/fast_enquiry", {
      params: {
        event_id: eventId
      }
    })
    .then(function(response) {
      if(response.status == '200') {
        localStorage.setItem("fast_enquiry_event", eventId);
        window.location.href = response.data.url
      }
    })
  }

  clickManualEnquiryButton(e) {
    let country = e.currentTarget.dataset.country
    let eventType = e.currentTarget.dataset.eventType
    let url = '/' + country + '/search?search[event_types_supported]=' + eventType

    window.location.href = url
  }
}