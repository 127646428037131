import { Controller } from 'stimulus'
import Details from './show/details'
import Documents from './show/documents'
import TodoList from './show/todo_list'
import Activities from './show/activities'
// import Notes from './show/notes'
// import SmallActivities from './show/small_activities'
import SidebarDetails from './show/sidebar_details'
import Rails from '@rails/ujs'
import { hideModal } from '../../../library/modal'
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'eventName',
    'selectEventStatus',
    'prevButton',
    'nextButton',
    'eventStatusSelected',
    'upgradeButton',
    'eventWrapper',
    'noEventWrapper',
    'eventEmailTplSelect',
    'eventEmailComposeBtn'
  ]

  connect() {
    this.activeTab = this.defaultTab
    this.showTab()
    // this.loadNotes()
    // this.loadSmallActivities()
    this.loadSidebarDetails()
    this.eventStatusTrigger()
    this.showHideNavButton()
  }

  checkingVenueFree() {
    if (this.checkVenueFree == 'true') {
      $('#subpupgrade').modal('show');
      this.upgradeButtonTarget.setAttribute('href', this.element.dataset.contactAdmin)
      return
    }
  }

  changeEvent(event) {
    if (this.checkVenueFree == 'true') {
      $('#subpupgrade').modal('show');
      this.upgradeButtonTarget.setAttribute('href', this.element.dataset.contactAdmin)
      return
    }

    if (event.currentTarget.dataset.navigation == 'next') {
      this.element.dataset.eventPage = parseInt(this.element.dataset.eventPage) + 1
    } else if (event.currentTarget.dataset.navigation == 'prev') {
      this.element.dataset.eventPage = parseInt(this.element.dataset.eventPage) - 1
    } else if (event.currentTarget.dataset.navigation == 'status') {
      this.element.dataset.eventPage = 1
    }

    if(this.selectEventStatusTarget[this.selectEventStatusTarget.selectedIndex].dataset.realTotal == 0) {
      this.showHideNavButton()
      this.eventWrapperTarget.classList.add('hidden');
      this.noEventWrapperTarget.classList.remove('hidden');
    } else {
      this.eventWrapperTarget.classList.remove('hidden');
      this.noEventWrapperTarget.classList.add('hidden');
      this.fetchEvent()
    }

    this.showHideNavButton()
    this.eventStatusTrigger()
  }

  showHideNavButton() {
    let input = this.selectEventStatusTarget
    let total = input.options[input.selectedIndex].dataset.realTotal

    if (parseInt(this.page) == 1) {
      document.querySelector('[data-navigation="prev"]').classList.add('hidden')
    } else {
      document.querySelector('[data-navigation="prev"]').classList.remove('hidden')
    }

    if (parseInt(this.page) >= total) {
      document.querySelector('[data-navigation="next"]').classList.add('hidden')
    } else {
      document.querySelector('[data-navigation="next"]').classList.remove('hidden')
    }
  }

  fetchEvent() {
    let url = this.element.dataset.pageUrl
    let input = this.selectEventStatusTarget
    let event_status = input.options[input.selectedIndex].dataset.real
    url = url.replace("event_number", this.page) + `?event_status=${event_status}`

    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successfetchEvent.bind(this)
    })
  }

  successfetchEvent (response) {
    this.element.dataset.venueFree = response.venue_free_plan
    this.element.dataset.url = response.event_url
    this.element.dataset.contactAdmin = response.contact_admin
    this.eventNameTarget.innerHTML = response.event_name
    document.getElementById('event_actions_size').innerHTML = response.event_actions_size
    window.history.pushState({}, null, response.event_id)

    this.showTab()
    // this.loadNotes()
    // this.loadSmallActivities()
    this.loadSidebarDetails()
  }

  eventStatusTrigger() {
    let input = this.selectEventStatusTarget
    let total = input.options[input.selectedIndex].dataset.total
    let realTotal = input.options[input.selectedIndex].dataset.realTotal
    let text = input.options[input.selectedIndex].value

    if (realTotal > 0) {
      this.eventStatusSelectedTarget.innerHTML = `${this.page} of ${total} <strong>${text}</strong> Enquiries`
    } else {
      this.eventStatusSelectedTarget.innerHTML = `No <strong>${text}</strong> Enquiries`
    }
  }

  clickTab (event) {
    this.activeTab = event.currentTarget.dataset.value
    this.showTab()
  }

  contactUserDetails(event) {
    this.detailsTab.clickContact(event.currentTarget)
  }

  editForm(event) {
    switch(event.currentTarget.dataset.type) {
      case 'getEditOrganisationDetails':
        this.detailsTab.getEditOrganisationDetails()
        break;
      case 'getEditEventInfo':
        this.detailsTab.getEditEventInfo()
        break;
      case 'getAddTodoList':
        this.todoListTab.getAddTodoList()
        break;
      case 'todoStatusUpdate':
        this.todoListTab.todoStatusUpdate(event.currentTarget)
        break;
      case 'getAddDocument':
        this.documentTab.getAddDocument(event.currentTarget)
        break;
      case 'todoFormUpdate':
        this.todoListTab.todoFormUpdate(event.currentTarget)
        break;
    }
  }

  deleteForm(event) {
    switch(event.currentTarget.dataset.type) {
      case 'removeTodo':
        this.todoListTab.removeTodo(event.currentTarget)
        break;
      case 'removeDocument':
        this.documentTab.removeDocument(event.currentTarget)
        break;
    }
  }

  submitForm(event) {
    // html5 validation
    event.currentTarget.checkValidity()
    event.preventDefault()

    switch(event.currentTarget.dataset.type) {
      case 'submitEditOrganisationDetails':
        this.detailsTab.submitEditOrganisationDetails(event.currentTarget)
        break;
      case 'submitEditEventInfo':
        this.detailsTab.submitEditEventInfo(event.currentTarget)
        break;
      case 'submitToDo':
        this.todoListTab.submitToDo(event.currentTarget)
        break;
      case 'submitDocument':
        this.documentTab.submitDocument(event.currentTarget)
        break;
      case 'submitNote':
        this.noteTab.submitNote(event.currentTarget)
        break;
      case 'submitEditToDo':
        this.todoListTab.submitEditToDo(event.currentTarget)
        break;
    }
  }

  showTab () {
    switch(this.activeTab) {
      case 'details':
        this.detailsTab = new Details(this.element.dataset.url)
        this.detailsTab.call()
        break;
      case 'documents':
        this.documentTab = new Documents(this.element.dataset.url)
        this.documentTab.call()
        break;
      case 'todoList':
        this.todoListTab = new TodoList(this.element.dataset.url)
        this.todoListTab.call()
        break;
      case 'eventActions':
        new Activities(this.element.dataset.url).call()
        break;
      default:
        new Details(this.element.dataset.url).call()
    }
  }

  // loadNotes() {
  //   this.noteTab = new Notes(this.element.dataset.url)
  //   this.noteTab.call()
  // }

  // loadSmallActivities() {
  //   new SmallActivities(this.element.dataset.url).call()
  // }

  loadSidebarDetails() {
    new SidebarDetails(this.element.dataset.url).call()
  }

  editQuotation () {
    this.documentTab.editQuotation()
  }

  get base_url() {
    return this.element.dataset.url
  }

  get page() {
    return this.element.dataset.eventPage
  }

  get pageUrl() {
    return this.element.dataset.pageUrl
  }

  get checkVenueFree () {
    return this.element.dataset.venueFree
  }

  get defaultTab () {
    let urlParams = new URLSearchParams(window.location.search);
    let tabList = ['details', 'documents', 'todoList', 'eventActions']

    if(urlParams.get('tab') && tabList.includes(urlParams.get('tab'))) {
      return urlParams.get('tab')
    } else {
      return 'details'
    }
  }

  showEmailTemplatesDialog(event) {
    $(`#${event.currentTarget.dataset.dialogId}`).modal();
  }

  showWhatsAppTemplatesDialog(event) {
    $(`#${event.currentTarget.dataset.dialogId}`).modal();
  }

  // change event for final budget at accept reject modal
  onChangeBudgetFinalToAccepted() {
    $('.price-change-reason-input-to-accepted').attr('required', true)
    $('.price-change-reason-input-to-accepted-wrapper').removeClass('hidden')
  }

  // click event to reset price change reason state(requirement and visibility) upon accept button
  onClickToAccepted() {
    $('.price-change-reason-input-to-accepted').attr('required', false)
    $('.price-change-reason-input-to-accepted-wrapper').addClass('hidden')
  }

  // change event for budget at edit event info modal
  onChangeEditEventInfoPrice() {
    $('.price-change-reason-input-edit-event-info').attr('required', true)
    $('.price-change-reason-input-edit-event-info-wrapper').removeClass('hidden')
  }

  showLockedPopup() {
    bootbox.alert('Event info is locked after 7 days from completed. Please contact Venuerific Team if help needed!')
  }

  clickSaveReview(event) {
    let controller = this
    let button = event.currentTarget
    button.disabled = true

    const form = new FormData();
    form.append('user_review[rating]', document.querySelector('input[name="user_review[rating]"]:checked').value);
    form.append('user_review[comment]', document.getElementsByName("user_review[comment]")[0].value);

    Rails.ajax({
      type: button.dataset.method,
      url: button.dataset.url,
      dataType: "json",
      data: form,
      success: (response) => {
        button.disabled = false
        hideModal( document.getElementById('reviewModal'), true ).then(() => {
          controller.activeTab = 'details'
          controller.showTab()
        })
      },
      error: (response) => {
        button.disabled = false
        if(response.error != undefined && response.error != null) {
          toastr.error(response.error)
        }
      }
    })
  }

  xeroToggle(e) {
    const target = e.currentTarget
    const url = target.dataset.url
    const redirectUrl = target.dataset.redirectUrl

    axios({
      method: 'put',
      url: url
    }).then(function (_response) {
      var data = _response.data
      if (data.error) {
        target.checked = !target.checked
        toastr.options.timeOut = 0;
        toastr.options.extendedTimeOut = 0;
        toastr.error(data.message, '', {})
        return
      }
      var text = ''
      if (target.checked) {
        text = 'Active'
        target.classList.add('active')
        target.nextElementSibling.classList.add('active')
      } else {
        text = 'Inactive'
        target.classList.remove('active')
        target.nextElementSibling.classList.remove('active')
      }
      target.nextElementSibling.innerText = text
      if (data.from === 'document') {
        window.location.href = redirectUrl
      } else {
        toastr.success(data.message, '', {})
      }
    }).catch(function (_error) {
      target.checked = !target.checked
      toastr.options.timeOut = 0;
      toastr.options.extendedTimeOut = 0;
      toastr.error(_error, '', {})
    });
  }
}