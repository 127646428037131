import { Controller } from 'stimulus'
import { getCreditCardNameByNumber, isValid } from 'creditcard.js'

export default class extends Controller {
  static targets = [
    'cardTypeSelect',
    'cardFirstName',
    'cardLastName',
    'cardEmail',
    'cardAddress',
    'cardAddressState',
    'cardAddressCountry',
    'cardAddressZip',
    'cardNumberInput',
    'cardMonthInput',
    'cardYearInput',
    'cardCvvInput',
    'submitCard',
    "form", "card", "errors", "token"
  ]

  static values = {}

  initialize() {}

  connect() {
    //this.setCardNumberInputListener()
    this.stripeForm()
  }

  disconnect() {}

  setCardNumberInputListener() {
    const ctrl = this
    ctrl.cardNumberInputTarget.addEventListener('keyup', function(e) {
      if(e.keyCode == 13) { return false }
      // start to detect after 5 digits number entered
      if(ctrl.cardNumberInputTarget.value.length < 4) { return false }
      if(isValid(ctrl.cardNumberInputTarget.value)) {
        let cardType = getCreditCardNameByNumber(ctrl.cardNumberInputTarget.value)
        ctrl.setSelectedCardType(cardType)
      } else {
        return false
      }
    })
  }

  setSelectedCardType(type) {
    const ctrl = this
    if(type != null && type != undefined && type != "") {
      ctrl.cardTypeSelectTarget.querySelectorAll('option').forEach(function(opt, idx) {
        if(opt.value == type) {
          ctrl.cardTypeSelectTarget.value = opt.value
          ctrl.cardTypeSelectTarget.selectedIndex = idx
        }
      })
    }
  }

  change(event) {
    console.log(event)
    // if (event.error) {
    //   this.errorsTarget.textContent = event.error.message
    // } else {
    //   this.errorsTarget.textContent = ""
    // }
  }

  async stripeForm() {
    const controller = this

    this.stripe = Stripe(this.formTarget.dataset.stripePublicKey, {
      apiVersion: '2020-08-27'
    })

    const elements = this.stripe.elements()

    this.card = elements.create("card", {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          fontWeight: 300,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '15px',
          '::placeholder': {
            color: '#CFD7E0',
          },
        },
      }
    })

    this.cardFilled = false

    //Add a listener in order to check if
    this.card.addEventListener('change', function(event) {
      //the div card-errors contains error details if any
      var displayError = controller.errorsTarget;
      //this.submitCardTarget.disabled = false;
      if (event.error) {
        // Display error
        displayError.textContent = event.error.message;
      } else {
        // Clear error
        displayError.textContent = '';
      }

      controller.cardFilled = event.complete
    });

    this.card.mount(this.cardTarget)
  }

  stripeTokenHandler(token) {
    const controller = this
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "credit_card[token_id]")
    hiddenInput.setAttribute("value", token.id)
    this.tokenTarget.appendChild(hiddenInput)

    controller.formTarget.submit()
    // // generate card and put card to customer invoice default payment method
    // const cardResponse = await fetch(`/generate_card_source?stripe_customer_id=${controller.customerId}&stripe_token_id=${token.id}`, {
    //   method: 'POST',
    // });
    // const cardJson = await cardResponse.json()
    // // console.log('card result', cardJson)

    // const hiddenInput2 = document.createElement("input")

    // hiddenInput2.setAttribute("type", "hidden")
    // hiddenInput2.setAttribute("name", "credit_card[card_id]")
    // hiddenInput2.setAttribute("value", cardJson.id)
    // this.cardIdTarget.appendChild(hiddenInput2)

    // // create subscription or retrive one if exist from features subscription
    // const subResponse = await fetch(`/generate_subscription?subscription_id=${controller.formTarget.dataset.freeTrialSubscription}&stripe_customer_id=${controller.customerId}&stripe_price_id=${document.querySelector('input[name="credit_card[payment_setup][stripe][price_id]"]:checked').value}`, {
    //   method: 'POST',
    // });
    // const subJson = await subResponse.json()
    // // console.log('sub result', subJson)

    // this.setupIntentId = subJson.pending_setup_intent

    // if (this.setupIntentId === null) {
    //   // console.log('kosong si nya', this.setupIntentId)
    //   controller.formTarget.submit()
    // } else {
    //   // update payment method to setup intent
    //   const intentResponse = await fetch(`/update_setup_intent?stripe_setup_intent_id=${controller.setupIntentId}&stripe_payment_method_id=${cardJson.id}`, {
    //     method: 'POST',
    //   });
    //   const intentJson = await intentResponse.json()
    //   // console.log('update intent payment method', intentJson)

    //   this.clientSecret = intentJson.client_secret

    //   // confirm setup intent(type: card)
    //   controller.stripe.confirmCardSetup(controller.clientSecret, {
    //     payment_method: cardJson.id,
    //   })
    //   .then(function(result) {
    //     console.log('confirm setup', result)
    //     // Handle result.error or result.setupIntent
    //     if (result.setupIntent && result.setupIntent.status === 'succeeded') {
    //       controller.formTarget.submit()
    //       // console.log('success')
    //     } else {
    //       controller.submitCardTarget.disabled = false
    //       console.log(result.error)
    //     }
    //   });
    // }
  }

  fillCardInformation(card) {
    const ctrl = this
    ctrl.cardTypeSelectTarget.value = card.brand
    ctrl.cardNumberInputTarget.value = card.last4
    ctrl.cardMonthInputTarget.value = card.exp_month
    ctrl.cardYearInputTarget.value = card.exp_year
  }

  submitForm(event) {
    const ctrl = this
    event.preventDefault()

    if (ctrl.cardFilled) {
      this.stripe.createToken(this.card, {
        name: ctrl.cardFirstNameTarget.value+" "+ctrl.cardLastNameTarget.value,
        address_line1: ctrl.cardAddressTarget.value,
        address_state: ctrl.cardAddressStateTarget.value,
        address_zip: ctrl.cardAddressZipTarget.value,
        address_country: ctrl.cardAddressCountryTarget.value,
      }).then((result) => {
        if (result.error) {
          this.errorsTarget.textContent = result.error.message
        } else {
          this.submitCardTarget.disabled = true
          this.fillCardInformation(result.token.card)
          this.stripeTokenHandler(result.token)
        }
      })
    } else {
      this.submitCardTarget.disabled = true
      this.formTarget.submit()
    }
  }
}