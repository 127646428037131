import _ from 'lodash'

export default class EmailDetail {
  constructor(data, email_id, email_date) {
    this.data = data
    this.email_id = email_id
    this.email_date = email_date
  }

  call() {
    this.templateWrapper.innerHTML = this.parseTemplate();
    $('[data-toggle="tooltip"]').tooltip()
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  get compiledData() {
    return this.data.email
  }

  get templateWrapper() {
    return document.getElementById('stats-detail')
  }

  get template() {
    return document.getElementById('template-email-detail')
  }
}