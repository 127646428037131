export default class DetailsAction {
  constructor() {
    $('#why-accepted').on('show.bs.modal', () => {
      this.setDisplayStateWhyAccepted();
    });

    $('#why-accepted #update_value_of_contract_field .yes_button').click(() => {
      if ($('#update_event_status_users_form')[0].reportValidity()) {
        $('#event_budget_final').data('default-value', $('#event_budget_final').val());
        this.setDisplayStateWhyAccepted('upload_invoice_question');
      }
    });

    $('#why-accepted #update_value_of_contract_field .no_button').click(() => {
      $('#event_budget_final').val($('#event_budget_final').data('default-value'));
      this.setDisplayStateWhyAccepted('upload_invoice_question');
    });

    $('#why-accepted #upload_invoice_question .yes_button').click(() => {
      this.setDisplayStateWhyAccepted('upload_invoice_field');
    });

    $('#why-accepted #upload_invoice_field .no_button').click(() => {
      this.setDisplayStateWhyAccepted('upload_invoice_question');
    });

    $('#why-accepted #upload_invoice_field .yes_button').click(() => {
      if ($('#update_event_status_users_form')[0].reportValidity()) {
        this.setDisplayStateWhyAccepted('confirmed');
      }
    });

    $('#why-accepted #upload_invoice_question .no_button').click(() => {
      this.setDisplayStateWhyAccepted('confirmed');
    });

    $('#event_set_comment_event_action').change(function(e){
      if (e.target.value == 'Other') {
        $('#input_other_comment').show()
      } else {
        $('#input_other_comment').hide()
      }
    });
  }

  setDisplayStateWhyAccepted(state) {
    switch(state) {
      case 'update_value_of_contract_field':
        $('#update_value_of_contract_field').show();
        $('#upload_invoice_question').hide();
        $('#upload_invoice_field').hide();
        $('#event_document').removeAttr('required');
        $('#confirmed_by_client').hide();
        break;
      case 'upload_invoice_question':
        $('#update_value_of_contract_field').hide();
        $('#upload_invoice_question').show();
        $('#upload_invoice_field').hide();
        $('#event_document').removeAttr('required');
        $('#confirmed_by_client').hide();
        break;
      case 'upload_invoice_field':
        $('#update_value_of_contract_field').hide();
        $('#upload_invoice_question').hide();
        $('#upload_invoice_field').show();
        $('#event_document').attr('required', 'required');
        $('#confirmed_by_client').hide();
        break;
      case 'confirmed':
        $('#update_value_of_contract_field').hide();
        $('#upload_invoice_question').hide();
        $('#upload_invoice_field').hide();
        $('#event_document').removeAttr('required');
        $('#confirmed_by_client').show();
        break;
      default:
        $('#update_value_of_contract_field').show();
        $('#upload_invoice_question').hide();
        $('#upload_invoice_field').hide();
        $('#event_document').removeAttr('required');
        $('#confirmed_by_client').hide();
    }
  };
}