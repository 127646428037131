import Cookies from 'js-cookie'

function pushNotificationWrapper() {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js');
  }

  function allowSetCookieUserNotification() {
    if (!Cookies.get('set_user_notification_keys')) { false }

    return Cookies.get('set_user_notification_keys') != currentUserEmail()
  }

  function currentUserEmail() {
    return (document.getElementsByTagName('body')[0].getAttribute('data-current-user') || true)
  }

  function savingInfoGranted() {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        return serviceWorkerRegistration.pushManager.getSubscription()
        .then((subscription) => {
          if(subscription) {
            return subscription;
          }

          return serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: window.vapidPublicKey
          });
        }).then(function(subscription) {
          let country = document.getElementsByTagName('body')[0].getAttribute('data-country');
          $.post("/user_notification_keys?country="+country, { subscription: subscription.toJSON() }, () => {
            Cookies.set('set_user_notification_keys', currentUserEmail(), { expires: 1 });
          });
        });
      });
  }

  window.savingInfoGranted = function() {
    savingInfoGranted()
  };

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    savingInfoGranted()
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        savingInfoGranted()
      }
    });
  }
}

$(document).ready(function() {
  pushNotificationWrapper()
});