import Rails from '@rails/ujs'
import _ from "lodash";
import {hideModal, insertTmpModal} from '../../../../library/modal'
import bootbox from "bootbox";
import toastr from 'toastr'

export default class Documents {
  constructor(base_url) {
    this.base_url = base_url
  }

  call() {
    this.getData()
    this.clickToPreviewPdf()
    this.clickToSendPdf()
    this.clickToSendXeroPdf()
  }

  clickDateRemote() {
    $('[data-remote-href]').click(function() {
      var link = $(this).data('remote-href');
      var win = window.open(link, '_blank');
      win.focus();
    });
  }

  clickToPreviewPdf () {
    $(document).on('click', '.click-to-preview-pdf', function () {
      var link = $(this).data('url')
      var iframe = document.createElement('iframe');
      iframe.style = 'width: 100%; min-height: 500px;';
      iframe.src = '/ViewerJS/#..' + link;

      $('#modal-pdf-preview-wrapper').html(iframe);
      $('#modal-pdf-preview').modal('show');
    });
  }

  clickToSendPdf() {
    $(document).on('click', '#send_quotation', () => {
      let url = this.base_url + '/quotation/sent.json'

      Rails.ajax({
        type: "GET",
        url: url,
        success: (response) => {
          bootbox.alert(response.message);
        }
      })
    })
  }

  clickToSendXeroPdf() {
    $(document).on('click', '#send_xero', () => {
      const target = document.querySelector('#send_xero')
      let url = target.dataset.url

      Rails.ajax({
        type: "GET",
        url: url,
        success: (response) => {
          bootbox.alert(response.message);
        }
      })
    })
  }

  getData() {
    let url = this.base_url + '/show_data?data_type=documents'

    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successResponse.bind(this)
    })
  }

  removeDocument (element) {
    bootbox.confirm({
      message: `<h5 class='modal-title text-center'>Remove this document ?</h5>`,
      closeButton: false,
      onEscape: true,
      backdrop: true,
      buttons: {
        cancel: {
          label: 'No, I like it',
          className: 'btn-orange'
        },
        confirm: {
          label: 'Remove',
          className: 'btn-shadow'
        },
      },
      callback: result => {
        if (result) {
          let url = this.base_url + '/documents/' + element.dataset.documentId;
          let parent = element.dataset.parent

          Rails.ajax({
            type: "DELETE",
            url: url,
            success: function(response) {
              if (response.success) {
                document.getElementById('wrapper-documentlist-' + element.dataset.documentId).remove();

                if (document.querySelectorAll('#' + parent + ' .docs-wrapper').length == 0){
                  document.getElementById(parent).remove();
                }

                if (document.querySelectorAll('.docs-wrapper').length == 0){
                  document.getElementById("nav-sub-doc").click();
                }
                toastr.success('success delete document')
              } else {
                toastr.error(response.data.message)
              }
            },
            error: (response) => {
              toastr.error(response.message)
            }
          })
        }
      }
    });
  }

  initForm() {
    // this.loadPreview()
    // this.check_size_validation()
    let previewFileDoms = document.querySelectorAll('.preview-file')
    console.log(previewFileDoms)
    if(!previewFileDoms || previewFileDoms.length <= 0)
      return

    for (let previewFileDom of previewFileDoms) {
      previewFileDom.addEventListener('change', function(e) {
        let labelDom = e.target.parentElement.querySelector('.custom-file-label')

        if (!labelDom) return

        if(!e.target.files || e.target.files.length <= 0 || typeof e.target.files[0].name === 'undefined') {
          labelDom.innerHTML = 'Choose file'
        } else {
          labelDom.innerHTML = e.target.files[0].name
        }
      })
    }
  }

  check_size_validation () {
    $('.check-size-validation').each(function(index, element) {
      let limit_size = $(element).data('limit-size')
      let limit = limit_size*1024*1024;

      $(element).change(function() {
        if(this.files[0] && this.files[0].size < limit) {
          $(this).closest('form').find('[type=submit]').removeAttr('disabled');
          if ($(this).next() && $(this).next().hasClass('help-block')) {
            $(this).next().remove()
          }
        } else {
          $(this).after("<p class='help-block has-error'>File is more than "+limit_size+"MB</p>" );
          $(this).closest('form').find('[type=submit]').prop('disabled', true)
        }
      });
    });
  }

  loadPreview() {
    let previewFileDoms = document.querySelectorAll('.preview-file')
    if(!previewFileDoms || previewFileDoms.length <= 0)
      return

    for (let previewFileDom of previewFileDoms) {
      previewFileDom.addEventListener('change', function(e) {
        let labelDom = e.target.parentElement.querySelector('.custom-file-label')

        if (!labelDom) return

        if(!e.target.files || e.target.files.length <= 0 || typeof e.target.files[0].name === 'undefined') {
          labelDom.innerHTML = 'Choose file'
        } else {
          labelDom.innerHTML = e.target.files[0].name
        }
      })
    }
  }

  successResponse(response) {
    this.data = response
    this.wrapperDocuments.innerHTML = this.parseTemplate()
    this.clickDateRemote()
  }

  parseTemplate () {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    }

    let compiled = _.template(this.templateDocuments.innerHTML)

    return compiled(this.data)
  }

  editQuotation () {
    let url = this.base_url + '/quotation';
    window.location.href = url;
  }

  getAddDocument() {
    let url = this.base_url + '/documents/new';
    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        insertTmpModal(response.html, this.wrapperDocuments, 'document_form').then(() => {
          // this.initForm
        })
      }
    })
  }

  submitDocument(element) {
    let url = this.base_url + '/documents';

    Rails.ajax({
      type: 'POST',
      url: url,
      data: Rails.serializeElement(element),
      success: (response) => {
        hideModal(document.getElementById('document_form'), true ).then(() => {
          this.successResponse(response)
        })
      },
      error: (response) => {
        insertTmpModal(response.html, this.wrapperDocuments, 'document_form')
      }
    })
  }



  get wrapperDocuments() {
    return document.getElementById('wrapper-documents')
  }

  get templateDocuments() {
    return document.getElementById('template-documents')
  }
}