import { Controller } from 'stimulus'
import moveable from '../../../library/moveable'

export default class extends Controller {
  connect() {
    moveable()
    this.bindingPackagesLinkModal()
  }

  bindingPackagesLinkModal(){
    $(".btn-packages").on('click', function(e) {
      $("#modalRoomPackage").modal("show")
      var packages_html = $(this).data("packages")
      $("#modalRoomPackage #list_packages").html(packages_html)
    })

    $('#modalRoomPackage').on('hidden.bs.modal', function () {
      $("#modalRoomPackage #list_packages").html("")
    })

    $('#btnLiveRooms').on('click', function(e) {
      $('.noroom').hide()

      $('#liveRooms').show()
      $('#btnLiveRooms').addClass("active-hover")

      $('#draftRooms').hide()
      $('#btnDraftRooms').removeClass("active-hover")

      var total_rooms = $('#liveRooms').find(".venue_room_item").length
      $("#total_rooms").html(total_rooms)
      
      if (total_rooms == 0){
        $('.noroom').show()
      }
    })
    $('#btnDraftRooms').on('click', function(e) {
      $('.noroom').hide()

      $('#draftRooms').show()
      $('#btnDraftRooms').addClass("active-hover")

      $('#liveRooms').hide()
      $('#btnLiveRooms').removeClass("active-hover")

      var total_rooms = $('#draftRooms').find(".venue_room_item").length
      $("#total_rooms").html(total_rooms)

      if (total_rooms == 0){
        $('.noroom').show()
      }
    })
  }
}
