import { Controller } from 'stimulus'
import axios from '../../../vendor/axios'
import bootbox from 'bootbox'
import { loadUnlayer, headUnlayer } from '../../../library/unlayer'

const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const AwsS3Multipart = require('@uppy/aws-s3-multipart')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@uppy/webcam/dist/style.css')

export default class extends Controller {
  static targets = [ 'content' ]
  static values = {
    subject: String
  };

  connect() {
    this.publishedButton.classList.add('hidden')
    window.adminCustomMessagesEdit = this
    this.loadAllHeaderFunction()
    this.setSubject()
    this.initEditor()
    this.uppyEditor()
  }

  loadAllHeaderFunction () {
    let controller = this;

    this.backButton.addEventListener('click', function () {
      window.location.href = controller.contentTarget.dataset.linkBack;
    })

    this.saveButton.addEventListener('click', function() {
      $(controller.modalSetName).modal('show')
    });

    this.realSave.addEventListener('click', function() {
      controller.save()
    });
  }

  save () {
    this.saveData({ showing_failed_saving: true })
  }

  published () {
    this.saveData({ showing_failed_saving: true, published: true })
  }

  saveData (params = {}) {
    let controller = this
    let showing_failed_saving = params.showing_failed_saving
    let published = params.published
    let subject = controller.subjectEditor.value

    this.editor.exportHtml(function(data) {
      axios({
        method: 'POST',
        url: controller.contentTarget.dataset.url,
        data: {
          custom_message: {
            message_content: data,
            published: published,
            message_subject: subject,
            name: controller.setName.value,
          },
          message_type: 'free-design-email'
        },
      }).then(function (response) {
        window.location.href = response.data.go_url
      }).catch(function (error) {
        if (showing_failed_saving) {
          bootbox.alert({
            size: "small",
            title: "Error",
            message: error.response.data.messages,
          })
        } else {
          console.log(error)
        }
      });
    });
  }

  initEditor() {
    headUnlayer(this.loadEditor.bind(this))
  }

  loadEditor() {
    this.editor = loadUnlayer(this.editorId.getAttribute('id'))

    let data_saving = JSON.parse(this.contentTarget.dataset.value)

    if (data_saving && data_saving.design) {
      this.editor.loadDesign(data_saving.design);
    }

    this.editor.registerCallback('selectImage', function (_data, done) {
      console.log('hello')
      // Open the modal
      $('#mediaLibrary').modal();

      $(document).on('click', '#mediaLibrary #image-upload img', function (e) {
        // User has clicked on an image
        // This is where we will call Unlayer's "done" callback function
        // We will pass the image URL so Unlayer knows what image is added
        done({ url: e.target.getAttribute('src') });

        // Close the modal
        $('#mediaLibrary').modal('hide');
      });
    });

    $('#mediaLibrary').on('show.bs.modal', function () {
      $(document).off('click', '#mediaLibrary #image-upload img');
    });
  }

  uppyEditor() {
    let controller = this

    Uppy({
      id: 'upload-area',
      debug: true,
      restrictions: {
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      }
    })
    .use(Dashboard, {
      target: '#upload-area',
      inline: true,
      replaceTargetContent: true,
      showProgressDetails: true,
      browserBackButtonClose: true,
      proudlyDisplayPoweredByUppy: false
    })
    .use(AwsS3Multipart, {
      companionUrl: '/',
    })
    .on('upload-success', function (_file, data) {
      let dataUrl = data.uploadURL
      let targetReplaceUrl = controller.contentTarget.dataset.targetReplaceUrl;
      let replaceUrl = controller.contentTarget.dataset.replaceUrl;

      dataUrl = 'https://' + replaceUrl + dataUrl.split(targetReplaceUrl).pop();

      axios({
        method: 'POST',
        url: controller.contentTarget.dataset.uploadImageUrl,
        data: {
          custom_message_photo: {
            photo_data: dataUrl
          }
        },
      }).then(function (_response) {
        let imageWrapper = document.createElement('div')
        imageWrapper.classList.add('image', 'col-md-3')

        let image = document.createElement('img')
        image.src = dataUrl
        image.setAttribute('style', 'width: 100%;')

        imageWrapper.append(image)
        controller.imageUpload.prepend(imageWrapper)
      });
    })
  }

  get modalSetName () {
    return document.getElementById('modalSetName')
  }

  get setName () {
    return document.getElementById('input_name')
  }

  get realSave () {
    return document.getElementById('real-save')
  }

  get editorId () {
    return document.getElementById('email-editor')
  }

  get imageUpload () {
    return document.getElementById('image-upload')
  }

  get saveButton () {
    return document.getElementById('save-button')
  }

  get backButton () {
    return document.getElementById('back-button')
  }

  get publishedButton () {
    return document.getElementById('published-button')
  }

  get subjectEditor () {
    return document.getElementById('subject-editor')
  }

  get lastPublishedWrapper () {
    return document.getElementById('last-published-wrapper')
  }

  setSubject() {
    this.subjectEditor.value = this.subjectValue;
  }
}
