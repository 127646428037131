import Cookies from 'js-cookie'
window.publicLoadingAnimation = function() {
  let firstLoadPage = Cookies.get('firstLoadPage')
  window.addEventListener('DOMContentLoaded', function() {
    let g = document.getElementById('loading-state-gif-wrapper')
    if(firstLoadPage != null && firstLoadPage != undefined && firstLoadPage != '') {
      if (g != null && g!= undefined) { g.parentNode.removeChild(g) }
    } else {
      Cookies.set('firstLoadPage', window.location.pathname, { expires: 1 })
      setTimeout(function() {
        if (g != null && g!= undefined) { g.parentNode.removeChild(g) }
      }, 1500);
    }
  });
}