import { Controller } from 'stimulus'
import copyTextInput from '../../../library/click-link'
import toastr from 'toastr'

export default class extends Controller {
  static targets = ['source', 'backLinkLeadSource', 'inputLeadSource', 'inputIframe', 'iframeWidth', 'iframeHeight', 'iframeButtonColor', 'iframeButtonTextColor', 'iframeFontFamily', 'iframeGoogleFontFamily', 'iframeSaveText', 'iframeGoogleFontFamilyError', 'previewIframe']

  connect() {
    this.setCopyLink()
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    this.previewIframe()
  }

  previewIframe() {
    this.previewIframeTarget.innerHTML = this.inputIframeTarget.value.replace('&layout=form', '&layout=form&preview=true');
    document.querySelector('.preview-wrapper').querySelector('iframe').style.borderRadius = '8px';
  }

  copyLink () {
    copyTextInput(this.inputLeadSourceTarget, this.callback)
  }

  copyIframe () {
    copyTextInput(this.inputIframeTarget, this.callback)
  }

  checkUrl() {
    if (!this.backLinkLeadSourceTarget.value.includes("https://") && !this.backLinkLeadSourceTarget.value.includes("http://")) {
      this.backLinkLeadSourceTarget.nextElementSibling.innerHTML = ''
      if (this.backLinkLeadSourceTarget.value.length > 0) {
        this.backLinkLeadSourceTarget.nextElementSibling.innerHTML = 'INVALID URL. Please include http:// or https:// address.'
      }
    } else if (!this.backLinkLeadSourceTarget.checkValidity()) {
      this.backLinkLeadSourceTarget.nextElementSibling.innerHTML = inpObj.validationMessage
    } else {
      this.backLinkLeadSourceTarget.nextElementSibling.innerHTML = ''
    }

    this.setCopyLink();
  }

  setCopyLink () {
    let url = this.sourceTarget.dataset.baseUrl;
    let value = this.sourceTarget.value;

    url = url + '&customer_get_in_touch=' + value;

    this.inputIframeTarget.value = `<iframe src="${url}&layout=form&button_color=${encodeURIComponent(this.iframeButtonColorTarget.value || '#0D6EFD')}&button_text_color=${encodeURIComponent(this.iframeButtonTextColorTarget.value || '#FFFFFF')}&font_family=${encodeURIComponent(this.iframeGoogleFontFamilyTarget.value || this.iframeFontFamilyTarget.value || '')}" title="${this.sourceTarget.dataset.venueName}" width="${this.iframeWidthTarget.value || '100%'}" height="${this.iframeHeightTarget.value || '500px'}" border="0" frameborder="0"></iframe>`
    this.previewIframe()

    if(this.backLinkLeadSourceTarget.value && this.backLinkLeadSourceTarget.checkValidity()) {
      url = url + '&back_url=' + this.backLinkLeadSourceTarget.value
    }

    this.inputLeadSourceTarget.value = url

    this.iframeSaveTextTarget.innerText = ''
  }

  callback () {
    toastr.success("URL Copied")
  }

  checkIframeFontFamily() {
    if (this.iframeGoogleFontFamilyTarget.value.length > 0) {
      this.iframeFontFamilyTarget.value = '';
      this.iframeFontFamilyTarget.setAttribute('disabled', 'disabled');
    } else {
      this.iframeFontFamilyTarget.removeAttribute('disabled');
    }
  }

  changeFontFamily() {
    this.iframeFontFamilyTarget.fontFamily = 'Lato'
    if (this.iframeFontFamilyTarget.value.length > 0) {
      this.iframeFontFamilyTarget.style.fontFamily = this.iframeFontFamilyTarget.value
    }
  }

  async changeGoogleFontFamily() {
    this.iframeGoogleFontFamilyTarget.style.fontFamily = 'Montserrat'
    this.iframeGoogleFontFamilyErrorTarget.innerText = ''
    if (this.iframeGoogleFontFamilyTarget.value.length > 0) {
      try {
        const response = await fetch(`https://fonts.googleapis.com/css2?family=${encodeURIComponent(this.iframeGoogleFontFamilyTarget.value)}&display=swap)`)
        if (response.status == 200) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(this.iframeGoogleFontFamilyTarget.value)}&display=swap)`;
          document.head.appendChild(link);

          this.iframeGoogleFontFamilyTarget.style.fontFamily = this.iframeGoogleFontFamilyTarget.value;
        }
      } catch {
        this.iframeGoogleFontFamilyErrorTarget.innerText = `"${this.iframeGoogleFontFamilyTarget.value}" font not found.`
      }
    }
  }

  saveStyle() {
    this.iframeSaveTextTarget.innerText = 'Style successfully updated. please copy the iframe code and implement on your website.'
  }
}
