import { Controller } from 'stimulus'
import cropper from '../../../library/cropper'

export default class extends Controller {
  connect() {
    // Package Photo
    this.initCropper()
    
    this.minMaxCapacityChange()
    this.unitTypeChange()

    $(".textarea-description-enter-key").on('keypress', function (e) {
      // If the user has pressed enter
      if ((e.keyCode == 10 || e.keyCode == 13)) {
        e.preventDefault()
        this.value = this.value + "\n"
      }
    })
  }

  initCropper() {
    let input = document.getElementById('photo-input')
    cropper(input)
  }

  unitTypeChange() {
    let classDefine = this

    $(document).on('change', '.unit_type_changed', function() {
      var nested_wrapper = $(this).parents('.package-fields').first()
      var value = $(this).val()
      classDefine.changeStateUnitType(nested_wrapper, value)
    })

    $('.package-fields').each(function () {
      var nested_wrapper = $('.package-field')
      var value = $(this).find('.unit_type_changed').first().val()

      classDefine.changeStateUnitType(nested_wrapper, value)
    })
  }

  delay(callback, ms) {
    var timer = 0;
    return function () {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  minMaxCapacityChange() {
    // Price
    $("#venue_package_min_capacity").on("keyup change", this.delay(function (e) {
      var min_capacity = $(this).val()
      $("#venue_package_max_capacity").attr('min', min_capacity)
      var value = parseInt(min_capacity)
      if ($("#venue_package_unit_type").val() == "pax") {
        var $min_inputs = $("#venue_package_min_unit")
        var $max_inputs = $("#venue_package_max_unit")
        var min_input_value = parseInt($min_inputs.val())
        $min_inputs.attr('min', min_capacity)
        $max_inputs.attr('min', min_capacity)
        $min_inputs.data('min', min_capacity)
        $max_inputs.data('min', min_capacity)
        if (min_input_value < value) {
          $min_inputs.val(value).trigger("change")
        }
      }
    }, 1000))
    
    $("#venue_package_max_capacity").on("keyup change", this.delay(function (e) {
      var max_capacity = $(this).val()
      var value = parseInt(max_capacity)
      if ($("#venue_package_unit_type").val() == "pax") {
        var $min_inputs = $("#venue_package_min_unit")
        var $max_inputs = $("#venue_package_max_unit")
        var max_input_value = parseInt($max_inputs.val())
        $min_inputs.attr('max', max_capacity)
        $max_inputs.attr('max', max_capacity)
        $min_inputs.data('max', max_capacity)
        $max_inputs.data('max', max_capacity)
        if (max_input_value > value) {
          $max_inputs.val(value).trigger("change")
        }
      }
    }, 1000))
  }

  changeStateUnitType(wrapper, value) {
    if (value == 'minimum spend') {
      wrapper.find('.hide-minimum-spend').hide()
      wrapper.find('.show-minimum-spend').show()
      $(wrapper.find("input[name*='min_unit']").get(0)).val(1)
      $(wrapper.find("input[name*='min_unit']").get(0)).removeAttr('required')
      $(wrapper.find("input[name*='min_unit']").get(0)).removeAttr('min')
      $(wrapper.find("input[name*='min_unit']").get(0)).removeAttr('max')
      $(wrapper.find("input[name*='max_unit']").get(0)).val("")
      $(wrapper.find("input[name*='max_unit']").get(0)).removeAttr('required')
      $(wrapper.find("input[name*='max_unit']").get(0)).removeAttr('min')
      $(wrapper.find("input[name*='max_unit']").get(0)).removeAttr('max')
    } else {
      wrapper.find('.hide-minimum-spend').show()
      wrapper.find('.show-minimum-spend').hide()
      wrapper.find('.pax-hour-label').html(value)
      wrapper.find('.pax-min-label').html(value)
      wrapper.find('.pax-max-label').html(value)
      var $min_input = $(wrapper.find("input[name*='min_unit']").get(0))
      var $max_input = $(wrapper.find("input[name*='max_unit']").get(0))
      $min_input.attr('required', 'required')
      $max_input.attr('required', 'required')
      if (value == "pax"){
        $min_input.attr('min', $min_input.data('min'))
        $min_input.attr('max', $min_input.data('max'))
        $max_input.attr('min', $max_input.data('min'))
        $max_input.attr('max', $max_input.data('max'))
      }else{
        $min_input.attr('min', 1)
        $min_input.attr('max', '')
        $max_input.attr('min', 1)
        $max_input.attr('max', '')
      }
    }
  }
}
