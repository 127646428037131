import CanRejectOrAccept from './details/can_reject_or_accept';

export default class DetailsAction {
  constructor(action_type) {
    this.action_type = action_type
    this.call()
  }

  call() {
    switch(this.action_type) {
      case 'can_reject_or_accept':
        this.can_reject_or_accept()
        break;
    }
  }

  can_reject_or_accept () {
    new CanRejectOrAccept()
  }
}