import Rails from '@rails/ujs'
import _, { size } from 'lodash'
import { hideModal, insertTmpModal } from '../../../../library/modal'
import bootbox from "bootbox";
import axios from "axios";
import toastr from "toastr";

export default class TodoList {
  constructor(base_url) {
    this.base_url = base_url
    this.data = {
      todos: []
    }
  }

  call() {
    this.getData()
  }

  getData() {
    let url = this.base_url + '/show_data?data_type=todos'

    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successResponse.bind(this)
    })
  }

  getAddTodoList () {
    let url = this.base_url + '/todos/new';
    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        insertTmpModal(response.html, this.wrapperTodos, 'todo_list_form').then(() => {
          this.initForm()
        })
      }
    })
  }

  removeTodo (element) {
    bootbox.confirm({
      message: `<h5 class='modal-title text-center'>Remove this todo ?</h5>`,
      closeButton: false,
      onEscape: true,
      backdrop: true,
      buttons: {
        cancel: {
          label: 'No, I like it',
          className: 'btn-orange'
        },
        confirm: {
          label: 'Remove',
          className: 'btn-shadow'
        },
      },
      callback: result => {
        if (result) {
          let url = this.base_url + '/todos/' + element.dataset.todoId;
          axios({
            headers: { 'Content-Type': 'application/json' },
            method: 'DELETE',
            url: url
          }).then(response => {
            if (response.data.success) {
              document.getElementById('wrapper-todolist-' + response.data.todo_id).remove();
              this.calculateTodo()
              if (document.querySelectorAll('.list-item-todo').length === 0){
                document.getElementById("nav-sub-todo").click();
              }
            }
          })
        }
      }
    });
  }

  todoStatusUpdate(element) {
    let url = this.base_url + '/todos/' + element.dataset.todoId;
    Rails.ajax({
      type: 'PUT',
      url: url,
      data: 'event_todo[is_done]=' + element.dataset.todoStatus,
      success: () => {
        if(element.dataset.todoStatus === "true" ){
          element.innerHTML = 'Undone';
          document.getElementById('wrapper-todolist-' + element.dataset.todoId).classList.add('done');
        }
        else{
          element.innerHTML = 'Done';
          document.getElementById('wrapper-todolist-' + element.dataset.todoId).classList.remove('done');
        }
        element.dataset.todoStatus = element.dataset.todoStatus !== "true";
        this.calculateTodo()
      },
      error: () => {
      }
    })
  }

  todoFormUpdate(element) {
    let url = this.base_url + '/todos/' + element.dataset.todoId + '/edit';
    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        insertTmpModal(response.html, this.wrapperTodos, 'todo_list_form').then(() => {
          this.initForm()
        })
      }
    })
  }

  submitToDo(element) {
    let url = this.base_url + '/todos';

    Rails.ajax({
      type: 'POST',
      url: url,
      data: Rails.serializeElement(element),
      success: (response) => {
        hideModal( document.getElementById('todo_list_form'), true ).then(() => {
          this.successResponse(response)
          this.calculateTodo()
        })
      },
      error: (response) => {
        insertTmpModal(response.html, this.wrapperDetails, 'todo_list_form')
      }
    })
  }

  submitEditToDo(element) {
    let url = element.getAttribute('action');

    Rails.ajax({
      type: 'PUT',
      url: url,
      data: Rails.serializeElement(element),
      success: (response) => {
        hideModal( document.getElementById('todo_list_form'), true ).then(() => {
          this.successResponse(response)
          this.calculateTodo()
        })
      },
      error: (response) => {
        insertTmpModal(response.html, this.wrapperDetails, 'todo_list_form')
      }
    })
  }

  initForm() {
    let controller = this;
    document.querySelectorAll('.start-end-date').forEach(function(element, _index) {
      controller.initDateTimePicker(element);
    })
  }

  initDateTimePicker(element) {
    let now = new Date();
    let oneYr = new Date();
    oneYr.setFullYear(now.getFullYear() + 1);

    $(element).daterangepicker({
      timePicker: true,
      showDropdowns: true,
      timePicker24Hour: true,
      autoUpdateInput: true,
      minDate: now,
      maxDate: oneYr,
      timePickerIncrement: 15,
      cancelButtonClasses: 'hidden',
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    });


    function changeStartDate(date) {
      $(element).datepicker('option', 'minDate', date);
    }

    $(element).on('apply.daterangepicker', function(ev, picker) {
      changeStartDate(picker.endDate.add(1, 'day').toDate())
    });

    $(element).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
  }

  successResponse(response) {
    this.data = response
    this.wrapperTodos.innerHTML = this.parseTemplate()
    this.calculateTodo()
  }

  parseTemplate () {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    }

    let compiled = _.template(this.templateTodos.innerHTML)

    return compiled(this.data)
  }

  calculateTodo () {
    let sizeList = document.querySelectorAll('.list-item.list-item-todo:not(.done)').length

    if (sizeList == 0) {
      this.wrapperTodoListSize.innerHTML = ''
    } else {
      this.wrapperTodoListSize.innerHTML = sizeList
    }
  }

  get wrapperTodos() {
    return document.getElementById('wrapper-todos')
  }

  get templateTodos() {
    return document.getElementById('template-todos')
  }

  get wrapperTodoListSize () {
    return document.getElementById('todo_list_size')
  }
}