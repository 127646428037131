$(document).ready(function() {
  $('.custom-ga-onclick').click(function() {
    // GA tracking with specified class and data
    if (dataLayer && $(this).data('analytics-category')) {
      dataLayer.push({
        'event': 'eventTracking',
        'category': $(this).data('analytics-category'),
        'action': $(this).data('analytics-action'),
        'label': $(this).data('analytics-label')
      });
    }
  });
});