import { Controller } from "stimulus";
import $ from 'jquery'
global.$ = jQuery
import '../../../../../../../vendor/assets/javascripts/cropper/cropper.min'
import '../../../../../../../vendor/assets/javascripts/cropper/jquery-cropper.min'

export default class extends Controller {
  static targets = [
    'fileInput'
  ];

  static values = {
    'dimension': Object
  };

  initialize() {
    this.dimensionValue = {
      x: 0,
      y: 0,
      h: 0,
      w: 0
    };
  }

  connect() {
    const controller = this;
    $(this.fileInputTarget).on('change', function(e) {
      controller.insertPhotoModal();
      controller.showHidePreview('show');
    });
    this.uploadButton.on('click', function(e) {
      e.preventDefault();
      controller.fileInputTarget.click();
    });
    this.editButton.on('click', function(e) {
      e.preventDefault();
      controller.fileInputTarget.click();
    });
    $(document).on('click', '.close-media-input-preview', function() {
      controller.showHidePreview('hide');
    });
    this.savingCrop.on('click', function(e) {
      e.preventDefault();
      controller.inputTargetX.val(controller.dimensionValue.x);
      controller.inputTargetY.val(controller.dimensionValue.y);
      controller.inputTargetH.val(controller.dimensionValue.h);
      controller.inputTargetW.val(controller.dimensionValue.w);
      controller.photoPreview.attr('src', controller.mediaInputPreviewContent.cropper('getCroppedCanvas').toDataURL());
      controller.photoImageResult.show();
    });
  }
  disconnect() {}

  get ids() {
    return $(this.fileInputTarget).data();
  }

  get uploadButton() {
    return $(`#${this.ids.uploadButton}`);
  }

  get inputTargetX() {
    return $(`#${this.ids.inputTargetX}`);
  }

  get inputTargetY() {
    return $(`#${this.ids.inputTargetY}`);
  }

  get inputTargetH() {
    return $(`#${this.ids.inputTargetH}`);
  }

  get inputTargetW() {
    return $(`#${this.ids.inputTargetW}`);
  }

  get photoPreview() {
    return $(`#${this.ids.photoPreview}`);
  }

  get editButton() {
    return $(`#${this.ids.editButton}`);
  }

  get mediaInputWrapper() {
    return $(`#${this.ids.mediaInputWrapper}`);
  }

  get mediaInputPreviewWrapper() {
    return $(`#${this.ids.mediaInputPreviewWrapper}`);
  }

  get mediaInputPreviewContent() {
    return $(`#${this.ids.mediaInputPreviewContent}`);
  }

  get photoImageResult() {
    return $(`#${this.ids.photoImageResult}`);
  }

  get savingCrop() {
    return $(`#${this.ids.savingCrop}`);
  }

  insertPhotoModal() {
    var preview = this.mediaInputPreviewContent;
    var file = this.fileInputTarget.files[0];
    var reader = new FileReader();

    reader.onload = function(){
      preview.attr('src', reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  showHidePreview(state) {
    const controller = this;
    if(state === undefined) {
      state = 'hide';
    }

    if(state === 'hide') {
      this.mediaInputPreviewWrapper.hide();
      this.mediaInputWrapper.show();
    } else {
      this.mediaInputPreviewWrapper.show('fast', function() {
        setTimeout(function() { controller.buildCropper(); }, 1000);
      });
      this.mediaInputWrapper.hide()
    }
  }

  buildCropper() {
    const controller = this;
    if(this.mediaInputPreviewContent.hasClass('cropper-hidden')) {
      this.mediaInputPreviewContent.cropper("destroy");
    }

    this.mediaInputPreviewContent.cropper({
      aspectRatio: 1,
      zoomable: false,
      scalable: false,
      rotatable: false,
      crop(event) {
        controller.dimensionValue = {
          x: event.detail.x,
          y: event.detail.y,
          h: event.detail.height,
          w: event.detail.width
        };
      }
    });
  }
}