import { Controller } from "stimulus"
import 'daterangepicker'

export default class extends Controller {
  connect() {
    this.loadCalendar()
    this.loadModalVideo()
  }

  loadModalVideo () {
    document.querySelectorAll('.modal-video').forEach(function(element) {
      $(element).on('shown.bs.modal', function() {
        console.log('hello')
        let video = element.querySelector('video');
        video.play()
      });

      $(element).on('hide.bs.modal', function() {
        let video = element.querySelector('video');
        video.pause()
      });
    });
  }

  loadCalendar () {
    $('#marketing_meeting_schedule').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      minDate: new Date(),
      timePickerIncrement: 15,
      cancelButtonClasses: 'hidden',
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    })

    $('#marketing_meeting_schedule').on('apply.daterangepicker', function(ev, picker) {
      changeStartDate(picker.endDate.add(1, 'day').toDate())
    });

    $('#marketing_meeting_schedule').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });

    function changeStartDate(date) {
      $('#marketing_meeting_schedule').datepicker('option', 'minDate', date);
    }
  }
}