import { Controller } from 'stimulus'
import moveable from '../../../library/moveable'

export default class extends Controller {
  connect() {
    moveable();
    window.usersVenueDocumentsIndex = this
  }

  putEditWrapperForm(html) {
    let editWrapper = document.getElementById('edit-upload-document');
    editWrapper.innerHTML = html

    $('#uploadDocs').modal('show').on('hidden.bs.modal', function () {
      document.getElementById('uploadDocs').remove()
    });
  }
}
