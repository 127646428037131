import SimpleMDE from 'simplemde'

let venue_booking = [
  {
    name: "client_name",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{client_name}')
    },
    className: "editor_button client_name",
    title: "event contact name",
    id: "client_name_button"
  },{
    name: "owner_name",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{owner_name}')
    },
    className: "editor_button owner_name",
    title: "user owner name",
    id: "owner_name_button"
  },{
    name: "venue_name",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{venue_name}')
    },
    className: "editor_button venue_name",
    title: "venue name",
    id: "venue_name_button"
  },{
    name: "event_name",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{event_name}')
    },
    className: "editor_button event_name",
    title: "event name",
    id: "event_name_button"
  },{
    name: "no_of_attendances",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{no_of_attendances}')
    },
    className: "editor_button no_of_attendances",
    title: "event attendance",
    id: "no_of_attendances_button"
  },{
    name: "event_date",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{event_date}')
    },
    className: "editor_button event_date",
    title: "date booking",
    id: "event_date_button"
  },{
    name: "event_time",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{event_time}')
    },
    className: "editor_button event_time",
    title: "date booking",
    id: "event_time_button"
  },{
    name: "event_inquiry_id",
    action: function customFunction(editor){
      insertInfoBlock(editor, '{event_inquiry_id}')
    },
    className: "editor_button event_inquiry_id",
    title: "date booking",
    id: "event_time_button"
  }
]

function insertInfoBlock(editor, chars) {
  if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className))
      return;

  let cm = editor.codemirror;

  let startPoint = cm.getCursor('start');
  let endPoint = cm.getCursor('end');

  let text = cm.getSelection();
  cm.replaceSelection(chars);

  startPoint.ch += chars.length;
  endPoint.ch = startPoint.ch + text.length;

  cm.setSelection(startPoint, endPoint);
  cm.focus();
};

export default function simpleMde(element, configParams = {}) {
  let forWhatsapp = configParams.forWhatsapp || false
  let override = configParams.override || false
  let config = {
    element: element,
    renderingConfig: {
      singleLineBreaks: true,
    },
    toolbar: venue_booking
  }

  if (forWhatsapp) {
    config.toolbar = ["bold", "italic"].concat(venue_booking)
    config.blockStyles = {
      bold: "*",
      italic: "_"
    }
  }

  if(typeof override == 'object') {
    config = Object.assign(override, config);
  }

  let simplemde = new SimpleMDE(config);

  simplemde.codemirror.on("change", function(){
    element.value = simplemde.value();
  });
}