import { Controller } from 'stimulus'
import axios from '../../../vendor/axios';
import bootbox from 'bootbox';

const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const AwsS3Multipart = require('@uppy/aws-s3-multipart')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@uppy/webcam/dist/style.css')

export default class extends Controller {
  static targets = [ 'content' ]

  connect() {
    window.adminCustomMessagesEdit = this
    this.loadAllHeaderFunction()
    this.initEditor()
    this.uppyEditor()
  }

  loadAllHeaderFunction () {
    let controller = this
    let timer = null;


    this.saveButton.addEventListener('click', function() {
      controller.save()
    });

    this.publishedButton.addEventListener('click', function() {
      controller.published()
    });

    // setting stopping typing then saving
    this.subjectEditor.addEventListener('keyup', function () {
      clearTimeout(timer);
      timer = setTimeout(doStuff, 1000)
    });

    function doStuff() {
      controller.saveSubject()
    }
  }

  save () {
    this.saveData({ showing_failed_saving: true })
  }

  published () {
    this.saveData({ showing_failed_saving: true, published: true })
  }

  saveSubject () {
    this.saveData({ showing_failed_saving: true, subject: this.subjectEditor.value })
  }

  saveData (params = {}) {
    let controller = this
    let showing_failed_saving = params.showing_failed_saving
    let published = params.published
    let subject = params.subject

    this.editor.exportHtml(function(data) {
      axios({
        method: controller.contentTarget.dataset.method,
        url: controller.contentTarget.dataset.url,
        data: {
          custom_message: {
            message_content_draft: data,
            published: published,
            message_subject_draft: subject
          }
        },
      }).then(function (response) {
        if (response.data.last_published) {
          controller.lastPublishedWrapper.innerHTML = "Last published " + response.data.last_published
        }
      }).catch(function (error) {
        if (showing_failed_saving) {
          bootbox.alert('error for saving data. try again later')
        } else {
          console.log(error)
        }
      });
    });
  }

  isScriptInjected () {
    const scripts = document.querySelectorAll('script');
    let injected = false;
    let controller = this

    scripts.forEach((script) => {
      if (script.src.includes(controller.scriptUrl)) {
        injected = true;
      }
    });

    return injected;
  };

  initEditor() {
    let controller = this
    if (!this.isScriptInjected()) {
      const embedScript = document.createElement('script');
      embedScript.setAttribute('src', controller.scriptUrl);
      embedScript.onload = () => {
        controller.loadEditor();
      };
      document.head.appendChild(embedScript);
    } else {
      controller.loadEditor();
    }
  }

  loadEditor() {
    let controller = this
    this.editor = unlayer.createEditor({
      id: 'email-editor',
      displayMode: 'email',
      mergeTags: {
        first_name: {
          name: "First Name",
          value: "{{first_name}}",
          sample: "John"
        },
        last_name: {
          name: "Last Name",
          value: "{{last_name}}",
          sample: "Doe"
        }
      }
    });

    this.editor.addEventListener('design:updated', function(_updates) {
      controller.saveData()
    })

    let data_saving = JSON.parse(this.contentTarget.dataset.value)

    if (data_saving && data_saving.design) {
      this.editor.loadDesign(data_saving.design);
    }

    this.editor.registerCallback('selectImage', function (data, done) {
      // Open the modal
      $('#mediaLibrary').modal();

      $(document).on('click', '#mediaLibrary #image-upload img', function (e) {
        // User has clicked on an image
        // This is where we will call Unlayer's "done" callback function
        // We will pass the image URL so Unlayer knows what image is added
        done({ url: e.target.getAttribute('src') });

        // Close the modal
        $('#mediaLibrary').modal('hide');
      });
    });

    $('#mediaLibrary').on('show.bs.modal', function () {
      $(document).off('click', '#mediaLibrary #image-upload img');
    });
  }

  addPhoto(func) {
    return func;
  }

  uppyEditor() {
    let controller = this

    Uppy({
      id: 'upload-area',
      debug: true,
      restrictions: {
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      }
    })
    .use(Dashboard, {
      target: '#upload-area',
      inline: true,
      replaceTargetContent: true,
      showProgressDetails: true,
      browserBackButtonClose: true,
      proudlyDisplayPoweredByUppy: false
    })
    .use(AwsS3Multipart, {
      companionUrl: '/',
    })
    .on('upload-success', function (_file, data) {
      let dataUrl = data.uploadURL
      let targetReplaceUrl = controller.contentTarget.dataset.targetReplaceUrl;
      let replaceUrl = controller.contentTarget.dataset.replaceUrl;

      dataUrl = 'https://' + replaceUrl + dataUrl.split(targetReplaceUrl).pop();

      axios({
        method: 'POST',
        url: controller.contentTarget.dataset.uploadImageUrl,
        data: {
          custom_message_photo: {
            photo_data: dataUrl
          }
        },
      }).then(function (_response) {
        let imageWrapper = document.createElement('div')
        imageWrapper.classList.add('image', 'col-md-3')

        let image = document.createElement('img')
        image.src = dataUrl
        image.setAttribute('style', 'width: 100%;')

        imageWrapper.append(image)
        controller.imageUpload.prepend(imageWrapper)

      });
    })
  }

  get scriptUrl () {
    return '//editor.unlayer.com/embed.js?2'
  }

  get editorId () {
    return document.getElementById('email-editor')
  }

  get imageUpload () {
    return document.getElementById('image-upload')
  }

  get saveButton () {
    return document.getElementById('save-button')
  }

  get publishedButton () {
    return document.getElementById('published-button')
  }

  get subjectEditor () {
    return document.getElementById('subject-editor')
  }

  get lastPublishedWrapper () {
    return document.getElementById('last-published-wrapper')
  }
}
