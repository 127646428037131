import { Controller } from "@stimulus/core";
import 'select2'
//import '../../../../../../../node_modules/@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'
import toastr from 'toastr';
import _ from 'lodash';
import '../../../../../../assets/javascripts/tools/summernote';

import '../../../../../../../vendor/assets/javascripts/jquery.timepicker.js'
import '../../../../../../../vendor/assets/stylesheets/jquery.timepicker.css'

window.googleMap = null;
window.venueMarker = null;
window.defaultMarkerPosition = {lat: -33.8688, lng: 151.2195};

export default class extends Controller {
  static targets = [
    'googleMap',
    'placeInput',
    'addressInput',
    'googlePlaceIdInput',
    'locationDetailsInput',
    'accordion',
    'form',
    'touCheck',
    'faqs',
    'faqsUi',
    'faqTpl',
    'faqTplUi',
    'faqButton'
  ];
  static classes = [];
  static values = {
    venueid: Number,
    locationdetails: String,
    valid: Boolean,
    location: Object,
    faqs: String,
    faqsui: String
  };

  initialize() {
    this.locationValue = new Object();
    if(this.locationdetailsValue != null && this.locationdetailsValue != undefined && this.locationdetailsValue.toString().length > 0) {
      this.locationValue = Object.assign(this.locationValue, JSON.parse(this.locationdetailsValue));
    }
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };
  }
  connect() {
    const controller = this;
    $('[data-toggle="tooltip"]').tooltip();
    this.initMap();
    this.injectPlaceInput();
    this.updateMapMarker(null);
    this.initPlaceAutocomplete();
    this.initSelect2Fields();
    this.bindRequiredFields();
    this.initAvailabilityTimePicker();
    this.bindingWhatsappCheckbox();
    $('#accordion').on('shown.bs.collapse', function() {
      let expanded = $(this).find('.collapse.show').first();
      let expHeader = expanded.prev('.card-header');
      if(expanded.length > 0) {
        $('html, body').animate({
          scrollTop: expHeader.parent().offset().top - expHeader.outerHeight()
        }, 100);
        expanded.find('input, select, textarea').first().trigger('focus');
        controller.checkRequiredFields();
      }
    });
  }
  disconnect() {}

  initMap() {
    if(window.googleMap != undefined) { return true; }

    window.googleMap = new google.maps.Map(this.googleMapTarget, {
      center: window.defaultMarkerPosition,
      zoom: 17,
      scrollwheel: false,
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: true
    });
  }

  updateMapMarker(place = null) {
    let currentPosition = window.defaultMarkerPosition;
    if(place != null) {
      let loc = place.geometry.location;
      currentPosition = { lat: loc.lat(), lng: loc.lng() };
    } else if(this.locationCached) {
      currentPosition = this.locationValue.geometry;
    }

    if(window.venueMarker == null || window.venueMarker == undefined) {
      window.venueMarker = new google.maps.Marker({
        map: window.googleMap,
        position: currentPosition
      });
    }

    window.venueMarker.setVisible(false);
    if(place != null && place != undefined) {
      if(place.geometry.viewport) {
        window.googleMap.fitBounds(place.geometry.viewport);
      } else {
        window.googleMap.setCenter(place.geometry.location);
        window.googleMap.setZoom(17);
      }
      window.venueMarker.setIcon({
        url: place.icon,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(35, 35)
      });
      window.venueMarker.setPosition(place.geometry.location);
    } else if(this.locationCached) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'placeId': this.locationValue.place_id }, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            let loc = results[0].geometry.location;
            window.googleMap.setCenter(loc);
            window.venueMarker.setPosition(loc);
          } else {
            toastr.error('Geocode was not successful for the following reason: ' + status);
          }
       });
    } else {
      window.googleMap.setCenter(currentPosition);
      window.venueMarker.setPosition(currentPosition);
    }
    window.venueMarker.setVisible(true);
  }

  injectPlaceInput() {
    window.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.placeInputTarget);
    this.placeInputTarget.addEventListener('keypress', function(event) {
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
    window.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(this.types);
  }

  get types() { return document.getElementById('type-selector'); }

  initPlaceAutocomplete() {
    const controller = this;
    var autocomplete = new google.maps.places.Autocomplete(this.placeInputTarget);
    var infowindow = new google.maps.InfoWindow();

    autocomplete.bindTo('bounds', window.googleMap);

    autocomplete.addListener('place_changed', function() {
      infowindow.close();
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        toastr.error("Autocomplete's returned place contains no geometry");
        return;
      }

      var address = '';
      var place_id = place.place_id;
      var geometry = place.geometry.location;
      var place_details = {place_id: place_id, address: place.formatted_address, geometry: geometry };
      var googleJSON = JSON.stringify(place_details);
      if(place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name || ''),
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[2] && place.address_components[2].short_name || '')
        ].join(', ');
      }

      controller.addressInputTarget.innerText = place.formatted_address;
      controller.addressInputTarget.value = place.formatted_address;
      controller.googlePlaceIdInputTarget.value = place.place_id;;
      controller.locationDetailsInputTarget.value = googleJSON;
      controller.locationdetailsValue = googleJSON;
      controller.locationValue = Object.assign(controller.locationValue, place_details);

      controller.updateMapMarker(place);

      infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
      infowindow.open(window.googleMap, window.venueMarker);
    });
  }

  toggleAvailabilityRow(event) {
    const row = $(event.currentTarget).parents('.availability-row');
    let isChecked = $(event.currentTarget).is(':checked');

    $.each(row.find('.available-time--field'), function() {
      if(isChecked) {
        row.find('.available-time--field').first().trigger('focus');
      }
    });
  }

  checkRequiredFields() {
    let errors = 0;
    const controller = this;

    $.each($('.accordion .collapse'), function() {
      errors += controller.findEmptyOn($('#' + $(this).attr('id')))
    });

    return errors == 0;
  }

  findEmptyOn(cardBody) {
    const cardHeader = cardBody.closest('.card').find('.card-header');
    let fields = cardBody.find('[required=required], .required');
    let empties = [];
    $.each(fields, function() {
      if($(this).is('input[type=text]') || $(this).is('input[type=number]')) {
        if($(this).val().toString().trim().length == 0 || parseInt($(this).val().toString()) == 0) {
          empties.push(this);
          $(this).closest('.form-group').addClass('has-error');
        } else {
          $(this).closest('.form-group').removeClass('has-error');
        }
      } else if($(this).is('select')) {
        if($(this).val().length == 0) {
          empties.push(this);
          $(this).closest('.form-group').addClass('has-error');
        } else {
          $(this).closest('.form-group').removeClass('has-error');
        }
      } else if($(this).hasClass('multiple-checkbox')) {
        if($(this).find('input[type=checkbox]:checked').length == 0) {
          empties.push($(this).find('input[type=checkbox]').first());
          $(this).addClass('has-error');
        } else {
          $(this).removeClass('has-error');
        }
      } else if($(this).is('textarea')) {
        if($(this).hasClass('summernote-editor')) {
          if($(this).summernote('code').length == 0) {
            empties.push(this);
            $(this).closest('.form-group').addClass('has-error');
          } else {
            $(this).closest('.form-group').removeClass('has-error');
          }
        } else {
          if($(this).val().length == 0) {
            empties.push(this);
            $(this).closest('.form-group').addClass('has-error');
          } else {
            $(this).closest('.form-group').removeClass('has-error');
          }
        }
      }
    });

    if(empties.length > 0) {
      cardHeader.addClass('has-errors');
      cardHeader.removeClass('has-completed');
    } else {
      cardHeader.removeClass('has-errors');
      cardHeader.addClass('has-completed');
    }

    return empties;
  }

  submit(event) {
    event.preventDefault();
    this.validValue = this.checkRequiredFields() && this.touCheckTarget.checked;
    if(this.validValue) {
      event.currentTarget.setAttribute('disabled', 'disabled')
      this.formTarget.submit();
    } else {
      let header = $('.card-header.has-errors').first()
      if(header != null) {
        $.each($('.card-header'), function() {
          $(this).removeClass('collapsed');
          $(this).addClass('collapsed');
          $(this).next('.collapse').removeClass('show');
        });
        let body = header.next('.collapse');
        header.removeClass('collapsed');
        body.addClass('show');
        $(window).scrollTop(header.offset().top);
        body.find('.form-group.has-error .form-control, \
                  .form-group.has-error .custom-control-input').first().trigger('focus');
        toastr.error("You have to fill in all the required fields");
      } else if(!this.touCheckTarget.checked) {
        this.touCheckTarget.focus();
        toastr.error("You have to agree with our Terms of Use for listing venue!");
      }
    }
  }

  get locationCached() {
    return this.locationValue != null && this.locationValue.hasOwnProperty('geometry')
  }

  initSelect2Fields() {
    $('.form select.select2').each(function() {
      var paramsChange = {
        //theme: "bootstrap",
        tags: $(this).data('tags') || false,
        placeholder: $(this).data('placeholder')
      };

      if($(this).data('max-selection') != undefined) {
        paramsChange['maximumSelectionLength'] = $(this).data('max-selection')
      };

      $(this).select2(paramsChange);
    });

    $("select.select2-wo-order").on("select2:select", function (evt) {
      var element = evt.params.data.element;
      var $element = $(element);

      $element.detach();
      $(this).append($element);
      $(this).trigger("change");
    });

    $("select.select2-wo-order").on('select2:open', function (e) {
      $('.select2-dropdown').css({ "font-family": 'Montserrat' });
    });
  }

  initAvailabilityTimePicker() {
    $('.available-time--field').timepicker({ step: 30, timeFormat: 'g:iA' });
    $(document).on('change', '.available-time--field.start_time', function(){
      let endTime = $(this).closest('.available-time--col')
                           .next('.available-time--col')
                           .find('.available-time--field.end_time');
      endTime.timepicker('option', { 'minTime': $(this).val() });
      if(endTime.val().toString().trim().length == 0) {
        endTime.val($(this).val());
      }
    });

    $(document).on('click', '.btn-add-time', function(){
      let day_name = $(this).data('day')
      let fields_html = $(this).closest('.available-time--col')
                          .closest('.availability-row')
                          .find('.availability-fields').html()
      $("#"+day_name+"_row").append("<div class='row availability-row'><div class='col-sm-2'></div><div class='col-sm-6 d-inline-flex'>"+fields_html+"</div><div class='col-sm-3 available-time--col'><button type='button' class='btn btn-default btn-delete-time'><i class='fa fa-minus mr-2'></i><span>Delete this time</span></button></div></div>");
      $('.available-time--field').timepicker({ step: 30, timeFormat: 'g:iA' });
    });

    $(document).on('click', '.btn-delete-time', function(){
      $(this).closest('.available-time--col')
              .closest('.availability-row').remove();
    });
  }

  bindRequiredFields() {
    $.each($(document).find('[required=required], .required'), function() {
      if($(this).hasClass('multiple-checkbox')) {
        $.each($(this).find('input[type=checkbox].form-control--input-checkbox'), function() {
          $(this).on('change', function(event) {
            if($(this).is(':checked')) {
              $(this).closest('.form-group').removeClass('has-error');
            } else {
              controller.findEmptyOn($(this).closest('.collapse'));
            }
          });
        });
      } else if($(this).is('select')) {
        $(this).on('change', function() {
          if($(this).val().length > 0) {
            $(this).closest('.form-group').removeClass('has-error');
          } else {
            $(this).closest('.form-group').addClass('has-error');
          }
        });
      } else {
        $(this).on('blur', function() {
          if($(this).val().length > 0) {
            $(this).closest('.form-group').removeClass('has-error');
          } else {
            $(this).closest('.form-group').addClass('has-error');
          }
        });
      }
    });
  }

  updateFaqFields(faqs, category, categoryId) {
    const controller = this;
    let html = '';
    let htmlUi = '';

    this.updateFaqsCache(faqs);
    this.updateFaqsUiCache(category, categoryId);

    $.each(this.cachedFaqs, function(index, faq) {
      let tpl = _.template(controller.faqTplTarget.innerHTML);
      html += tpl(faq);
    });
    this.faqsTarget.innerHTML = html;

    $.each(this.cachedFaqsUi, function(index, faq) {
      let tplUi = _.template(controller.faqTplUiTarget.innerHTML);
      htmlUi += tplUi(faq);
    });
    this.faqsUiTarget.innerHTML = htmlUi;

    if(this.cachedFaqsUi.length > 0) {
      this.faqsUiTarget.classList.remove('hidden');
    } else {
      this.faqsUiTarget.classList.add('hidden');
    }

    this.updateFaqButtonLabel();
  }

  updateFaqsCache(faqs) {
    let tmp = this.cachedFaqs;
    let merge = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] == bb[p])).concat(b)
    if(tmp == null) { tmp = new Array(); }

    this.faqsValue = JSON.stringify(merge(tmp, faqs, "index"));
  }

  updateFaqsUiCache(category, categoryId) {
    let tmp = this.cachedFaqsUi;
    let merge = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] == bb[p])).concat(b)
    if(tmp == null) { tmp = new Array(); }

    console.log(tmp)

    this.faqsuiValue = JSON.stringify(merge(tmp, [{ category_value: category, category_value_to_id: categoryId }], "category_value_to_id"));
  }

  deleteFaqsCache(category, categoryId) {
    let tmp = this.cachedFaqs;
    if(tmp == null) { tmp = new Array(); }

    let newFaqs = $.grep(tmp, function(faq) { return faq.category_value_to_id != categoryId; });

    this.faqsValue = JSON.stringify(newFaqs);
  }

  deleteFaqsUiCache(category, categoryId) {
    let tmp = this.cachedFaqsUi;
    if(tmp == null) { tmp = new Array(); }

    let newFaqs = $.grep(tmp, function(faq) { return faq.category_value_to_id != categoryId; });

    this.faqsuiValue = JSON.stringify(newFaqs);
  }

  updateFaqButtonLabel() {
    this.faqButtonTarget.innerText = this.cachedFaqs.length > 0 ? 'Add more FAQs' : 'Add FAQ';
  }

  get cachedFaqs() {
    try { return JSON.parse(this.faqsValue); } catch(error) { return null; }
  }

  get cachedFaqsUi() {
    try { return JSON.parse(this.faqsuiValue); } catch(error) { return null; }
  }

  deleteFaq(event) {
    const contoller = this
    event.preventDefault();
    let category = $(event.currentTarget).closest('.faq-input-wrapper').data('category');
    let categoryId = $(event.currentTarget).closest('.faq-input-wrapper').data('category-id');

    $.each($("#all_faqs").find(`.faq-input-wrapper[data-category="${category}"]`), function() {
      let destroy = $(this).find('.faq-destroy-input');
      destroy.prop('disabled', false);
      destroy.prop('checked', true);
      destroy.attr('checked', 'checked');
      $(this).addClass('hidden').addClass('removed');
      contoller.deleteFaqsCache(category, categoryId)
      contoller.deleteFaqsUiCache(category, categoryId)
    });

    contoller.updateFaqButtonLabel();
  }

  bindingWhatsappCheckbox() {
    $("#venue_use_whatsapp_enquiry").on('change', function() {
      if(this.checked) {
        $("#venue_whatsapp_number").attr('required', 'required');
      }else{
        $("#venue_whatsapp_number").removeAttr('required');
      }
    });
  }
}