import Rails from '@rails/ujs'
import _ from "lodash";
import 'select2/dist/js/select2.full';
import bootbox from 'bootbox';
import DetailsAction from './details_action'
import axios from 'axios'

export default class SidebarDetails {
  constructor(base_url) {
    this.base_url = base_url
  }

  call() {
    this.getData()
  }

  assignOperator() {
    let controller = this
    if (document.querySelector('.assign-operator:not(.select2-hidden-accessible)')) {
      var element = document.querySelector('.assign-operator:not(.select2-hidden-accessible)')
      $(element).select2({
        dropdownAutoWidth: true,
        placeholder: $(element).attr('placeholder'),
        ajax: {
          url: $(element).data('url')
        }
      })
      .on('select2:select', function (e) {
        let user = e.params.data;
        let url_operator = $(element).data('assign-event-operator-url')

        bootbox.confirm("Assign "+user.text+" as the event manager?", function(result){
          if(result) {
            axios({
              method: 'post',
              url: url_operator,
              data: {
                entity_role: {
                  user_id: user.id
                }
              }
            })
            .then(function (response) {
              controller.getData()
            })
          }
        })
      })
    }

    if (document.querySelector('.remove-assign-user')) {
      var element2 = document.querySelector('.remove-assign-user')
      element2.addEventListener("click", function() {
        var url_remove_operator = element2.dataset.href
        bootbox.confirm(element2.dataset.confirmMessage, function(result){
          //data-remote="true" rel="nofollow" data-method="delete"
          if(result) {
            axios({
              headers: { 'Accept': 'application/javascript' },
              method: 'delete',
              url: url_remove_operator,
            })
            .then(function (response) {
              controller.getData()
            })
          }
        })
      })
    }
  }

  getData() {
    let url = this.base_url + '/show_data?data_type=sidebar_details'

    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successResponse.bind(this)
    })
  }

  getDataAction() {
    let url = this.base_url + '/action_button';
    Rails.ajax({
      type: "GET",
      url: url,
      success: (response) => {
        if (response.html) {
          this.wrapperButtonDetails.innerHTML = response.html
          new DetailsAction(response.action_type)
        }
      }
    })
  }

  successResponse(response) {
    this.data = response
    this.getDataAction()
    this.wrapperSidebarDetails.innerHTML = this.parseTemplate()
    this.assignOperator()
  }

  parseTemplate () {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    }

    let compiled = _.template(this.templateSidebarDetails.innerHTML)

    return compiled(this.data)
  }

  get wrapperSidebarDetails() {
    return document.getElementById('wrapper-sidebar-details')
  }

  get templateSidebarDetails() {
    return document.getElementById('template-sidebar-details')
  }

  get wrapperButtonDetails() {
    return document.getElementById('wrapper-button-details')
  }
}