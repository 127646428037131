import { Controller } from 'stimulus'
import bootbox from 'bootbox';

export default class extends Controller {
  clickSubscribe(e) {
    e.preventDefault()
    const ct = e.currentTarget
    bootbox.confirm({
      message: e.currentTarget.dataset.message,
      closeButton: false,
      callback: function(result) {
        if (result) {
          $('.subscribe-button').attr('disabled', 'disabled')
          ct.value = 'Subscribing...'
          $(ct).parents('form').submit()
        }
      }
    });
  }
}