let tags = {
  client_name: {
    name: "Client Name",
    value: "{client_name}",
    sample: "John"
  },
  venue_name: {
    name: "Venue Name",
    value: "{venue_name}",
    sample: "Doe"
  },
  event_name: {
    name: "Event Name",
    value: "{event_name}",
    sample: "Doe"
  },
  event_date: {
    name: "Event Date",
    value: "{event_date}",
    sample: "Doe"
  },
  event_time: {
    name: "Event Time",
    value: "{event_time}",
    sample: "Doe"
  },
  user_name: {
    name: "User Name",
    value: "{user_name}",
    sample: "John"
  },
}

export function loadUnlayer (editorId) {
  let data = unlayer.createEditor({
    id: editorId,
    displayMode: 'email',
    mergeTags: tags
  });

  return data;
}

export function headUnlayer (callback) {
  if (!isScriptInjected()) {
    const embedScript = document.createElement('script');
    embedScript.setAttribute('src', scriptUrl());
    embedScript.onload = () => {
      callback()
    };
    document.head.appendChild(embedScript);
  } else {
    callback()
  }
}

function isScriptInjected () {
  const scripts = document.querySelectorAll('script');
  let injected = false;

  scripts.forEach((script) => {
    if (script.src.includes(scriptUrl())) {
      injected = true;
    }
  });

  return injected;
};

function scriptUrl () {
  return '//editor.unlayer.com/embed.js?2'
}