import _ from 'lodash'
import axios from '../../../../vendor/axios';
import Rails from '@rails/ujs'
import toastr from 'toastr'
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';

const Uppy = require('@uppy/core');
const FileInput = require('@uppy/file-input');
const XHRUpload = require('@uppy/xhr-upload');
const ProgressBar = require('@uppy/progress-bar');

require('@uppy/core/dist/style.css');
import '@uppy/progress-bar/dist/style.css'

/**
 * Customers data controller to show list of customers who had
 * enquiried selected venue.
 *
 * @data is JSON response given by endpoint selected on stats_controller.js
 * this.data = { success: true, customers: arrayOf(Customer) }
 */
export default class Customers {
  constructor(data) {
    this.data = data
  }

  call() {
    this.bindingCustomerTemplate()

    /**
     *  Email or WhatsApp Template Selector dialog
     *  - Preview Email button
     *  - Send Email button
     *  - Send WhatsApp button
     *
     *  binding buttons action inside each dialog
     */
    this.bindEmailPreviewButton()
    this.bindingModalEmailSave()
    this.bindingModalWhatsappSave()

    this.bindingShowHideColumns()

    this.bindingModalImport()
    this.bindingUppy()

    this.bindingSelect2()
    this.bindingSelectFilters()

    //this.bindCustomerSearchButton()
  }

  bindingSelect2() {
    $('[data-select]').select2({
      width: '100%'
    })

    $('[data-select-phone]').select2({
      width: 'auto'
    })

    $('.select-2-job-title').select2({
      width: '100%',
      ajax: {
        url: '/api/v1/jobs/search',
        data: function (params) {
          var query = {
            job: params.term
          }

          // Query parameters will be ?search=[term]&type=public
          return query;
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: item.job_title,
                slug: item.job_title,
                id: item.job_title
              }
            })
          };
        }
      }
    })
  }

  bindingUppy() {
    let controller = this

    if (!document.getElementById('import_data')) return

    controller.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        minNumberOfFiles: 1,
        allowedFileTypes: ['.xls', '.xlsx']
      },
      onBeforeFileAdded: (currentFile, files) => {
        $("#upload_text_error").html('')
        $("#upload_text_error").hide()
        $("#upload_text_success").html('')
        $("#upload_text_success").hide()
      },
    })
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    controller.uppy
    .use(FileInput, {
      target: ".Uppy",
      pretty: true,
      limit: 1,
      inputName: 'customer_import_file',
      locale: {
        strings: {
          chooseFiles: 'Upload File',
        },
      },
    })
    .use(ProgressBar, {
      target: '.UppyProgressBar',
      hideAfterFinish: true,
    })
    .use(XHRUpload, {
      fieldName: 'customer_import_file',
      endpoint: 'customers/import',
      bundle: false,
      limit: 1,
      headers: { "X-CSRF-Token": csrfToken }
    })
    .on("upload-success", (file, response) => {
      if (response.body.length == 0) {
        $(".data-upload").removeClass("error")
        $("#upload_text_success").html("All data has been uploaded.")
        $("#upload_text_success").show()
        $(".data-upload").addClass("complete")
        setTimeout(function () {
          var click = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            synthetic: true,
            view: window
          });
          var tab = document.getElementById("nav-customer-tab");
          tab.dispatchEvent(click);
        }, 1000);
      } else {
        $(".data-upload").removeClass("complete")
        $("#upload_text_error").html(response.body.join("<br>"))
        $("#upload_text_error").show()
        $(".data-upload").addClass("error")
      }
    })
    .on("complete", (file, response) => {
      controller.uppy.reset()
    })
  }

  bindingModalImport() {
    let controller = this
    
    $("#import_data").on('hidden.bs.modal', function (event) {
      $("#upload_text_error").html('')
      $("#upload_text_error").hide()
      $("#upload_text_success").html('')
      $("#upload_text_success").hide()
      $(".data-upload").removeClass("error complete")
      controller.uppy.reset()
    })

    $("#edit_customer_data").on('hidden.bs.modal', function (event) {
      $("#edit_customer_data_form")[0].reset()
      $("#edit_customer_data_form").find(".select2-hidden-accessible").each(function() {
        $(this).val("").trigger('change')
      })
    })
  }

  bindingCustomerTemplate() {
    /** Init header section:
     *  - search field
     *  - email and whatsapp buttons when customer selected
     */
    // load and parse <template id="template-customers">
    this.templateWrapper.innerHTML = this.parseTemplate()

    // bind search field key event
    // this.bindingSearchCustomer()
    // bind email and whatsapp buttons to show template selector dialog
    this.bindingClickEmail()
    this.bindingClickWhatsapp()
    this.bindingSortColumn()
  }

  updateTables(data) {
    this.data = data

    this.bindingCustomerTemplate()

    /**
     *  Customers Table section
     *
     * load and parse <template id="template-customer-table"> then
     * bind each checkboxes for email and whatsapp template buttons toggle.
     */
    this.setTemplateTableWrapper()

    if ($("#import_data").hasClass('show')) {
      $("#import_data").modal('hide')
    }

    $(".btn-toggle-filter").each(function() {
      var $element = $(this).find('.fa')
      if ($(this).data('column') == "6" ||
          $(this).data('column') == "9" ||
          $(this).data('column') == "11" ||
          $(this).data('column') == "12"){
        if ($element.hasClass("fa-eye-slash")) {
          $element.removeClass('fa-eye-slash').addClass('fa-eye')
        }
      }else if ($element.hasClass("fa-eye")) {
        $element.removeClass('fa-eye').addClass('fa-eye-slash')
      }
    })
  }

  bindingDataTable() {
    if ($.fn.dataTable.isDataTable('#customers-table')) {
      this.datatable.destroy()
    }

    this.datatable = $('#customers-table').DataTable({
      columnDefs: [
        { orderable: false, targets: [0, 1] },
        { visible: false, targets: [7, 8, 10, 13, 14] },
      ],
      order: [[ 13, "asc" ]]
    })
  }

  setTemplateTableWrapper() {
    this.templateTableWrapper.innerHTML = this.parseTemplateTable()
    this.bindingcheckBox()
    this.bindCheckAllToggle()
  }

  bindingClickEmail() {
    let classDefine = this

    if (document.getElementById('click-contact-email')){
      document.getElementById('click-contact-email').addEventListener('click', function () {
        $(classDefine.modalEmails).modal('show')
      });
    }
  }

  bindingClickWhatsapp() {
    let classDefine = this

    if (document.getElementById('click-contact-whatsapp')){
      document.getElementById('click-contact-whatsapp').addEventListener('click', function () {
        $(classDefine.modalWhatsapp).modal('show')
      });
    }
  }

  bindingModalEmailSave() {
    let target = document.getElementById('customer-modal-email-save')
    let classDefine = this

    if (!target) return

    target.addEventListener('click', function () {
      let emailSelect = document.getElementById('customer-email-select')
      let elements = document.querySelectorAll('.form-check-input:checked')
      let elementDataSet = _.map(elements, function (data) { return Object.assign({}, data.dataset) })

      if (emailSelect.value) {
        axios.post(target.dataset.url + '?id=' + emailSelect.value, {
          users_data: elementDataSet
        }).then((_response) => {
          $(classDefine.modalEmails).modal('hide')
          toastr.success('Email successfully sent!')
        }).catch((_error) => {
          toastr.error('Failed to send email.')
        })
      } else {
        toastr.error('select template email first')
      }
    });
  }

  bindingModalWhatsappSave() {
    let target = document.getElementById('customer-modal-whatsapp-save')
    let classDefine = this

    if (!target) return

    target.addEventListener('click', function () {
      let emailSelect = document.getElementById('customer-whatsapp-select')
      let element = document.querySelector('.form-check-input:checked')

      if (element) {
        let phone = element.dataset.phone.replace(/\D/g, '');

        if (emailSelect.value) {
          axios.post(target.dataset.url + '?id=' + emailSelect.value, {
            user_data: Object.assign({}, element.dataset)
          }).then((response) => {
            $(classDefine.modalWhatsapp).modal('hide')
            toastr.success('success sent whatsapp')
            window.open("https://wa.me/" + phone + '?text=' + response.data.messages);
          }).catch((_error) => {
            toastr.error('failed to sent')
          })
        } else {
          window.open("https://wa.me/" + phone);
        }
      } else {
        toastr.error('select template email first')
      }
    })
  }

  bindingShowHideColumns() {
    let controller = this

    $('.btn-toggle-filter').on('click', function (e) {
      // Get the column API object
      let column = controller.datatable.column($(this).attr('data-column'))

      // Toggle the visibility
      column.visible(!column.visible(), false)

      //resize
      //controller.datatable.columns.adjust().draw()

      $(this).find('i').toggleClass('fa-eye-slash fa-eye');
    })
  }

  bindingcheckBox() {
    let classDefine = this

    document.querySelectorAll('.form-check-input').forEach(function (element) {
      element.addEventListener('change', function () {
        classDefine.showHideSendToContact();
        if (!this.checked) { classDefine.uncheckAll(false); }
      });
    });
  }

  showHideSendToContact() {
    let elements = document.querySelectorAll('.form-check-input:checked')

    if (elements.length > 0) {
      document.getElementById('click-contact-email').classList.remove('hidden')

      if (elements.length <= 1 && elements[0].dataset.phone) {
        document.getElementById('click-contact-whatsapp').classList.remove('hidden')
      } else {
        document.getElementById('click-contact-whatsapp').classList.add('hidden')
      }
    } else {
      document.getElementById('click-contact-email').classList.add('hidden')
      document.getElementById('click-contact-whatsapp').classList.add('hidden')
    }
  }

  // bindingSearchCustomer() {
  //   let classDefine = this

  //   document.getElementById('srchCust').addEventListener('keyup', function () {
  //     classDefine.setTemplateTableWrapper()
  //     classDefine.showHideSendToContact(false)
  //   });
  // }

  parseTemplate() {
    _.templateSettings = {
      evaluate: /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape: /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  parseTemplateTable() {
    let classDefine = this
    let htmlTemplate = ''

    _.templateSettings = {
      evaluate: /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape: /\{\{-(.+?)\}\}/g
    };

    let eventtypes_arr = []
    let industries_arr = []
    let roles_arr = []
    let countries_with_name_arr = []
    //
    let compiled = _.template(this.templateTable.innerHTML)

    this.dataCustomers.forEach(function (value, _index) {
      htmlTemplate += compiled(value)

      if (value.event_type && value.event_type.trim() != "") eventtypes_arr.push(value.event_type)
      if (value.contact_label_industry && value.contact_label_industry.trim() != "") industries_arr.push(value.contact_label_industry)
      if (value.contact_designation && value.contact_designation.trim() != "") roles_arr.push(value.contact_designation)
      if (value.contact_country && value.contact_country.trim() != "") countries_with_name_arr.push({ "val": value.contact_country, "label": value.contact_country_name})
    });

    eventtypes_arr = _.uniq(eventtypes_arr)
    industries_arr = _.uniq(industries_arr)
    roles_arr = _.uniq(roles_arr)
    countries_with_name_arr = [...new Map(countries_with_name_arr.map(item =>
      [item["val"], item])).values()];

    eventtypes_arr.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item
      option.innerHTML = item
      classDefine.filterEventTypeWrapper.append(option)
    })

    industries_arr.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item
      option.innerHTML = item
      classDefine.filterIndustryWrapper.append(option)
    })

    roles_arr.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item
      option.innerHTML = item
      classDefine.filterRoleWrapper.append(option)
    })

    countries_with_name_arr.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item.val
      option.innerHTML = item.label
      classDefine.filterCountryWrapper.append(option)
    })

    return htmlTemplate;
  }

  // open up new browser window and load free-design email editor
  bindEmailPreviewButton() {
    let target = document.getElementById('customer-modal-email-preview');

    if (!target) return

    // by default, the URL to edit venue custom message prepopulated with
    // '&ID&' string as custom message ID replacable pattern.
    let urlTpl = target.getAttribute('data-viewer-url');

    target.addEventListener('click', function (event) {
      event.preventDefault();

      let emailSelect = document.getElementById('customer-email-select');
      let selectedTplId = emailSelect.value;

      if (selectedTplId == null ||
        (typeof (selectedTplId) == "string" && selectedTplId.trim().length == 0)) {
        toastr.error('select template email first')
        return false;
      } else {
        // replace '&ID&' string to the actual custom message ID
        let openUrl = urlTpl.replace("\&ID\&", selectedTplId);
        // 1024x700 is the Recommended Size for editor to open.
        // As seen on this documentation https://docs.unlayer.com/docs
        window.open(openUrl, "Preview & Edit Template", "width=1024,height=700", false);
      }
    });
  }

  // bind checkbox to toggle .form-check-input checked/unchecked all
  bindCheckAllToggle() {
    const classDefine = this;
    let toggler = document.querySelector("[role=checkAll]");
    if (toggler == null || toggler == undefined) { return false; }

    toggler.addEventListener('change', function (_event) {
      const source = this;
      let checkboxes = document.querySelectorAll('#customers-table .form-check-input');
      let checkedCount = 0;

      checkboxes.forEach(function (checkbox) {
        // update checked attribute from checkAll toggler
        checkbox.checked = source.checked;
        if (checkbox.checked) { checkedCount += 1; }
      });

      // evaluate checked customers to toggle Email and WhatsApp buttons
      classDefine.showHideSendToContact();
    });
  }

  // update checkAll checked state triggered by unchecked .form-check-input
  uncheckAll(triggerClick = true) {
    let target = document.querySelector("[role=checkAll]");
    if (target == null || target == undefined) { return false; }

    if (triggerClick && !target.checked) { target.click(); }
    target.checked = false;
  }

  get compiledData() {
    return this.data
  }

  get templateWrapper() {
    return document.getElementById('nav-customers')
  }

  get template() {
    return document.getElementById('template-customers')
  }

  get modalEmails() {
    return document.getElementById('customer-modal-emails')
  }

  get modalWhatsapp() {
    return document.getElementById('customer-modal-whatsapp')
  }

  get dataCustomers() {
    let data = this.data.customers
    // let searchCustomer = this.searchKeyword.value;
    // let regex = new RegExp(searchCustomer, 'gi')

    // if(searchCustomer) {
    //   data = _.filter(data, function(item) {
    //     return _.join(_.values(item), ' ').match(regex) != null
    //   })
    // }

    return data
  }

  get templateTableWrapper() {
    return document.querySelector('table#customers-table tbody')
  }

  get templateTable() {
    return document.getElementById('template-customer-table')
  }

  get customerSearchBtn() {
    return document.getElementById('customer-search--button');
  }

  // bindCustomerSearchButton() {
  //   const controller = this;
  //   this.disableSearchFieldEnterCallback();

  //   this.customerSearchBtn.addEventListener('click', function (event) {
  //     event.preventDefault();
  //     event.currentTarget.removeEventListener('click', this);

  //     controller.loadingState.classList.remove('hidden');
  //     axios.get(controller.statsController.dataset.submitUrl, {
  //       params: {
  //         day_range_picker: controller.calendar.value,
  //         range_type: controller.calendarType.value,
  //         data_type: 'customers'
  //       }
  //     }).then(function (response) {
  //       if (response.status == '200') {
  //         controller.data = response.data;
  //         controller.setTemplateTableWrapper()
  //       }
  //       setTimeout(function () {
  //         controller.loadingState.classList.add('hidden');
  //       }, 400);
  //       controller.bindCustomerSearchButton()
  //     });
  //   });
  // }

  get statsController() {
    return document.querySelector('[data-controller="users--venues--stats"]');
  }

  get calendar() {
    return this.statsController.querySelector('[data-users--venues--stats-target="calendar"]');
  }

  get calendarType() {
    return this.statsController.querySelector('[data-users--venues--stats-target="calendarType"]');
  }

  get loadingState() {
    return this.statsController.querySelector('#loading-stats');
  }

  get searchKeyword() {
    return document.getElementById('srchCust');
  }

  disableSearchFieldEnterCallback() {
    this.searchKeyword.addEventListener('keypress', function (event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      } else {
        return true;
      };
    });
  }

  successResponse(response) {
    this.updateTables(response)
    this.bindingDataTable()
  }

  submitCustomer (element) {
    let controller = this
    let action = element.dataset["form"]
    let method = $(element).attr('method')

    Rails.ajax({
      type: method,
      url: $(element).attr('action'),
      data: Rails.serializeElement(element),
      success: (response) => {
        if (action == "add") {
          toastr.success("Customer added successfully")
          $('#single_data').modal('hide')
        }else if (action == "update") {
          toastr.success("Customer updated successfully")
          $('#edit_customer_data').modal('hide')
        }
        element.reset()
        var click = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          synthetic: true,
          view: window
        });
        var tab = document.getElementById("nav-customer-tab");
        tab.dispatchEvent(click);
        //controller.successResponse(response)
      },
      error: (response) => {
        toastr.error(response.messages)
      }
    })
  }

  applyEditCustomer (url, data) {
    let controller = this

    $("#edit_customer_data_form").attr('action', url)
    $("#edit_customer_data_form").attr('method', 'put')

    $("#edit_customer_data_form").find(".form-control").each(function() {
      if ($(this).data('attribute') == "contact_designation") {
        var option = new Option(data.customer["contact_designation"], data.customer["contact_designation"], true, true);
        $(this).append(option).trigger('change');
      }else if ($(this).hasClass('select2-hidden-accessible')) {
        $(this).val(data.customer[$(this).data('attribute')]).trigger('change')
      }else {
        $(this).val(data.customer[$(this).data('attribute')])
      }
    })

    $('#edit_customer_data').modal('show')
  }

  bindingSelectFilters() {
    let controller = this
    $('.select-more-filter').on('change', function(e){
      var value = $(this).val();

      // $('.status-dropdown').val(value)
      // console.log(status)
      //dataTable.column(6).search('\\s' + status + '\\s', true, false, true).draw();
      controller.datatable.column($(this).data('column')).search(value).draw();
    })
  }

  bindingSortColumn() {
    let controller = this

    $('.sortby-column').on('click', function(e){
      e.preventDefault();

      switch($(this).data('sort')) {
        case 'a':
          controller.datatable.order( [ 2, 'asc' ] ).draw();
          break;
        case 'z':
          controller.datatable.order( [ 2, 'desc' ]).draw();
          break;
        case 'n':
          controller.datatable.order( [ 14, 'desc' ] ).draw();
          break;
        case 'o':
          controller.datatable.order( [ 14, 'asc' ] ).draw();
          break;
      }
    })
  }

  get filterEventTypeWrapper() {
    return document.getElementById('filter_eventtype')
  }

  get filterIndustryWrapper() {
    return document.getElementById('filter_industry')
  }

  get filterRoleWrapper() {
    return document.getElementById('filter_role')
  }

  get filterCountryWrapper() {
    return document.getElementById('filter_country')
  }
}