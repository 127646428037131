import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    $(".feature-items").slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: $(".feature-prev"),
      nextArrow: $(".feature-next"),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })    
  }
}