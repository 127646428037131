import { Controller } from 'stimulus'
import 'select2'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'multiEmailSelect',
    'multiGroupSelect',
    'roleSelect',
    'assignForm',
    'assignSubmitBtn'
  ]

  static values = {
    arguments: Object,
    defaulturl: String
  }

  initialize() {}

  connect() {
    const controller = this;

    $(this.multiEmailSelectTarget).select2({
      tags: true,
      maximumSelectionLength: 10,
      tokenSeparators: [','],
      placeholder: this.multiEmailSelectTarget.dataset.placeholder,
      createTag: function(params) {
        let val = params.term
        return controller.validateEmail(val) ? { id: val, text: val } : null
      }
    })
    $(this.multiGroupSelectTarget).select2({
      tags: true,
      maximumSelectionLength: 3,
      tokenSeparators: [','],
      placeholder: this.multiGroupSelectTarget.dataset.placeholder,
      createTag: function(params) {
        let val = params.term
        return { id: val.toString().toLowerCase().replaceAll(/\s/g, '_'), text: _.startCase(_.lowerCase(val)) }
      }
    })

    $(this.assignModal).on('hidden.bs.modal', function() {
      controller.resetAssignForm()
    })
  }

  disconnect() {
    this.resetAssignForm()
  }

  validateEmail(email) {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailPattern.test(email)
  }

  openModal(object) {
    this.arguments = object

    if(this.arguments.submitUrl != undefined && this.arguments.submitUrl != null) {
      this.assignFormTarget.setAttribute('action', this.arguments.submitUrl)
      if(this.arguments.entity != undefined && this.arguments.entity != null) {
        this.fillAssignForm(this.arguments.entity)
        this.assignSubmitBtnTarget.innerText = 'Update'
      }
    } else {
      this.resetAssignForm()
    }

    if( ($(this.assignModal).data('bs.modal') || {})._isShown ) { this.closeModal() }

    $(this.assignModal).modal('show')
  }

  closeModal() {
    this.arguments = {}

    $(this.assignModal).modal('hide')
  }

  get assignModal() { return document.getElementById('assign_user_modal') }

  resetAssignForm() {
    this.assignFormTarget.setAttribute('action', this.defaulturlValue)
    this.fillAssignForm({})
    this.assignSubmitBtnTarget.innerText = 'Send invite'
  }

  fillAssignForm(object) {
    const controller = this

    if(object.email != null && object.email != undefined) {
      let opt = new Option(object.email, object.email, false, false)
      if($(controller.multiEmailSelectTarget).find("option[value='"+object.email+"']").length > 0) {
        $(controller.multiEmailSelectTarget).val(object.email).trigger('change')
      } else {
        $(controller.multiEmailSelectTarget).append(opt).trigger('change')
      }
      $(this.multiEmailSelectTarget).prop('readonly', true)
    } else {
      $(this.multiEmailSelectTarget).val(null);
      $(this.multiEmailSelectTarget).trigger('change')
      $(this.multiEmailSelectTarget).prop('readonly', false)
    }
    if(object.user_groups != null && object.user_groups != undefined) {
      object.user_groups.forEach(function(group) {
        let opt = new Option(_.startCase(_.lowerCase(group)), group, false, false)
        if($(controller.multiGroupSelectTarget).find("option[value='"+group+"']").length) {
          $(controller.multiGroupSelectTarget).val(group).trigger('change')
        } else {
          $(controller.multiGroupSelectTarget).append(opt).trigger('change')
        }
      })
    } else {
      $(this.multiGroupSelectTarget).val(null);
      $(this.multiGroupSelectTarget).trigger('change')
    }
    if(object.role != null && object.role != undefined) {
      this.roleSelectTarget.value = object.role
    } else {
      $(this.roleSelectTarget).val(null);
      $(this.roleSelectTarget).trigger('change')
    }
  }
}