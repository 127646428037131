import { Controller } from 'stimulus'
import axios from '../../../vendor/axios';

export default class extends Controller {
  static targets = [
    'noAnalyzedYet',
    'responsiveRateCol',
    'conversionRateCol',
    'numberOfBookingCol',
    'respondTimeCol',
    'venueRatingCol',
    'qualifiedText',
    'analyzeButton',
    'requestButton',
    'helpButton',
    'subscribeButton',
    'infoQualificationText',
  ];

  static values = {
    'venueid': String,
    'venuecountry': String
  };

  connect() {
  }

  analyzeVenue(e) {
    let controller = this

    controller.analyzeButtonTarget.disabled = true;

    axios.post(`/${controller.venuecountryValue}/users/venues/${controller.venueidValue}/super_venue_analyze`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then(function(response) {
      var data = response.data
      if (data.success) {
        if (controller.noAnalyzedYetTarget) {
          controller.noAnalyzedYetTarget.classList.add("d-none")
        }

        controller.responsiveRateColTarget.innerHTML = data.result.responsive_text
        controller.labelColumnClass(controller.responsiveRateColTarget, data.result.responsive)
        controller.conversionRateColTarget.innerHTML = data.result.conversion_rate_text
        controller.labelColumnClass(controller.conversionRateColTarget, data.result.conversion_rate)
        controller.numberOfBookingColTarget.innerHTML = data.result.number_of_booking_text
        controller.labelColumnClass(controller.numberOfBookingColTarget, data.result.number_of_booking)
        controller.respondTimeColTarget.innerHTML = data.result.respond_time_text
        controller.labelColumnClass(controller.respondTimeColTarget, data.result.respond_time)
        controller.venueRatingColTarget.innerHTML = data.result.venue_rating_text
        controller.labelColumnClass(controller.venueRatingColTarget, data.result.venue_rating)

        if (!controller.analyzeButtonTarget.classList.contains("d-none")) {
          controller.analyzeButtonTarget.classList.add("d-none");
          controller.analyzeButtonTarget.disabled = false;
        }

        controller.labelColumnClass(controller.qualifiedTextTarget, data.result.qualified)

        if (data.result.qualified) {
          controller.qualifiedTextTarget.innerHTML = "Your venue met the requirement to be a Super Venue";

          // if (!controller.subscribeButtonTarget.classList.contains("d-none")) {
          //   controller.subscribeButtonTarget.classList.add("d-none");
          // }

          if (!controller.helpButtonTarget.classList.contains("d-none")) {
            controller.helpButtonTarget.classList.add("d-none");
            controller.helpButtonTarget.disabled = true
          }

          if (controller.requestButtonTarget.classList.contains("d-none")) {
            controller.requestButtonTarget.classList.remove("d-none");
          }
        } else {
          controller.qualifiedTextTarget.innerHTML = "Your venue does not meet the criteria of a super venue.";

          if (controller.responsiveRateColTarget.classList.contains("failed"))
            controller.infoQualificationTextTarget.innerHTML += "Your response rate is lower than 90%.<br>"

          if (controller.conversionRateColTarget.classList.contains("failed"))
            controller.infoQualificationTextTarget.innerHTML += "Your conversions are lower than 6%.<br>"

          if (controller.numberOfBookingColTarget.classList.contains("failed"))
            controller.infoQualificationTextTarget.innerHTML += "Your bookings are lower than 5 per month.<br>"

          if (controller.respondTimeColTarget.classList.contains("failed"))
            controller.infoQualificationTextTarget.innerHTML += "Your response time is over 24 hours.<br>"

          if (controller.venueRatingColTarget.classList.contains("failed"))
            controller.infoQualificationTextTarget.innerHTML += "Your venue rating is lower than 4 stars.<br>"

          // if (controller.subscribeButtonTarget.classList.contains("d-none")) {
          //   controller.subscribeButtonTarget.classList.remove("d-none");
          // }

          if (!controller.requestButtonTarget.classList.contains("d-none")) {
            controller.requestButtonTarget.classList.add("d-none");
          }

          if (controller.helpButtonTarget.classList.contains("d-none")) {
            controller.helpButtonTarget.classList.remove("d-none");
            controller.helpButtonTarget.disabled = false
          }
        }
      }else {
        controller.analyzeButtonTarget.disabled = false;
      }
    });
  }

  labelColumnClass(element, result) {
    var labelClass = "failed";
    if (result) {
      labelClass = "pass";
    }

    if (element.classList.contains("d-none")) {
      element.classList.remove("d-none");
    }

    element.classList.remove("pass");
    element.classList.remove("failed");
    element.classList.add(labelClass);
  }

  mailSales(e) {
    let controller = this

    controller.helpButtonTarget.disabled = true;

    axios.put(`/${controller.venuecountryValue}/users/venues/${controller.venueidValue}/improvement_help`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then(function(response) {
      var data = response.data
      if (data.success) {
        toastr.success("Your request to improve your Super Venue stats has been sent to our team. Keep your lines open, they will get back to you ASAP!")
      }else {
        toastr.error("Improvement Help request failed.")
      }
    });
  }

  createMailto(e) {
    const link = document.createElement('a');
    link.href = 'mailto:sales@venuerific.com?subject=Super Venue Program&body=Hello Team, I want to talk about Super Venue Program / Subscription';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}