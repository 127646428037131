import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
  }

  // next slide button
  nextSlide(e) {
    let currentSlide = e.currentTarget.closest('.venue-cslider-wrapper').querySelector('.venue-cslider__photo.active')
    let nextSlideElement = currentSlide.nextElementSibling

    if (nextSlideElement !== null) {
      let nextPager = e.currentTarget.closest('.venue-cslider-wrapper').querySelector('.pager-' + (parseInt(currentSlide.dataset.photoIdx) + 1))
      currentSlide.classList.remove('active')
      nextSlideElement.classList.add('active')
      this.clickPager(null, nextPager)
    }
  }

  // prev slide button
  prevSlide(e) {
    let currentSlide = e.currentTarget.closest('.venue-cslider-wrapper').querySelector('.venue-cslider__photo.active')
    let prevSlideElement = currentSlide.previousElementSibling

    if (prevSlideElement !== null) {
      let prevPager = e.currentTarget.closest('.venue-cslider-wrapper').querySelector('.pager-' + (parseInt(currentSlide.dataset.photoIdx) - 1))
      currentSlide.classList.remove('active')
      prevSlideElement.classList.add('active')
      this.clickPager(null, prevPager)
    }
  }

  // dot click or from next/prev Slide
  clickPager(e, ect = null) {
    let clickedPager = e ? e.currentTarget : ect
    let prevActivePager = clickedPager.closest('.venue-cslider-pager').querySelector('.active')
    prevActivePager.classList.remove('active')
    clickedPager.classList.add('active')
    // if from dot click then change the slide
    if (e) {
      let pagerIdx = clickedPager.dataset.pagerIdx
      let clickedSlide = clickedPager.closest('.venue-cslider-wrapper').querySelector('.venue-cslider__photo.photo-' + pagerIdx)
      this.slideChange(clickedSlide)
    }
  }

  // change slide with provided slide element to be active
  slideChange(slideToActive) {
    let prevActiveSlide = slideToActive.closest('.venue-cslider').querySelector('.venue-cslider__photo.active')
    prevActiveSlide.classList.remove('active')
    slideToActive.classList.add('active')
  }
}
