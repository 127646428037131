import _ from 'lodash'

export default class CustomerDetail {
  constructor(data) {
    this.data = data
  }

  call() {
    this.templateWrapper.innerHTML = this.parseTemplate()
    this.setTemplateTableWrapper()
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  setTemplateTableWrapper () {
    this.templateTableWrapper.innerHTML = this.parseTemplateTable()
  }

  parseTemplateTable () {
    let htmlTemplate = ''
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.templateTable.innerHTML)
    this.dataEvents.forEach(function(item, _index) {
      htmlTemplate += compiled(item)
    });

    return htmlTemplate;
  }

  get compiledData () {
    return this.data
  }

  get templateWrapper() {
    return document.getElementById('stats-detail')
  }

  get template() {
    return document.getElementById('template-customer-detail')
  }

  get templateTableWrapper() {
    return document.querySelector('table#customer-detail-table tbody')
  }

  get templateTable() {
    return document.getElementById('template-customer-detail-table')
  }

  get dataEvents () {
    return this.data.data_events
  }
}