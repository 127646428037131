import $ from 'jquery'
global.$ = jQuery
// library
const moment = require('moment')
const toastr = require("toastr")
global.toastr = toastr
const images = require.context('../img', true)
const imagePath = (name) => images(name, true)
require("@nathanvda/cocoon")

const Rails = require('@rails/ujs')

import '../../assets/javascripts/awards/awards'
import '../js/frontend_application/users/events'
import '../js/frontend_application/library/behaviors/global'
import '../js/frontend_application/library/upgrade_modal'
import '../js/frontend_application/library/lightslider'
import '../js/frontend_application/vendor/file_input_preview'
import '../js/frontend_application/vendor/particle_vca'
import '../js/frontend_application/vendor/rails_data_confirm'
import '../js/frontend_application/library/ga_tracking'
import '../js/frontend_application/library/loading_animation'
import '../js/library/push_notification'

// load controller
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// vendor library
import 'bootstrap';
import '../../../node_modules/jquery-ui/ui/widgets/autocomplete';
import '../../../node_modules/jquery-ui/themes/base/base';
import '../../../node_modules/jquery-ui/themes/base/theme';
import '../../../node_modules/jquery-ui/themes/base/autocomplete';

// styling
import '../styles/frontend_application/base'

const application = Application.start()
const context = require.context("../js/frontend_application/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Rails.start()