window.onload = function () {
  loadPreview()
}

$(document).on('shown.bs.modal', '.modal', function () {
  loadPreview()
})

function loadPreview() {
  let previewFileDoms = document.querySelectorAll('.preview-file')
  if(!previewFileDoms || previewFileDoms.length <= 0)
    return

  for (let previewFileDom of previewFileDoms) {
    previewFileDom.addEventListener('change', function(e) {
      let labelDom = e.target.parentElement.querySelector('.custom-file-label')

      if (!labelDom) return

      if(!e.target.files || e.target.files.length <= 0 || typeof e.target.files[0].name === 'undefined') {
        labelDom.innerHTML = 'Choose file'
      } else {
        labelDom.innerHTML = e.target.files[0].name
      }
    })
  }
}