import { Controller } from 'stimulus'
import moveable from '../../../library/moveable'

export default class extends Controller {
  connect() {
    moveable();
    window.usersVenuePhotosIndex = this
  }

  putEditWrapperForm(html) {
    let editWrapper = document.getElementById('edit-upload-photo');
    editWrapper.innerHTML = html
  }

  showHideNewUploadPhoto(show = true) {
    let newWrapper = document.getElementById('new-upload-photo')
    let editWrapper = document.getElementById('edit-upload-photo')

    if (show) {
      newWrapper.classList.remove('hidden')
      editWrapper.classList.add('hidden')
    } else {
      newWrapper.classList.add('hidden')
      editWrapper.classList.remove('hidden')
    }
  }

  closeWrapperForm() {
    this.showHideNewUploadPhoto();
  }

  scrollToEditForm() {
    document.getElementById('edit-upload-photo').scrollIntoView();
  }
}
