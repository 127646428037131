import 'lightslider';
import Plyr from 'plyr';
import trackPage from '../users/events'

function loadLightSlider () {
  if ($(".venue_photo_lightslider").length > 0) {
    $(".venue_photo_lightslider").lightSlider({
      item: 1
    });
  }
}

function initThumbnailYoutube () {
  $('.youtube_video_thumbnail').each(function() {
    var player = new Plyr($(this)[0], {
      controls: ['play'],
      youtube: { showinfo: 0, modestbranding: 1 },
      loop: { active: true }
    })

    // https://stackoverflow.com/questions/53585355/play-multiple-plyr-vimeo-embeds-on-hover improving
    $(player.elements.container).parent().hover(
      function () {
        player.play()
      }, function () {
        player.pause()
      }
    )

    $(window).blur(function() {
      player.pause()
    });
  });
}

function clickOnMedia () {
  $('.media-for-click').click(function (e) {
    e.preventDefault()
    var link = $(this).data('link')
    var venue_name = $(this).data('venue-name')
    trackPage('link', 'click', venue_name)
    var win = window.open(link, '_blank');
    win.focus();
  });
}

function venueCardLoad() {
  loadLightSlider();
  initThumbnailYoutube();
  clickOnMedia();
}

window.venueCardLoad = venueCardLoad;

$(document).ready(function () {
  venueCardLoad();
});