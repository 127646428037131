import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'minusButton',
    'plusButton',
    'userNumber',
    'roomNumber',
    'totalUsers',
    'totalRooms',
    'totalUsersPrice',
    'totalRoomsPrice',
    'totalCharged',
    'subscriptionPrice',
    'userPrice',
    'roomPrice',
    'totalPrice',
    'submitButton',
  ]

  connect() {
    this.bindingNumberInput()
  }

  bindingNumberInput() {
    let controller = this

    // Pricing
    let subscription_price_word = this.subscriptionPriceTarget.innerText;
    this.subscription_price = +subscription_price_word.match(/\d/g).join('')
    this.currency = subscription_price_word.match(/[^\d,.]/g).join('')
    this.user_price = this.userNumberTarget.dataset.price;
    this.room_price = this.roomNumberTarget.dataset.price;

    document.querySelectorAll('.minus').forEach(minusBtn => {
      minusBtn.addEventListener("click", function(e) {
        let input = e.currentTarget.parentElement.querySelector('input');
        let count = parseInt(input.value) - 1;
        let min = parseInt(input.min)
        count = count < min ? min : count;
        input.value = count;
        input.dispatchEvent(new Event('change'));
      });
    });

    document.querySelectorAll('.plus').forEach(plusBtn => {
      plusBtn.addEventListener("click", function(e) {
        let input = e.currentTarget.parentElement.querySelector('input');
        var count = parseInt(input.value) + 1;
        count = count > 99 ? 99 : count;
        input.value = count;
        input.dispatchEvent(new Event('change'));
      });
    });

    this.userNumberTarget.onkeydown = function(e) {
      if(!((e.keyCode > 95 && e.keyCode < 106)
        || (e.keyCode > 47 && e.keyCode < 58)
        || e.keyCode == 8)) {
          return false;
      }
    }

    this.userNumberTarget.addEventListener("keyup", function(e) {
      let value = e.currentTarget.value
      let min = e.currentTarget.min

      if (value < min)
        e.currentTarget.value = min;

      if (value > 99)
        e.currentTarget.value = 99;
    })

    this.roomNumberTarget.onkeydown = function(e) {
      if(!((e.keyCode > 95 && e.keyCode < 106)
        || (e.keyCode > 47 && e.keyCode < 58)
        || e.keyCode == 8)) {
          return false;
      }
    }

    this.roomNumberTarget.addEventListener("keyup", function(e) {
      let value = e.currentTarget.value
      let min = e.currentTarget.min

      if (value < min)
        e.currentTarget.value = min;

      if (value > 99)
        e.currentTarget.value = 99;
    })

    this.userNumberTarget.addEventListener("change", function(e) {
      let value = parseInt(e.currentTarget.value);
      let wrapper = document.querySelectorAll('.total-users-wrapper')
      let total_price = 0;

      if (value == 0) {
        controller.toggleWrapper('hide', wrapper)
      }else {
        controller.toggleWrapper('show', wrapper)
        total_price = value * controller.user_price
      }

      controller.totalUsersTarget.innerText = value;
      controller.totalUsersPriceTarget.innerText = controller.currency + total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      controller.userPriceTarget.value = total_price;

      controller.totalPriceTarget.value = controller.subscription_price + total_price +  parseInt(controller.roomPriceTarget.value);
      controller.totalChargedTarget.innerText = controller.currency + controller.totalPriceTarget.value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      controller.checkButtonAfterAction()
    })

    this.roomNumberTarget.addEventListener("change", function(e) {
      let value = parseInt(e.currentTarget.value);
      let wrapper = document.querySelectorAll('.total-rooms-wrapper')
      let total_price = 0;

      if (value == 0) {
        controller.toggleWrapper('hide', wrapper)
      }else {
        controller.toggleWrapper('show', wrapper)
        total_price = value * controller.room_price
      }

      controller.totalRoomsTarget.innerText = value;
      controller.totalRoomsPriceTarget.innerText = controller.currency + total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      controller.roomPriceTarget.value = total_price;

      controller.totalPriceTarget.value = controller.subscription_price + parseInt(controller.userPriceTarget.value) + total_price;
      controller.totalChargedTarget.innerText = controller.currency + controller.totalPriceTarget.value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      controller.checkButtonAfterAction()
    })
  }

  toggleWrapper(action, elements) {
    elements.forEach(element => {
      if (action == "hide" && !element.classList.contains("d-none")){
        element.classList.toggle("d-none");
      }else if (action == "show" && element.classList.contains("d-none")){
        element.classList.toggle("d-none");
      }
    });
  }

  checkButtonAfterAction() {
    let controller = this

    if (controller.userPriceTarget.value == 0 && controller.roomPriceTarget.value == 0) {
      controller.submitButtonTarget.disabled = true;
    }else {
      controller.submitButtonTarget.disabled = false;
    }
  }
}