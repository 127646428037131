import { Controller } from "stimulus"
import axios from '../../../../vendor/axios'
import { Device } from 'twilio-client'
import 'bootstrap-daterangepicker'
import bootbox from 'bootbox'
import MobileDetect from 'mobile-detect'

export default class extends Controller {
  static targets = ['calendar', 'call']

  // pseudo params
  // this.activeCall, this.from

  connect() {
    if (!this.hasCalendarTarget) {
      return
    }

    this.calendarInit()
    this.twilioInitialize()
  }

  twilioInitialize () {
    let controller = this
    let urlCallLog = this.element.dataset['callUrl']

    axios.post(urlCallLog).then(function (response) {
      controller.from = response.data.to;
      controller.user_id = response.data.user_id;

      Device.setup(response.data.token);
    });

    Device.ready(function (_device) {});

    Device.error(function (error) {
      controller.turnOffActiveCall()
      console.log("ERROR: " + error.message);
    });

    /* Callback for when a call ends */
    Device.disconnect(function(_connection) {
      // Disable the hangup button and enable the call buttons
      controller.turnOffActiveCall()
    });

    Device.connect(function (_connection) {
      controller.turnOnActiveCall()
    });
  }

  clickToCall(event) {
    let md = new MobileDetect(window.navigator.userAgent)

    if (md.mobile() || md.tablet()) {
      this.clickToCallMobile(event)
    } else {
      this.clickToCallDesktop(event)
    }
  }

  clickToCallMobile (event) {
    let to = event.target.dataset['phoneNumber']
    window.open('tel:' + to);
  }

  clickToCallDesktop (event) {
    if (this.activeCall) {
      if (this.activeCall == event.target) {
        Device.disconnectAll();
      } else {
        bootbox.alert('Other call still active');
      }
    } else {
      let to = event.target.dataset['phoneNumber']
      this.activeCall = event.target

      Device.connect({
        'From': this.from,
        'To': to,
        'user_id': this.user_id,
        'venue_id': this.element.dataset['venueId']
      });
    }
  }

  turnOnActiveCall() {
    if (!this.activeCall) {
      return
    }

    this.activeCall.classList.add('calling')
  }

  turnOffActiveCall() {
    if (!this.activeCall) {
      return
    }

    this.activeCall.classList.remove('calling')
    this.activeCall = undefined
  }

  calendarInit() {
    $(this.calendarTarget).daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'DD-MM-YYYY'
      }
    });

    $(this.calendarTarget).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
    });

    $(this.calendarTarget).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
  }
}