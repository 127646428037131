import _ from 'lodash'

export default class ExternalInquiryDetail {
  constructor(data, external_inquiry_type) {
    this.data = data
    this.external_inquiry_type = external_inquiry_type
  }

  call() {
    this.templateWrapper.innerHTML = this.parseTemplate()
    this.setTemplateTableWrapper()
    this.setFilterEventTypes()
    this.setFilterBudget()
    this.bindingFilterEventType()
    this.bindingFilterBudget()
  }

  bindingFilterEventType () {
    let classDefine = this

    this.filterEventTypeWrapper.addEventListener('change', function() {
      classDefine.setTemplateTableWrapper()
    })
  }

  bindingFilterBudget () {
    let classDefine = this

    this.filterBudgetWrapper.addEventListener('change', function() {
      classDefine.setTemplateTableWrapper()
    })
  }

  setFilterEventTypes () {
    let classDefine = this

    this.dataEventTypes.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item
      option.innerHTML = item
      classDefine.filterEventTypeWrapper.append(option)
    });
  }

  setFilterBudget () {
    let classDefine = this

    this.dataBudget.forEach(function(item, index) {
      let option = document.createElement("option");
      option.value = item.value
      option.innerHTML = item.label
      classDefine.filterBudgetWrapper.append(option)
    });
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  setTemplateTableWrapper () {
    this.templateTableWrapper.innerHTML = this.parseTemplateTable()
  }

  parseTemplateTable () {
    let htmlTemplate = ''
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.templateTable.innerHTML)
    this.dataEvents.forEach(function(item, _index) {
      htmlTemplate += compiled(item)
    });

    return htmlTemplate;
  }

  get compiledData () {
    let data = this.data

    data['external_inquiry_type'] = this.external_inquiry_type

    return data
  }

  get templateWrapper() {
    return document.getElementById('stats-detail')
  }

  get template() {
    return document.getElementById('template-external-inquiry-detail')
  }

  get templateTableWrapper() {
    return document.querySelector('table#table-external-inquiry-detail tbody')
  }

  get templateTable() {
    return document.getElementById('template-external-inquiry-detail-table')
  }

  get filterEventTypeWrapper() {
    return document.getElementById('external-inquiry-detail-eventtype')
  }

  get filterBudgetWrapper() {
    return document.getElementById('external-inquiry-detail-budget')
  }

  get dataEvents() {
    let data = this.data.data_events
    let eventType = this.filterEventTypeWrapper.value
    let budget = this.filterBudgetWrapper.value


    if(eventType) {
      data = _.filter(data, function(item) { return item.event_type == eventType })
    }

    if (budget) {
      let budget_down = budget.split("-")[0];
      let budget_up = budget.split("-")[1];

      data = _.filter(data, function(item) {
        if (budget_down == '<') {
          return parseInt(item.budget) < parseInt(budget_up)
        } else if (budget_up == '>') {
          return parseInt(item.budget) > parseInt(budget_down)
        } else {
          return parseInt(item.budget) >= parseInt(budget_down) && parseInt(item.budget) <= parseInt(budget_up)
        }
      })
    }

    return data
  }

  get dataEventTypes() {
    return _.uniq(_.map(this.data.data_events, 'event_type'))
  }

  get dataBudget() {
    return this.data.data_budgets
  }
}