import { Controller } from 'stimulus'
import { freeTrialSetup } from '../../mixins/free_trial_setup'

export default class extends Controller {
  static values = {
    showAfterCampaignRegModal: Boolean
  }

  connect() {
    freeTrialSetup(this)
    this.initAfterSignUpModal()
  }

  get afterCampaignRegModal() { return document.getElementById('afterRegistrationModal') }
}