import _ from 'lodash'
import axios from '../../../../vendor/axios';

export default class Goals {
  constructor(data) {
    this.data = data
  }

  call() {
    this.templateWrapper.innerHTML = this.parseTemplate()
    this.setWrapperProgressGoals(this.compiledData.goal)
    this.bindingForm()
    this.clickOnEventToGoTab()
    this.buildEventToGoGraphTotal()
  }

  bindingForm () {
    let classDefine = this
    let submitForm = document.getElementById('form_goal');

    submitForm.addEventListener('submit', function(e) {
      e.preventDefault()

      axios.put(submitForm.getAttribute('data-url'), {
        goal: {
          user_goal: classDefine.userGoal,
          venue_goal: classDefine.venueGoal,
          date: classDefine.date
        }
      }).then(function (response) {
        classDefine.setWrapperProgressGoals(response.data)
        $('#monthGoal').modal('hide');
      });
    });
  }

  setWrapperProgressGoals (goalData) {
    this.wrapperProgressGoals.innerHTML = this.parseTemplateProgressGoals(goalData);
    let wrapperStart = document.getElementById('wrapper-star');

    if (goalData.stars) {
      wrapperStart.innerHTML = '<i class="fa fa-star" aria-hidden="true"></i>'.repeat(goalData.stars)
    }

    document.getElementById('user_goal').value = goalData.user_goal
    if (document.getElementById('venue_goal')) {
      document.getElementById('venue_goal').value = goalData.venue_goal
    }
  }

  parseTemplateProgressGoals(goalData) {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.templateProgressGoals.innerHTML)

    return compiled(goalData)
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  clickOnEventToGoTab () {
    let thisClass = this;
    document.querySelectorAll('.badge-change-chart').forEach(function(element) {
      element.addEventListener('click', function() {
        document.querySelectorAll('.badge-change-chart').forEach(function(el) {
          el.classList.remove('active')
        });

        element.classList.add('active')
        thisClass.buildEventToGoGraphTotal(element.getAttribute('data-value'))
      });
    });
  }

  buildEventToGoGraphTotal(type = '6_months') {
    document.getElementById('event-to-go-total').innerHTML = this.data.event_to_go[type].header

    let ctx = document.getElementById('chartEventtogo').getContext('2d');
    let dataset = {
      labels: _.keys(this.data.event_to_go[type].data),
      datasets: [{
        label: 'totals ($)',
        data: _.values(this.data.event_to_go[type].data),
        borderColor: "#004383",
        fill: false
      }]
    }
    this.buildChartGraph(ctx, dataset)
  }

  buildChartGraph(ctx, dataset, yAxesLegend = false, xAxesLegend = true) {
    new Chart(ctx, {
      type: 'line',
      data: dataset,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              display: yAxesLegend
            }
          }],
          xAxes: [{
            ticks: {
              display: xAxesLegend //this will remove only the label
            }
          }],
        },
        legend: {
          display: false
        }
      }
    });
  }

  get compiledData () {
    return this.data
  }

  get templateWrapper() {
    return document.getElementById('nav-goals')
  }

  get template() {
    return document.getElementById('template-goals')
  }

  get wrapperProgressGoals() {
    return document.getElementById('wrapper-progress-goals')
  }

  get templateProgressGoals() {
    return document.getElementById('template-progress-goals')
  }

  get date() {
    return document.querySelector('[data-users--venues--stats-target="calendar"]').value
  }

  get userGoal() {
    return document.getElementById('user_goal').value
  }

  get venueGoal() {
    if (document.getElementById('venue_goal')) {
      return document.getElementById('venue_goal').value
    } else {
      null
    }
  }
}