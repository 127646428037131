import _ from 'lodash'
import axios from 'axios'

export default class Emails {
  constructor(data) {
    this.data = data
  }

  call() {
    this.tableDate = []
    this.bindingEmailTemplate()
    this.setTemplateTableWrapper()
    this.bindingSelectTime()
    this.bindingInputName()
    this.bindingDownloadButton()
  }

  bindingInputName () {
    let controller = this
    let select = document.getElementById("email_date_filter")
    let input = document.getElementById("email_name_filter")
    let button = document.getElementById("email_button_filter")

    button.addEventListener('click', function (e) {
      axios.get(controller.submitPath, {
        params: {
          day_range_picker: select.value,
          email_name: input.value,
          data_type: 'emails',
        }
      }).then(function (response) {
        controller.tableDate = []
        controller.setTemplateTableWrapper(response.data)
        var responseURL = response.request.responseURL.replace("stats_data", "stats_data.xlsx");
        $('#btn_download_email').data('location', responseURL)
      })
    });
  }

  bindingSelectTime () {
    let controller = this
    let select = document.getElementById("email_date_filter")
    let input = document.getElementById("email_name_filter")

    select.addEventListener('change', function (e) {
      axios.get(controller.submitPath, {
        params: {
          day_range_picker: select.value,
          email_name: input.value,
          data_type: 'emails',
        }
      }).then(function (response) {
        controller.tableDate = []
        controller.setTemplateTableWrapper(response.data)
        var responseURL = response.request.responseURL.replace("stats_data", "stats_data.xlsx");
        $('#btn_download_email').data('location', responseURL)
      })
    });
  }

  bindingDownloadButton () {
    $('#btn_download_email').on('click', function (e) {
      e.preventDefault()
      
      window.location.href = $(this).data('location');
    });
  }

  bindingEmailTemplate () {
    this.templateWrapper.innerHTML = this.parseTemplate()
  }

  setTemplateTableWrapper (data = null) {
    let dataTable
    let controller = this

    if (data == null) {
      dataTable = controller.dataEmails
    }else {
      dataTable = data.emails
    }

    this.templateTableWrapper.innerHTML = this.parseTemplateTable(dataTable)
    this.tableDate.forEach(function(date, _index) {
      dataTable[date].trackings.forEach(function(tracking, _index) {
        let htmlContent = ''+
        '<tr>'+
          '<td>'+
            '<a data-action="click->users--venues--stats#clickForEmailDetail" data-id="'+tracking.id+'" data-date="'+date+'">'+
              '<p class="link">'+tracking.name+'</p>'+
            '</a>'+
          '</td>'+
          '<td>'+tracking.recipients+'</td>'+
          '<td>'+tracking.open+' ('+tracking.open_percentage+'%)</td>'+
          '<td>'+tracking.click+' ('+tracking.click_percentage+'%)</td>'+
        '</tr>';
        $("#table_"+date+" tbody").append(htmlContent)
      });
    })
  }

  parseTemplate() {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.template.innerHTML)

    return compiled(this.compiledData)
  }

  parseTemplateTable(data) {
    let controller = this
    let htmlTemplate = ''
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    };

    let compiled = _.template(this.templateTable.innerHTML)
    data["keys"].forEach(function(date, _index) {
      controller.tableDate.push(date)
      htmlTemplate += compiled(data[date])
    });

    return htmlTemplate;
  }

  get submitPath() {
    return document.querySelector('main.stats[data-controller]').getAttribute('data-submit-url')
  }

  get compiledData() {
    return this.data
  }

  get templateWrapper() {
    return document.getElementById('nav-emails')
  }

  get template() {
    return document.getElementById('template-emails')
  }

  get templateTableWrapper() {
    return document.querySelector('#emails-table')
  }

  get templateTable() {
    return document.getElementById('template-emails-table')
  }

  get dataEmails() {
    let data = this.data.emails
    
    return data
  }
}