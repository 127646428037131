import { Controller } from 'stimulus'
import simpleMde from '../../../library/simplemde'

export default class extends Controller {
  connect () {
    this.initSimpleMde()
  }

  initSimpleMde() {
    document.querySelectorAll('.simplemde-editor').forEach((element) => {
      simpleMde(element, {
        override: {
          forceSynch: true,
          status: [
            'lines',
            'words',
            {
              className: 'chars',
              defaultValue: function(el) {
                this.chars = element.value.replaceAll(/\s/g, '').length;
                el.innerHTML = 'characters: ' + this.chars;
              },
              onUpdate: function(el) {
                this.chars = element.value.replaceAll(/\s/g, '').length;
                el.innerHTML = 'characters: ' + this.chars;
              }
            }
          ]
        }
      });
    });
  }
}
