import { Controller } from "stimulus"

export default class extends Controller {
  initialize() { }

  connect() {
    // testimoni slider
    $(".community-slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $(".slider-prev"),
      nextArrow: $(".slider-next"),
      infinite: false,
      dots: true
    });
  }

  disconnect() { }

  // Trigger in gtm => 'eventTracking'
  // Data Layer variables in gtm => 'DL - category', 'DL - action', 'DL - label'
  // gtm event track for free trial landing page form
  onSubmitForm(e) {
    e.preventDefault()
    var btn = e.currentTarget.querySelector('button');
    var event_category = $(btn).data('analytics-category');
    var event_action = $(btn).data('analytics-action');
    var event_label = $(btn).data('analytics-label');
    this.dataLayerEventPush(event_category, event_action, event_label);
    e.currentTarget.submit();
  }
  dataLayerEventPush(category, action, label) {
    if (dataLayer) {
      dataLayer.push({
        'event': 'eventTracking',
        'category': category,
        'action': action,
        'label': label
      });
    }
  }
}