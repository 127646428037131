import { Controller } from "stimulus";
import $ from 'jquery'
global.$ = jQuery
import '../../../../../../../vendor/assets/javascripts/cropper/cropper.min'
import '../../../../../../../vendor/assets/javascripts/cropper/jquery-cropper.min'

export default class extends Controller {
  static targets = [];
  static values = {
    vars: Object
  }; 
  
  initialize() {
    this.clearAllVariable();
  }
  connect() {
    const controller = this;
    $(document).on('click', '.close-media-input-preview', function() {
      controller.showHidePreview('hide');
      $('#input-btn-add-photo').removeData('target');

      if ($(this).attr('id') == 'close-button') {
        // clear all field by checking if there are value on photo remote url or not
        var photo_remote = $('#'+controller.varsValue.id_target);
        if (photo_remote.data('new-record') == true && photo_remote.val() == '') {
          photo_remote.parents('.nested-fields').first().remove();
        }
      }
    });

    $('.btn-add-photo').on('click', function(e) {
      e.preventDefault();
      $('#input-btn-add-photo').removeData('target');
      $('#input-btn-add-photo')[0].click();
    });

    $(document).on('change', '#input-btn-add-photo', function() {
      controller.clearAllVariable();
      controller.clearSelectorImage();
      if($(this).data('target') != undefined) {
        controller.insertPhotoModal('#'+$(this).data('target'));
        controller.showHidePreview('show');
        controller.showHideSavingCrop('hide');
      } else {
        $('#pseudo-button-add-photo')[0].click();
      }

      $(this).val('');
    });

    $(document).on('click', '.edit-photo-image-venue', function(e) {
      e.preventDefault()
      $('#input-btn-add-photo').data('target', $(this).data('target'));
      $('#input-btn-add-photo')[0].click();
    });

    $(document).on('click', '#saving-crop', function() {
      $(controller.varsValue.target_x).val(controller.varsValue.value_x);
      $(controller.varsValue.target_y).val(controller.varsValue.value_y);
      $(controller.varsValue.target_h).val(controller.varsValue.value_h);
      $(controller.varsValue.target_w).val(controller.varsValue.value_w);
      $('#input-btn-add-photo').removeData('target')
      $('#'+controller.varsValue.id_target).val(controller.varsValue.photo_remote_link);
      $(controller.varsValue.target_preview).attr('src', controller.image_selector.cropper('getCroppedCanvas').toDataURL());
      $('#photo-image-wrapper'+controller.varsValue.id_target).show();
    });

    $('#photos')
      .on('cocoon:after-insert', function() {
        var target = $('#photos .photo-remote-url').last().attr('id');
        controller.insertPhotoModal('#'+target);
        controller.showHidePreview('show');
        controller.showHideSavingCrop('hide');
      });
  }
  disconnect() {}

  showHidePreview(state) {
    if(state === undefined) {
      state = 'hide';
    }

    if(state === 'hide') {
      $('#media-input-preview').hide()
      $('#media-input-wrapper').show()
    } else {
      $('#media-input-preview').show()
      $('#media-input-wrapper').hide()
    }
  }

  insertPhotoModal(input) {
    this.setVar('id_target', $(input).attr('id') );
    this.setStatusSelector('loading');
    
    var formData = new FormData();
    formData.append('file', $('#input-btn-add-photo')[0].files[0])
    
    const controller = this;
    $.ajax({
      type: 'POST',
      url: '/venue_photo_cache_uploader/upload',
      processData: false,
      contentType: false,
      data: formData,
      success: function (data) {
        controller.setVar('photo_remote_link', $(input).data('base-url') + '/shrine/cache/' + data.id)

        controller.setStatusSelector('success')
        controller.buildCropper()
      },
      error: function (error) {
        controller.setStatusSelector(error.responseText)
      }
    });
  }

  setStatusSelector(state) {
    if(state === undefined) {
      state = 'loading';
    }

    var text = 'Uploading...';

    if (state === 'loading') {
      text = 'Uploading...';
    } else if (state === 'success') {
      text = ''
    } else {
      text = state;
    }

    text = (text == 'Upload Too Large') ? '<span style="color: red;"> File uploaded has exceeded 2MB </span>' : text;
    this.status_selector.html('<p>'+text+'</p>')
  }

  get status_selector() {
    return $('#media-input-preview #status-image');
  }

  // clear and prepared input
  clearSelectorImage() {
    this.image_selector.attr('src', '')

    if(this.image_selector.hasClass('cropper-hidden')) {
      this.image_selector.cropper("destroy");
    }

    this.image_selector.removeData('id')
  }

  get image_selector() {
    return $('#media-input-preview #image');
  }

  showHideSavingCrop(state) {
    if(state === undefined) {
      state = 'hide';
    }

    if(state === 'hide') {
      $('#saving-crop').hide()
    } else {
      $('#saving-crop').show()
    }
  }

  buildCropper() {
    this.setVar('target_x', '#x'+this.varsValue.id_target);
    this.setVar('target_y', '#y'+this.varsValue.id_target);
    this.setVar('target_h', '#h'+this.varsValue.id_target);
    this.setVar('target_w', '#w'+this.varsValue.id_target);
    this.setVar('target_preview', '#photo_preview'+this.varsValue.id_target);

    this.image_selector.attr('src', this.varsValue.photo_remote_link);
    this.image_selector.attr('crossorigin', 'anonymous');
    
    const controller = this;
    controller.image_selector.cropper({
      aspectRatio: 8 / 6,
      zoomable: false,
      scalable: false,
      rotatable: false,
      crop(event) {
        controller.showHideSavingCrop('show')
        controller.setVar('value_x', event.detail.x);
        controller.setVar('value_y', event.detail.y);
        controller.setVar('value_h', event.detail.height);
        controller.setVar('value_w', event.detail.width);
      }
    });
  }

  clearAllVariable() {
    this.varsValue = {
      id_target: null,
      target_x: null, 
      target_y: null, 
      target_h: null, 
      target_w: null, 
      target_preview: null, 
      value_x: null, 
      value_y: null, 
      value_h: null, 
      value_w: null, 
      photo_remote_link: null
    };
  }

  setVar(key, value) {
    let tmp = {}
    tmp[key] = value;

    this.varsValue = Object.assign(this.varsValue, tmp);

    return this.varsValue[key];
  }
}