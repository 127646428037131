import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let controller = this

    $('.btnNext').on('click', function () {
      $('.nav-pills .active').next().trigger('click');
      controller.stopVideo($(this).closest('.action-wrapper').prev()[0]);
    });

    $('.btnPrev').on('click', function () {
      $('.nav-pills .active').prev().trigger('click');
      controller.stopVideo($(this).closest('.action-wrapper').prev()[0]);
    });

    $('.btnListing').on('click', function () {
      $('#v-pills-intro-tab').trigger('click');
      controller.stopVideo($(this).closest('.action-wrapper').prev()[0]);
    });

    $('#v-pills-tab a').on('click',function(){
      var tab_id = $('#v-pills-tab a.active').attr('href');
      controller.stopVideo($(tab_id).find('.video-wrapper')[0]);
    });
  }

  stopVideo (container) {
    var iframe = container.getElementsByTagName("iframe")[0];
    var url = iframe.getAttribute('src');
    iframe.setAttribute('src', '');
    iframe.setAttribute('src', url);
  }
}
