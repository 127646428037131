import WOW from 'wow.js'
import 'flexslider';
import 'flexslider/flexslider.css';

window.get_cookie = function(cname){
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

window.set_voted_status = function (){
  $('.category_divs').each(function() {
    var category_id = $(this).data('category-id');
    var voted = get_cookie("voted_category_" + category_id);
    if(voted){
      var vote_status = $('#category_' + category_id + ' .vote_status');
      $('#category_' + category_id).addClass('voted');
      vote_status.addClass('bold');
      vote_status.removeClass('link');
      vote_status.html('VOTED');

      $('#category_' + category_id + ' .bg-main').removeClass('hidden');
      $('#category_' + category_id + ' .bg-placeholder').addClass('hidden');
    }
    else{
      var vote_status = $('#category_' + category_id + ' .vote_status');
      $('#category_' + category_id).removeClass('voted');
      vote_status.addClass('link');
      vote_status.removeClass('bold');
      vote_status.html('VOTE NOW');
    }
  });
};

$(document).ready(function() {
  if (document.getElementById('venuerific-choice-awards')) {

    if ($('#popupVca').length > 0) {
    $('#popupVca').modal('show');
    }

    set_voted_status();

    var category_names = []
    document.querySelectorAll('.categories-list').forEach(cat => { category_names.push(cat.dataset.name); });

    $('#carousel-'+category_names[0]).flexslider({
      animation: "slide",
      controlNav: false,
      animationLoop: true,
      slideshow: true,
      itemMargin: 40,
      asNavFor: '#slider-'+category_names[0],
      itemWidth: 264,
      pauseOnAction: true,
      pauseOnHover: false,
      startAt: 0
    });

    $('#slider-'+category_names[0]).flexslider({
      animation: "slide",
      controlNav: false,
      animationLoop: false,
      slideshow: false,
      sync: '#carousel-'+category_names[0]
    });

    $('.flexslider-nominee').flexslider({
      animation: "fade",
      animationLoop: true,
      selector: '.slides-category > figure',
      controlNav: false,
      slideshowSpeed: 1500,
      animationSpeed: 1500,
      touch: true,
      slideshow: true,
      startAt: 0,
      initDelay: 0,
      directionNav: false,
    });

    $("#carousel-"+category_names[0]+"-winner").trigger('click')

    $(document).on('shown.bs.tab', '.categories-list', function (e) {
      var item = e.currentTarget.dataset.name

      $('#carousel-'+item).flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: true,
        slideshow: true,
        itemMargin: 40,
        asNavFor: '#slider-'+item,
        itemWidth: 264,
        pauseOnAction: true,
        pauseOnHover: false,
        startAt: 0
      });

      $('#slider-'+item).flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        sync: '#carousel-'+item
      });

      $('#slider-'+item).resize();
    });

    new WOW().init();

    $(".copy_to_clip").click(function(){
      /* Get the text field */
      var copyText = document.getElementById("award_url_input");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
    });

    $('#voteModal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget) // Button that triggered the modal

      $('#error_explanation_vote ul').empty();
      var cat_ids = []
      document.querySelectorAll('.category-link').forEach(cat => { cat_ids.push(cat.dataset.name); });
      var index = cat_ids.indexOf(button.data('category-name'));
      index = index === (cat_ids.length-1) ? 0 : (index + 1);
      document.getElementById("lets_continue").setAttribute('data-next-category', cat_ids[index])

      $('#error_explanation_vote').addClass('d-none');
      $('#new_venue_award_vote').find("input[type=text], input[type=email], select").val("");
      var venue_name = button.data('venue-name') // Extract info from data-* attributes
      var modal = $(this)

      modal.find('#vote_modal_title').text(venue_name);
      modal.find('.vote_name').val(get_cookie("voted_category_name"));
      modal.find('.vote_email').val(get_cookie("voted_category_email"));
      modal.find('.vote_user_id').val(button.data('user-id'));
      modal.find('.vote_venue_award_category_id').val(button.data('category-id'));
      modal.find('.vote_venue_id').val(button.data('venue-id'));
      // GA tracking when clicking "Vote for this venue" button
      if (dataLayer && button.data('analytics-category')) {
        dataLayer.push({
          'event': 'eventTracking',
          'category': button.data('analytics-category'),
          'action': button.data('analytics-action'),
          'label': button.data('analytics-label')
        });
      }
    })

    $('#voteInModal').on('show.bs.modal', function (event) {
      $('#lets_continue').removeClass('active');
    })

    $('#lets_continue').on('click', function (e) {
      var category = $(this).data('next-category')
      $('#voteInModal').modal('hide')

      $("#"+category+"-tab").trigger('click')
    })

    $("#win-prizes").on('click',function() {
      $('html, body').animate({
        'scrollTop' : $("#prize-section").position().top
      });
    });

    $(".got-to-categories").on('click',function() {
      $('html, body').animate({
        'scrollTop' : $("#vca-cat").position().top
      });
    });

    $(".categories-list").on('click',function() {
      if ($("#vcaTabContent").length){
        $('html, body').animate({
          'scrollTop' : $("#vcaTabContent").position().top - 336,
        });
      }
    });

    // $(".carousel-nominee ul.slides li").on('click',function() {
    //   if ($("#vcaTabContent").length){
    //     $('html, body').animate({
    //       'scrollTop' : $("#vcaTabContent").position().top - 336,
    //     });
    //   }
    // });

    $(".parent li a").on("click", function (e) {
      var winner = false;
      if (!$(this).hasClass('category-link')) winner = true
      var data_name = $(this).data('name');
      setTimeout(function(){
        if (winner){
          $("#carousel-"+data_name+"-winner").trigger('click')
        }else{
          $("#carousel-"+data_name+" li:first").trigger('click')
        }
      }, 200);
    });
  }

  loadScrollEvent();
});

function loadScrollEvent() {
  $(window).on('scroll', function () {
    // vca--pt0 add padding top 0, for transparent nav, if not exist then not using transparent nav then skip code to toggle between white/trans nav
    if (!document.querySelector('.vca--pt0')) { return; }
    var scroll = $(window).scrollTop();
    if (scroll >= document.querySelector('.main-banner').offsetHeight) {
      $(".trans-nav .nav-main").addClass("white");
      $(".sticky-top").addClass("full");
      $(".sticky-mobile").addClass("full");
    } else {
      $(".trans-nav .nav-main").removeClass("white");
      $(".sticky-top").removeClass("full");
      $(".sticky-mobile").removeClass("full");
    }
  });
}