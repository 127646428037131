import { Controller } from 'stimulus'
import 'summernote'
import Cropper from 'cropperjs';
import cropper from '../../../library/cropper'

export default class extends Controller {
  static targets = ['amenity']

  connect() {
    //
    this.loadSummernoteEditor()
    this.disabledEnterForm()
    this.modalDeleteSimpleForm()
    //
    this.tags = []
    this.customTags()
    this.appendExistingAmenity()
    //
    this.unitTypeChange()
    //
    //this.minMaxCapacityChange()
    //this.bindingDiscountLimit()
    //
    this.bindingAddDiscount()
    this.bindingDiscountInputs()

    // Room Photos Function
    this.image_selector = $('#media-input-preview #image')
    this.status_selector = $('#media-input-preview #status-image')
    this.id_target, this.target_x, this.target_y, this.target_h, this.target_w,
      this.target_preview, this.value_x, this.value_y, this.value_h, this.value_w,
      this.photo_remote_link, this.photo_remote_link, this.cropperElement = null
    this.photosAdded()
    this.bindingImageUpload()

    // Package Photo
    this.bindingPackageButton()

    this.initSelect2Fields()
    $('#venue_room_allow_cuisine').on('click', function(e) {
      if ($('#venue_room_allow_cuisine').is(":checked")) {
        $("select.select2-wo-order").removeAttr("disabled");
      } else {
        $("select.select2-wo-order").attr("disabled", "disabled");
      }
    });
  }

  initSelect2Fields() {
    $('.form select.select2').each(function() {
      var paramsChange = {
        //theme: "bootstrap",
        tags: $(this).data('tags') || false,
        placeholder: $(this).data('placeholder')
      };

      if($(this).data('max-selection') != undefined) {
        paramsChange['maximumSelectionLength'] = $(this).data('max-selection')
      };

      $(this).select2(paramsChange);
    });

    $("select.select2-wo-order").on("select2:select", function (evt) {
      var element = evt.params.data.element;
      var $element = $(element);

      $element.detach();
      $(this).append($element);
      $(this).trigger("change");
    });

    $("select.select2-wo-order").on('select2:open', function (e) {
      $('.select2-dropdown').css({ "font-family": 'Montserrat' });
    });
  }

  delay(callback, ms) {
    var timer = 0;
    return function () {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  minMaxCapacityChange() {
    // Price
    $("#venue_room_min_capacity").on("keyup change", this.delay(function (e) {
      var min_capacity = $(this).val()
      $("#venue_room_max_capacity").attr('min', min_capacity)
      var value = parseInt(min_capacity)
      if ($("#venue_room_prices_attributes_0_unit_type").val() == "pax") {
        var $min_inputs = $("#venue_room_prices_attributes_0_min_unit")
        var $max_inputs = $("#venue_room_prices_attributes_0_max_unit")
        var min_input_value = parseInt($min_inputs.val())
        $min_inputs.attr('min', min_capacity)
        $max_inputs.attr('min', min_capacity)
        if (min_input_value < value) {
          $min_inputs.val(value).trigger("change")
        }
      }

      var $packages = $("#packages").find(".package-fields")
      if ($packages.length > 0) {
        $packages.each(function (i, obj) {
          var $obj = $(obj)
          var $min_capacity = $($obj.find("input[name*='min_capacity']")[0])
          var $max_capacity = $($obj.find("input[name*='max_capacity']")[0])
          $min_capacity.attr('min', min_capacity)
          $max_capacity.attr('min', min_capacity)
          var min_input_value = parseInt($min_capacity.val())
          if (min_input_value < value) {
            $min_capacity.val(value)
          }
          // var unit_type = $($(obj).find("select[name*='unit_type']")[0]).val()
          // if (unit_type == "pax") {
          //   var $min_input = $($(obj).find("input[name*='min_unit']")[0])
          //   var $max_input = $($(obj).find("input[name*='max_unit']")[0])
          //   var min_input_value = parseInt($min_input.val())
          //   $min_input.attr('min', min_capacity)
          //   $max_input.attr('min', min_capacity)
          //   if (min_input_value < value) {
          //     $min_input.val(value)
          //   }
          // }
        })
      }
    }, 1000))

    $("#venue_room_max_capacity").on("keyup change", this.delay(function (e) {
      var max_capacity = $(this).val()
      var value = parseInt(max_capacity)
      if ($("#venue_room_prices_attributes_0_unit_type").val() == "pax") {
        var $min_inputs = $("#venue_room_prices_attributes_0_min_unit")
        var $max_inputs = $("#venue_room_prices_attributes_0_max_unit")
        var max_input_value = parseInt($max_inputs.val())
        $min_inputs.attr('max', max_capacity)
        $max_inputs.attr('max', max_capacity)
        if (max_input_value > value) {
          $max_inputs.val(value).trigger("change")
        }
      }

      var $packages = $("#packages").find(".package-fields")
      if ($packages.length > 0) {
        $packages.each(function (i, obj) {
          var $obj = $(obj)
          var $min_capacity = $($obj.find("input[name*='min_capacity']")[0])
          var $max_capacity = $($obj.find("input[name*='max_capacity']")[0])
          var max_input_value = parseInt($max_capacity.val())
          $min_capacity.attr('min', max_capacity)
          $max_capacity.attr('min', max_capacity)
          if (max_input_value > value) {
            $max_capacity.val(value).trigger("change")
          }

          // var obj = $(obj)
          // var unit_type = $($(obj).find("select[name*='unit_type']")[0]).val()
          // if (unit_type == "pax") {
          //   var $min_input = $($(obj).find("input[name*='min_unit']")[0])
          //   var $max_input = $($(obj).find("input[name*='max_unit']")[0])
          //   var max_input_value = parseInt($max_input.val())
          //   $min_input.attr('max', max_capacity)
          //   $max_input.attr('max', max_capacity)
          //   if (max_input_value > value) {
          //     $max_input.val(value)
          //   }
          // }
        })
      }
    }, 1000))

    $(document).on('change keyup', '.min_capacity_package', this.delay(function (e) {
      var min_capacity = $(this).val()
      var $parent = $(this).parents('.package-fields')
      var $unit_type = $parent.find("select[name*='unit_type']")
      $parent.find(".max_capacity_package").attr('min', min_capacity)
      var value = parseInt(min_capacity)
      if ($unit_type.val() == "pax") {
        var $min_inputs = $parent.find("input[name*='min_unit']")
        var $max_inputs = $parent.find("input[name*='max_unit']")
        var min_input_value = parseInt($min_inputs.val())
        $min_inputs.attr('min', min_capacity)
        $max_inputs.attr('min', min_capacity)
        $min_inputs.data('min', min_capacity)
        $max_inputs.data('min', min_capacity)
        if (min_input_value < value) {
          $min_inputs.val(value).trigger("change")
        }
      }
    }, 1000))

    $(document).on('change keyup', '.max_capacity_package', this.delay(function (e) {
      var max_capacity = $(this).val()
      var $parent = $(this).parents('.package-fields')
      var $unit_type = $parent.find("select[name*='unit_type']")
      var value = parseInt(max_capacity)
      if ($unit_type.val() == "pax") {
        var $min_inputs = $parent.find("input[name*='min_unit']")
        var $max_inputs = $parent.find("input[name*='max_unit']")
        var max_input_value = parseInt($max_inputs.val())
        $min_inputs.attr('max', max_capacity)
        $max_inputs.attr('max', max_capacity)
        $min_inputs.data('max', max_capacity)
        $max_inputs.data('max', max_capacity)
        if (max_input_value > value) {
          $max_inputs.val(value).trigger("change")
        }
      }
    }, 1000))
  }

  bindingDiscountLimit() {
    // Discount
    $("#venue_room_prices_attributes_0_min_unit").on("keyup change", this.delay(function (e) {
      var $discount_limit_unit = $("#price_discounts").find("input[name*='discount_limit_unit']")
      var value = parseInt($(this).val())
      $discount_limit_unit.attr('min', $(this).val())
      $discount_limit_unit.each(function (i, el) {
        el = $(el)
        var discount_value = parseInt(el.val())
        if (discount_value < value) {
          el.val(value).trigger("input")
        }
      })
    }, 1000))
    $("#venue_room_prices_attributes_0_max_unit").on("keyup change", this.delay(function (e) {
      var $discount_limit_unit = $("#price_discounts").find("input[name*='discount_limit_unit']")
      var value = parseInt($(this).val())
      $discount_limit_unit.attr('max', value)
      $discount_limit_unit.each(function (i, el) {
        el = $(el)
        var discount_value = parseInt(el.val())
        if (discount_value > value) {
          el.val(value).trigger("input")
        }
      })
    }, 1000))
  }

  bindingPackageButton() {
    let classDefine = this

    //
    var $packages = $("#packages").find(".package-fields")
    if ($packages.length > 0) {
      $('#package .form-group--button .image-wrapper').hide()
      $('#package .form-group--button').css({ "-webkit-box-alight": "start", "-ms-flex-alight": "start", "align-items": "flex-start" })

      $packages.each(function (i, obj) {
        var obj = $(obj)
        var index = obj[0].dataset["index"]
        classDefine.initCropper(obj.find('#photo-input' + index).get(0))
      })
    }

    $('#packages')
      .on('cocoon:after-insert', function (e, added_task) {
        var index = $('#packages').find('.package-fields').length
        added_task.find('#media-photo-input-wrapper').attr("id", "media-photo-input-wrapper" + index);
        added_task.find('#button-add-photo').attr("id", "button-add-photo" + index);
        added_task.find('#photo-input').attr("id", "photo-input" + index);
        added_task.find('#photo-x').attr("id", "photo-x" + index);
        added_task.find('#photo-y').attr("id", "photo-y" + index);
        added_task.find('#photo-h').attr("id", "photo-h" + index);
        added_task.find('#photo-w').attr("id", "photo-w" + index);
        added_task.find('#photo-photo-image-result').attr("id", "photo-photo-image-result" + index);
        added_task.find('#photo-photo-preview').attr("id", "photo-photo-preview" + index);
        added_task.find('#edit-photo-image-package').attr("id", "edit-photo-image-package" + index);
        added_task.find('#media-photo-input-preview').attr("id", "media-photo-input-preview" + index);
        added_task.find('#media-photo-input-preview-content').attr("id", "media-photo-input-preview-content" + index);
        added_task.find('#saving-photo-crop').attr("id", "saving-photo-crop" + index);

        $('#photo-input' + index).attr({
          'data-upload-button': "button-add-photo" + index,
          'data-input-target-x': "photo-x" + index,
          'data-input-target-y': "photo-y" + index,
          'data-input-target-h': "photo-h" + index,
          'data-input-target-w': "photo-w" + index,
          'data-image-wrapper': "photo-photo-image-wrapper" + index,
          'data-photo-preview': "photo-photo-preview" + index,
          'data-edit-button': "edit-photo-image-package" + index,
          'data-media-input-preview-wrapper': "media-photo-input-preview" + index,
          'data-media-input-preview-content': "media-photo-input-preview-content" + index,
          'data-saving-crop': "saving-photo-crop" + index,
          'data-media-input-wrapper': "media-photo-input-wrapper" + index,
          'data-photo-image-result': "photo-photo-image-result" + index
        })

        classDefine.initCropper($('#photo-input' + index).get(0))
        added_task.data("index", index)
        added_task.find('.package-index').html(index)

        if (index == 1) {
          $('#package .form-group--button .image-wrapper').hide()
          $('#package .form-group--button').css({ "-webkit-box-alight": "start", "-ms-flex-alight": "start", "align-items": "flex-start" })
        }

        // Initialize package min unit
        // var min_unit = added_task.find("input[name*='min_unit']")[0]
        // var max_unit = added_task.find("input[name*='max_unit']")[0]
        // var price_min_unit = $("#venue_room_min_capacity").val()
        // var price_max_unit = $("#venue_room_max_capacity").val()
        // $(min_unit).attr('min', price_min_unit)
        // $(min_unit).attr('max', price_max_unit)
        // $(min_unit).val(price_min_unit)
        // $(max_unit).attr('min', price_min_unit)
        // $(max_unit).attr('max', price_max_unit)
      })
  }

  initCropper(input) {
    cropper(input)
  }

  photosAdded() {
    let classDefine = this

    if ($('#photos-upload-wrapper').length > 0) {
      $(document).on('click', '.close-media-input-preview', function () {
        classDefine.showHidePreview('hide')
        // showHideSavingForm('show');
        $('#input-btn-add-photo').removeData('target')

        if ($(this).attr('id') == 'close-button') {
          // clear all field by checking if there are value on photo remote url or not
          var photo_remote = $('#' + classDefine.id_target)
          if (photo_remote.data('new-record') == true && photo_remote.val() == '') {
            photo_remote.parents('.nested-fields').first().remove()
          }
        }
      })

      $(document).on('click', '.btn-add-photo', function () {
        $('#input-btn-add-photo').removeData('target')
        $('#input-btn-add-photo').click()
      })

      $(document).on('change', '#input-btn-add-photo', function () {
        classDefine.clearAllVariable()
        classDefine.clearSelectorImage()
        if ($(this).data('target') !== undefined) {
          classDefine.insertPhotoModal('#' + $(this).data('target'))
          classDefine.showHidePreview('show')
          classDefine.showHideSavingCrop('hide')
        } else {
          $('#pseudo-button-add-photo').click()
        }

        $(this).val('')
      })

      $(document).on('click', '.edit-photo-image-venue', function (e) {
        e.preventDefault()
        $('#photo-image-wrapper' + $(this).data('target')).data('action', 'edit')
        $('#input-btn-add-photo').data('target', $(this).data('target'))
        $('#input-btn-add-photo').click()
      })

      $(document).on('click', '#saving-crop', function () {
        classDefine.target_x.val(classDefine.value_x)
        classDefine.target_y.val(classDefine.value_y)
        classDefine.target_h.val(classDefine.value_h)
        classDefine.target_w.val(classDefine.value_w)
        $('#input-btn-add-photo').removeData('target')
        $('#' + classDefine.id_target).val(classDefine.photo_remote_link)
        classDefine.target_preview.attr('src', classDefine.cropperElement.getCroppedCanvas().toDataURL())
        $('#photo-image-wrapper' + classDefine.id_target).show()

        var html = "<figure class='thumbnail-item item'>" +
          "<img src='" + classDefine.cropperElement.getCroppedCanvas().toDataURL() + "' class='img-fluid thumbnail-image'>" +
          "</figure>"
        var index = 0
        if ($('#photo-image-wrapper' + classDefine.id_target).data('action') == "edit") {
          index = $('#photo-image-wrapper' + classDefine.id_target).data('index')
        } else {
          index = $('#photos').find('.photo-fields:visible').length - 1
          if (index == 0) {
            $('#is_primary' + classDefine.id_target).val(true)
            $('#photo-image-wrapper' + classDefine.id_target).find('.photo-image-star-wrapper').show()
          }
        }
        var $parent = $('#photo-image-wrapper' + classDefine.id_target).parents('.photo-fields')
        $parent.data("index", index)
        $parent.find("[data-index]").data("index", index)

        if ($('#photos').find('.photo-fields:visible').length == 1){
          $(".thumbnail-slider").slick('slickAdd', html, 0)
        } else {
          $(".thumbnail-slider").slick('slickAdd', html, index-1)
        }

        if ($('#photo-image-wrapper' + classDefine.id_target).data('action') == "edit") {
          $(".thumbnail-slider").slick('slickRemove', index)
        }
        $(".thumbnail-container--room").addClass("uploaded")
      })

      $('#photos')
        .on('cocoon:after-insert', function (e, added_task) {
          var target = $('#photos .photo-remote-url').last().attr('id')
          classDefine.insertPhotoModal('#' + target)
          classDefine.showHidePreview('show')
          classDefine.showHideSavingCrop('hide')

          added_task.on('click', function (e) {
            if (e.target.id.includes("photo_preview_")) {
              $("#modalRoomPackage").modal("show")
              $(".thumbnail-slider").slick("slickGoTo", $(e.target).data('index'), true)
              $("#modalRoomPackage #setPrimary").data('index', $(e.target).data('index'))
            }
          })
          // showHideSavingForm('hide');
        })
        .on("cocoon:after-remove", function (e, task) {
          var index = task.data('index')
          var $photos_fields = $('#photos').find('.nested-fields:visible')
          if ($photos_fields.length > 0) {
            $photos_fields.each(function (i, obj) {
              var old_index = $(obj).data("index")
              $(obj).data("index", old_index - 1)
              $(obj).find("[data-index]").data("index", old_index - 1)
            })
          }

          if (task.find("input[name*='is_primary']").val() == "true") {
            var first_photo = $('#photos').find('.photo-fields:visible').eq(0)
            first_photo.find("input[name*='is_primary']").val(true)
            first_photo.find('.photo-image-star-wrapper').show()
          }

          $(".thumbnail-slider").slick('slickRemove', index)
        })

  }
}

  //for make sure previous variables not includes
  clearAllVariable() {
    this.id_target, this.target_x, this.target_y, this.target_h, this.target_w,
      this.target_preview, this.value_x, this.value_y, this.value_h, this.value_w,
      this.photo_remote_link = null
  }

  // clear and prepared input
  clearSelectorImage() {
    this.image_selector.attr('src', '')

    if (this.image_selector.hasClass('cropper-hidden')) {
      this.cropperElement.destroy()
    }

    this.image_selector.removeData('id')
  }

  setStatusSelector(state) {
    if (state === undefined) {
      state = 'loading';
    }

    var text = 'Uploading...';

    if (state === 'loading') {
      text = 'Uploading...';
    } else if (state === 'success') {
      text = ''
    } else {
      text = state;
    }

    text = (text == 'Upload Too Large') ? '<span style="color: red;"> File uploaded has exceeded 2MB </span>' : text;
    this.status_selector.html('<p>' + text + '</p>')
  }

  showHidePreview(state) {
    if (state === undefined) {
      state = 'hide';
    }

    if (state === 'hide') {
      $('#media-input-preview').hide()
      $('#media-input-wrapper').show()
    } else {
      $('#media-input-preview').show()
      $('#media-input-wrapper').hide()
    }
  }

  showHideSavingCrop(state) {
    if (state === undefined) {
      state = 'hide';
    }

    if (state === 'hide') {
      $('#saving-crop').hide()
    } else {
      $('#saving-crop').show()
    }
  }

  showHideSavingForm(state) {
    if (state === undefined) {
      state = 'hide';
    }

    if (state === 'hide') {
      $('#save-form').hide()
    } else {
      $('#save-form').show()
    }
  }

  insertPhotoModal(input) {
    let classDefine = this

    classDefine.id_target = $(input).attr('id');
    classDefine.setStatusSelector('loading');

    var formData = new FormData();
    formData.append('file', $('#input-btn-add-photo')[0].files[0])

    $.ajax({
      type: 'POST',
      url: '/venue_photo_cache_uploader/upload',
      processData: false,
      contentType: false,
      data: formData,
      success: function (data) {
        classDefine.photo_remote_link = $(input).data('base-url') + '/shrine/cache/' + data.id;

        classDefine.setStatusSelector('success')
        classDefine.buildCropper()
      },
      error: function (error) {
        classDefine.setStatusSelector(error.responseText)
      }
    })
  }

  buildCropper() {
    let classDefine = this

    classDefine.target_x = $('#x' + classDefine.id_target);
    classDefine.target_y = $('#y' + classDefine.id_target);
    classDefine.target_h = $('#h' + classDefine.id_target);
    classDefine.target_w = $('#w' + classDefine.id_target);
    classDefine.target_preview = $('#photo_preview' + classDefine.id_target);

    classDefine.image_selector.attr('src', classDefine.photo_remote_link);
    classDefine.image_selector.attr('crossorigin', 'anonymous');

    this.cropperElement = new Cropper(image, {
      aspectRatio: 8 / 6,
      zoomable: false,
      scalable: false,
      rotatable: false,
      crop(event) {
        classDefine.showHideSavingCrop('show')
        // showHideSavingForm('hide')
        classDefine.value_x = event.detail.x;
        classDefine.value_y = event.detail.y;
        classDefine.value_h = event.detail.height;
        classDefine.value_w = event.detail.width;
      }
    })
  }

  bindingAddDiscount() {
    let classDefine = this

    $('.btn-discount').on('click', function () {
      if ($('#discount_summary').hasClass('d-none')) {
        $('#discount_summary').removeClass('d-none')
      }
    })

    $('#price_discounts')
      .on('cocoon:after-insert', function () {
        var index = $(this).find('.discount-fields').length
        var unit_type = $(this).parents(".price-wrapper").find('.unit_type_changed').val()
        // Initialize data attribute
        $(this).find(".discount-fields:last").find('.discount-unit-type').html('(' + unit_type + ')')
        $(this).find(".discount-fields:last").data("index", index)
        $(this).find(".discount-fields:last").find('.discount-index').html(index)
        $(this).find(".discount-fields:last").find('input, select').data('id', index)

        // Initialize discount limit unit
        // var $discount_limit_unit = $(this).find(".discount-fields:last").find("input[name*='discount_limit_unit']")
        // var price_min_unit = $("#venue_room_prices_attributes_0_min_unit").val()
        // var price_max_unit = $("#venue_room_prices_attributes_0_max_unit").val()
        // $discount_limit_unit.attr('min', price_min_unit)
        // $discount_limit_unit.attr('max', price_max_unit)
        // $discount_limit_unit.attr('value', price_min_unit)

        classDefine.bindingDiscountInputs()
        classDefine.appendSummaryDiscount(index, unit_type)
      })
      .on("cocoon:after-remove", function (e, task) {
        var index = task.data('index')
        classDefine.removeSummaryDiscount(index)
      })
  }

  bindingDiscountInputs() {
    $('#price_discounts').find('input').each(function () {
      $(this).on('input', function (e) {
        var value = $(this).val()
        var id = $(this).data('id')
        var attr = $(this).data('attr')
        if (attr == "discount") {
          var result = parseFloat(value).toFixed(2)
          var discount_type = $(this).closest('.form-group').find("select[data-attr='discount']").val()
          if (discount_type == '$') {
            var currency = $('#venue_room_prices_attributes_0_currency').val();
            $('#summary_discount_' + id + ' .summary-discount').html(currency + result)
          } else {
            $('#summary_discount_' + id + ' .summary-discount').html(result + '%')
          }
        } else {
          $('#summary_discount_' + id + ' .summary-discount-' + attr).html(value)
        }
      })
    })

    $('#price_discounts').find('select').each(function () {
      $(this).on('change', function (e) {
        var value = $(this).val()
        var id = $(this).data('id')
        var attr = $(this).data('attr')
        if (attr == "discount") {
          var discount_value = $(this).closest('.form-group').find("input[data-attr='discount']").val()
          var result = parseFloat(discount_value).toFixed(2)
          if (!isNaN(result)) {
            if (value == '$') {
              var currency = $('#venue_room_prices_attributes_0_currency').val();
              $('#summary_discount_' + id + ' .summary-discount').html(currency + result)
            } else {
              $('#summary_discount_' + id + ' .summary-discount').html(result + '%')
            }
          }
        }
      })
    })
  }

  appendSummaryDiscount(index, unit_type) {
    $('#discount_summary').append("<p id='summary_discount_" + index + "' class='text'>" +
      "- Minimum of <span class='bold'><span class='summary-discount-limit-unit'></span> <span class='summary-discount-unit-type'>" + unit_type + "(s)</span></span> rental to enjoy " +
      "<span class='bold'><span class='summary-discount'></span> discount</span>")
  }

  removeSummaryDiscount(index) {
    $('#summary_discount_' + index).remove()
  }

  appendExistingAmenity() {
    for (let amenityDOM of this.amenityTargets) {
      if (amenityDOM.dataset['id']) {
        this.tags.push(amenityDOM.dataset['id'])
      }
    }
  }

  customTags() {
    let classDefine = this
    let input = document.querySelector('.input-tags')

    if (input !== null) {
      input.addEventListener('keyup', function (event) {
        const keyPressedIsEnter = event.key === 'Enter'

        if ($("#venue_room_other_amenities > option").length > 4) return
        if (keyPressedIsEnter) {
          input.value.split(',').forEach(function (tag, index) {
            tag = tag.trim()
            if (tag == "") return
            var optionExists = ($("#venue_room_other_amenities option[value='" + tag + "']").length > 0)
            if (optionExists) return

            classDefine.tags.push(tag.trim())
          })

          classDefine.updateTags()
          input.value = ''
        }
      })
    }
  }

  updateTags() {
    let classDefine = this
    classDefine.clearTags()

    classDefine.tags.slice().reverse().forEach(tag => {
      document.querySelector('.tag-container').prepend(classDefine.createTag(tag))
      // Add tag to select option
      var optionExists = ($("#venue_room_other_amenities option[value='" + tag + "']").length > 0)
      if (!optionExists) {
        $('#venue_room_other_amenities').prepend("<option selected='selected' value='" + tag + "'>" + tag + "</option>")
      }
    })
  }

  createTag(tag) {
    // Preview Badge
    const div = document.createElement('div')
    div.classList.add('badge', 'amenties')
    const span = document.createElement('span')
    span.innerHTML = tag
    const i = document.createElement('i')
    i.classList.add('fa', 'fa-times-circle', 'close')
    i.setAttribute('data-id', tag)
    i.setAttribute('data-action', 'click->users--venue-rooms--form#removeTags')
    i.setAttribute('data-users--venue-rooms--form-target', 'amenity')
    span.append(i)
    div.append(span)

    return div
  }

  removeTags(event) {
    const buttonX = event.currentTarget
    const id = buttonX.dataset.id
    const index = this.tags.indexOf(id)

    // Remove tag inside tag container
    this.tags.splice(index, 1)

    // Remove option from select
    $("#venue_room_other_amenities option[value='" + id + "']").remove()

    this.updateTags()
  }

  clearTags() {
    document.querySelector('.tag-container')
      .querySelectorAll('.amenties')
      .forEach(tagElement => tagElement.remove())
  }

  disabledEnterForm() {
    $('#form_listing').on('keyup keypress', function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault()
        return false
      }
    })
  }

  modalDeleteSimpleForm() {
    $('#modalDeleteConfirmation').on('show.bs.modal', function (e) {
      //get id attribute of the clicked element
      var link_input_id = $(e.relatedTarget).find('input').attr('id')
      $(e.currentTarget).find('input').val(link_input_id)
    })

    $('button#modal_delete_it').on('click', function (e) {
      var link_id = $(this).siblings("input").val()
      $('#' + link_id).siblings("a").trigger('click')
      if ($('#price_discounts').find('.discount-fields').length == 1) {
        $('#discount_summary').addClass('d-none')
      }
    })
  }

  loadSummernoteEditor() {
    $(".textarea-description").on('keypress', function (e) {
      if (this.value.length > 600) {
        return false
      }
      // If the user has pressed enter
      if ((e.keyCode == 10 || e.keyCode == 13)) {
        e.preventDefault()
        this.value = this.value + "\n"
      }

      $(".limit-character").html((600 - this.value.length))
    })
    $(".textarea-description").trigger('keypress')

    $('.form-control--text-editor').each(function () {
      $(this).summernote({
        tooltip: false,
        toolbar: [['style', ['highlight', 'bold', 'italic', 'underline', 'clear']], ['para', ['ul', 'ol']]],
        callbacks: {
          onPaste: function (e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html')

            e.preventDefault()

            var div = $('<div>')
            div.append(bufferText)

            div.find('iframe, style, script, applet, embed, noframes, noscript').remove()
            div.find('*').removeAttr('style class id')

            setTimeout(function () {
              document.execCommand('insertHtml', false, div.html())
            }, 10)
          }
        }
      })
    })

    $("#packages").on('keypress', ".textarea-description-enter-key", function (e) {
      // If the user has pressed enter
      if ((e.keyCode == 10 || e.keyCode == 13)) {
        e.preventDefault()
        this.value = this.value + "\n"
      }
    })
  }

  unitTypeChange() {
    let classDefine = this

    $(document).on('change', '.unit_type_changed', function () {
      var nested_wrapper = $(this).parents('.nested-fields').first()
      var value = $(this).val()
      classDefine.changeStateUnitType(nested_wrapper, value)
    })

    $('.price-fields').each(function () {
      var nested_wrapper = $(this)
      var value = $(this).find('.unit_type_changed').first().val()

      classDefine.changeStateUnitType(nested_wrapper, value)
    })

    $('.package-fields').each(function () {
      var nested_wrapper = $(this)
      var value = $(this).find('.unit_type_changed').first().val()

      classDefine.changeStateUnitType(nested_wrapper, value)
    })
  }

  changeStateUnitType(wrapper, value) {
    if (value == 'minimum spend') {
      wrapper.find('.hide-minimum-spend').hide()
      wrapper.find('.show-minimum-spend').show()
      wrapper.parents(".price-wrapper").find('#price_discounts').hide()
      wrapper.parents(".price-wrapper").find('#discount_summary').hide()
      wrapper.parents(".price-wrapper").find('.btn-discount').hide()
      $(wrapper.find("input[name*='min_unit']").get(0)).val(1)
      // $(wrapper.find("input[name*='min_unit']").get(0)).removeAttr('required')
      // $(wrapper.find("input[name*='min_unit']").get(0)).removeAttr('min')
      // $(wrapper.find("input[name*='min_unit']").get(0)).removeAttr('max')
      $(wrapper.find("input[name*='max_unit']").get(0)).val("")
      // $(wrapper.find("input[name*='max_unit']").get(0)).removeAttr('required')
      // $(wrapper.find("input[name*='max_unit']").get(0)).removeAttr('min')
      // $(wrapper.find("input[name*='max_unit']").get(0)).removeAttr('max')
    } else {
      wrapper.find('.hide-minimum-spend').show()
      wrapper.find('.show-minimum-spend').hide()
      wrapper.find('.pax-hour-label').html(value)
      wrapper.find('.pax-min-label').html(value)
      wrapper.find('.pax-max-label').html(value)
      wrapper.parents(".price-wrapper").find('.discount-unit-type').html('(' + value + ')')
      wrapper.parents(".price-wrapper").find('.summary-discount-unit-type').html(value + '(s)')
      wrapper.parents(".price-wrapper").find('#price_discounts').show()
      wrapper.parents(".price-wrapper").find('#discount_summary').show()
      wrapper.parents(".price-wrapper").find('.btn-discount').show()
      // var $min_input = $(wrapper.find("input[name*='min_unit']").get(0))
      // var $max_input = $(wrapper.find("input[name*='max_unit']").get(0))
      // $min_input.attr('required', 'required')
      // $max_input.attr('required', 'required')
      // if (value == "pax") {
      //   var min_input_value = parseInt($("#venue_room_min_capacity").val())
      //   var max_input_value = parseInt($("#venue_room_max_capacity").val())
      //   var min_value = parseInt($min_input.val())
      //   var max_value = parseInt($max_input.val())
      //   $min_input.attr('min', min_input_value)
      //   $min_input.attr('max', max_input_value)
      //   $max_input.attr('min', min_input_value)
      //   $max_input.attr('max', max_input_value)
      //   if (min_value < min_input_value) {
      //     $min_input.val(min_input_value).trigger("change")
      //   }
      //   if (max_value > max_input_value) {
      //     $max_input.val(max_input_value).trigger("change")
      //   }
      // } else {
      //   $min_input.attr('min', 1)
      //   $min_input.attr('max', '')
      //   $max_input.attr('min', 1)
      //   $max_input.attr('max', '')
      // }
    }
  }

  bindingImageUpload() {
    $(".thumbnail-slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      prevArrow: $(".thumbnail-prev"),
      nextArrow: $(".thumbnail-next"),
      infinite: true,
      adaptiveHeight: true,
    })

    var $photos_fields = $('#photos').find('.nested-fields')
    if ($photos_fields.length > 0) {
      $photos_fields.find('img').each(function (i, obj) {
        var html = "<figure class='thumbnail-item item'>" +
          "<img src='" + $(obj).attr('src') + "' class='img-fluid thumbnail-image'>" +
          "</figure>"

        $(".thumbnail-slider").slick('slickAdd', html)
      })
    }

    $(".thumbnail-inner-wrapper").on('click', function (e) {
      if (e.target.id.includes("photo_preview_")) {
        $("#modalRoomPackage").modal("show")
        $(".thumbnail-slider").slick("slickGoTo", $(e.target).data('index'), true)
        $("#modalRoomPackage #setPrimary").data('index', $(e.target).data('index'))
      }
    })

    $("#modalRoomPackage").on("shown.bs.modal", function (e) {
      $(".thumbnail-slider").slick("setPosition")
    })

    $('.thumbnail-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
      $("#modalRoomPackage #setPrimary").data('index', currentSlide)
    })

    $("#modalRoomPackage #setPrimary").on('click', function (e) {
      var index = $(this).data('index')
      $("#photos").find("input[name*='is_primary']").each(function (i, obj) {
        $(obj).val(false)
        $(obj).parents('.photo-fields').find('.photo-image-star-wrapper').hide()
        if (index == $(obj).data('index')) {
          $(obj).val(true)
          $(obj).parents('.photo-fields').find('.photo-image-star-wrapper').show()
        }
      })
    })
  }
}
