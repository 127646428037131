import { Controller } from 'stimulus'
import toastr from 'toastr'

/**
 * File: /controllers/users/custom_messages/tpl_controller.js
 *
 * data-controller=users--custom-messages--tpl (script loader)
 */
export default class extends Controller {
  static targets = [
    'selectBox',
    'previewBtn',
    'createBtn',
    'selectTypeBox',
    'createNewBtn',
    'actionOptions'
  ];
  static values = {
    createnewurl: String,
    templates: String,
    createnewbytypeurl: String
  };
  static classes = ['contactemail', 'freedesignemail'];

  // the first method that called after script's loaded
  connect() {
    // init code here
    this.loadTplOptions();
    this.actionOptionsTarget.classList.add(this.contactemailClass);
    this.bindCreateButton();
    this.bindCreateNewButton();
  }

  // dynamic prop
  // Callable in this controller with: this.templates
  // returns Object
  get templates() { return JSON.parse(this.templatesValue); }

  loadTplOptions() {
    const selectBox = this.selectBoxTarget;

    this.templates.forEach(function(tpl) {
      let opt = document.createElement('option');
      opt.setAttribute('data-preview-url', tpl.preview_url);
      opt.setAttribute('value', tpl.id);
      opt.innerText = tpl.name;

      selectBox.appendChild(opt);
    });
  }

  // void
  // Callable: this.bindCreateButton()
  // Bind create button click event to open email builder page
  // prepopulated by selected template
  bindCreateButton() {
    const controller = this;
    this.createBtnTarget.addEventListener('click', function(event) {
      event.preventDefault();
      let selected = controller.selectBoxTarget.value
      if(selected == null || selected == undefined || !(/^[0-9]+/g.test(selected))) {
        toastr.error('Select template first');
        return false;
      }

      let _url = controller.createnewurlValue;
      controller.createnewurlValue = _url.replace("\-\-ID\-\-", selected);

      window.location = controller.createnewurlValue;
    });
  }

  bindCreateNewButton() {
    const controller = this;
    this.createNewBtnTarget.addEventListener('click', function(event) {
      event.preventDefault();
      let selected = controller.selectTypeBoxTarget.value;
      if(selected == null || selected == undefined) {
        toastr.error('Select type first');
        return false;
      }

      let _url = controller.createnewbytypeurlValue;
      controller.createnewbytypeurlValue = _url.replace("\-\-TYPE\-\-", selected);

      window.location = controller.createnewbytypeurlValue;
    });
  }

  toggleActionOptions() {
    if(this.selectTypeBoxTarget.value == 'free-design-email') {
      this.actionOptionsTarget.classList.remove(this.contactemailClass);
      this.actionOptionsTarget.classList.add(this.freedesignemailClass);
    } else {
      this.actionOptionsTarget.classList.remove(this.freedesignemailClass);
      this.actionOptionsTarget.classList.add(this.contactemailClass);
    }
  }

  toggleActionButtons() {
    let selected = this.selectBoxTarget.value;

    if(selected != null && selected != undefined && (/^[0-9]+/g.test(selected))) {
      this.createBtnTarget.disabled = false;
    } else {
      this.createBtnTarget.disabled = true;
    }
  }
}