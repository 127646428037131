import { Controller } from 'stimulus'
import 'slick-carousel'
import WOW from 'wow.js'

export default class extends Controller {
  static targets = [
    'nextBtn',
    'formInput'
  ]

  initialize() {
    this.meetingRequestValue = null
    this.captchaValue = null
  }

  connect() {
    this.window_scroll()
    this.testimony_slider()
    this.initialize_wow()
    this.multiple_items()
    this.feature_items()
    this.pro_items()
    this.center_items()
    this.data_toggle()
    this.preloadCaptcha()
    this.formInputEvent()
  }

  createSuccess(event){
    alert('fully tested');
  }


  data_toggle(){
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
  }

  window_scroll(){
    $(window).on('scroll', function() {
          var scroll = $(window).scrollTop();
          if (scroll >= 450) {
              $(".sticky-top").addClass('full');
          } else {
              $(".sticky-top").removeClass("full");
          }
      });
  }

  center_items(){
    $('.center-items').slick({
        infinite: true,
        centerMode: true,
        variableWidth: true,
        prevArrow: $('.center-prev'),
        nextArrow: $('.center-next')
      });
  }


  pro_items(){
    $('.promo-items').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: $('.promo-prev'),
        nextArrow: $('.promo-next')
      });
  }

  feature_items(){
    $('.feature-items').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: $('.feature-prev'),
        nextArrow: $('.feature-next')
      });
  }

  multiple_items(){
    $('.multiple-items').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1
    });
  }

  initialize_wow(){
    new WOW().init();
  }

  testimony_slider(){
    $(".testimonial-slider").slick({
      dots: true,
      infinite: true,
      speed: 300,
    });
  }

  preloadCaptcha() {
    const controller = this

    $(controller.nextBtnTarget).off('click')
    $(controller.nextBtnTarget).on('click', function (e) {
      e.preventDefault()
      if (controller.formIsValid() === true) {
        $('#captchaModal').modal('show')
      }
    })

    if(!this.captchaValue != null) {
      this.captchaValue = sliderCaptcha({
        id: "captcha",
        repeatIcon: "fa fa-repeat",
        onSuccess: function() {
          controller.copyCaptchaSession()
          setTimeout(function() { controller.postMeetingRequest() }, 0)
        },
        onFail: function() {
          controller.captchaValue.reset()
        }
      });
    } else {
      this.captchaValue.reset()
    }
  }

  get whyDetailForm() { return document.getElementById('why_details_form') }

  get whySidField() { return document.getElementById('captcha_sid') }

  postMeetingRequest() {
    const controller = this

    if(this.meetingRequestValue != null) {
      this.meetingRequestValue.abort()
    }

    this.meetingRequestValue = $.ajax({
      method: this.whyDetailForm.getAttribute('method'),
      url: this.whyDetailForm.getAttribute('action'),
      data: $(this.whyDetailForm).serialize(),
      type: 'script'
    })

    this.meetingRequestValue
      .done(function() {})
      .always(function() {
        $('#captchaModal').modal('hide')
        controller.captchaValue.reset()
      })
  }

  copyCaptchaSession() {
    this.whySidField.removeAttribute('disabled')
    this.whySidField.value = this.whyDetailForm.dataset.session
  }

  formInputEvent() {
    const controller = this
    controller.nextBtnTarget.setAttribute('disabled', 'disabled')
    for (const input of controller.formInputTargets) {
      input.addEventListener('input', () => {
        if (controller.formIsValid() === true) {
          controller.nextBtnTarget.removeAttribute('disabled')
        } else {
          controller.nextBtnTarget.setAttribute('disabled', 'disabled')
        }
      })
    }
  }

  formIsValid() {
    const controller = this
    let validForm = true
    for (const input of controller.formInputTargets) {
      if (input.value === '') {
        validForm = false
        break
      }
    }
    return validForm
  }
}