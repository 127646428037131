import { Controller } from 'stimulus'
import 'select2'
import bootbox from 'bootbox';

export default class extends Controller {
  static targets = [ 'rewardInput', 'rewardOptionContainer', 'rewardDetailOptionContainer' ]
  static values = {
    venueSlug: String,
    eventType: String
  }

  connect() {
    this.init_select2()
    this.bindingNextButton()
    this.bindingSelectBudgetType()
    this.bindingPromoSearch()
    this.urgencyCheck()
    this.bindingPhoneValidation()
    // disabled for phase one reward
    //this.bindingRewardRadio()
    this.isFastEnquiry()
    this.exitIntentPopUp()
    this.bindingEventTypeSelect()
  }
  exitIntentPopUp() {
    const sizes = {
      height: window.innerHeight
    }
    const cursor = {
      y: 0
    }
    window.addEventListener('mousemove', (event) => {
      cursor.y = -(event.clientY / sizes.height - 0.5)
      if (cursor.y === 0.5) {
        $('#exitIntentModal').modal('show')
      }
    })
  }
  isFastEnquiry() {
    if($("#enquiry_info").data('fast-enquiry')) {
      $("#next_button").trigger('click')
    }
  }

  init_select2(){
    $('[data-select]').select2()
  }

  bindingPhoneValidation(){
    $(".phone-number-field").on("keypress keyup blur",function (event) {
      $(this).val($(this).val().replace(/[^\d].+/, ""))
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault()
      }
    })
  }

  bindingNextButton(){
    const controller = this
    $('#next_button').on('click', function(e){
      var form = $('#new_event')[0]
      var form_valid = form.checkValidity()
      var country = $('#nav_venue_default').data('country')

      // dont use global version
      e.preventDefault()

      if (form_valid){
        $('#enquiry_info').hide()
        $('#create_enquiry').show()

        var event_first_name = $('#event_first_name').val()
        var event_last_name = $('#event_last_name').val()
        var event_contact_phone_country_code = $('#event_contact_phone_country_code').val()
        var event_contact_phone = $('#event_contact_phone').val()
        var event_contact_email = $('#event_contact_email').val()
        var event_name = $('#event_event_name').val()
        var event_start_date = $('#event_start_date').val()
        var event_start_time = $('#event_start_time').val()
        var event_start_duration = $('#event_duration').val()
        var event_event_attendance = $('#event_event_attendance').val()
        var event_type = $('#event_event_type').val()
        var budget_type = $('#event_budget_type').val()
        var budget_currency = $('#event_budget_currency').html()
        var estimation_budget = 0

        if (budget_type == "fix"){
          estimation_budget = budget_currency+$('#event_budget').val()
        }else if(budget_type == "flexible"){
          estimation_budget = budget_currency+$('#event_budget_from').val()+" - "+budget_currency+$('#event_budget_to').val()
        }

        // DATE
        var splitedDate = event_start_date.split("-")
        var objDate = new Date(splitedDate[2], splitedDate[1] - 1, splitedDate[0])
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        var fullDate = ''
        if (objDate !== 'Invalid Date' && !isNaN(objDate)) {
          var fullDate = objDate.getDate() + ' ' + months[objDate.getMonth()] + ' ' + objDate.getFullYear()
        }

        let coorporate_event = [
          "Business Breakfast", "Business Luncheon", "Business Dinner", "Business Reception", "Business Brunch",
          "Media Event", "Press Event", "Influencer Event",
          "Corporate Seminar", "Corporate Hackathon", "Product Launch", "Product Exhibition",
          "Company Dinner and Dance", "Company Cocktail Party", "Company Rooftop Party", "Company Christmas Party", "Office Party",
          "Client Meeting", "Board Meeting", "Conference", "Interview", "Meeting", "Sales Meeting",
          "Offsite Meeting", "Training", "Team Bonding"
        ]

        if (country == "sg" && $('#registration_detail').length && coorporate_event.includes(event_type)) {
          $.ajax({
            url : '/api/users/find_email?email=' + event_contact_email,
            dataType: 'json',
            success: function(response) {
              if (!response.found) {
                $('#registration_detail').show()
                $('#newuser_firstname').html(event_first_name)
                $('#newuser_lastname').html(event_last_name)
                $('#newuser_phone').html(event_contact_phone_country_code+event_contact_phone)
                $('#newuser_email').html(event_contact_email)
              }

              $('#summary_event_name').html('“'+event_name+'”')
              $('#summary_event_dates').html(fullDate + " / "+ event_start_time)
              $('#summary_event_duration').html(event_start_duration+" Hours")
              $('#summary_event_guests').html(event_event_attendance+" Pax")
              $('#summary_event_type').html(event_type)
              $('#summary_estimation_budget').html(estimation_budget)
              if ($('#event_room_id').is("select")){
                $('#summary_event_room_name').html($('#event_room_id option:selected').text())
              }

              $("html, body").stop().animate({ scrollTop:0 }, 500, 'swing')
            },
            error: function(response) {
            }
          })
        } else {
          $('#summary_event_name').html('“'+event_name+'”')
          $('#summary_event_dates').html(fullDate + " / "+ event_start_time)
          $('#summary_event_duration').html(event_start_duration+" Hours")
          $('#summary_event_guests').html(event_event_attendance+" Pax")
          $('#summary_event_type').html(event_type)
          $('#summary_estimation_budget').html(estimation_budget)
          if ($('#event_room_id').is("select")){
            $('#summary_event_room_name').html($('#event_room_id option:selected').text())
          }

          $("html, body").stop().animate({ scrollTop:0 }, 500, 'swing')
        }
      }else{
        form.reportValidity()
      }
    })

    $("#new_password").on("keyup",function(){
      $("#password").val($(this).val())
    })

    $(".toggle-password").on('click', function () {
      var inputPassword = $(this).next(".form-control--password");
      $(this).toggleClass('fa fa-eye fa fa-eye-slash')
      if (inputPassword.attr("type") === "password") {
        inputPassword.attr("type", "text");
      } else {
        inputPassword.attr("type", "password");
      }
    });

    $('.back_to_inquiry_info').on('click', function(e){
      $('#enquiry_info').show()
      $('#create_enquiry').hide()
    })

    $('#create_enquiry_button').on('click', function(e){
      // condition if has multiple reward to choose(phase one reward)
      // if ($('input[name="reward_radio"]').length > 0 && $('input[name="reward_radio"]:checked').length === 0) {
      //   $('#modalRewardChecked').modal('show');

      var form = $('#new_event')[0]

      // dont use global version
      var strong = new RegExp(
        "^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$"
      )
      var password_cond = ($('#password').length === 0) || ( ($('#password').val().length === 0) || ( strong.test($('#password').val()) && ($('#password').val() === $('#password_confirmation').val()) ) )
      e.preventDefault()

      if (password_cond){
        $('#create_enquiry_button').attr('disabled', true)
        $('#submit-booking').trigger('click')
        // TODO ensure if data valid before submit
        controller.trackFastEnquiry()
      }else{
        form.reportValidity()
        if ($('#new_password').length && $('#new_password').val().length) {
          var message = null;
          if (strong.test($('#new_password').val()) === false) {
            message = 'Password must contain at least 8 characters and at least 1 of each of the following: uppercase, lowercase, number and symbols (!@#$%^&*.,?)';
          }
          if ((message === null) && ($('#new_password').val() !== $('#new_password_confirmation').val())) {
            message = 'Password mismatch!'
          }
          if (message !== null) {
            bootbox.alert({ message: message,
                            centerVertical: true,
                            closeButton: false });
          }
        }
      }
    })
  }

  // gtm event track for fast enquiry
  trackFastEnquiry() {
    var submitBookingButton = document.getElementById('submit-booking')
    if (submitBookingButton.dataset.track !== 'track_fast_enquiry') { return }
    var venue_name = submitBookingButton.dataset.venue
    this.dataLayerEventPush('Click-Submit Enquiry-Summary Fast Enquiry', 'Click-Submit Enquiry-Summary Fast Enquiry', 'Venue-' + venue_name)
  }

  dataLayerEventPush(category, action, label) {
    if (dataLayer) {
      dataLayer.push({
        'event': 'eventTracking',
        'category': category,
        'action': action,
        'label': label
      });
    }
  }

  bindingSelectBudgetType(){
    $('#event_budget_type').on('change', function(e){
      var budget_type = $(this).val()
      $('#budget_fix').hide()
      $('#budget_flexible').hide()

      $('#budget_'+budget_type).show()
      if (budget_type == "fix"){
        $('#event_budget').attr('required', true)
        $('#event_budget').val($('#event_budget').data('old-value'))
        $('#event_budget').attr({'min': $('#event_budget').data('old-value')})

        $('#event_budget_from').val('')
        $('#event_budget_from').removeAttr('required')
        $('#event_budget_from').removeAttr('min')
        $('#event_budget_to').val('')
        $('#event_budget_to').removeAttr('required')
        $('#event_budget_to').removeAttr('min')
      }else if (budget_type == "flexible"){
        $('#event_budget').removeAttr('required')
        $('#event_budget').removeAttr('min')
        $('#event_budget').val('')

        $('#event_budget_from').attr('required', true)
        $('#event_budget_from').val($('#event_budget_from').data('old-value'))
        $('#event_budget_from').attr({ 'min': $('#event_budget_from').data('old-value') })
        $('#event_budget_to').attr('required', true)
        $('#event_budget_to').val($('#event_budget_to').data('old-value'))
        $('#event_budget_to').attr({ 'min': $('#event_budget_to').data('old-value') })
      }
    })
    $('#event_budget_type').trigger('change')
  }

  bindingPromoSearch(){
    $('#promo_code_search').on('click', function(e){
      e.preventDefault()
      var promo_code = $('#promo_code_input')
      var promo_data = $('#promo_id_data').data('promo')
      var promo_field = $('#event_promo_id')

      $('#promo_info_tnc').hide()
      $('#terms_and_conditions_text').html()
      if (promo_code.length > 0){
        var arr = $.grep(promo_data, function( n, i ) {
          return ( n['code'] == promo_code.val() )
        })

        $('#promo_code_icon').removeClass()
        $('#promo_input_wrapper').removeClass('available invalid')
        if(arr.length > 0){
          $('#promo_input_wrapper').addClass('available')
          $('#promo_code_icon').addClass('fa fa-check-square')
          promo_field.val(arr[0]['id'])
          $('#promo_info_text').html("Promo will be applied at Venue Owner's discretion")
          if (arr[0]['terms'] != ""){
            $('#promo_info_tnc').show()
            $("#terms_and_conditions_text").html(arr[0]['terms'].replace(/\n/g, "<br />"))
          }
        }else{
          $('#promo_input_wrapper').addClass('invalid')
          $('#promo_code_icon').addClass('fa fa-times-rectangle')
          $('#promo_info_text').html('Promo code is invalid')
          promo_field.val('')
        }
      }else{
        $('#promo_code_icon').removeClass()
        $('#promo_input_wrapper').removeClass('available invalid')
        $('#promo_info_text').html('')
      }
    })
  }

  urgencyCheck() {
    checkInput()

    // Datepicker
    var options = {
      singleDatePicker: true,
      timePicker: false,
      cancelButtonClasses: 'hidden',
      locale: {
        format: 'DD-MM-YYYY'
      }
    }
    if ($("#event_start_date").data('not-allow-back')) {
      options['minDate'] = new Date()
    }else{
      options['minDate'] = 0
    }
    $(".form-control--date-start").daterangepicker(options)

    $('[name="event[urgency]"]').on('click', function() {
      checkInput()
    })

    function checkInput () {
      if ($('[name="event[urgency]"]:checked').val() == 'Interested to do site visit (choose preferred date & time)') {
        $('#urgency_input_date_time').show()
        $('#event_urgency_date').attr('required', 'required')
        $('#event_urgency_time').attr('required', 'required')
      } else {
        $('#urgency_input_date_time').hide()
        $('#event_urgency_date').removeAttr('required')
        $('#event_urgency_time').removeAttr('required')
      }
    }
  }

  bindingRewardRadio() {
    // set event which rewards field value from reward radio button outside form
    $('input[name="reward_radio"]').on('change', function() {
      $('#event_which_rewards').val($(this).val());
    });
  }

  bindingEventTypeSelect() {
    let controller = this
    // update venue reward on event type changes
    $('#event_event_type').on('change', () => {
      controller.getVenueReward(controller.venueSlugValue, $('#event_event_type').val())
    })
    // update venue reward if event type value exist(fast enquiry and failed submit_new_deal render)
    if (controller.eventTypeValue) {
      controller.getVenueReward(controller.venueSlugValue, controller.eventTypeValue)
    }
  }

  getVenueReward(venue_id, event_type) {
    let controller = this
    $.ajax({
      url : '/find_reward?id=' + venue_id + '&event_type=' + event_type,
      dataType: 'json',
      success: function(response) {
        if (response.venue_rewards && response.venue_rewards.length > 0) {
          var html = '', html_detail = '';
          for (let i = 0; i < response.venue_rewards.length; i++) {
            html += '<div class="radio-wrapper">';
            html += '<label class="radio-label" for="reward-radio-' + i + '">';
            html += '<input type="radio" name="reward_radio" id="reward-radio-' + i + '" value="' + response.venue_rewards[i].venue_reward_id + '" class="custom-control-input radio-input">';
            // hide radio button circle since only one result expected(phase one reward)
            // html += '<div class="radio-circle">';
            // html += '<span class="inner-circle"></span>';
            // html += '</div>';
            html += '<p class="radio-title">' + response.venue_rewards[i].name + '</p>';
            html += '</label>';
            html += '</div>';

            html_detail += '<div class="reward-field">';
            html_detail += '<h4 class="title">' + response.venue_rewards[i].name + '</h4>';
            html_detail += '<p class="text">' + response.venue_rewards[i].description + '</p>';
            html_detail += '<p class="text text--valid-date">Valid date: ' + response.venue_rewards[i].valid_date + '</p>';
            html_detail += '</div>';
          }
          // show venue rewards elements(see rewards details, radio, how to redeem)
          $('.venue-rewards-elm').removeClass('hidden');
          $(controller.rewardOptionContainerTarget).html(html)
          $(controller.rewardDetailOptionContainerTarget).html(html_detail)
          // controller.bindingRewardRadio()
          // set rewards to first venue rewards result, assuming only one result(phase one reward)
          controller.rewardInputTarget.value = response.venue_rewards[0].venue_reward_id
        } else {
          // hide venue rewards elements(see rewards details, radio, how to redeem)
          $('.venue-rewards-elm').addClass('hidden');
        }
      },
      error: function(response) {
      }
    })
  }
}