import { Controller } from 'stimulus'
import moveable from '../../../library/moveable'
import infiniteScroll from '../../../library/infinite_scroll';
import copyTextInput from '../../../library/click-link';

export default class extends Controller {
  connect() {
    moveable();
    infiniteScroll('venues_wrapper', 'ipagination', 'Venues');
    this.shareLink();
  }

  copyLink (event) {
    let inputTarget = event.currentTarget.getAttribute('data-target-link')
    copyTextInput(inputTarget)
  }

  clickFiltering(event) {
    let target = event.currentTarget
    let targetSet = document.getElementById('search_status')
    console.log(target.getAttribute('value'))

    targetSet.value = target.getAttribute('value')
    this.submitForm()
  }

  submitForm() {
    document.getElementById('search_subpage').submit()
  }

  shareLink () {
    $(document).on('shown.bs.modal', '.modal-share-link', function () {
      setCopyLink($(this));
    });

    $(document).on('change', '.select-lead-source', function () {
      setCopyLink($(this).parents('.modal-share-link').first());
    });

    // $(document).on('keyup', '.back-link-lead-source', function () {
    //   setCopyLink($(this).parents('.modal-share-link').first());
    // });

    let timer = null;

    $(document).on('keyup', '.back-link-lead-source', function (event) {
      let current_target = event.currentTarget;

      clearTimeout(timer);
      timer = setTimeout(checkUrl(current_target), 1000)
    });


    function checkUrl(inpObj) {
      if (!inpObj.value.includes("https://") && !inpObj.value.includes("http://")) {
          inpObj.nextElementSibling.innerHTML = 'INVALID URL. Please include http:// or https:// address.'
      } else if (!inpObj.checkValidity()) {
        inpObj.nextElementSibling.innerHTML = inpObj.validationMessage
      } else {
        inpObj.nextElementSibling.innerHTML = ''
      }

      setCopyLink($(inpObj).parents('.modal-share-link').first());
    }

    function setCopyLink (wrapper) {
      let url = wrapper.find('.select-lead-source').first().data('base-url');
      let value = wrapper.find('.select-lead-source').first().val();
      let back_value = wrapper.find('.back-link-lead-source').first();
      let input = wrapper.find('.input-lead-source').first();

      url = url + '?customer_get_in_touch=' + value;

      if(back_value.val() && back_value[0].checkValidity()) {
        url = url + '&back_url=' + back_value.val()
      }

      input.val(url);
    }
  }

  populateTaskCompletionModal(e) {
    const target = e.currentTarget
    const controller = this
    const modal = document.querySelector('.task-30-day-modal')
    const json = JSON.parse(target.dataset.json)

    if (json.percentage == 100) {
      modal.querySelector('.task-incomplete-wrapper').classList.add('d-none')
      modal.querySelector('.task-complete-wrapper').classList.remove('d-none')
      modal.querySelector('.task-complete-claim-form').setAttribute('action', json.reward_link)
    } else {
      modal.querySelector('.task-complete-wrapper').classList.add('d-none')
      modal.querySelector('.task-incomplete-wrapper').classList.remove('d-none')
      modal.querySelector('.task-complete-claim-form').setAttribute('action', '')
    }

    modal.querySelector('.venue-photo').src = json.photo
    modal.querySelector('.venue-name').innerText = json.name
    modal.querySelector('.venue-address').innerText = json.address
    modal.querySelector('.venue-profile-link').href = json.edit
    modal.querySelector('.venue-calendar-link').href = json.calendar
    modal.querySelector('.venue-xero-link').href = json.xero

    modal.querySelector('.progress-bar').setAttribute('aria-valuenow', json.percentage)
    modal.querySelector('.progress-bar').style.width = `${json.percentage}%`
    modal.querySelector('.progress-wrapper__info span').innerText = `${json.percentage}% Completed`

    modal.querySelector('.venue-profile-label').innerText = `Complete venue profile (${json.tasks[0].items.filter((x) => x.done).length}/${json.tasks[0].items.length})`
    this.checkAndStrike(modal, 'venue-profile', json.tasks[0].items.filter((x) => x.done).length == json.tasks[0].items.length)

    modal.querySelector('.venue-profile-detail-label').innerText = json.tasks[0].items[0].name
    modal.querySelector('.venue-profile-location-label').innerText = json.tasks[0].items[1].name
    modal.querySelector('.venue-profile-contact-label').innerText = json.tasks[0].items[2].name
    modal.querySelector('.venue-profile-media-label').innerText = json.tasks[0].items[3].name
    this.checkAndStrike(modal, 'venue-profile-detail', json.tasks[0].items[0].done)
    this.checkAndStrike(modal, 'venue-profile-location', json.tasks[0].items[1].done)
    this.checkAndStrike(modal, 'venue-profile-contact', json.tasks[0].items[2].done)
    this.checkAndStrike(modal, 'venue-profile-media', json.tasks[0].items[3].done)

    modal.querySelector('.venue-calendar-label').innerText = json.tasks[1].items[0].name
    this.checkAndStrike(modal, 'venue-calendar', json.tasks[1].items[0].done)

    modal.querySelector('.venue-xero-label').innerText = json.tasks[2].items[0].name
    this.checkAndStrike(modal, 'venue-xero', json.tasks[2].items[0].done)
  }

  checkAndStrike(modal, elm, status) {
    modal.querySelector(`#${elm}-check`).checked = status
    if (status) {
      modal.querySelector(`.${elm}-label`).classList.add('strike')
      if (modal.querySelector(`.${elm}-link`)) {
        modal.querySelector(`.${elm}-link`).classList.add('d-none')
        modal.querySelector(`.${elm}-complete`).classList.remove('d-none')
      }
    } else {
      modal.querySelector(`.${elm}-label`).classList.remove('strike')
      if (modal.querySelector(`.${elm}-link`)) {
        modal.querySelector(`.${elm}-complete`).classList.add('d-none')
        modal.querySelector(`.${elm}-link`).classList.remove('d-none')
      }
    }
  }
}
