import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    country: String,
    event: String,
  }

  connect() {
    this.loadPayNowQr()
    this.copyClipboard()
    this.contactLink()
  }

  loadPayNowQr() {
    const controller = this
    if (document.querySelector('#qrcode')) {
      setTimeout(function() {
        fetch(`/${controller.countryValue}/users/events/${controller.eventValue}/paynow_options.json`)
        .then(function(response) {
          return response.json();
        }).then(function(data) {
          if (data.error) {
            return;
          }
          const qrstr = generatePayNowStr(data);
          new QRCode(document.getElementById("qrcode"), qrstr);
        });
      }, 1);
    }
  }

  fileChange(e) {
    const target = e.currentTarget
    $(target).parents('.custom-input-wrapper').find('.image-input-wrapper').addClass("uploaded");

    let limit_size = target.dataset.limitSize;
    let type = target.dataset.type;
    let limit = limit_size*1024*1024;
    let error_p = $(`.${type}-error`);

    if(target.files[0] === undefined) { return; }
    if(target.files[0] && target.files[0].size < limit) {
      error_p.addClass('hidden');
      $(`.${type}-form`).find('[type=submit]').removeAttr('disabled');
    } else {
      error_p.text("File is more than "+limit_size+"MB");
      error_p.removeClass('hidden');
      $(`.${type}-form`).find('[type=submit]').prop('disabled', true)
    }

    var pdf_file = target.files[0];
    if (pdf_file !== undefined) {
      var pdf_file_url = URL.createObjectURL(pdf_file);
      $(target).parents('.form-container').find('.file-preview').attr('href', pdf_file_url);
    }
  }

  // Copy clipboard function
  copyClipboard() {
    var copyText = document.getElementById("accountNumber");
    $(".btn-copy").on('click', function () {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    });
  }

  fileValidation(e) {
    const target = e.currentTarget
    let type = target.dataset.type
    let error_p = $(`.${type}-error`);
    if ($(target).parents('.form-container').find('.form-control--file').val().length === 0) {
      e.preventDefault()
      error_p.text("Please upload your payment proof");
      error_p.removeClass('hidden');
    } else {
      error_p.addClass('hidden');
    }
  }

  contactLink() {
    $(document).on('click', '[data-remote-href]', function() {
      var link = $(this).data('remote-href');
      var win = window.open(link, '_blank');
      win.focus();
    });

    $( "[data-remote-href]" ).contextmenu(function(e) {
      e.preventDefault();
    });
  }

  selectPayment(e) {
    const controller = this;
    const target = e.currentTarget

    $('.payment__type').addClass('hidden')
    $('.form-container').removeClass('active')

    if (!target.value) { return }

    $(`.payment__type--${target.value}`).removeClass('hidden');

    document.querySelector(`.form-container--${target.value}`).classList.add("active");
  }
}