import 'slick-carousel'

export const freeTrialSetup = controller => {
  Object.assign(controller, {
    initAfterSignUpModal() {
      if(controller.showAfterCampaignRegModalValue) {
        //controller.afterCampaignModalSlidesCount = $('.carrousel-content').children('.carrousel-item').length
        $(controller.afterCampaignRegModal).on('shown.bs.modal', function() {
          $('.carrousel-content').removeClass('hidden')
          $('.carrousel-content').slick({
            dots: true,
            infinite: false,
            prevArrow: $(".slider-prev"),
            nextArrow: $(".slider-next"),
            appendDots: $(".slider-dots")
          })
        })
        $('.carrousel-content').on('init', function(e, s, d) {
          $(".slider-prev").addClass('disabled')
        })
        $('.carrousel-content').on('afterChange', function(e, s, c) {
          if(c == 0) {
            $(".slider-prev").addClass('disabled')
          } else if(c == 6) {
            $(".slider-next").addClass('hidden disabled')
            $(".activate-next").removeClass('hidden')
          } else {
            $(".slider-prev").removeClass('disabled')
            $(".slider-next").removeClass('hidden disabled')
            $(".activate-next").addClass('hidden')
          }
        })
        $(controller.afterCampaignRegModal).modal('show')
      }
    }
  })
}