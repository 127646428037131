import { Controller } from 'stimulus'
import axios from '../../../vendor/axios';

export default class extends Controller { 
  connect() {
    this.bindingFastEnquiryButton()
  }

  bindingFastEnquiryButton() {
    document.getElementById('btn_fast_enquiry').addEventListener('click', function (e) {
      let eventId = this.dataset.event
      
      axios.get("/api/v1/venues/fast_enquiry", {
        params: {
          event_id: eventId
        }
      })
      .then(function(response) {
        if(response.status == '200') {
          window.location.href = response.data.url
        }
      })
    });
  }
}