import { Controller } from 'stimulus'
import 'select2'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination", "footer"]

  initialize() {
    this.footer = this.footerTarget;
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.init_select2()
    this.bindingFormSearchChanged()

    this.intersectionObserver.observe(this.footer)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.footer)
  }

  init_select2(){
    $('[data-select]').select2()

    $('input[name="search[service][]"]').remove();
  }

  bindingFormSearchChanged(){
    $('#searchFormVendors').on('change', 'select', function(){
      this.form.submit()
    });
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      // fetch new posts only when footer is getting into view port
      if (entry.intersectionRatio > 0){
        const url = this.paginationTarget.querySelector("a[rel='next']")?.href
        if (url) this.loadMore(url);
      }
    }, { threshold: 1 })
  }

  loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
      }
    });
  }
}