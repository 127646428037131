import { Controller } from 'stimulus'
import axios from 'axios';
import toastr from 'toastr';

export default class extends Controller {
  static targets = ['templatesList'];
  static values = {
    sendtestemailurl: String
  };

  initialize() {}
  connect() {
    const controller = this;
    this.templatesListTarget
        .querySelectorAll('.free-design-email--action')
        .forEach(function(anchor) {
          anchor.addEventListener('click', function(e) {
            controller.sendTestEmail(anchor, e);
          });
        });
    this.defaultEmailList = $('.emails-content').html()
    this.bindingSearch()
  }
  disconnect() {}

  bindingSearch() {
    let controller = this
    $("#search").on("click", function() {
      var value = $("#search_email").val().toLowerCase();
      $(".email-title").filter(function() {
        $(this).parents('.row').toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    })

    $("#sort_by").on("change", function() {
      var value = $(this).val();
      var dataSort = $('.emails-content').data('sort')

      if (value != dataSort) {
        $('.emails-content').data('sort', value)
        if (value == "newest" || value == "oldest") {
          var $emails = $('.emails-content')
          if (value == "newest") {
            $emails.html('').append(controller.defaultEmailList)
          }else {
            $emails.append($emails.find('.row').get().reverse())
          }
        } else if (value == "open" || value == "click") {
          $('.emails-content .row').sort(function(a, b) {
            return parseInt($(b).find("."+value+"-count").text()) - parseInt($(a).find("."+value+"-count").text());
          }).appendTo('.emails-content');
        }
      }
    })
  }

  sendTestEmail(anchor, event) {
    event.preventDefault();
    let url = this.sendtestemailurlValue;
    url = url.replace("\-\-ID\-\-", anchor.dataset.templateid);
    axios.post(url, {})
         .then(function(response) {
          toastr.success('Test email sent successfully')
         })
         .catch(function(error) {
          toastr.error('Test email failed to sent')
         });
  }
}