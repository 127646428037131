import Rails from '@rails/ujs'

export default class Notes {
  constructor(base_url) {
    this.base_url = base_url
  }

  call () {
    this.wrapperNotes.innerHTML = this.templateNotes.innerHTML
    this.getData()
  }

  getData() {
    let url = this.base_url + '/show_data?data_type=notes'

    Rails.ajax({
      type: "GET",
      url: url,
      success: this.successResponse.bind(this)
    })
  }

  successResponse(response) {
    response.notes.forEach((element) => {
      this.addItem(element)
    });
  }

  addItem(element) {
    this.wrapperTemplateNoteItem.insertAdjacentHTML( 'afterbegin', this.parseItem(element) );
  }

  parseItem(item) {
    _.templateSettings = {
      evaluate:    /\{\{(.+?)\}\}/g,
      interpolate: /\{\{=(.+?)\}\}/g,
      escape:      /\{\{-(.+?)\}\}/g
    }

    let compiled = _.template(this.templateNoteItem.innerHTML)

    return compiled(item)
  }

  submitNote (element) {
    let url = this.base_url + '/notes';

    Rails.ajax({
      type: 'POST',
      url: url,
      data: Rails.serializeElement(element),
      success: (response) => {
        this.addItem(response)
        document.getElementById('note_description').value = ''
      },
      error: (response) => {

      }
    })
  }

  get wrapperNotes() {
    return document.getElementById('wrapper-notes')
  }

  get templateNotes() {
    return document.getElementById('template-notes')
  }

  get templateNoteItem() {
    return document.getElementById('template-note-item')
  }

  get wrapperTemplateNoteItem () {
    return document.getElementById('template-note-item-wrapper')
  }
}