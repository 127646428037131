import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    canStripe: Boolean,
    canDirect: Boolean,
  }

  connect() {
    this.paymentSelector()
    this.directTransferFileChange()
    this.copyClipboard()
    this.directTransferValidation()
    this.directTransferPreview()
    this.contactLink()
    this.displayPaymentTypeInfo()
  }

  // Hide and show direct transfer form
  paymentSelector() {
    var select = document.querySelector("#selectPayment");
    var directTransferContainer = document.querySelector(
      ".form-container--direct-transfer"
    );
    var creditCardContainer = document.querySelector(
      ".form-container--credit-card"
    );
    var payNowContainer = document.querySelector(
      ".form-container--pay-now"
    );
    select.addEventListener("change", function () {
      if (this.value === "direct-transfer") {
        directTransferContainer.classList.add("active");
        creditCardContainer.classList.remove("active");
        payNowContainer.classList.remove("active");
      } else if (this.value === "paynow") {
        directTransferContainer.classList.remove("active");
        creditCardContainer.classList.remove("active");
        payNowContainer.classList.add("active");
      } else {
        directTransferContainer.classList.remove("active");
        creditCardContainer.classList.add("active");
        payNowContainer.classList.remove("active");
      }
    });
  }

  // upload file in payment
  directTransferFileChange() {
    $(".form-control--file").on("change", function () {
      $(".image-input-wrapper").addClass("uploaded");
    });
  }

  // Copy clipboard function
  copyClipboard() {
    var copyText = document.getElementById("accountNumber");
    $(".btn-copy").on('click', function () {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    });
  }

  // Check uploaded file size
  directTransferValidation() {
    $('.check-size-validation').each(function(index, element) {
      let limit_size = $(element).data('limit-size');
      let limit = limit_size*1024*1024;
      let error_p = $('.direct-transfer-error');

      $(element).on('change', function() {
        if(this.files[0] === undefined) { return; }
        if(this.files[0] && this.files[0].size < limit) {
          error_p.addClass('hidden');
          $('.direct-transfer-form').find('[type=submit]').removeAttr('disabled');
        } else {
          error_p.text("File is more than "+limit_size+"MB");
          error_p.removeClass('hidden');
          $('.direct-transfer-form').find('[type=submit]').prop('disabled', true)
        }
      });
    });
    $('.direct-transfer-form').find('[type=submit]').on('click', function() {
      let error_p = $('.direct-transfer-error');
      if ($('#uploadPaymentProof').val().length === 0) {
        error_p.text("Please upload your payment proof");
        error_p.removeClass('hidden');
      } else {
        error_p.addClass('hidden');
      }
    });

  }

  directTransferPreview() {
    $('#uploadPaymentProof').on('change', function() {
      var pdf_file = document.getElementById("uploadPaymentProof").files[0];
      if (pdf_file !== undefined) {
        var pdf_file_url = URL.createObjectURL(pdf_file);
        $('#upload-payment-proof-preview').attr('href', pdf_file_url);
      }
    });
  }

  contactLink() {
    $(document).on('click', '[data-remote-href]', function() {
      var link = $(this).data('remote-href');
      var win = window.open(link, '_blank');
      win.focus();
    });

    $( "[data-remote-href]" ).contextmenu(function(e) {
      e.preventDefault();
    });
  }

  displayPaymentTypeInfo() {
    const controller = this;
    // initial load
    if (this.canStripeValue) {
      $('.can-stripe').removeClass('hidden');
    } else if (this.canDirectValue) {
      $('.can-direct').removeClass('hidden');
    }
    // payment type select trigger
    var select = document.querySelector("#selectPayment");
    select.addEventListener("change", function () {
      if (this.value === "direct-transfer") {
        $('.can-stripe').addClass('hidden');
        if (controller.canDirectValue) { $('.can-direct').removeClass('hidden'); }
      } else if (this.value === "credit-card") {
        if (controller.canStripeValue) { $('.can-stripe').removeClass('hidden'); }
        $('.can-direct').addClass('hidden');
      }
    });
  }
}