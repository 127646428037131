$(document).on('show.bs.modal', '#upgradeModal', function(event) {
  let url;
  let payment_url;

  if (event.relatedTarget) {
    url = event.relatedTarget.getAttribute('data-url')
    payment_url = event.relatedTarget.getAttribute('data-payment-link')
  }

  let base_url = document.getElementById('upgradeModal').getAttribute('data-url')
  let target_button = document.querySelectorAll('.upgrade-account-manager');

  target_button.forEach(function(element) {
    if (url) {
      element.setAttribute('href', url)
    } else {
      element.setAttribute('href', base_url)
    }
  });

  if (payment_url) {
    document.getElementById("btnPayment").setAttribute('href', payment_url)
    document.getElementById("btnPayment").style.display = "block";
  }else {
    document.getElementById("btnPayment").setAttribute('href', '')
    document.getElementById("btnPayment").style.display = "none";
  }
});