import Cropper from 'cropperjs';

export default function cropper (element) {
  if (!element) {
    return;
  }

  let uploadButton = document.getElementById(element.getAttribute('data-upload-button'))
  let inputTargetX = document.getElementById(element.getAttribute('data-input-target-x'))
  let inputTargetY = document.getElementById(element.getAttribute('data-input-target-y'))
  let inputTargetW = document.getElementById(element.getAttribute('data-input-target-w'))
  let inputTargetH = document.getElementById(element.getAttribute('data-input-target-h'))
  let photoPreview = document.getElementById(element.getAttribute('data-photo-preview'))
  let editButton = document.getElementById(element.getAttribute('data-edit-button'))
  let mediaInputWrapper = document.getElementById(element.getAttribute('data-media-input-wrapper'))
  let mediaInputPreviewWrapper = document.getElementById(element.getAttribute('data-media-input-preview-wrapper'))
  let mediaInputPreviewContent = document.getElementById(element.getAttribute('data-media-input-preview-content'))
  let photoImageResult = document.getElementById(element.getAttribute('data-photo-image-result'))
  let savingCrop = document.getElementById(element.getAttribute('data-saving-crop'))
  let cropperElement;

  element.addEventListener('change', () => {
    insertPhotoModal();
    showHidePreview('show');
  });

  uploadButton.addEventListener('click', () => {
    element.click();
  });

  document.querySelectorAll('.close-media-input-preview').forEach((element) => {
    element.addEventListener('click', () => {
      showHidePreview('hide');
    })
  });

  editButton.addEventListener('click', (e) => {
    e.preventDefault();
    element.click();
  });

  function insertPhotoModal() {
    var preview = mediaInputPreviewContent;
    var file = element.files[0];
    var reader = new FileReader();

    reader.onload = function(){
      preview.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  function showHidePreview(state) {
    if(state === undefined) {
      state = 'hide';
    }

    if(state === 'hide') {
      mediaInputPreviewWrapper.style.display = 'none';
      mediaInputWrapper.style.display = 'block';
    } else {
      $(mediaInputPreviewWrapper).show('fast', function() {
        setTimeout(buildCropper(), 1000);
      });
      $(mediaInputWrapper).hide()
    }
  }

  let value_x, value_y, value_h, value_w;

  function buildCropper() {
    if(mediaInputPreviewContent.classList.contains('cropper-hidden')) {
      cropperElement.destroy();
    }

    cropperElement = new Cropper(mediaInputPreviewContent, {
      aspectRatio: 1,
      zoomable: false,
      scalable: false,
      rotatable: false,
      crop(event) {
        value_x = event.detail.x;
        value_y = event.detail.y;
        value_h = event.detail.height;
        value_w = event.detail.width;
      }
    })
  }

  savingCrop.addEventListener('click', () => {
    inputTargetX.value = value_x;
    inputTargetY.value = value_y;
    inputTargetW.value = value_w;
    inputTargetH.value = value_h;
    photoPreview.setAttribute('src', cropperElement.getCroppedCanvas().toDataURL());
    showHidePreview('hide');
  })
}