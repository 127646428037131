const Rails = require('@rails/ujs')
import bootbox from 'bootbox';

// Overrides default rails confirmation with bootstrap 4 confirm dialog
Rails.confirm = function (message, element) {
  // hiding all modal
  $('.modal').modal('hide');
  var confirmLabel = "Yes";
  var cancelLabel = "No";
  var cancelClass = 'btn-orange text-white';
  var confirmClass = 'btn-shadow';

  if (("cancelButtonLabel" in element.dataset)) {
    cancelLabel = element.dataset['cancelButtonLabel'];
  }
  if (("confirmButtonLabel" in element.dataset)) {
    confirmLabel = element.dataset['confirmButtonLabel'];
  }
  if (("cancelButtonClass" in element.dataset)) {
    cancelClass = element.dataset['cancelButtonClass'];
  }
  if (("confirmButtonClass" in element.dataset)) {
    confirmClass = element.dataset['confirmButtonClass'];
  }

  bootbox.confirm({
    message: `<h5 class='modal-title text-center'>${message}</h5>`,
    closeButton: false,
    onEscape: true,
    backdrop: true,
    swapButtonOrder: element.dataset['swapButtonOrder'],
    buttons: {
      cancel: {
        label: cancelLabel,
        className: cancelClass
      },
      confirm: {
        label: confirmLabel,
        className: confirmClass
      },
    },
    callback: function (result) {
      if (result) {
        let old = Rails.confirm
        Rails.confirm = function () { return true }
        $(element).get(0).click()
        Rails.confirm = old
      }
    }
  });
  return false;
}