import { Controller } from 'stimulus'
import cropper from '../../../library/cropper'

export default class extends Controller {
  static targets = ['venue']

  connect() {
    //
    this.tags = []
    this.customTags()
    this.appendExistingVenue()
    //
    this.initCropper()
  }

  appendExistingVenue() {
    for (let venueDOM of this.venueTargets) {
      if (venueDOM.dataset['id']) {
        this.tags.push(venueDOM.dataset['id'])
      }
    }
  }

  customTags() {
    let classDefine = this
    let input = document.querySelector('.input-tags')

    if (input !== null) {
      input.addEventListener('change', function (event) {
        var id = $(this).val()

        if (id !== "") {
          if (classDefine.tags.indexOf(id) == -1){
            classDefine.tags.push(id)

            classDefine.updateTags()
          }
          $(this).val($(":first-child", this).val())
        }
      })
    }
  }

  updateTags() {
    let classDefine = this
    classDefine.clearTags()

    classDefine.tags.slice().reverse().forEach(tag => {
      document.querySelector('.tag-container').prepend(classDefine.createTag(tag))
      // Add tag to select option
      var optionExists = ($("#venue_group_venue_ids option[value='" + tag + "']").length > 0)
      if (optionExists) {
        $("#venue_group_venue_ids option[value='" + tag + "']").prop("selected", true)
      }
    })
  }

  createTag(tag) {
    // Preview Badge
    var value = $("#venue_group_venue_ids option[value='" + tag + "']").html()
    const div = document.createElement('div')
    div.classList.add('badge', 'venues')
    div.innerHTML = value
    const i = document.createElement('i')
    i.classList.add('fa', 'fa-times-circle')
    i.setAttribute('id', tag)
    i.setAttribute('data-id', tag)
    i.setAttribute('data-value', value)
    i.setAttribute('data-action', 'click->users--venue-groups--form#removeTags')
    i.setAttribute('data-users--venue-groups--form-target', 'venue')
    div.append(i)

    return div
  }

  removeTags(event) {
    const buttonX = event.currentTarget
    const id = buttonX.dataset.id
    const index = this.tags.indexOf(id)

    // Remove tag inside tag container
    this.tags.splice(index, 1)

    // Unselected option from select
    $("#venue_group_venue_ids option[value='" + id + "']").prop("selected", false)

    this.updateTags()
  }

  clearTags() {
    document.querySelector('.tag-container')
      .querySelectorAll('.venues')
      .forEach(tagElement => tagElement.remove())
  }

  initCropper() {
    let input = document.getElementById('logo-input')
    cropper(input)
  }
}
