import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'formInput',
    'formButton'
  ];

  connect() {
    this.togglePasswordDisplay()
    // Password strength checker
    $(".form-control--password-strength").on('keyup', function (e) {
      var result = document.querySelector("#progress"),
        pass = this.value,
        strong = new RegExp(
          "^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
          "g"
        ),
        medium = new RegExp(
          "^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
          "g"
        ),
        normal = new RegExp("(?=.{6,}).*", "g"),
        meter;
      if (pass.length) {
        if (strong.test(pass)) {
          meter = "strong";
        } else if (medium.test(pass)) {
          meter = "medium";
        } else if (normal.test(pass)) {
          meter = "normal";
        } else {
          meter = "bad";
        }
      } else {
        meter = "";
      }
      result.className = meter;
      return true;
    });
    this.formInputEvent()
  }

  // toggle password auth
  togglePasswordDisplay() {
    $(".toggle-password").on('click', function () {
      var inputPassword = $(this).next(".form-control--password");
      $(this).toggleClass('fa fa-eye fa fa-eye-slash')
      if (inputPassword.attr("type") === "password") {
        inputPassword.attr("type", "text");
      } else {
        inputPassword.attr("type", "password");
      }
    });
  }

  get authForm() { return document.querySelector('.auth__form') }

  get captchaController() {
    return this.application.getControllerForElementAndIdentifier(this.mainWrapper, 'users--captcha')
  }

  get mainWrapper() { return document.getElementById('users_registration_new_main') }

  submitRegistration(e) {
    e.preventDefault()

    const controller = this
    if (controller.formIsValid() === true) {
      this.captchaController.requireCaptcha(
        function() {
          controller.captchaController.dismiss()
          if(controller.copyCaptchaSession()) {
            setTimeout(function() {
              controller.authForm.submit()
            }, 0)
          } else {
            bootbox.alert('Unable to recognize captcha session, please reload page and retry registration')
          }
        },
        function() {
          controller.captchaController.resetCaptcha()
        }
      )
    }
  }

  formInputEvent() {
    const controller = this
    if (!controller.hasFormButtonTarget) { return; }
    controller.formButtonTarget.setAttribute('disabled', 'disabled')
    for (const input of controller.formInputTargets) {
      input.addEventListener('input', () => {
        if (controller.formIsValid() === true) {
          controller.formButtonTarget.removeAttribute('disabled')
        } else {
          controller.formButtonTarget.setAttribute('disabled', 'disabled')
        }
      })
    }
  }

  formIsValid() {
    const controller = this
    let validForm = true
    for (const input of controller.formInputTargets) {
      if (input.value === '') {
        validForm = false
        break
      }
    }
    return validForm
  }

  copyCaptchaSession() {
    if(typeof(this.sidField) == 'undefined') { return false }

    this.sidField.removeAttribute('disabled')
    this.sidField.value = this.authForm.dataset.session

    return true
  }

  get sidField() { return document.getElementById('captcha_sid') }
}