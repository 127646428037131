import 'daterangepicker'
import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.usersPromotionsIndex = this
    this.loadDatePicker()
  }

  putNewWrapperForm(html) {
    if (document.querySelector('#modalNewPromo .modal-content').innerHTML.length > 0) {
      document.querySelector('#modalNewPromo .modal-content').innerHTML = ''
    }

    document.querySelector('#modalNewPromo .modal-content').innerHTML = html
    this.loadDatePicker()
  }

  putEditWrapperForm(html) {
    if (document.getElementById('modalEditPromo')) {
      document.getElementById('modalEditPromo').remove()
      document.querySelector('.modal-backdrop.fade.show').remove()
    }

    let editWrapper = document.getElementById('modalEditWrapper');
    editWrapper.innerHTML = html
    this.loadDatePicker()

    $('#modalEditPromo').modal('show').on('hidden.bs.modal', function () {
      document.getElementById('modalEditPromo').remove()
    })
  }

  loadDatePicker () {
    $('.promotion-valid-until').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      drops: 'up',
      minYear: 2000,
      locale: {
        format: 'DD-MM-YYYY'
      }
    })
  }
}
