import { Controller } from "@stimulus/core"
import lottie from 'lottie-web'

export default class extends Controller {
  static targets = [
    'wheelDefault',
    'wheelGame',
    'spinBtn',
    'formInput',
    'claimBtn',
    'greetingText',
    'popupSponsorLogo',
    'popupPrizeName',
    'popupSponsorName',
    'popupLogoWrapper'
  ]

  static values = {
    setToWin: String,
    isPlaying: Boolean,
    country: String,
    eventId: String,
    playToken: String
  }

  initialize() {
    this.wheelDefaultAnim = null
    this.wheelGameAnim = null
  }

  connect() {
    const ctrl = this
    if(this.wheelDefaultAnim == null)
      this.wheelDefaultAnim = this.loadDefaultAnimation()
    if(this.wheelGameAnim == null) {
      this.wheelGameAnim = this.loadGameAnimation()
      this.wheelGameAnim.addEventListener('DOMLoaded', function(e) {
        ctrl.onGameAnimationLoaded(ctrl, e)
      })
      this.wheelGameAnim.addEventListener('complete', function(e) {
        ctrl.onGameAnimationStopped()
      })
    }
    this.initPlayWheelFrom()
  }

  disconnect() {
    this.isPlayingValue = false
    this.wheelDefaultAnim.destroy()
    this.wheelGameAnim.destroy()
    this.spinBtnTarget.setAttribute('disabled', 'disabled')
  }

  playGame() {
    let controller = this
    var wheelWrapper = document.querySelector('.wheel--player-wrapper')
    if(this.wheelGameAnim == null) return false

    controller.spinBtnTarget.setAttribute('disabled', 'disabled')

    $.ajax({
      url: '/' + controller.countryValue + '/users/events/' + controller.eventIdValue + '/spin_wheel?play_token=' + controller.playTokenValue,
      type: 'json',
      method: 'get',
      success: function(data) {
        // TODO remove log
        console.log('playGame', data)
        if (data.success) {
          // GA tracking
          controller.dataLayerEventPush('Click-Spin It-Venuerific Wheel of Joy', 'Click-Spin It-Venuerific Wheel of Joy', 'Click-Spin It-Venuerific Wheel of Joy');
          if (data.prize && (data.prize.prize_type === 'digital')) {
            for (const input of controller.formInputTargets) {
              $(input).hide()
            }
          }
          // display data.prize
          if (controller.hasGreetingTextTarget) {
            // set popup content if hasGreetingTextTarget(winning)
            controller.popupSponsorLogoTarget.src = data.prize.image_url
            controller.popupSponsorLogoTarget.alt = data.prize.image_alt
            controller.popupSponsorLogoTarget.title = data.prize.image_title
            controller.popupPrizeNameTarget.textContent = data.prize.name
            controller.popupSponsorNameTarget.textContent = data.prize.sponsor_name
            if (data.prize.win_greeting) {
              // override winning text
              controller.greetingTextTarget.innerHTML = data.prize.win_greeting
            }
          } else {
            $(controller.popupLogoWrapperTarget).hide()
          }

          controller.isPlayingValue = true
          controller.wheelDefaultAnim.destroy() // destroy anim instance since it's not use anymore
          controller.wheelGameAnim.play()
          wheelWrapper.classList.add("active");
          controller.createUserPrize()
        } else {
          controller.spinBtnTarget.removeAttribute('disabled')
        }
      }
    });
  }

  onGameAnimationStopped() {
    this.isPlayingValue = false
    // show popup
    $(this.gameModalElement).modal()
    // hit claim prize endpoint
    // set played attribute on events
  }

  loadDefaultAnimation() {
    return lottie.loadAnimation({
      container: this.wheelDefaultTarget,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animation/christmas/wheel_default.json'
    });
  }

  loadGameAnimation() {
    let json = this.isSetToWin ? 'wheel_win.json' : 'wheel_lose.json'

    return lottie.loadAnimation({
      container: this.wheelGameTarget,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/animation/christmas/' + json
    });
  }

  get isSetToWin() { return this.setToWinValue == 'yes' }

  onGameAnimationLoaded(ctrl, evt) {
    ctrl.spinBtnTarget.removeAttribute('disabled')
  }

  get gameModalElement() { return document.getElementById('wheelGameModal') }

  initPlayWheelFrom() {
    $('#play-wheel-form').on('submit', function(e) {
      e.preventDefault();
    });
  }

  createUserPrize() {
    let controller = this

    let token = function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    };

    $.ajax({
      beforeSend: token,
      dataType: 'json',
      url: '/' + controller.countryValue + '/users/events/' + controller.eventIdValue + '/christmas_user_prizes?play_token=' + controller.playTokenValue,
      method: 'POST',
    }).done(function(data) {
      // TODO remove log
      console.log('createUserPrize', data);
      if (data.success) {
      } else {
      }
    });
  }

  claimPrize() {
    let controller = this

    controller.claimBtnTarget.setAttribute('disabled', 'disabled')

    let token = function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    };

    $.ajax({
      beforeSend: token,
      dataType: 'json',
      url: '/' + controller.countryValue + '/users/events/' + controller.eventIdValue + '/claim_christmas_user_prize?play_token=' + controller.playTokenValue,
      method: 'PUT',
      data: $('#play-wheel-form').serialize()
    }).done(function(data) {
      // TODO remove log
      console.log('claimPrize', data);
      if (data.success) {
        //$('#play-wheel-form').remove()
        $(controller.gameModalElement).modal('hide') // hide popup after claimed
      } else {
        controller.claimBtnTarget.removeAttribute('disabled')
      }
    });
  }

  dataLayerEventPush(category, action, label) {
    if (dataLayer) {
      dataLayer.push({
        'event': 'eventTracking',
        'category': category,
        'action': action,
        'label': label
      });
    }
  }
}