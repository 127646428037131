import 'daterangepicker'
import axios from 'axios'
import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'nodata', 'data', 'listDone', 'listOngoing', 'filter', 'search' ]

  connect() {
    window.eventTodosIndex = this
    this.initForm();
  }

  filterData() {
    let controller = this
    let text_filter = new RegExp(this.filterTarget.value.toLowerCase(), 'g');

    document.querySelectorAll('.list-group-item .title').forEach(function(element, _index){
      let display = 'none';

      if((controller.filterTarget.value.trim().length == 0) || (element.innerHTML.toLowerCase().match(text_filter) != null)) {
        display = 'block';
      }

      element.parentNode.parentNode.parentNode.parentNode.style.display = display;
    })
  }

  initForm() {
    let controller = this;

    document.querySelectorAll('.start-end-date').forEach(function(element, _index) {
      controller.initDateTimePicker(element);
    })
  }

  initDateTimePicker(element) {
    let now = new Date();
    let oneYr = new Date();
    oneYr.setFullYear(now.getFullYear() + 1);

    $(element).daterangepicker({
      timePicker: true,
      showDropdowns: true,
      timePicker24Hour: true,
      autoUpdateInput: true,
      minDate: now,
      maxDate: oneYr,
      cancelButtonClasses: 'hidden',
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    });

    function changeStartDate(date) {
      $(element).datepicker('option', 'minDate', date);
    }

    $(element).on('apply.daterangepicker', function(ev, picker) {
      changeStartDate(picker.endDate.add(1, 'day').toDate())
    });

    $(element).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
  }

  checkDataExist() {
    if (document.querySelectorAll('.list-group-item').length > 0) {
      this.showHideElementData('data')
    } else {
      this.showHideElementData('nodata')
    }
  }

  showHideElementData(show = 'data') {
    if(show == 'data') {
      this.nodataTarget.style.display = 'none';
      this.dataTarget.style.display = null;
      this.searchTarget.style.display = null;
    } else {
      this.nodataTarget.style.display = null;
      this.dataTarget.style.display = 'none';
      this.searchTarget.style.display = 'none';
    }
  }

  editData(data) {
    let url = data.currentTarget.dataset.url;
    Rails.ajax({
      type: "get",
      url: url
    })
  }

  moveData(data) {
    let element = document.getElementById('wrapper-' + data.target.id);
    let url = data.target.getAttribute('data-url')
    let list;

    if(data.target.checked) {
      this.listDoneTarget.appendChild(element)
      list = this.listDoneTarget;
    } else {
      this.listOngoingTarget.appendChild(element)
      list = this.listOngoingTarget;
    }

    this.sortingList(list)

    axios.put(url + '?is_done=' + data.target.checked)
          .then(function (response) {
            element.querySelector('.editby span').innerHTML = response.data.last_update;
          })
  }

  sortingList(list) {
    [...list.children]
      .sort((a,b)=>a.getAttribute('data-start-date') > b.getAttribute('data-start-date') ? 1 : -1)
      .forEach(node=>list.appendChild(node))
  }

  cleanupNewForm() {
    let newTodo = document.getElementById('new_event_todo');
    newTodo.querySelectorAll('input, textarea').forEach(function(element, _index){
      element.value = ''
    });
    $('#todoCreateModal').modal('hide');
  }
}